import {
  Div,
  Divider,
  Image,
  Loader,
  Text,
} from '../../components/_ui';
import React, { SyntheticEvent, useEffect } from 'react';
import { FamilyProfileBrief } from '../../components/_common';
import {
  Maybe,
  SuggestedConnectionObjectType,
} from '../../__generated__/graphql';
// import { useConnectionApi } from "../../hooks/api.old";
import { useStore } from '../../hooks/useStore';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { useParams } from 'react-router-dom';

const ViewConnectionProfile: React.FC<{
  handlePass?: (
    family: Maybe<SuggestedConnectionObjectType>,
  ) => void;
  handleConnect?: (
    family: Maybe<SuggestedConnectionObjectType>,
  ) => void;
}> = ({ handleConnect, handlePass }) => {
  const params = useParams();
  const { actions, state } = useStore();
  const systemMessages = state.app.systemMessages;

  const family = state.connection.connectionProfile.data;

  useEffect(() => {
    if (
      !state.connection.connectionProfile.data ||
      isEmpty(state.connection.connectionProfile.data)
    ) {
      //fetch connection data for both new and existing connections if not exists.
      actions.connection.query.connectionProfile({
        familyUuid: params.profileId,
      });
    }
  }, []);

  if (state.connection.connectionProfile.error) {
    return (
      <Text>{state.connection.connectionProfile.error}</Text>
    );
  }

  const isLoading =
    state.connection.connectionProfile.loading || !family;

  const handleConnectPassThrough = (e: SyntheticEvent) => {
    // this should not be undefined because the loader takes care of it.
    handleConnect!(
      family as Maybe<SuggestedConnectionObjectType>,
    );
  };
  const handlePassPassThrough = (e: SyntheticEvent) => {
    // this will not be undefined because the loader takes care of it
    handlePass!(family as Maybe<SuggestedConnectionObjectType>);
  };

  const communityCount =
    family?.match_criteria.same_community_count;
  const connectionCount =
    family?.match_criteria.same_connection_count;
  const schoolCount = family?.match_criteria.same_school_count;
  const interestCount =
    family?.match_criteria.same_interest_count;

  return (
    <Div className={'card fullWidth'}>
      {isLoading ? (
        <Loader position={'center'} color={'grey400'} />
      ) : (
        <>
          <FamilyProfileBrief family={family} />
          <Div className={'familyProfileDetail'}>
            <Image
              src={
                family.family.secondary_profile_image_url ||
                '/defaultImages/defaultFamilyProfileImage.jpg'
              }
              alt={`${family.family.name} secondary photo`}
              height={250}
              width={398}
              withBorderRadius
            />
            <Divider color={'transparent'} />
            <Divider color={'transparent'} />
            <Div>
              <Text variant={'h6'} color={'grey800'}>
                Special Considerations
              </Text>
              {communityCount && (
                <Div className={'profileSpecialConsiderations'}>
                  {communityCount}{' '}
                  {
                    systemMessages?.general.connection
                      .specialConsiderations.communityCount
                  }
                </Div>
              )}
              {connectionCount && (
                <Div className={'profileSpecialConsiderations'}>
                  {connectionCount}{' '}
                  {
                    systemMessages?.general.connection
                      .specialConsiderations.connectionCount
                  }
                </Div>
              )}
              {schoolCount && (
                <Div className={'profileSpecialConsiderations'}>
                  {schoolCount}{' '}
                  {
                    systemMessages?.general.connection
                      .specialConsiderations.schoolCount
                  }
                </Div>
              )}
              {interestCount && (
                <Div className={'profileSpecialConsiderations'}>
                  {interestCount}{' '}
                  {
                    systemMessages?.general.connection
                      .specialConsiderations.interestCount
                  }
                </Div>
              )}
            </Div>
          </Div>
          {/*{!(family.status === 'connected' || false) &&*/}
          {/*  handleConnect &&*/}
          {/*  handlePass && (*/}
          {/*    <ConnectWithFamilyRow*/}
          {/*      handleConnect={handleConnectPassThrough}*/}
          {/*      handlePass={handlePassPassThrough}*/}
          {/*    />*/}
          {/*  )}*/}
        </>
      )}
    </Div>
  );
};

export default ViewConnectionProfile;
