import { useStore } from '../../hooks/useStore';
import { useParams } from 'react-router-dom';
import { CreateEditPostInputs } from '../../forms';
import { useEffect } from 'react';
import get from '../../utils/utilityFunctions/get';
import routes from '../../navigation/routes';
import { Div } from '../../components/_ui';
import PostForm from '../../forms/feed/PostForm';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const EditCommunityPost = () => {
  const { state, actions } = useStore();
  const params = useParams();
  const navigate = useCustomNavigate();

  const onSubmit = (args: CreateEditPostInputs) => {
    actions.post.mutation.updatePost({
      postUuid: args.uuid as string,
      draft: args.draft,
      content: args.description,
      title: args.title,
    });
  };

  useEffect(() => {
    if (
      !state.post.createPost.data &&
      get(state, 'post.createPost.data.uuid', null) !==
        params.postId
    ) {
      // fetch post to view.
      if (params.postId) {
        actions.post.query.viewPost({
          postUuid: params.postId,
        });
      } else {
        actions.app.setErrorMessage(
          state.app.systemMessages?.errors.community
            .notAvailable ||
            'The community is not available, please try again',
        );
        navigate(routes.communities);
      }
    }
  }, []);

  return (
    <Div className={'fullWidth card'}>
      <PostForm
        onSubmit={onSubmit}
        loading={state.post.updatePost.loading || false}
        error={state.post.updatePost.error}
        existingPost={
          state.post.updatePost.data ||
          state.post.createPost.data ||
          state.post.viewPost.data
        }
      />
    </Div>
  );
};

export default EditCommunityPost;
