import { AppStoreState } from '../types/reducers';
import { ConnectionReducerActions } from '../types/reducers/connection';

const connectionReducer = (
  state: AppStoreState,
  action: ConnectionReducerActions,
) => {
  switch (action.type) {
    case 'connectionByFamily':
      state.connection.connectionByFamily = action.payload;
      return state;
    case 'connectionRequestsByFamily':
      state.connection.connectionRequestsByFamily =
        action.payload;
      return state;
    case 'connectionInvitesByFamily':
      state.connection.connectionInvitesByFamily =
        action.payload;
      return state;
    case 'meetFamilyCards':
      state.connection.meetFamilyCards = action.payload;
      return state;
    case 'connectionProfile':
      state.connection.connectionProfile = action.payload;
      return state;
    case 'setViewFamilyUUID':
      state.connection.viewFamilyUUID = action.payload.uuid;
      return state;
    case 'approveConnection':
      state.connection.approveConnection = action.payload;
      if (
        action.payload.data?.uuid &&
        state.connection.connectionInvitesByFamily.data
      ) {
        state.connection.connectionInvitesByFamily.data =
          state.connection.connectionInvitesByFamily.data.filter(
            (connection) =>
              connection?.uuid !== action.payload.data?.uuid,
          );
        // can set something here to show a toast or something saying connected. confetti perhaps?
        state.app.messages.successMessage = `Connected!`;
      }
      return state;
    case 'ignoreConnection':
      state.connection.ignoreConnection = action.payload;
      if (
        action.payload.data?.success &&
        state.connection.connectionInvitesByFamily.data
      ) {
        state.connection.connectionInvitesByFamily.data =
          state.connection.connectionInvitesByFamily.data.filter(
            (connection) =>
              connection?.uuid !== action.payload.data?.uuid,
          );
      }
      return state;
    case 'revokeConnectionInvite':
      state.connection.revokeConnectionInvite = action.payload;
      if (
        action.payload.data?.success &&
        state.connection.connectionRequestsByFamily.data
      ) {
        state.connection.connectionRequestsByFamily.data =
          state.connection.connectionRequestsByFamily.data.filter(
            (connection) =>
              connection?.uuid !== action.payload.data?.uuid,
          );
      }
      return state;
    case 'removeActiveConnection':
      state.connection.removeActiveConnection = action.payload;
      return state;
    case 'createConnection':
      state.connection.createConnection = action.payload;
      return state;
    default:
      return state;
  }
};

export default connectionReducer;
