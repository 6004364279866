import {
  InputMaybe,
  Scalars,
  School,
} from '../../__generated__/graphql';
import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import get from '../../utils/utilityFunctions/get';
import { useStore } from '../../hooks/useStore';
import {
  Controller,
  FieldNamesMarkedBoolean,
  useForm,
} from 'react-hook-form';
import {
  Button,
  Div,
  Divider,
  FlexRow,
  Form,
  Input,
  Loader,
  Select,
  Text,
  TextArea,
} from '../../components/_ui';
import { SelectOptionsProps } from '../../components/_ui/Select';
import { isAlphanumeric } from '../../utils/stringUtils';
import { ManagePermissions } from '../../components/profile';
import routes from '../../navigation/routes';
import {
  ChooseCommunities,
  ChooseInterests,
} from '../../components/_common/profile';
import RenderSchoolSelect from '../../components/_common/common/RenderSchoolSelect';
import { getFormDateFromUnix } from '../../utils/dateUtils';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import ManageImageUpload from '../../components/_common/ManageImageUpload';

export type ProfileFormInputs = {
  family_member: {
    gender: SelectOptionsProps;
    is_admin: boolean;
    first_name: string;
    last_name: string;
    bio: string;
    profile_image: InputMaybe<Scalars['Upload']['input']>;
    date_of_birth: string | number;
    relation_type: SelectOptionsProps;
    school: SelectOptionsProps;
  };
  profile: {
    nickname: string;
    password: string;
    email: string;
  };
  permissions: {
    chat: {
      receive: boolean;
      send: boolean;
    };
    community: {
      comment: boolean;
      create: boolean;
      invite: boolean;
      join: boolean;
      post: boolean;
      like: boolean;
    };
    post: {
      comment: boolean;
      create: boolean;
      like: boolean;
    };
  };
};

const ProfileForm = ({
  onSubmit,
  loading,
  error,
  canDelete = false,
}: {
  onSubmit: (
    dirtyFields: Partial<
      Readonly<FieldNamesMarkedBoolean<ProfileFormInputs>>
    >,
  ) => (args: ProfileFormInputs) => void;
  loading: boolean;
  error?: string;
  canDelete?: boolean;
}) => {
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();
  const [tempFile, setTempFile] = useState<
    string | ArrayBuffer | null
  >(null);

  const {
    common: { gender, schoolByUUID, school },
    family: { familyRelationType },
    profile: { selectedProfile },
  } = state;

  const myUUID = selectedProfile?.profile.uuid;

  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<ProfileFormInputs>({
    defaultValues: {
      permissions: {
        chat: {
          receive: false,
          send: false,
        },
        community: {
          comment: false,
          create: false,
          invite: false,
          join: false,
          post: false,
          like: false,
        },
        post: {
          comment: false,
          create: false,
          like: false,
        },
      },
    },
  });
  const { errors, dirtyFields } = formState;
  const existingProfileImage = watch(
    'family_member.profile_image',
  );

  useEffect(() => {
    // reset chosen interests and chosen communities component state on mount
    actions.profile.resetCommunities();
    const communities =
      state.profile.selectedProfile?.my_communities || [];
    if (!isEmpty(communities)) {
      communities.map((comm) =>
        // @ts-ignore
        actions.profile.addCommunity(comm),
      );
    }
    actions.profile.resetInterests();
    const interests =
      state.profile.selectedProfile?.my_interests || [];
    if (!isEmpty(interests)) {
      interests.map((interest) =>
        // @ts-ignore
        actions.profile.addInterest(interest),
      );
    }
    actions.community.query.suggestedCommunitiesForNewFamilyMember();
    if (!selectedProfile) {
      setValue(
        'family_member.last_name',
        state.profile.familyProfile.data?.family?.name || '',
      );
    }
    if (selectedProfile?.family_member.school_uuid) {
      actions.common.query.schoolByUUID({
        schoolUuid: selectedProfile?.family_member.school_uuid,
      });
    }
  }, []);

  useEffect(() => {
    if (
      gender.data &&
      familyRelationType.data &&
      selectedProfile
    ) {
      // set form with default values;
      const prof = selectedProfile.profile;
      const fm = selectedProfile.family_member;
      const isMyProfile = myUUID === prof.uuid;
      const { permissions = {} } =
        (isMyProfile
          ? state.family.myPermissions.data
          : state.family.familyMemberPermissions.data) || {};
      if (fm.profile_image_url) {
        setTempFile(fm.profile_image_url);
      }
      reset({
        profile: {
          nickname: prof.nickname,
          email: prof.email,
          password: '*****',
        },
        family_member: {
          gender: gender.data.find(
            (i) => i?.uuid === fm.gender_uuid,
          ),
          is_admin: fm.is_admin,
          first_name: fm.first_name,
          last_name: fm.last_name,
          bio: fm.bio,
          profile_image: fm.profile_image_url,
          date_of_birth: fm.date_of_birth
            ? getFormDateFromUnix(fm.date_of_birth)
            : '',
          relation_type: familyRelationType.data.find(
            (i) => i?.uuid === fm.relation_type_uuid,
          ),
          // school: schoolByUUID.data,
        },
        permissions: {
          chat: {
            receive: get(permissions, 'chat.receive', false),
            send: get(permissions, 'chat.send', false),
          },
          community: {
            comment: get(
              permissions,
              'community.comment',
              false,
            ),
            create: get(permissions, 'community.create', false),
            invite: get(permissions, 'community.invite', false),
            join: get(permissions, 'community.join', false),
            post: get(permissions, 'community.post', false),
            like: get(permissions, 'community.like', false),
          },
          post: {
            comment: get(permissions, 'post.comment', false),
            create: get(permissions, 'post.create', false),
            like: get(permissions, 'post.like', false),
          },
        },
      } as unknown as ProfileFormInputs);
    }
    if (schoolByUUID.data) {
      setValue('family_member.school', {
        value: schoolByUUID.data.uuid,
        name: schoolByUUID.data.name,
      });
    }
  }, [
    gender.data,
    schoolByUUID.data,
    familyRelationType.data,
    selectedProfile,
  ]);

  const formRequirements = useMemo(() => {
    const isRequired = selectedProfile == null;
    return {
      family_member: {
        first_name: {
          required: isRequired
            ? 'First name is required'
            : false,
        },
        last_name: {
          required: isRequired,
          'Last name is required': 'false',
        },
        profile_image: {
          required: isRequired
            ? 'profile image is required'
            : false,
        },
        date_of_birth: {
          required: isRequired
            ? 'date of birth is required'
            : false,
        },
        relation_type: {
          required: isRequired
            ? 'relation to you is required'
            : false,
        },
        school: {
          required: false,
        },
        bio: {
          required: false,
          validate: (e: string) => e.length <= 255,
        },
      },
      profile: {
        nickname: {
          required: isRequired ? 'Nickname is required' : false,
          validate: (e: string) => {
            if (e.indexOf(' ') > -1)
              return 'Spaces are not allowed';
            if (!isAlphanumeric(e)) {
              return 'Only A-Z, a-z, & 0-9 are acceptable characters';
            }
            return true;
          },
        },
        password: {
          required: isRequired ? 'password is required' : false,
        },
      },
    };
  }, [selectedProfile]);

  if (loading) {
    return <Loader position={'center'} />;
  }

  const searchForSchools = (searchTerm: string) => {
    actions.common.query.school({
      phrase: searchTerm,
      limit: state.common.school.limit || 50,
      offset: 0,
    });
  };

  const frtSelectOptions: SelectOptionsProps[] = (
    familyRelationType.data || []
  ).map((frt) => ({
    value: frt?.uuid || '',
    name: frt?.name || '',
  }));

  const schoolSelectOptions: SelectOptionsProps[] = (
    school.data || []
  ).map((s, i) => ({
    value: s?.uuid || '',
    name: (
      <RenderSchoolSelect
        {...(s as School)}
        key={`${s?.uuid}-${i}`}
      />
    ),
  }));

  const genderSelectOptions: SelectOptionsProps[] = (
    gender.data || []
  ).map((g) => ({
    value: g?.uuid || '',
    name: g?.name || '',
  }));

  const handleDeleteProfile = (e: SyntheticEvent) => {
    e.stopPropagation();
    actions.app.setModal({
      open: true,
      title: 'Delete Profile',
      type: 'delete-profile',
      handleSuccess: () => {
        actions.profile.mutation.deleteAccount();
      },
    });
    e.preventDefault();
  };

  return (
    <Form
      className={'editProfileForm'}
      onSubmit={handleSubmit(onSubmit(dirtyFields))}
    >
      {error && (
        <Text variant={'h4'} color={'error'}>
          {error}
        </Text>
      )}
      <Div className={'editProfileImageContainer'}>
        <ManageImageUpload
          control={control}
          name={'family_member.profile_image'}
          error={
            errors.family_member?.profile_image
              ?.message as string
          }
          existingImage={existingProfileImage}
        />
      </Div>
      <Text variant={'h5'} color={'grey800'}>
        Update Login Information
      </Text>
      <FlexRow column>
        <Input
          type={'text'}
          label={'nickname'}
          error={errors.profile?.nickname?.message}
          {...register(
            'profile.nickname',
            formRequirements.profile.nickname,
          )}
        />
        <Input
          type={'email'}
          label={'email'}
          error={errors.profile?.email?.message}
          {...register('profile.email')}
        />
        <Text variant={'span'} color={'grey400'}>
          Email is not required except on admin profiles and must
          be unique.
        </Text>
        <Input
          type={'password'}
          label={'password'}
          error={errors.profile?.password?.message}
          {...register(
            'profile.password',
            formRequirements.profile.password,
          )}
        />
      </FlexRow>
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <Text variant={'h5'} color={'grey800'}>
        Update Profile Information
      </Text>
      <FlexRow column>
        <Input
          type={'text'}
          label={'first name'}
          error={errors.family_member?.first_name?.message}
          {...register(
            'family_member.first_name',
            formRequirements.family_member.first_name,
          )}
        />
        <Input
          type={'text'}
          label={'last name'}
          error={errors.family_member?.last_name?.message}
          {...register(
            'family_member.last_name',
            formRequirements.family_member.last_name,
          )}
        />
        <TextArea
          textSize={'sm'}
          label={'biography'}
          maxLength={255}
          error={errors.family_member?.bio?.message}
          {...register(
            'family_member.bio',
            formRequirements.family_member.bio,
          )}
        />
        <Input
          type={'date'}
          label={'date of birth'}
          error={errors.family_member?.date_of_birth?.message}
          {...register(
            'family_member.date_of_birth',
            formRequirements.family_member.date_of_birth,
          )}
        />
        <Controller
          name={'family_member.gender'}
          control={control}
          render={({ field }) => (
            <Select
              label={'gender'}
              error={errors.family_member?.gender?.message}
              {...register('family_member.gender')}
              onChange={(option) => {
                field.onChange(option);
              }}
              value={field.value?.name}
              options={genderSelectOptions}
            />
          )}
        />
        <Controller
          name={'family_member.relation_type'}
          control={control}
          render={({ field }) => (
            <Select
              label={'relation'}
              error={
                errors.family_member?.relation_type?.message
              }
              {...register(
                'family_member.relation_type',
                formRequirements.family_member.relation_type,
              )}
              onChange={(option) => {
                field.onChange(option);
              }}
              value={field.value?.name as string}
              options={frtSelectOptions}
            />
          )}
        />
        <Controller
          name={'family_member.school'}
          control={control}
          render={({ field }) => (
            <Select
              label={"child's school"}
              error={errors.family_member?.school?.message}
              {...register(
                'family_member.school',
                formRequirements.family_member.school,
              )}
              onChange={(option) => {
                field.onChange(option);
              }}
              value={field.value?.name as string}
              options={schoolSelectOptions}
              searchable
              actionOnSearchComplete={searchForSchools}
              loading={state.common.school.loading}
            />
          )}
        />
      </FlexRow>
      {/*end family member attributes*/}
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <ChooseInterests />
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <ChooseCommunities />
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <ManagePermissions<ProfileFormInputs>
        canMakeAdmin={
          myUUID !== selectedProfile?.family_member.uuid
        }
        control={control}
      />
      {canDelete && (
        <Button onClick={handleDeleteProfile}>
          Delete Profile
        </Button>
      )}
      <FlexRow className="pinnedBottom" spreadX centerY>
        <Button
          onClick={() => navigate(routes.profile)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button type={'submit'} disabled={loading}>
          Save
        </Button>
      </FlexRow>
    </Form>
  );
};

export default ProfileForm;
