import React from 'react';
import '../../styles/components/_ui/list.scss';

interface ListProps {
  children: React.ReactNode;
  className?: string;
  ordered?: boolean;
}
const List: React.FC<ListProps> = ({
  children,
  className,
  ordered = false,
}) => {
  if (ordered)
    return (
      <ol className={`list ordered ${className}`}>{children}</ol>
    );
  return <ul className={`list ${className}`}>{children}</ul>;
};

export default List;
