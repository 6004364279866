import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  UnblockUserMutation,
  UnblockUserMutationVariables,
} from '../../../__generated__/graphql';
import useBlockedUsersUUIDS from './useBlockedUsersUUIDS';
import useBlockedUsersProfiles from './useBlockedUsersProfiles';

const useUnblockUser = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'unblockUser';
  const refetchBlockedUserUuids = useBlockedUsersUUIDS(
    state,
    dispatch,
  );
  const refetchBlockedUserProfiles = useBlockedUsersProfiles(
    state,
    dispatch,
  );

  const handleOnCompleted = (data: UnblockUserMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.unblockUser,
      },
    });
    refetchBlockedUserProfiles();
    refetchBlockedUserUuids();
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [unblockUser] = useMutation(
    api.mutation.common.UNBLOCK_USER,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: UnblockUserMutationVariables) => {
    if (!state.common.unblockUser.loading) {
      unblockUser({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useUnblockUser;
