import { gql } from '../../../__generated__';

const GENERATE_IMAGE = gql(`
  mutation GenerateImage($description: String!) {
    common {
      generateImage(description: $description) {
        imageBase64
      }
    }
  }
`);

const REPORT_USER = gql(`
  mutation ReportUser(
    $profileUuid: String!
    $reportUserTypeUuid: String!
    $reportUserSubtypeUuid: String!
    $type: ReportUserEnum!
    $referenceUuid: String!
  ) {
    common {
      reportUser(
        profile_uuid: $profileUuid
        report_user_type_uuid: $reportUserTypeUuid
        report_user_subtype_uuid: $reportUserSubtypeUuid
        type: $type
        reference_uuid: $referenceUuid
      ) {
        success
        uuid
      }
    }
  }
`);

const BLOCK_USER = gql(`
  mutation BlockUser(
    $profileUuid: String!
    $blockReportUserTypeUuid: String!
    $blockReportUserSubtypeUuid: String!
    $type: ReportUserEnum!
    $referenceUuid: String
  ) {
    common {
      blockUser(
        profile_uuid: $profileUuid
        block_report_user_type_uuid: $blockReportUserTypeUuid
        block_report_user_subtype_uuid: $blockReportUserSubtypeUuid
        type: $type
        reference_uuid: $referenceUuid
      ) {
        success
        uuid
      }
    }
  }
`);

const UNBLOCK_USER = gql(`
  mutation UnblockUser($profileUuid: String!) {
    common {
      unblockUser(profile_uuid: $profileUuid) {
        success
        uuid
      }
    }
  }
`);

const common = {
  GENERATE_IMAGE,
  REPORT_USER,
  BLOCK_USER,
  UNBLOCK_USER,
};
export default common;
