import { Button, FlexRow, Pill, Text } from "../../_ui";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { SyntheticEvent } from "react";
import { useStore } from "../../../hooks/useStore";
import { Maybe, Tag } from "../../../__generated__/graphql";

const ChooseInterests = () => {
  const { state, actions } = useStore();

  const removeInterest = (interest: Maybe<Tag>) => (e: SyntheticEvent) => {
    if (!interest) return null;
    e.stopPropagation();
    actions.profile.removeInterest(interest);
  };

  return (
    <FlexRow column>
      <Text variant={"h4"} color={"grey800"}>
        Choose Suggested Interests
      </Text>
      <FlexRow spreadX={false} wrap>
        {state.profile.chosenInterests.map((interest) => (
          <Pill
            onClick={removeInterest(interest)}
            title={interest?.name}
            key={interest?.uuid}
            variant={"outlined"}
            color={"primary"}
            iconEnd={faClose}
            imageStart={<Text>{interest?.tag_icon}</Text>}
          />
        ))}
      </FlexRow>
      <Button
        variant={"outlined"}
        size={"md"}
        color={"primary"}
        type={"button"}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          e.preventDefault();
          actions.app.setModal({
            open: true,
            title: "Choose Interests",
            type: "choose-interests",
          });
        }}
      >
        Edit Interests
      </Button>
    </FlexRow>
  );
};

export default ChooseInterests;
