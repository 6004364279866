import useLogin from './api/useLogin';
import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useCheckIfOnboarded from './api/useCheckIfOnboarded';
import useCreateNewProfile from './api/useCreateNewProfile';
import useLogout from './api/useLogout';
import useFamilyProfile from './api/useFamilyProfile';
import useFamilyMemberProfile from './api/useFamilyMemberProfile';
import useCheckIfVerified from './api/useCheckIfVerified';
import {
  CommunityObjectType,
  FamilyMemberProfileObjectType,
  FamilyProfileObjectType,
  Tag,
} from '../../__generated__/graphql';
import useUpdateProfile from './api/useUpdateProfile';
import { ProfileStoreActions } from '../../types/reducers/profile';
import useDeleteAccount from './api/useDeleteAccount';
import useViewPublicProfile from './api/useViewPublicProfile';

const useProfileActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): ProfileStoreActions => {
  return {
    setCurrentProfile: (
      profile?:
        | FamilyProfileObjectType
        | FamilyMemberProfileObjectType
        | undefined,
    ) =>
      dispatch({
        parent: 'profile',
        type: 'setCurrentProfile',
        payload: profile,
      }),
    setEditFamily: (editFamily: boolean) =>
      dispatch({
        parent: 'profile',
        type: 'setEditFamily',
        payload: editFamily,
      }),
    setFamilyMemberOrFamilyUUID: (uuid: string) =>
      dispatch({
        parent: 'profile',
        type: 'setFamilyMemberOrFamilyUUID',
        payload: uuid,
      }),
    resetInterests: () =>
      dispatch({
        parent: 'profile',
        type: 'resetInterests',
      }),
    addInterest: (interest: Tag | Tag[]) =>
      dispatch({
        parent: 'profile',
        type: 'addInterest',
        payload: interest,
      }),
    removeInterest: (interest: Tag) =>
      dispatch({
        parent: 'profile',
        type: 'removeInterest',
        payload: interest,
      }),
    resetCommunities: () =>
      dispatch({
        parent: 'profile',
        type: 'resetCommunities',
      }),
    addCommunity: (community: CommunityObjectType) =>
      dispatch({
        parent: 'profile',
        type: 'addCommunity',
        payload: community,
      }),
    removeCommunity: (community: CommunityObjectType) =>
      dispatch({
        parent: 'profile',
        type: 'removeCommunity',
        payload: community,
      }),
    query: {
      checkIfOnboarded: useCheckIfOnboarded(state, dispatch),
      familyProfile: useFamilyProfile(state, dispatch),
      familyMemberProfile: useFamilyMemberProfile(
        state,
        dispatch,
      ),
      checkIfVerified: useCheckIfVerified(state, dispatch),
      viewPublicProfile: useViewPublicProfile(state, dispatch),
    },
    mutation: {
      login: useLogin(state, dispatch),
      createNewProfile: useCreateNewProfile(state, dispatch),
      updateProfile: useUpdateProfile(state, dispatch),
      logout: useLogout(state, dispatch),
      deleteAccount: useDeleteAccount(state, dispatch),
    },
  };
};

export default useProfileActions;
