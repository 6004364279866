import { useStore } from './useStore';
import { ReportUserEnum } from '../__generated__/graphql';
import { modalEnumKeys } from '../types/reducers/app';
import { capitalizeChar0 } from '../utils/stringUtils';
import routes from '../navigation/routes';
import useCustomNavigate from './useCustomNavigate';
import { SyntheticEvent } from 'react';

export type ReportType = 'block' | 'report';

export const useReportUser = () => {
  const { actions, state } = useStore();
  const navigate = useCustomNavigate();

  return (
    profileUUID: string,
    type: ReportUserEnum,
    reportType: ReportType,
    referenceUUID: string,
  ) => {
    actions.app.setUserToReport({
      profileUUID,
      type,
      referenceUUID,
    });
    actions.app.setModal({
      open: true,
      successButtonText: reportType,
      cancelButtonText: 'cancel',
      title: `${capitalizeChar0(reportType)} User`,
      type: modalEnumKeys.reportUser,
      handleSuccess: (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const {
          profileUUID,
          type,
          reportTypeUUID,
          reportSubTypeUUID,
          referenceUUID,
        } = state.app.userToReport;
        if (
          !profileUUID ||
          !type ||
          !reportTypeUUID ||
          !reportSubTypeUUID ||
          !referenceUUID
        ) {
          actions.app.setErrorMessage(
            `You must select a reason to ${reportType} someone`,
          );
          return;
        }
        // slight delay to not remove the check when fading out.
        setTimeout(() => {
          actions.app.setUserToReport(null);
        }, 1000);
        if (reportType === 'report') {
          actions.common.mutation.reportUser({
            profileUuid: profileUUID,
            reportUserSubtypeUuid: reportSubTypeUUID,
            reportUserTypeUuid: reportTypeUUID,
            referenceUuid: referenceUUID,
            type,
          });
        }
        if (reportType === 'block') {
          actions.common.mutation.blockUser({
            profileUuid: profileUUID,
            blockReportUserSubtypeUuid: reportSubTypeUUID,
            blockReportUserTypeUuid: reportTypeUUID,
            referenceUuid: referenceUUID,
            type,
          });
          const route =
            routes[
              state.app.prevPages[state.app.prevPages.length - 1]
            ];
          navigate(route);
          actions.app.navigateBack(route.name);
        }
      },
      handleClose: () => {
        actions.app.setUserToReport(null);
      },
    });
  };
};

export default useReportUser;
