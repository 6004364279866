import family from './family';
import profile from './profile';
import post from './post';
import community from './community';
import connection from './connection';
import notification from './notification';
import common from './common';
import chat from './chat';

const query = {
  family,
  profile,
  post,
  community,
  connection,
  notification,
  common,
  chat,
};
export default query;
