import { Div, FlexRow, Text } from '../../_ui';
import React, { SyntheticEvent } from 'react';
import {
  CommunityObjectType,
  FamilyMemberPartialObjectType,
  Maybe,
  ProfileObjectType,
  Scalars,
} from '../../../__generated__/graphql';
import Avatar from '../Avatar';
import JoinCommunityButton from './JoinCommunityButton';
import { GeneralPostHeader } from './index';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import routes from '../../../navigation/routes';

const MediaPostHeader: React.FC<{
  community?: Maybe<CommunityObjectType>;
  profile?: Maybe<ProfileObjectType>;
  posterName?: Maybe<Scalars['String']['output']> | undefined;
  posterAvatarUrl?:
    | Maybe<Scalars['String']['output']>
    | undefined;
  canJoin?: boolean;
  isMember?: boolean;
  familyMember?:
    | Maybe<FamilyMemberPartialObjectType>
    | undefined;
}> = ({
  community,
  profile,
  posterName,
  posterAvatarUrl,
  canJoin,
  isMember,
  familyMember,
}) => {
  const navigate = useCustomNavigate();
  const handleNavigateToProfile = (e: SyntheticEvent) => {
    if (familyMember?.uuid) {
      e.stopPropagation();
      navigate(routes.viewPublicProfile, [familyMember.uuid]);
    }
  };

  if (community) {
    return (
      <Div>
        <FlexRow inline>
          <Div>
            <Text variant={'span'}>
              You might like {community.name}
            </Text>
            <FlexRow center onClick={handleNavigateToProfile}>
              <Avatar url={posterAvatarUrl} />
              <FlexRow column inline>
                <Text variant={'span'} color={'grey800'}>
                  {posterName}
                </Text>
                <Text variant={'span'} color={'grey200'}>
                  {community.name}
                </Text>
              </FlexRow>
            </FlexRow>
          </Div>
          {!isMember && (
            <JoinCommunityButton
              canJoin={canJoin || false}
              communityUUID={community.uuid}
            />
          )}
        </FlexRow>
      </Div>
    );
  }

  if (profile) {
    return <Div>Profile Header</Div>;
  }

  return (
    <GeneralPostHeader
      title={`${posterName}`}
      familyMember={familyMember}
    />
  );
};

export default MediaPostHeader;
