import { SyntheticEvent, useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import { Div, Loader } from '../../components/_ui';
import { CommunityCard } from '../../components/communities';
import { CommunityObjectType } from '../../__generated__/graphql';
import routes from '../../navigation/routes';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const ViewAllCommunities = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();

  useEffect(() => {
    actions.community.query.allCommunities({});
  }, []);

  const isLoading = state.community.allCommunities.loading;

  const handleNavigateCommunity =
    (id: string) => (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate(routes.community, [id]);
    };

  if (isLoading) {
    return <Loader position={'center'} color={'white'} />;
  }

  return (
    <Div className={'communitiesContainer'}>
      {state.community.allCommunities.data?.map((community) => (
        <CommunityCard
          {...(community as CommunityObjectType)}
          onClick={handleNavigateCommunity}
          isMember={!!community?.is_member}
        />
      ))}
    </Div>
  );
};

export default ViewAllCommunities;
