import { FlexRow, Loader, Pill, Text } from "../_ui";
import { useStore } from "../../hooks/useStore";
import { SyntheticEvent } from "react";
import { Maybe, Tag } from "../../__generated__/graphql";

const ChooseInterests = () => {
  const { state, actions } = useStore();

  const chosenTagIds = (state.profile.chosenInterests || []).map(
    (t) => t?.uuid,
  );

  const handleManageTag = (tag: Maybe<Tag>) => (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!tag) {
      return null;
    }
    if (chosenTagIds.indexOf(tag?.uuid) > -1) {
      actions.profile.removeInterest(tag);
    } else {
      actions.profile.addInterest(tag);
    }
  };

  return (
    <FlexRow wrap spreadX={false}>
      {state.common.tag.loading && <Loader position={"center"} />}
      {(state.common.tag.data || []).map((tag) => (
        <Pill
          onClick={handleManageTag(tag)}
          title={tag?.name}
          key={tag?.uuid}
          variant={"outlined"}
          color={"primary"}
          active={chosenTagIds.indexOf(tag?.uuid) > -1}
          imageStart={<Text>{tag?.tag_icon}</Text>}
        />
      ))}
    </FlexRow>
  );
};

export default ChooseInterests;
