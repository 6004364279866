import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import {
  CommentCommentsQuery,
  CommentCommentsQueryVariables,
} from '../../../__generated__/graphql';

const useCommentComments = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'post';
  const type = 'commentComments';

  const handleOnCompleted = (data: CommentCommentsQuery) => {
    const response = data.post.commentComments;
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        // @ts-ignore
        data: response.data || [],
        offset: response.offset,
        limit: response.limit,
        phrase: response.phrase,
        total: response.total,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [commentComments] = useLazyQuery(
    api.query.post.COMMENT_COMMENTS,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: CommentCommentsQueryVariables, path: string) => {
    if (!state.post.commentComments.loading) {
      commentComments({
        variables: {
          commentUuid: args.commentUuid,
          refine: args.refine,
        },
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
        path: path,
      },
    });
  };
};

export default useCommentComments;
