import {
  AppStoreActions,
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import systemMessages from '../../a11y';
import storage from '../../utils/storage';
import isEmpty from '../../utils/utilityFunctions/isEmpty';

const useInitializeApplication = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  return async (actions: AppStoreActions) => {
    // set up system messages
    systemMessages().then((data) => {
      dispatch({
        parent: 'app',
        type: 'getSystemMessages',
        payload: data,
      });
    });

    storage.getFromStorage('themeMode').then((data) => {
      if (!data) {
        storage.saveToStorage('themeMode', 'light');
      }
      if (data && data === 'dark') {
        dispatch({
          parent: 'app',
          type: 'toggleTheme',
        });
      }
    });

    const token = await storage.getFromStorage('token');

    // if not onboarded, check if onboarded
    if (
      (!state.profile.checkIfOnboarded.data ||
        isEmpty(state.profile.checkIfOnboarded)) &&
      token
    ) {
      actions.profile.query.checkIfOnboarded({});
    }

    const checkStorageOrFetchForCommon = async (key: string) => {
      // manually overwrite because this will always be accurate,
      // @ts-ignore
      if (!state.common[key] || isEmpty(state.common[key])) {
        // @ts-ignore
        const items = await storage.getFromStorage(key);
        if (items) {
          dispatch({
            parent: 'common',
            // @ts-ignore
            type: key,
            payload: { data: items },
          });
        } else if (token) {
          // @ts-ignore
          actions.common.query[key]({});
        }
      }
    };

    await checkStorageOrFetchForCommon('gender');
    await checkStorageOrFetchForCommon('city');
    await checkStorageOrFetchForCommon('country');
    await checkStorageOrFetchForCommon('language');
    await checkStorageOrFetchForCommon('timezone');
    await checkStorageOrFetchForCommon('state');
    await checkStorageOrFetchForCommon('likeType');
    await checkStorageOrFetchForCommon('reportTypes');

    if (
      (!state.common.tag.data ||
        isEmpty(state.common.tag.data)) &&
      token
    ) {
      actions.common.query.tag({
        limit: 50,
        // offset at 17 because the first 17 are religious and idk, seemed off-putting having 17 religions as the first tags you can choose...
        offset: 17,
      });
    }

    if (
      (!state.common.school.data ||
        isEmpty(state.common.school.data)) &&
      token
    ) {
      actions.common.query.school({
        limit: 50,
        offset: 0,
      });
    }

    if (token) {
      actions.common.query.blockedUsersUUIDS();
    }

    // get family relation type infos for app wide usage
    if (
      !state.family.familyRelationType.data ||
      isEmpty(state.family.familyRelationType.data)
    ) {
      const familyRelationType = await storage.getFromStorage(
        'familyRelationType',
      );
      if (familyRelationType) {
        dispatch({
          parent: 'family',
          type: 'familyRelationType',
          payload: {
            data: familyRelationType,
          },
        });
      } else if (token) {
        actions.family.query.familyRelationType({});
      }
    }

    if (
      (!state.profile.familyProfile ||
        isEmpty(state.profile.familyProfile)) &&
      token
    ) {
      actions.profile.query.familyProfile({});
    }

    if (
      (!state.family.myPermissions ||
        isEmpty(state.family.myPermissions)) &&
      token
    ) {
      actions.family.query.myPermissions();
    }
  };
};

export default useInitializeApplication;
