import { useStore } from '../../hooks/useStore';
import ProfileForm, {
  ProfileFormInputs,
} from '../../forms/profile/ProfileForm';
import { CreateFamilyMemberMutationVariables } from '../../__generated__/graphql';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import get from '../../utils/utilityFunctions/get';

const AddFamilyMember = () => {
  const { state, actions } = useStore();

  const onSubmit = () => (formValues: ProfileFormInputs) => {
    const baseCreateFields: Partial<CreateFamilyMemberMutationVariables> =
      {
        profile: {
          nickname: formValues.profile.nickname,
          password: formValues.profile.password,
          email: isEmpty(formValues.profile.email)
            ? null
            : formValues.profile.email,
        },
        familyMember: {
          bio: formValues.family_member.bio,
          date_of_birth: formValues.family_member
            .date_of_birth as string,
          first_name: formValues.family_member.first_name,
          last_name: formValues.family_member.last_name,
          gender_uuid: get(
            formValues,
            'family_member.gender.value',
            null,
          ),
          relation_type_uuid: get(
            formValues,
            'family_member.relation_type.value',
            null,
          ),
          profile_image: get(
            formValues,
            'family_member.profile_image[0]',
            null,
          ),
          school_uuid: get(
            formValues,
            'family_member.school.value',
            null,
          ),
        },
        permissions: formValues.permissions,
        interestUuids: state.profile.chosenInterests
          .map((i) => i && i.uuid)
          .filter((i) => i != null),
        communityUuids: state.profile.chosenCommunities
          .map((c) => c && c.uuid)
          .filter((c) => c != null),
      };

    actions.family.mutation.createFamilyMember(
      baseCreateFields as CreateFamilyMemberMutationVariables,
    );
  };

  return (
    <ProfileForm
      onSubmit={onSubmit}
      loading={!!state.family.createFamilyMember.loading}
      error={state.family.createFamilyMember.error}
    />
  );
};

export default AddFamilyMember;
