import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  InviteManyConnectionsToCommunityMutation,
  InviteManyConnectionsToCommunityMutationVariables,
} from '../../../__generated__/graphql';
import useConnectionByFamily from '../../connection/api/useConnectionByFamily';

const useInviteManyConnectionsToCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'inviteManyConnectionsToCommunity';
  const refetchConnectionByFamily = useConnectionByFamily(
    state,
    dispatch,
  );

  const handleOnCompleted = (
    data: InviteManyConnectionsToCommunityMutation,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.inviteManyConnectionsToCommunity,
      },
    });
    refetchConnectionByFamily({});
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [inviteManyConnectionsToCommunity] = useMutation(
    api.mutation.community.INVITE_MANY_CONNECTIONS_TO_COMMUNITY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createCommunity',
        },
      },
    },
  );

  return (
    args: InviteManyConnectionsToCommunityMutationVariables,
  ) => {
    if (
      !state.community.inviteManyConnectionsToCommunity.loading
    ) {
      inviteManyConnectionsToCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useInviteManyConnectionsToCommunity;
