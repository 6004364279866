import {
  CommunityObjectType,
  CreateNewProfileObjectType,
  LoginType,
} from '../__generated__/graphql';
// TODO port this to capacitor storage whenever we hook up to native
// https://capacitorjs.com/docs/guides/storage

type StorageTypes =
  | 'token'
  | 'user_uuid'
  | 'last_login'
  | 'family_uuid'
  | 'family_member_uuid'
  | 'profile_completeness'
  | 'language'
  | 'likeType'
  | 'chosenLanguage'
  | 'tag'
  | 'city'
  | 'gender'
  | 'country'
  | 'timezone'
  | 'state'
  | 'familyRelationType'
  | 'school'
  | 'recentCommunities'
  | 'reportTypes'
  | 'themeMode';
const getFromStorage = async (key: StorageTypes) => {
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

const getManyFromStorage = async (keys: StorageTypes[]) => {
  const promises = keys.map(async (key) => ({
    [key]: await getFromStorage(key),
  }));
  const results = await Promise.all(promises);
  return results.reduce(
    (acc, result) => ({ ...acc, ...result }),
    {},
  );
};

const saveToStorage = async (key: StorageTypes, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const updateStorage = async (key: StorageTypes, data: any) => {
  const existingItems = await getFromStorage(key);
  if (existingItems) {
    if (Array.isArray(existingItems)) {
      // add new items to array
      await saveToStorage(key, [...existingItems, ...data]);
    }
  }
  // overwrite items
  await saveToStorage(key, data);
};

const saveLoginToStorage = async (
  profile: LoginType | CreateNewProfileObjectType,
) => {
  await storage.saveToStorage('token', profile.token);
  await storage.saveToStorage('user_uuid', profile.uuid);
  await storage.saveToStorage('last_login', profile.last_login);
  await storage.saveToStorage(
    'family_uuid',
    profile.family?.uuid,
  );
  await storage.saveToStorage(
    'family_member_uuid',
    profile.family_member?.uuid,
  );
};

const updateRecentlyVisitedCommunities = async (
  community: CommunityObjectType,
) => {
  const communityToSave = {
    uuid: community.uuid,
    name: community.name,
    image_url: community.image_url,
    is_member: community.is_member,
  };
  const recentCommunities = await getFromStorage(
    'recentCommunities',
  );
  if (recentCommunities) {
    await saveToStorage(
      'recentCommunities',
      Array.from(
        new Map(
          [...recentCommunities, communityToSave].map((item) => [
            item.uuid,
            item,
          ]),
        ).values(),
      ).slice(0, 5),
    );
  } else {
    await saveToStorage('recentCommunities', [communityToSave]);
  }
};

const removeFromStorage = async (key: StorageTypes) => {
  localStorage.removeItem(key);
};

const clearStorage = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_uuid');
  localStorage.removeItem('family_uuid');
  localStorage.removeItem('family_member_uuid');
};

const deleteAllFromStorage = () => {
  localStorage.clear();
};

const storage = {
  getFromStorage,
  getManyFromStorage,
  saveToStorage,
  updateStorage,
  saveLoginToStorage,
  removeFromStorage,
  clearStorage,
  updateRecentlyVisitedCommunities,
  deleteAllFromStorage,
};

export default storage;
