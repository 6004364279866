import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  ViewPostQuery,
  ViewPostQueryVariables,
} from "../../../__generated__/graphql";

const useViewPost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "viewPost";

  const handleOnCompleted = (data: ViewPostQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.post.viewPost,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [viewPost] = useLazyQuery(api.query.post.VIEW_POST, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: ViewPostQueryVariables) => {
    if (!state.post.viewPost.loading) {
      viewPost({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useViewPost;
