import { gql } from "../../../__generated__";

const LOGIN = gql(`
  mutation Login($nickname: String, $email: String, $password: String) {
    profile {
      login(nickname: $nickname, email: $email, password: $password) {
        uuid
        email
        nickname
        last_login
        logged_in
        token
        family {
          uuid
          name
          zip
        }
        family_member {
          uuid
          last_name
          is_admin
        }
      }
    }
  }
`);

const LOGOUT = gql(`
  mutation Logout {
    profile {
      logout {
        logged_out
        profile_uuid
      }
    }
  }
`);

const CREATE_NEW_PROFILE = gql(`
  mutation CreateNewProfile(
  $nickname: String!
  $lastName: String!
  $email: String!
  $password: String!
  $phone: String!
  $zip: String!
) {
  profile {
    createNewProfile(
      nickname: $nickname
      last_name: $lastName
      email: $email
      password: $password
      phone: $phone
      zip: $zip
    ) {
      uuid
      email
      nickname
      last_login
      logged_in
      token
      family {
        uuid
        name
        zip
      }
      family_member {
        uuid
        last_name
        is_admin
      }
    }
  }
}
`);

const UPDATE_PROFILE = gql(`
  mutation UpdateProfile(
    $profile: updateProfileProfileInputType!
    $familyMember: updateProfileFamilyMemberInputType
    $permissions: updateProfilePermissionsInputType
  ) {
    profile {
      updateProfile(
        profile: $profile
        family_member: $familyMember
        permissions: $permissions
      ) {
        uuid
        email
        nickname
        last_login
        logged_in
        family {
          uuid
          name
          zip
        }
        family_member {
          uuid
          last_name
          is_admin
        }
      }
    }
  }
`);

const DELETE_ACCOUNT = gql(`
  mutation DeleteAccount {
    profile {
      deleteAccount {
        success
        uuid
      }
    }
  }
`)

const profile = {
  LOGIN,
  LOGOUT,
  CREATE_NEW_PROFILE,
  UPDATE_PROFILE,
  DELETE_ACCOUNT
};

export default profile;
