import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useCity from './api/useCity';
import useCountry from './api/useCountry';
import useGender from './api/useGender';
import useLanguage from './api/useLanguage';
import useState from './api/useState';
import useTag from './api/useTag';
import useTimezone from './api/useTimezone';
import useGenerateImage from './api/useGenerateImage';
import useLikeType from './api/useLikeType';
import { CommonStoreActions } from '../../types/reducers/common';
import useSchool from './api/useSchool';
import useSchoolByUUID from './api/useSchoolByUUID';
import useReportTypes from './api/useReportTypes';
import useReportUser from './api/useReportUser';
import useBlockedUsersUUIDS from './api/useBlockedUsersUUIDS';
import useBlockUser from './api/useBlockUser';
import useBlockedUsersProfiles from './api/useBlockedUsersProfiles';
import useUnblockUser from './api/useUnblockUser';

export const useCommonActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): CommonStoreActions => {
  return {
    query: {
      likeType: useLikeType(state, dispatch),
      timezone: useTimezone(state, dispatch),
      tag: useTag(state, dispatch),
      state: useState(state, dispatch),
      language: useLanguage(state, dispatch),
      gender: useGender(state, dispatch),
      country: useCountry(state, dispatch),
      city: useCity(state, dispatch),
      school: useSchool(state, dispatch),
      schoolByUUID: useSchoolByUUID(state, dispatch),
      reportTypes: useReportTypes(state, dispatch),
      blockedUsersUUIDS: useBlockedUsersUUIDS(state, dispatch),
      blockedUsersProfiles: useBlockedUsersProfiles(
        state,
        dispatch,
      ),
    },
    mutation: {
      generateImage: useGenerateImage(state, dispatch),
      reportUser: useReportUser(state, dispatch),
      blockUser: useBlockUser(state, dispatch),
      unblockUser: useUnblockUser(state, dispatch),
    },
  };
};

export default useCommonActions;
