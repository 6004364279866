import { A11YStructure } from '../../types/a11y';

const english: A11YStructure = {
  errors: {
    post: {
      doesNotExist: 'The Post does not exist. Please try again',
    },
    profile: {
      missingId:
        'There was an error fetching your profile, please try again.',
    },
    notification: {},
    family: {
      familyNotAvailable:
        'This family is not available. Something went wrong',
      bioNotAvailable: 'Bio not yet available',
    },
    connection: {},
    community: {
      notAvailable: 'Community not available',
    },
    common: {
      ifTheProblemPersists:
        'If the problem persists, please contact support',
      errorTitle: 'Error!',
      notSignedIn:
        'You are not signed in, please sign in to continue.',
      tokenExpiration:
        "You're login has expired, please log in again to continue",
    },
    chat: {
      chatDoesNotExist: 'Chat does not exist, redirecting',
    },
  },
  warn: {
    post: {
      foulLanguage:
        'Detected foul language, be careful or your account will be suspended',
    },
    profile: {},
    notification: {},
    family: {},
    connection: {},
    community: {},
    common: {
      warnTitle: 'Warning!',
    },
  },
  success: {
    post: {
      postCreated: 'The post has been created',
    },
    profile: {},
    notification: {},
    family: {
      interestsSaved:
        'Interests have been saved for your family!',
      familyCreated: 'Your family has been created',
      familyUpdated: 'Your family has been updated',
      familyMemberCreated: (familyMemberName: string) =>
        `${familyMemberName} created!`,
    },
    connection: {},
    community: {
      communityCreated: (communityName: string) =>
        `${communityName} has been created`,
      communityUpdated: (communityName: string) =>
        `${communityName} has been updated`,
    },
    common: {
      successTitle: 'Success!',
    },
  },
  general: {
    post: {
      postCreated: 'The post has been created',
    },
    profile: {},
    notification: {},
    family: {},
    connection: {
      specialConsiderations: {
        communityCount: 'similar communities',
        connectionCount: 'similar connections',
        schoolCount: 'children attending the same school',
        interestCount: 'similar interests',
      },
    },
    community: {},
    common: {
      successTitle: 'Success!',
    },
  },
};

export default english;
