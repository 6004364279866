import { AppStoreState } from '../types/reducers';
import { CommunityReducerActions } from '../types/reducers/community';

const communityReducer = (
  state: AppStoreState,
  action: CommunityReducerActions,
) => {
  switch (action.type) {
    case 'communities':
      state.community.communities = {
        ...state.community.communities,
        ...action.payload,
      };
      return state;
    case 'allCommunities':
      state.community.allCommunities = action.payload;
      return state;
    case 'community':
      state.community.community = {
        ...state.community.community,
        ...action.payload,
      };
      return state;
    case 'suggestedCommunitiesForNewFamilyMember':
      state.community.suggestedCommunitiesForNewFamilyMember =
        action.payload;
      return state;
    case 'joinCommunity':
      const { communitiesJoined = [] } =
        state.community.joinCommunity;
      if (action.payload.data?.community_uuid) {
        communitiesJoined.push(
          action.payload.data?.community_uuid,
        );
      }
      state.community.joinCommunity = {
        ...action.payload,
        communitiesJoined,
      };
      return state;
    case 'joinCommunities':
      state.community.joinCommunities = action.payload;
      return state;
    case 'addConnectionToCommunityQueue':
      const ids = new Set(
        state.community.connectionsToAddToCommunity,
      );
      ids.add(action.payload);
      state.community.connectionsToAddToCommunity = [...ids];
      return state;
    case 'removeConnectionFromCommunityQueue':
      const currentConnections =
        state.community.connectionsToAddToCommunity;
      state.community.connectionsToAddToCommunity =
        currentConnections.filter(
          (uuid) => uuid !== action.payload,
        );
      return state;
    case 'inviteByPhone':
      state.community.inviteByPhone = action.payload;
      return state;
    case 'inviteManyConnectionsToCommunity':
      state.community.inviteManyConnectionsToCommunity =
        action.payload;
      return state;
    case 'respondToCommunityInvitation':
      state.community.respondToCommunityInvitation.loading =
        !!action.payload.loading;
      if (action.payload.data)
        state.community.respondToCommunityInvitation.data =
          action.payload.data;
      if (
        action.payload.data?.uuid &&
        state.community.respondToCommunityInvitation
          .communitiesRespondedTo
      )
        state.community.respondToCommunityInvitation.communitiesRespondedTo.push(
          action.payload.data?.uuid,
        );
      return state;
    case 'createCommunity':
      state.community.createCommunity.loading =
        !!action.payload.loading;
      if (action.payload.data?.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.community.communityCreated(
            action.payload.data.name,
          );
        state.community.createCommunity.data =
          action.payload.data;
      }
      return state;
    case 'updateCommunity':
      state.community.updateCommunity.loading =
        !!action.payload.loading;
      if (action.payload.data?.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.community.communityUpdated(
            action.payload.data.name,
          );
        state.community.updateCommunity.data =
          action.payload.data;
      }
      return state;
    default:
      return state;
  }
};

export default communityReducer;
