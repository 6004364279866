import { AppStoreState, ReducerActions } from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import { DeleteAccountMutation } from '../../../__generated__/graphql';
import storage from '../../../utils/storage';
import routes from '../../../navigation/routes';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const useDeleteAccount = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'deleteAccount';
  const navigate = useCustomNavigate();

  const handleOnCompleted = (data: DeleteAccountMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.profile.deleteAccount,
      },
    });
    storage.deleteAllFromStorage();
    navigate(routes.signIn)
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [deleteAccount] = useMutation(api.mutation.profile.DELETE_ACCOUNT, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return () => {
    if (!state.profile.deleteAccount.loading) {
      deleteAccount();
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useDeleteAccount;
