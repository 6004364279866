import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  SortAndFilterBaseArgs,
  SortAndFilterBaseRespGenerator_Tag,
  TagQuery,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useTag = (state: AppStoreState, dispatch: Dispatch<ReducerActions>) => {
  const parent = "common";
  const type = "tag";

  const handleOnCompleted = (data: TagQuery) => {
    storage.saveToStorage("tag", data.common.tag.data);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.common.tag,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [tag] = useLazyQuery(api.query.common.TAG, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: Partial<SortAndFilterBaseArgs>) => {
    if (!state.common.tag.loading) {
      tag({
        variables: { refine: args },
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useTag;
