import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../navigation/routes';
import { UpdateProfileMutationVariables } from '../../__generated__/graphql';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useStore } from '../../hooks/useStore';
import '../../styles/pages/editProfile.scss';
import ProfileForm, {
  ProfileFormInputs,
} from '../../forms/profile/ProfileForm';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import get from '../../utils/utilityFunctions/get';

const EditProfile = () => {
  const navigate = useCustomNavigate();
  const params = useParams();
  const { state, actions } = useStore();

  const {
    app: { systemMessages },
    profile: { selectedProfile },
  } = state;

  const myUUID = selectedProfile?.profile.uuid;

  useEffect(() => {
    if (!params.familyMemberUUID) {
      // || !myUUID) {
      actions.app.setErrorMessage(
        systemMessages?.errors.profile.missingId ||
          'There was an error',
      );
      navigate(routes.feed);
      return;
    }

    if (
      params.familyMemberUUID !==
      selectedProfile?.family_member.uuid
    ) {
      actions.profile.query.familyMemberProfile({
        uuid: params.familyMemberUUID,
      });
    }
  }, []);

  const onSubmit =
    (
      dirtyFields: Partial<
        Readonly<FieldNamesMarkedBoolean<ProfileFormInputs>>
      >,
    ) =>
    (formValues: ProfileFormInputs) => {
      const {
        profile: dirtyProfileFields = {},
        family_member: dirtyFamilyMemberFields = {},
      } = dirtyFields;

      // stringify and parse to ensure undefined fields get removed
      const baseUpdateFields: Partial<UpdateProfileMutationVariables> =
        {
          profile: {
            uuid: selectedProfile!.profile.uuid,
          },
          familyMember: {},
          permissions: formValues.permissions,
        };

      if (dirtyProfileFields.email) {
        baseUpdateFields!.profile!.email =
          formValues.profile.email;
      }
      if (dirtyProfileFields.nickname) {
        baseUpdateFields!.profile!.nickname =
          formValues.profile.nickname;
      }
      if (dirtyProfileFields?.password) {
        baseUpdateFields!.profile!.password =
          formValues.profile.password;
      }

      if (dirtyFamilyMemberFields.first_name) {
        baseUpdateFields.familyMember!.first_name =
          formValues.family_member?.first_name;
      }
      if (dirtyFamilyMemberFields.last_name) {
        baseUpdateFields.familyMember!.last_name =
          formValues.family_member?.last_name;
      }
      if (dirtyFamilyMemberFields.is_admin) {
        baseUpdateFields.familyMember!.is_admin =
          formValues.family_member.is_admin;
      }
      if (dirtyFamilyMemberFields.gender) {
        baseUpdateFields.familyMember!.gender_uuid = formValues
          .family_member.gender.value as string;
      }
      if (dirtyFamilyMemberFields.relation_type) {
        baseUpdateFields.familyMember!.relation_type_uuid =
          formValues.family_member.relation_type.value as string;
      }
      if (dirtyFamilyMemberFields.bio) {
        baseUpdateFields.familyMember!.bio =
          formValues.family_member.bio;
      }
      if (dirtyFamilyMemberFields.date_of_birth) {
        baseUpdateFields.familyMember!.date_of_birth =
          formValues.family_member.date_of_birth.toString();
      }
      if (dirtyFamilyMemberFields.school) {
        baseUpdateFields.familyMember!.school_uuid = formValues
          .family_member.school.value as string;
      }
      if (formValues.family_member.profile_image) {
        baseUpdateFields.familyMember!.profile_image =
          formValues.family_member.profile_image[0];
      }
      if (
        baseUpdateFields.profile &&
        baseUpdateFields.profile?.uuid
      ) {
        actions.profile.mutation
          .updateProfile(
            baseUpdateFields as UpdateProfileMutationVariables,
          )
          .then(() => {
            actions.profile.query.familyMemberProfile({
              uuid: get(
                state,
                'profile.familyMemberProfile.data.family_member.uuid',
              ),
            });
          });
      }
      if (state.profile.chosenCommunities.length > 0) {
        actions.community.mutation.joinCommunities({
          communityUuiDs: state.profile.chosenCommunities.map(
            (comm) => comm && comm.uuid,
          ),
        });
      }
      if (state.profile.chosenInterests.length > 0) {
        actions.tag.mutation.createProfileTags({
          tagUuids: state.profile.chosenInterests.map(
            (interest) => interest && interest.uuid,
          ),
        });
      }
    };

  return (
    <ProfileForm
      onSubmit={onSubmit}
      loading={
        !!(
          !selectedProfile ||
          state.profile.familyMemberProfile.loading ||
          state.profile.updateProfile.loading
        )
      }
      error={state.profile.familyProfile.error}
      canDelete
    />
  );
};

export default EditProfile;
