import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import {
  ViewPublicProfileQuery,
  ViewPublicProfileQueryVariables,
} from '../../../__generated__/graphql';

const useViewPublicProfile = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'viewPublicProfile';

  const handleOnCompleted = (data: ViewPublicProfileQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.profile.viewPublicProfile,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [viewPublicProfile] = useLazyQuery(
    api.query.profile.VIEW_PUBLIC_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: ViewPublicProfileQueryVariables) => {
    if (!state.profile.viewPublicProfile.loading) {
      viewPublicProfile({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useViewPublicProfile;
