import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  PostFeedItemByIdQuery,
  PostFeedItemByIdQueryVariables,
} from "../../../__generated__/graphql";

const usePostFeedItemById = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "postFeedItemById";

  const handleOnCompleted = (data: PostFeedItemByIdQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.post.postFeedItemById,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [postFeedItemById] = useLazyQuery(api.query.post.POST_FEED_ITEM_BY_ID, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: PostFeedItemByIdQueryVariables) => {
    if (!state.post.postFeedItemById.loading) {
      postFeedItemById({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default usePostFeedItemById;
