import {
  Button,
  Div,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import { CommunityCard } from '../../components/communities';
import '../../styles/pages/communities.scss';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { CommunityObjectType } from '../../__generated__/graphql';
import { ChooseInterests } from '../onboarding';
import { useStore } from '../../hooks/useStore';
import { SyntheticEvent, useEffect } from 'react';
import routes from '../../navigation/routes';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const ViewCommunities = () => {
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();

  useEffect(() => {
    actions.community.query.communities({});
  }, []);

  const handleNavigateCommunity =
    (id: string) => (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate(routes.community, [id]);
    };

  const ViewAllButton = ({ id }: { id: string }) => (
    <FlexRow center column spreadX start={false}>
      <Button
        onClick={() => navigate(routes.allCommunities, [id])}
        variant={'contained'}
        color={'secondary'}
        size={'sm'}
      >
        View All
      </Button>
    </FlexRow>
  );

  const communitiesState = state.community.communities;
  if (communitiesState.loading) {
    return <Loader position={'center'} color={'white'} />;
  }

  const communitiesJoined =
    communitiesState.data?.communities_joined;
  const communitiesNear =
    communitiesState.data?.communities_near;
  const communitiesOther =
    communitiesState.data?.communities_other;
  return (
    <Div className={'communitiesContainer'}>
      {!state.profile.checkIfOnboarded.data
        ?.interests_complete && <ChooseInterests />}
      {!isEmpty(communitiesJoined) && (
        <Div>
          <Text
            variant={'p'}
            color={'grey800'}
            className={'communitySectionTitle'}
          >
            Your Communities
          </Text>
          {communitiesJoined?.map((community) => (
            <CommunityCard
              {...(community as CommunityObjectType)}
              onClick={handleNavigateCommunity}
              isMember={true}
              key={community!.uuid}
            />
          ))}
          <ViewAllButton id={'joined'} />
        </Div>
      )}
      {!isEmpty(communitiesNear) && (
        <Div>
          <Text
            variant={'p'}
            color={'grey800'}
            className={'communitySectionTitle'}
          >
            Communities Near You
          </Text>
          {communitiesNear?.map((community) => (
            <CommunityCard
              {...(community as CommunityObjectType)}
              onClick={handleNavigateCommunity}
              isMember={false}
              key={community!.uuid}
            />
          ))}
          <ViewAllButton id={'near'} />
        </Div>
      )}
      {!isEmpty(communitiesOther) && (
        <Div>
          <Text
            variant={'p'}
            color={'grey800'}
            className={'communitySectionTitle'}
          >
            Other Communities
          </Text>
          {communitiesOther?.map((community) => (
            <CommunityCard
              {...(community as CommunityObjectType)}
              onClick={handleNavigateCommunity}
              isMember={false}
              key={community!.uuid}
            />
          ))}
          <ViewAllButton id={'other'} />
        </Div>
      )}
    </Div>
  );
};

export default ViewCommunities;
