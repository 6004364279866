import { gql } from '../../../__generated__';

const CONNECTION_BY_FAMILY = gql(`
  query ConnectionByFamily($refine: sortAndFilterBaseArgs) {
    connection {
      connectionByFamily(refine: $refine) {
        data {
          family1_uuid
          family2_uuid
          family1_profile_image_url
          family2_profile_image_url
          family1_name
          family2_name
          family1_members
          family2_members
          family1_community_uuids
          family2_community_uuids
          status
          match_criteria
          updated_at
          family1_interests {
            uuid
            name
            tag_icon
          }
          family2_interests {
            uuid
            name
            tag_icon
          }
          uuid
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const MEET_FAMILY_CARDS = gql(`
  query MeetFamilyCards {
    connection {
      meetFamilyCards {
        uuid
        family {
          uuid
          name
          bio
          timezone_uuid
          city_uuid
          state_uuid
          country_uuid
          zip
          primary_profile_image_url
          secondary_profile_image_url
          tertiary_profile_image_url
          admin_profile_uuids
        }
        family_members
        interests {
          uuid
          name
          tag_icon
        }
        status
        match_criteria
      }
    }
  }
`);

const CONNECTION_PROFILE = gql(`
  query ConnectionProfile($familyUuid: String) {
    connection {
      connectionProfile(family_uuid: $familyUuid) {
        uuid
        family {
          uuid
          name
          bio
          timezone_uuid
          city_uuid
          state_uuid
          country_uuid
          zip
          primary_profile_image_url
          secondary_profile_image_url
          tertiary_profile_image_url
          admin_profile_uuids
        }
        family_members
        interests {
          uuid
          name
          tag_icon
        }
        status
        match_criteria
      }
    }
  }
`);

const CONNECTION_REQUESTS_BY_FAMILY = gql(`
  query ConnectionRequestsByFamily($refine: sortAndFilterBaseArgs) {
    connection {
      connectionRequestsByFamily(refine: $refine) {
        data {
          family1_uuid
          family2_uuid
          family1_profile_image_url
          family2_profile_image_url
          family1_name
          family2_name
          family1_members
          family2_members
          status
          match_criteria
          updated_at
          family1_interests {
            uuid
            name
            tag_icon
          }
          family2_interests {
            uuid
            name
            tag_icon
          }
          uuid
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const CONNECTION_INVITES_BY_FAMILY = gql(`
  query ConnectionInvitesByFamily($refine: sortAndFilterBaseArgs) {
    connection {
      connectionInvitesByFamily(refine: $refine) {
        data {
          family1_uuid
          family2_uuid
          family1_profile_image_url
          family2_profile_image_url
          family1_name
          family2_name
          family1_members
          family2_members
          status
          match_criteria
          updated_at
          family1_interests {
            uuid
            name
            tag_icon
          }
          family2_interests {
            uuid
            name
            tag_icon
          }
          uuid
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const connection = {
  CONNECTION_BY_FAMILY,
  CONNECTION_PROFILE,
  MEET_FAMILY_CARDS,
  CONNECTION_REQUESTS_BY_FAMILY,
  CONNECTION_INVITES_BY_FAMILY,
};

export default connection;
