import { Div, FlexRow, Image } from '../../_ui';
import { MediaPostHeader, MediaTitleOverlay, PostMeta } from './index';
import { PostObjectType, PostTypeEnumType } from '../../../__generated__/graphql';
import React from 'react';

const MediaPost: React.FC<{
  post: PostObjectType;
  singlePage: boolean;
}> = ({ post, singlePage }) => {
  const renderMedia = () => {
    const NoMedia = () => (
      <Image
        src={'/defaultImages/defaultPostImage.jpg'}
        alt={'lorem ipsum'}
        wrapperClass={'feedPostImage'}
      />
    );

    if (post.post_content?.link) {
      switch (post.post_type) {
        // case PostTypeEnumType.Video:
        //   return (
        //     <Video src={post.post_content.link} width={380} height={213} />
        //   );
        case PostTypeEnumType.Image:
          // case PostTypeEnumType.Slider:
          return (
            <Image
              src={post.post_content.link}
              alt={`${post.post_content.title} image`}
              wrapperClass={'feedPostImage'}
            />
          );
        default:
          return <NoMedia />;
      }
    }

    return <NoMedia />;
  };
  if (singlePage) {
    return (
      <FlexRow column className={'imagePostContainer'}>
        <Div>
          {renderMedia()}
          <MediaTitleOverlay
            title={post.post_content?.title}
            content={post.post_content?.content}
          />
        </Div>
        <PostMeta
          reactions={post.post_likes}
          commentCount={post.comment_count}
          postType={post.post_type}
          postUUID={post.uuid}
          singlePage
          profile_uuid={post.profile_uuid}
        />
      </FlexRow>
    );
  }
  return (
    <Div className={'imagePostContainer'}>
      <MediaPostHeader
        community={post?.community}
        posterAvatarUrl={post.family?.primary_profile_image_url}
        posterName={`${post.family_member!.last_name} Family | ${post.family_member!.first_name}`}
        canJoin={
          !!post.community?.uuid &&
          !post.community?.private &&
          !post.community?.is_member
        }
        isMember={post.community?.is_member || false}
        familyMember={post.family_member}
      />
      {renderMedia()}
      <PostMeta
        reactions={post.post_likes}
        commentCount={post.comment_count}
        postType={post.post_type}
        postUUID={post.uuid}
        profile_uuid={post.profile_uuid}
      />
    </Div>
  );
};

export default MediaPost;
