import { Capacitor } from '@capacitor/core';
import setupStatusBar from './statusBar';
import { PlatformType } from '../types/mobile';

export const setupMobilePlatform = async () => {
  const platform = Capacitor.getPlatform();
  if (Capacitor.isNativePlatform()) {
    await setupStatusBar(platform as PlatformType);
  }
};
