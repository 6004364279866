import { PostRecommendationObjectType } from "../../__generated__/graphql";
import { Div, FlexRow, Text } from "../_ui";
import React from "react";
import { JoinCommunityButton } from "../_common/post";

const CommunityRecommendation = ({
  post,
}: {
  post: PostRecommendationObjectType;
}) => {
  return (
    <Div className={"post textPost"}>
      <FlexRow column>
        <FlexRow spreadX fullWidth>
          <Text variant={"span"} color={"grey800"}>
            Recommended
          </Text>
          {post.community?.uuid && !post.community.is_member && (
            <JoinCommunityButton
              canJoin={!post.community?.private}
              communityUUID={post.community?.uuid}
            />
          )}
        </FlexRow>
        <Text variant={"h3"} color={"grey800"}>
          {post.community?.name}
        </Text>
        <Text variant={"span"} color={"grey800"}>
          {post.community?.description}
        </Text>
        <FlexRow inline>
          <Div />
          <Text variant={"span"}>{post.community?.member_count} members</Text>
        </FlexRow>
      </FlexRow>
    </Div>
  );
};

export default CommunityRecommendation;
