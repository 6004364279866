import {
  Button,
  Div,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { useForm } from 'react-hook-form';
import { SyntheticEvent, useEffect } from 'react';
import { AvatarAndName } from '../../components/_common';
import '../../styles/pages/inviteToCommunity.scss';
import { useParams } from 'react-router-dom';
import routes from '../../navigation/routes';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import get from '../../utils/utilityFunctions/get';
import {
  ConnectionQueryObjectType,
  Maybe,
} from '../../__generated__/graphql';

type InviteByPhoneInputProps = {
  first_name: string;
  last_name: string;
  phone: string;
};

const InviteToCommunity = () => {
  const { state, actions } = useStore();
  const params = useParams();
  const navigate = useCustomNavigate();
  const { addConnectionToQueue, removeConnectionFromQueue } =
    actions.community.manageConnectionInvites();
  const inviteIds = state.community.connectionsToAddToCommunity;
  const communityUuid: string | null = get(
    state,
    'community.community.data.uuid',
    null,
  );
  const connectionUUIDS: Maybe<ConnectionQueryObjectType>[] =
    get(state, 'connection.connectionByFamily.data', []);
  const filteredConnections = connectionUUIDS.filter(
    (connection) => {
      if (connection) {
        const isFamily1 =
          connection.family1_uuid ===
          state.profile.familyProfile.data?.family?.uuid;
        // opposite because need to get connection community Ids, not MY community ids.
        const currentCommunityIds = isFamily1
          ? connection.family2_community_uuids
          : connection.family1_community_uuids;
        return (
          currentCommunityIds &&
          currentCommunityIds.indexOf(communityUuid) < 0
        );
      }
    },
  );
  const isLoading = state.connection.connectionByFamily.loading;
  const canInvite = filteredConnections.length > 0;

  const { register, formState, handleSubmit, getValues } =
    useForm<InviteByPhoneInputProps>();
  const { errors } = formState;

  useEffect(() => {
    if (!state.community.community.data?.uuid) {
      // fetch community, should not get here but never know
      if (params.communityId) {
        actions.community.query.community({
          communityUuid: params.communityId,
        });
      } else {
        actions.app.setErrorMessage(
          state.app.systemMessages?.errors.community
            .notAvailable ||
            'The community is not available as this time, please try again',
        );
        navigate(routes.communities);
      }
    }
    actions.connection.query.connectionByFamily({
      refine: {
        limit: 20,
      },
    });
  }, []);

  const handleAddConnection =
    (uuid: string) => (e: SyntheticEvent) => {
      e.stopPropagation();
      if (inviteIds.indexOf(uuid) >= 0) {
        removeConnectionFromQueue(uuid);
      } else {
        addConnectionToQueue(uuid);
      }
    };

  const handleInviteConnection = () => {
    if (communityUuid) {
      actions.community.mutation.inviteManyConnectionsToCommunity(
        {
          communityUuid,
          connectionUuids: inviteIds,
        },
      );
    } else {
      actions.app.setErrorMessage(
        'There was a problem fetching the community, please navigate out and back in.',
      );
    }
  };

  const onSubmit = (formValues: InviteByPhoneInputProps) => {
    // send invite by phone number;

    actions.community.mutation.inviteByPhone({
      firstName: formValues.first_name,
      lastName: formValues.last_name,
      phone: formValues.phone,
      // this should always be here because you're coming from community. Checked in useEffect and fetched if not exists just in case.
      communityUuid: state.community.community.data
        ?.uuid as string,
    });
  };

  console.log('filteredConnections');
  console.log(filteredConnections);

  return (
    <Div className={'inviteToCommunityContainer'}>
      <Divider color={'transparent'} />
      {/*<Div className="card">*/}
      {/*  <Text variant={'h4'} color={'grey800'}>*/}
      {/*    Invite by Phone*/}
      {/*  </Text>*/}
      {/*  <Form onSubmit={handleSubmit(onSubmit)}>*/}
      {/*    <Input*/}
      {/*      textSize={'md'}*/}
      {/*      label={'first name'}*/}
      {/*      type={'text'}*/}
      {/*      {...register('first_name', {*/}
      {/*        required: 'First Name is required',*/}
      {/*      })}*/}
      {/*      error={errors.first_name?.message}*/}
      {/*    />*/}
      {/*    <Input*/}
      {/*      textSize={'md'}*/}
      {/*      label={'last name'}*/}
      {/*      type={'text'}*/}
      {/*      {...register('last_name', {*/}
      {/*        required: 'Last Name is required',*/}
      {/*      })}*/}
      {/*      error={errors.last_name?.message}*/}
      {/*    />*/}
      {/*    <Input*/}
      {/*      textSize={'md'}*/}
      {/*      label={'phone'}*/}
      {/*      type={'tel'}*/}
      {/*      {...register('phone', {*/}
      {/*        required: 'Phone is required',*/}
      {/*        validate: (phone) =>*/}
      {/*          isValidUSPhoneNumber(phone)*/}
      {/*            ? true*/}
      {/*            : 'Invalid Phone format',*/}
      {/*      })}*/}
      {/*      error={errors.phone?.message}*/}
      {/*    />*/}
      {/*    {state.community.inviteByPhone.data?.success && (*/}
      {/*      <Text variant={'p'} color={'success'}>*/}
      {/*        Invited {getValues().first_name} to Family Friend!*/}
      {/*      </Text>*/}
      {/*    )}*/}
      {/*    {state.community.inviteByPhone.error && (*/}
      {/*      <Text variant={'p'} color={'error'}>*/}
      {/*        {state.community.inviteByPhone.error}*/}
      {/*      </Text>*/}
      {/*    )}*/}
      {/*    <Divider color={'transparent'} />*/}
      {/*    <Button*/}
      {/*      type={'submit'}*/}
      {/*      fullWidth*/}
      {/*      disabled={state.community.inviteByPhone.loading}*/}
      {/*    >*/}
      {/*      {state.community.inviteByPhone.loading*/}
      {/*        ? 'Sending Invite'*/}
      {/*        : 'Invite'}*/}
      {/*    </Button>*/}
      {/*  </Form>*/}
      {/*</Div>*/}
      <Divider color={'transparent'} />
      <Div className="card">
        <Text variant={'h4'} color={'grey800'}>
          Invite Connections
        </Text>
        <Divider color={'transparent'} />
        {state.connection.connectionByFamily.loading && (
          <Loader color={'white'} variant={'overlay'} />
        )}
        {canInvite
          ? filteredConnections?.map((connection) => {
              if (!connection) return null;
              const isFamily1 =
                connection.family1_uuid ===
                state.profile.familyProfile.data?.family?.uuid;
              const imageSrc = isFamily1
                ? connection.family2_profile_image_url
                : connection.family1_profile_image_url;
              const connectionName = isFamily1
                ? connection.family2_name
                : connection.family1_name;
              const currentCommunityIds = isFamily1
                ? connection.family1_community_uuids
                : connection.family2_community_uuids;
              return (
                <Div
                  onClick={handleAddConnection(connection.uuid)}
                  key={connection.uuid}
                  className={[
                    inviteIds.indexOf(connection.uuid) > -1
                      ? 'active'
                      : '',
                    'inviteToCommunityCard',
                  ].join(' ')}
                >
                  <AvatarAndName
                    textVariant={'h5'}
                    name={
                      connectionName
                        ? `The ${connectionName}'s`
                        : ''
                    }
                    url={imageSrc}
                  />
                  <Divider color={'transparent'}></Divider>
                </Div>
              );
            })
          : !isLoading && (
              <FlexRow column centerY centerX>
                <Text variant={'h4'} color={'grey800'}>
                  No Connections to invite
                </Text>
                <Text variant={'p'} color={'grey800'}>
                  They have already been invited or are currently
                  a part of this community.
                </Text>
              </FlexRow>
            )}
        {state.community.inviteManyConnectionsToCommunity
          .error && (
          <Text variant={'p'} color={'error'}>
            {
              state.community.inviteManyConnectionsToCommunity
                .error
            }
          </Text>
        )}
        <Divider color={'transparent'} />
        {canInvite && (
          <Button
            onClick={handleInviteConnection}
            variant={'contained'}
            color={'primary'}
            fullWidth
            disabled={
              state.community.inviteManyConnectionsToCommunity
                .loading
            }
          >
            {state.community.inviteManyConnectionsToCommunity
              .loading
              ? 'Sending Invites'
              : 'Invite'}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default InviteToCommunity;
