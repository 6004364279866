import React, { MouseEventHandler } from 'react';
import '../../styles/components/_ui/text.scss';
import {
  colorsOptions,
  monoColorOptions,
  statusColorOptions,
} from '../../types/theme/colors';
import {
  TextAlignmentOptions,
  TextOptions,
  TextOptionsEnum,
} from '../../types/theme/typography';
import { useStore } from '../../hooks/useStore';

export interface TextProps {
  variant?: TextOptions;
  weight?: 100 | 200 | 300 | 400 | 800;
  color?: colorsOptions | monoColorOptions | statusColorOptions;
  className?: string;
  onClick?: MouseEventHandler<HTMLHeadingElement> | undefined;
  children: React.ReactNode;
  align?: TextAlignmentOptions;
}

const Text: React.FC<TextProps> = ({
  variant = TextOptionsEnum.p,
  className,
  onClick,
  children,
  color = 'primary',
  weight,
  align,
}) => {
  const { state } = useStore();
  const props = {
    onClick,
    className: [
      className,
      color,
      weight && `weight-${weight}`,
      variant,
      state.app.themeMode,
      align,
    ].join(' '),
  };
  switch (variant) {
    case 'h1':
      return <p {...props}>{children}</p>;
    case 'h2':
      return <p {...props}>{children}</p>;
    case 'h3':
      return <p {...props}>{children}</p>;
    case 'h4':
      return <p {...props}>{children}</p>;
    case 'h5':
      return <p {...props}>{children}</p>;
    case 'h6':
      return <p {...props}>{children}</p>;
    case 'span':
      return <span {...props}>{children}</span>;
    case 'inherit':
      return <span {...props}>{children}</span>;
    case 'p':
    case 'p2':
    default:
      return <p {...props}>{children}</p>;
  }
};

export default Text;
