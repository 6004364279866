import {
  Button,
  Card,
  Divider,
  FlexRow,
  Loader,
  Pill,
  Text,
} from '../../components/_ui';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import { Tag } from '../../__generated__/graphql';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { getRandomIndexes } from '../../utils/arrayUtils';
import { Maybe } from 'graphql/jsutils/Maybe';

const ChooseInterests = () => {
  const { state, actions } = useStore();
  const [randomInterests, setRandomInterests] = useState<
    Maybe<Tag>[]
  >([]);

  useEffect(() => {
    if (isEmpty(randomInterests) && state.common.tag.data) {
      const randoIdxs = getRandomIndexes(
        state.common.tag.data.length,
        10,
      );
      setRandomInterests(
        state.common.tag.data.filter((t, i) =>
          randoIdxs.includes(i),
        ),
      );
    }
  }, [state.common.tag.data]);

  useEffect(() => {
    if (
      state.family.saveFamilyInterests.data &&
      state.family.saveFamilyInterests.data.length > 0
    ) {
      actions.profile.resetInterests();
      actions.profile.query.checkIfOnboarded({});
    }
  }, [state.family.saveFamilyInterests.data]);

  const chosenTagIds = (state.profile.chosenInterests || []).map(
    (t) => t?.uuid,
  );

  const viewInterestModal = () => {
    actions.app.setModal({
      open: true,
      title: 'Choose Interests',
      type: 'choose-interests',
    });
  };

  const saveFamilyInterests = () => {
    actions.family.mutation.saveFamilyInterests({
      interestUuids: state.profile.chosenInterests.map(
        (i) => i && i.uuid,
      ),
    });
  };

  const handleSetInterest = (tag: Tag) => {
    if (chosenTagIds.indexOf(tag.uuid) > -1) {
      actions.profile.removeInterest(tag);
    } else {
      actions.profile.addInterest(tag);
    }
  };

  return (
    <Card>
      <Text variant={'h5'} color={'primary'}>
        Interests
      </Text>
      <Text variant={'h3'} color={'grey800'}>
        Choose some things that the{' '}
        {state.profile.familyProfile.data?.family?.name}'s are
        interested in
      </Text>
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <FlexRow wrap centerY spreadX={false}>
        {isEmpty(randomInterests) ? (
          <Loader type={'bar'} />
        ) : (
          <>
            {randomInterests.map(
              (tag) =>
                tag && (
                  <Pill
                    title={tag?.name}
                    key={tag?.uuid}
                    variant={
                      chosenTagIds.includes(tag.uuid)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleSetInterest(tag)}
                  />
                ),
            )}
            <Button
              size={'sm'}
              variant={'transparent'}
              color={'primary'}
              onClick={viewInterestModal}
            >
              View More
            </Button>
          </>
        )}
      </FlexRow>
      <Button
        fullWidth
        color={'primary'}
        variant={'contained'}
        disabled={state.family.saveFamilyInterests.loading}
        onClick={saveFamilyInterests}
      >
        Save
      </Button>
    </Card>
  );
};

export default ChooseInterests;
