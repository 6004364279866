import {
  Button,
  Card,
  Divider,
  FlexRow,
  Loader,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import routes from '../../navigation/routes';
import { FamilyMemberProfile } from '../../components/profile';
import { AppPagesEnum, IPages } from '../../types/pages';
import useReportUser from '../../hooks/useReportUser';
import { ReportUserEnum } from '../../__generated__/graphql';
import useCustomNavigate from '../../hooks/useCustomNavigate';

// This is not the typical profile view that you will see under your profile, this is used to view others' profiles. Needed a separate page because of the way the data is being accessed from a parent on that component.
const PublicProfile = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();
  const reportUser = useReportUser();

  const { familyMemberUUID } = useParams() as {
    familyMemberUUID: string;
  };

  useEffect(() => {
    if (familyMemberUUID) {
      actions.profile.query.viewPublicProfile({
        uuid: familyMemberUUID,
      });
    } else {
      actions.app.setErrorMessage(
        'That profile no longer exists',
      );
      if (state.app.prevPages.length > 0) {
        const prevPage: IPages =
          state.app.prevPages[state.app.prevPages.length - 1];
        navigate(routes[prevPage]);
        actions.app.navigateBack(
          prevPage === AppPagesEnum.signIn
            ? AppPagesEnum.feed
            : AppPagesEnum[prevPage],
        );
      } else {
        navigate(routes.feed);
      }
    }
  }, []);

  const familyMember =
    state.profile.viewPublicProfile.data?.family_member;
  const communities =
    state.profile.viewPublicProfile.data?.my_communities;
  const interests =
    state.profile.viewPublicProfile.data?.my_interests;
  const profile = state.profile.viewPublicProfile.data?.profile;

  const showReportUserModal = () => {
    if (profile?.uuid) {
      reportUser(
        profile.uuid,
        ReportUserEnum.Profile,
        'report',
        profile.uuid,
      );
    }
  };
  const blockUser = () => {
    if (profile?.uuid) {
      reportUser(
        profile.uuid,
        ReportUserEnum.Profile,
        'block',
        profile.uuid,
      );
    }
  };

  return (
    <Card>
      {state.profile.viewPublicProfile.loading && (
        <Loader position={'center'} />
      )}
      {state.profile.viewPublicProfile.data && (
        <>
          <FamilyMemberProfile
            familyMember={familyMember}
            communities={communities}
            interests={interests}
            doesOwn={false}
            profile={profile}
          />
          <Divider color={'transparent'} />
          <FlexRow>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={blockUser}
            >
              Block
            </Button>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={showReportUserModal}
            >
              Report
            </Button>
          </FlexRow>
        </>
      )}
    </Card>
  );
};

export default PublicProfile;
