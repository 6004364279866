import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  CountryQuery,
  CountryQueryVariables,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useCountry = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "common";
  const type = "country";

  const handleOnCompleted = (data: CountryQuery) => {
    storage.saveToStorage("country", data.common.country);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.country,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [country] = useLazyQuery(api.query.common.COUNTRY, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: CountryQueryVariables) => {
    if (!state.common.country.loading) {
      country({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCountry;
