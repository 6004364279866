import {
  Button,
  Div,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { useEffect } from 'react';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { FamilyProfileSmallCard } from '../../components/families';
import routes from '../../navigation/routes';

const ReceivedRequests = () => {
  const { state, actions } = useStore();

  useEffect(() => {
    actions.connection.query.connectionInvitesByFamily({});
  }, []);

  const isLoading =
    state.connection.connectionInvitesByFamily.loading;

  return (
    <Div className={'myFamilyCards sentRequests'}>
      {isLoading && (
        <Loader position={'center'} color={'grey400'} />
      )}
      {isEmpty(
        state.connection.connectionInvitesByFamily.data,
      ) && !isLoading ? (
        <FlexRow column>
          <Text variant={'h4'} color={'grey800'}>
            No Invites right now, check back later.
          </Text>
          <Button
            routeTo={routes.families}
            color={'primary'}
            variant={'contained'}
          >
            Back
          </Button>
        </FlexRow>
      ) : (
        state.connection.connectionInvitesByFamily.data?.map(
          (connection) =>
            connection && (
              <FamilyProfileSmallCard
                key={connection.uuid}
                connection={connection}
                accept
                ignore
              />
            ),
        )
      )}
    </Div>
  );
};

export default ReceivedRequests;
