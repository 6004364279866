import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  CreateProfileTagsMutation,
  CreateProfileTagsMutationVariables,
} from '../../../__generated__/graphql';

const useCreateProfileTags = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'tag';
  const type = 'createProfileTags';

  const handleOnCompleted = (
    data: CreateProfileTagsMutation,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.tag.createProfileTags,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createProfileTags] = useMutation(
    api.mutation.tag.CREATE_PROFILE_TAGS,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: CreateProfileTagsMutationVariables) => {
    if (!state.tag.createProfileTags.loading) {
      createProfileTags({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateProfileTags;
