import { AppPagesEnum } from '../types/pages';

export function capitalizeChar0(str: string): string {
  if (str.length === 0) {
    return str;
  }

  const firstLetter = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  return `${firstLetter}${restOfString}`;
}

export function camelToTitle(camelStr: string | AppPagesEnum) {
  let words = camelStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  return words.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function isValidUSPhoneNumber(
  phoneNumber: string,
): boolean {
  // Regular expression to match American phone numbers
  const phoneRegex =
    /^(1-?)?(\([0-9]{3}\)|[0-9]{3})-?[0-9]{3}-?[0-9]{4}$/;

  return phoneRegex.test(phoneNumber);
}

export function isValidZipCode(zip: string): boolean {
  const usaZipRegex = /^[0-9]{5}(-[0-9]{4})?$/;
  const canZipRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

  return usaZipRegex.test(zip) || canZipRegex.test(zip);
}

export function isValidEmail(email: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function isAlphanumeric(string: string): boolean {
  const pattern = /^[a-zA-Z0-9]+$/;
  return pattern.test(string);
}
