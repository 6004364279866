import '../../styles/pages/myFamilies.scss';
import { useStore } from '../../hooks/useStore';
import { useEffect } from 'react';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import {
  Button,
  Div,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import routes from '../../navigation/routes';
import { FamilyProfileSmallCard } from '../../components/families';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import get from '../../utils/utilityFunctions/get';
// import { useConnectionApi } from "../../hooks/api.old";

const MyFamilies = () => {
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();

  useEffect(() => {
    actions.connection.query.connectionByFamily({
      refine: {
        limit: state.connection.connectionByFamily.limit,
        // offset: state.connection.connectionByFamily.offset,
        offset: 0,
      },
    });
    actions.connection.query.meetFamilyCards({});
  }, []);

  const noFamilies = isEmpty(
    state.connection.connectionByFamily.data,
  );
  const isLoading = state.connection.connectionByFamily.loading;

  return (
    <Div
      className={[
        isLoading || noFamilies
          ? 'centerContentSection flexRow'
          : '',
        'fullWidth',
        'myFamiliesContainer',
      ].join(' ')}
    >
      {isLoading && (
        <Loader position={'center'} color={'white'} />
      )}
      {!isLoading && noFamilies && (
        <Text
          variant={'h4'}
          color={'grey400'}
          className={'noConnections'}
        >
          You're not connected with anyone, meet some new
          families
        </Text>
      )}
      <Div className={'myFamilyCards'}>
        {(state.connection.connectionByFamily.data || []).map(
          (connection, index) =>
            connection && (
              <FamilyProfileSmallCard connection={connection} />
            ),
        )}
      </Div>
      <FlexRow
        className={[
          'meetNewFamiliesActions',
          get(state, 'app.mobile.isNative', false)
            ? 'nativeApp'
            : '',
        ].join(' ')}
        column
        inline
        centerX
      >
        <FlexRow
          spreadX={false}
          inline
          centerX
          centerY
          fullWidth
        >
          <Button
            variant={'contained'}
            color={'secondary'}
            size={'sm'}
            onClick={() => navigate(routes.sentRequests)}
          >
            Sent Requests
          </Button>
          <Button
            variant={'contained'}
            color={'secondary'}
            size={'sm'}
            onClick={() => navigate(routes.receivedRequests)}
          >
            Received Requests
          </Button>
        </FlexRow>

        {(state.connection.meetFamilyCards?.data?.length || 0) >
          0 && (
          <>
            <Divider color={'transparent'} />
            <FlexRow
              spreadX={false}
              centerX
              centerY
              fullWidth
              inline
            >
              <Button
                variant={'contained'}
                color={'secondary'}
                routeTo={routes.meetFamilies}
                size={'sm'}
              >
                {state.connection.meetFamilyCards.loading
                  ? 'Loading'
                  : `Meet Families (${
                      state.connection.meetFamilyCards?.data
                        ?.length || 0
                    } new)`}
              </Button>
            </FlexRow>
          </>
        )}
      </FlexRow>
    </Div>
  );
};

export default MyFamilies;
