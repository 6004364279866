import {
  Button,
  Div,
  Divider,
  FlexRow,
  Icon,
  Image,
  Link,
  List,
  ListItem,
  Loader,
  Text,
} from '../_ui';
import {
  faChevronRight,
  faCircleHalfStroke,
  faEnvelope,
  faFile,
  faGear,
  faHouse,
  faPeopleRoof,
  faRightFromBracket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_common/sideNav.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import storage from '../../utils/storage';
import { CommunityObjectType } from '../../__generated__/graphql';
import routes from '../../navigation/routes';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import setupStatusBar from '../../mobile/statusBar';
import { Capacitor } from '@capacitor/core';
import { PlatformType } from '../../types/mobile';
import { StatusBar } from '@capacitor/status-bar';

const sideNavListItems = [
  {
    name: 'Meet Families',
    icon: faPeopleRoof,
    page: routes.families,
  },
  { name: 'Feed', icon: faHouse, page: routes.feed },
  {
    name: 'Discover Communities',
    icon: faUsers,
    page: routes.communities,
  },
  {
    name: 'Notifications',
    icon: faEnvelope,
    page: routes.notifications,
  },
];

const SideNav = () => {
  const { state, actions } = useStore();
  const sideBarOpen = state.app.sideBarOpen;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useCustomNavigate();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [recentCommunities, setRecentCommunities] = useState<
    CommunityObjectType[]
  >([]);

  // used to dynamically add the class so we can remove the item from the dom when not in use.
  useEffect(() => {
    if (sideBarOpen && !isOpen) {
      setTimeout(() => {
        setIsOpen(true);
      }, 100);
    }
    if (!sideBarOpen && isOpen) {
      setTimeout(() => setIsOpen(false), 500);
    }
    storage
      .getFromStorage('recentCommunities')
      .then((data) => setRecentCommunities(data));
  }, [sideBarOpen]);

  const handleClose = () => {
    setIsOpen(false);
    actions.app.closeSideNav();
  };

  const handleLogout = async () =>
    actions.profile.mutation.logout({});

  const handleEditProfile = () => {
    actions.app.closeSideNav();
    navigate<string>(routes.editProfile, [
      state.profile.familyProfile.data!.profile.uuid,
    ]);
  };

  const handleThemeToggle = () => {
    actions.app.toggleTheme();
  };

  // useClickOutside(modalRef, handleClose, isOpen);

  // if (!isOpen && !sideBarOpen) return null;

  const profileImage =
    state.profile.familyProfile.data?.family_member
      .profile_image_url ||
    '/defaultImages/defaultFamilyProfileImage.jpg';
  const profileImageAlt =
    state.profile.familyProfile.data?.family_member.first_name;

  return (
    <Div
      className={[
        'sideNav',
        isOpen && sideBarOpen && 'open',
        !sideBarOpen && !isOpen && 'closed',
      ].join(' ')}
      ref={modalRef}
    >
      <Div>
        <Div className={'sideNavProfileInfo '}>
          <Image
            className={'sideNavProfileImage'}
            src={profileImage}
            alt={`${profileImageAlt} profile image`}
          />
          <FlexRow
            className={'editProfileRow'}
            spreadX={true}
            centerX
            inline
          >
            <FlexRow inline centerY>
              <Div className="isOnline" />
              <Text variant={'h5'} color={'grey800'}>
                {
                  state.profile.familyProfile.data?.profile
                    .nickname
                }
              </Text>
            </FlexRow>
            <FlexRow>
              <Button
                variant={'transparent'}
                color={'primary'}
                size={'sm'}
                onClick={handleEditProfile}
              >
                Edit
              </Button>
            </FlexRow>
          </FlexRow>
        </Div>
        {!isEmpty(recentCommunities) && (
          <Div className={'sideNavContent'}>
            <Text variant={'p'} color={'grey200'}>
              Recent Communities
            </Text>
            <Divider color={'transparent'} />
            {recentCommunities.map((community) => (
              <Div
                key={community.uuid}
                onClick={() => {
                  actions.app.closeSideNav();
                  navigate<string>(routes.community, [
                    community.uuid,
                  ]);
                }}
                className={'recentCommunities'}
              >
                <FlexRow spreadX centerY>
                  <Text variant={'h5'} color={'grey800'}>
                    {community.name}
                  </Text>
                  <Icon
                    icon={faChevronRight}
                    color={'primary'}
                  />
                </FlexRow>
              </Div>
            ))}
          </Div>
        )}
        <Div className={'sideNavContent'}>
          <List className={'sideNavLinks'}>
            {sideNavListItems.map(({ name, icon, page }) => (
              <ListItem
                onClick={() => {
                  actions.app.closeSideNav();
                  navigate(page);
                }}
                key={name}
              >
                <Button
                  variant={'transparent'}
                  color={'grey800'}
                  size={'sm'}
                  iconProps={{
                    position: 'start',
                    icon,
                  }}
                >
                  {name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Div>
        <Div className={'sideNavContent'}>
          <List className={'sideNavLinks'}>
            <ListItem>
              <Button
                variant={'transparent'}
                color={'grey800'}
                iconProps={{
                  position: 'start',
                  icon: faGear,
                  color: 'grey200',
                }}
                size={'sm'}
                onClick={() => {
                  actions.app.closeSideNav();
                  navigate(routes.settings);
                }}
              >
                Settings
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant={'transparent'}
                color={'grey800'}
                iconProps={{
                  position: 'start',
                  icon: faFile,
                  color: 'grey200',
                }}
                size={'sm'}
              >
                <Link
                  to={'https://familyfriend.app/privacy-policy'}
                >
                  Privacy Policy
                </Link>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant={'transparent'}
                color={'grey800'}
                iconProps={{
                  position: 'start',
                  icon: faFile,
                  color: 'grey200',
                }}
                size={'sm'}
              >
                <Link
                  to={`${process.env.REACT_APP_EULA_AGREEMENT}`}
                >
                  EULA
                </Link>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                iconProps={{
                  position: 'start',
                  icon: faCircleHalfStroke,
                  color: 'grey200',
                }}
                variant={'transparent'}
                color={'grey800'}
                onClick={handleThemeToggle}
                size={'sm'}
              >
                Toggle Theme | {state.app.themeMode}
              </Button>
            </ListItem>
            <ListItem>
              <Button
                iconProps={{
                  position: 'start',
                  icon: faRightFromBracket,
                  color: 'grey200',
                }}
                variant={'transparent'}
                color={'grey800'}
                onClick={handleLogout}
                className={'sideNavLogoutButton'}
                size={'sm'}
              >
                {state.profile.logout.loading && (
                  <Loader
                    size={'md'}
                    className={'logoutLoader'}
                  />
                )}{' '}
                Logout
              </Button>
            </ListItem>
          </List>
        </Div>
      </Div>
    </Div>
  );
};

export default SideNav;
