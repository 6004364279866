export type IPages =
  | 'signIn'
  | 'feed'
  | 'feedPost'
  | 'createFeedPost'
  | 'editFeedPost'
  | 'families'
  | 'meetFamilies'
  | 'sentRequests'
  | 'receivedRequests'
  | 'familyProfile'
  | 'editFamilyProfile'
  | 'communities'
  | 'allCommunities'
  | 'community'
  | 'communityPost'
  | 'createCommunityPost'
  | 'editCommunityPost'
  | 'inviteToCommunity'
  | 'editCommunity'
  | 'createCommunity'
  | 'notifications'
  // | 'dependentNotifications'
  | 'profile'
  | 'addFamilyMember'
  | 'viewPublicProfile'
  | 'chat'
  | 'chats'
  | 'newChat'
  | 'settings'
  | 'blockedUsers';
// | 'dependentChat';

export enum AppPagesEnum {
  home = 'home',
  createProfile = 'createProfile',
  editProfile = 'editProfile',
  allCommunities = 'allCommunities',
  signIn = 'signIn',
  feed = 'feed',
  feedPost = 'feedPost',
  createFeedPost = 'createFeedPost',
  editFeedPost = 'editFeedPost',
  families = 'families',
  meetFamilies = 'meetFamilies',
  sentRequests = 'sentRequests',
  receivedRequests = 'receivedRequests',
  familyProfile = 'familyProfile',
  editFamilyProfile = 'editFamilyProfile',
  communities = 'communities',
  community = 'community',
  communityPost = 'communityPost',
  inviteToCommunity = 'inviteToCommunity',
  createCommunityPost = 'createCommunityPost',
  editCommunityPost = 'editCommunityPost',
  editCommunity = 'editCommunity',
  createCommunity = 'createCommunity',
  notifications = 'notifications',
  dependentNotifications = 'dependentNotifications',
  profile = 'profile',
  addFamilyMember = 'addFamilyMember',
  viewPublicProfile = 'viewPublicProfile',
  chat = 'chat',
  chats = 'chats',
  newChat = 'newChat',
  dependentChat = 'dependentChat',
  settings = 'settings',
  blockedUsers = 'blockedUsers',
}
