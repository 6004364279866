import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  ConnectionInvitesByFamilyQuery,
  ConnectionInvitesByFamilyQueryVariables,
} from "../../../__generated__/graphql";

const useConnectionByFamily = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "connectionInvitesByFamily";

  const handleOnCompleted = (data: ConnectionInvitesByFamilyQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.connection.connectionInvitesByFamily,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [connectionInvitesByFamily] = useLazyQuery(
    api.query.connection.CONNECTION_INVITES_BY_FAMILY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  return (args: ConnectionInvitesByFamilyQueryVariables) => {
    if (!state.connection.connectionInvitesByFamily.loading) {
      connectionInvitesByFamily({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useConnectionByFamily;
