import React, {
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_ui/button.scss';
import {
  colorsOptions,
  monoColorOptions,
  statusColorOptions,
} from '../../types/theme/colors';
import {
  ButtonFlourishHorizontal,
  ButtonFlourishVertical,
  ButtonShape,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../types/_ui/button';
import { IconAnimationStyle } from './Icon';
import { Div, Icon } from './index';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { BaseRoute } from '../../navigation/routes';
import { useStore } from '../../hooks/useStore';
import get from '../../utils/utilityFunctions/get';

export interface ButtonProps {
  children?: React.ReactNode;
  type?: ButtonType;
  variant?: ButtonVariant;
  shape?: ButtonShape;
  size?: ButtonSize;
  color?: colorsOptions | monoColorOptions | statusColorOptions;
  fullWidth?: boolean;
  disabled?: boolean;
  imageStart?: React.ReactElement;
  imageEnd?: React.ReactElement;
  flourish?: boolean;
  flourishLocationX?: ButtonFlourishHorizontal;
  flourishLocationY?: ButtonFlourishVertical;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconOnly?: boolean;
  routeTo?: BaseRoute;
  active?: boolean;
  iconProps?: {
    position: 'start' | 'end' | undefined;
    icon: IconDefinition | undefined;
    animationStyle?: IconAnimationStyle;
    timeout?: number;
    color?: colorsOptions | monoColorOptions;
  } | null;
}
const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  variant = 'contained',
  shape = 'rounded',
  color = 'primary',
  fullWidth = false,
  disabled = false,
  className,
  iconProps = {},
  imageStart,
  imageEnd,
  flourishLocationX,
  flourishLocationY,
  flourish,
  onClick,
  iconOnly = false,
  routeTo,
  active = false,
  size = 'md',
}) => {
  const { state } = useStore();
  const [animate, setAnimate] = useState<IconAnimationStyle>(
    iconProps?.animationStyle || null,
  );
  const navigate = useCustomNavigate();
  const {
    position,
    icon,
    animationStyle = {},
    timeout,
    color: iconColor,
  } = iconProps || {};
  const iconStart = position === 'start';
  const iconEnd = position === 'end';

  useEffect(() => {
    if (timeout)
      setTimeout(() => {
        setAnimate(null);
      }, timeout);
  }, []);

  return (
    <button
      onClick={routeTo ? () => navigate(routeTo) : onClick}
      className={[
        'button',
        state.app.themeMode,
        type,
        variant,
        shape,
        size,
        fullWidth ? 'fullWidth' : '',
        color,
        className,
        flourish && 'withFlourish',
        iconStart ? 'iconStart' : '',
        iconEnd ? 'iconEnd' : '',
        iconOnly ? 'iconOnly' : '',
        active ? 'active' : '',
        disabled ? 'disabled' : '',
        get(state, 'app.mobile.isNative', false)
          ? 'nativeButton'
          : '',
      ].join(' ')}
      disabled={disabled}
    >
      {flourish && (
        <Div
          className={`flourish flourish-${flourishLocationY} flourish-${flourishLocationX}`}
        />
      )}
      {imageStart && imageStart}
      {icon && iconStart && (
        <Icon
          icon={icon}
          animationStyle={animationStyle}
          color={iconColor}
        />
      )}
      {children && children}
      {icon && iconEnd && (
        <Icon
          icon={icon}
          animationStyle={animationStyle}
          color={iconColor}
        />
      )}
      {imageEnd && imageEnd}
    </button>
  );
};

export default Button;
