import { Div } from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import routes from '../../navigation/routes';
import CommunityForm, {
  CreateEditCommunityInputs,
} from '../../forms/community/CommunityForm';
import { UpdateCommunityMutationVariables } from '../../__generated__/graphql';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const EditCommunity = () => {
  const { state, actions } = useStore();
  const params = useParams();
  const navigate = useCustomNavigate();

  const onSubmit = (args: CreateEditCommunityInputs) => {
    const baseUpdateArgs: Partial<UpdateCommunityMutationVariables> =
      {
        isPrivate: args.isPrivate,
        uuid: state.community.community.data?.uuid,
      };
    if (
      args.name &&
      args.name !== state.community.community.data?.name
    ) {
      baseUpdateArgs.name = args.name;
    }
    if (
      args.description &&
      args.description !==
        state.community.community.data?.description
    ) {
      baseUpdateArgs.description = args.description;
    }
    if (args.image) {
      baseUpdateArgs.image = args.image[0];
    }
    actions.community.mutation.updateCommunity(
      baseUpdateArgs as UpdateCommunityMutationVariables,
    );
  };

  useEffect(() => {
    if (!state.community.createCommunity.data) {
      if (params.communityId) {
        actions.community.query.community({
          communityUuid: params.communityId,
        });
      } else {
        navigate(routes.communities);
      }
    }
  }, []);

  return (
    <Div>
      <CommunityForm
        onSubmit={onSubmit}
        loading={
          state.community.community.loading ||
          state.community.updateCommunity.loading ||
          false
        }
        existingCommunity={
          state.community.createCommunity.data ||
          state.community.community.data
        }
      />
    </Div>
  );
};

export default EditCommunity;
