import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import { CityQuery, CityQueryVariables } from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useCity = (state: AppStoreState, dispatch: Dispatch<ReducerActions>) => {
  const parent = "common";
  const type = "city";

  const handleOnCompleted = (data: CityQuery) => {
    storage.saveToStorage("city", data.common.city);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.city,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [city] = useLazyQuery(api.query.common.CITY, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: CityQueryVariables) => {
    if (!state.common.city.loading) {
      city({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCity;
