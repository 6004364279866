import { AppStoreState, ReducerActions } from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { LogoutMutation, LogoutMutationVariables } from '../../../__generated__/graphql';
import api from '../../../api';
import storage from '../../../utils/storage';
import routes from '../../../navigation/routes';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const useLogout = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'logout';
  const navigate = useCustomNavigate();

  const handleOnCompleted = async (data: LogoutMutation) => {
    dispatch({
      parent: 'app',
      type: 'closeSideNav',
      payload: {
        sideBarOpen: false,
      },
    });
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.profile.logout,
      },
    });
    storage.clearStorage().then(() => navigate(routes.signIn));
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: { loading: false, error: error.message },
    });
  };

  const [apiFunction] = useMutation(
    api.mutation.profile.LOGOUT,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache'
    },
  );

  return (args: LogoutMutationVariables) => {
    if (!state.profile.logout.loading) {
      apiFunction({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useLogout;
