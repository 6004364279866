import React, { useEffect, useState } from 'react';
import {
  Button,
  Div,
  FlexRow,
  Icon,
  Text,
} from '../components/_ui';
import { useStore } from '../hooks/useStore';
import '../styles/components/_common/messageProvider.scss';
import { capitalizeChar0 } from '../utils/stringUtils';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import useCustomNavigate from '../hooks/useCustomNavigate';
import routes from '../navigation/routes';
import storage from '../utils/storage';

type MessageType = 'error' | 'warn' | 'success';
const MessageProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { state, actions } = useStore();
  const [currentMessage, setCurrentMessage] = useState<
    string | null
  >(null);
  const [messageType, setMessageType] = useState<MessageType>();
  const navigate = useCustomNavigate();

  const {
    serverError,
    errorMessage,
    warnMessage,
    successMessage,
  } = state.app.messages || {};

  const handleCloseMessage = () => {
    setCurrentMessage(null);
    actions.app.resetMessages();
  };

  const handleMessage = (message: string, type: MessageType) => {
    const timer = setTimeout(() => {
      handleCloseMessage();
    }, 5000);

    setCurrentMessage(message);
    setMessageType(type);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (serverError) {
      return handleMessage(serverError, 'error');
    }
    if (errorMessage) {
      if (
        errorMessage === process.env.REACT_APP_SESSION_EXPIRED
      ) {
        storage.clearStorage().then(() => {
          navigate(routes.signIn);
          return handleMessage(
            "You're session has expired, please log back in.",
            'error',
          );
        });
      }
      return handleMessage(errorMessage, 'error');
    }
    if (warnMessage) {
      return handleMessage(warnMessage, 'warn');
    }
    if (successMessage) {
      return handleMessage(successMessage, 'success');
    }
  }, [serverError, errorMessage, warnMessage, successMessage]);

  return (
    <Div>
      {currentMessage && (
        <Div
          className={[
            'messageContainer',
            messageType,
            state.app.themeMode,
          ].join(' ')}
        >
          <FlexRow>
            <Div>
              <Text variant={'h6'} color={messageType}>
                {capitalizeChar0(messageType || '')}
              </Text>
              <Text variant={'p'} color={'grey800'}>
                {currentMessage}
              </Text>
            </Div>
            <Button
              variant={'transparent'}
              color={'primary'}
              onClick={handleCloseMessage}
            >
              <Icon icon={faClose} />
            </Button>
          </FlexRow>
        </Div>
      )}
      {children}
    </Div>
  );
};

export default MessageProvider;
