import common from './common';
import community from './community';
import connection from './connection';
import chat from './chat';
import family from './family';
import post from './post';
import profile from './profile';
import tag from './tag';

const mutation = {
  common,
  community,
  connection,
  family,
  post,
  profile,
  tag,
  chat,
};

export default mutation;
