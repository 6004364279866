import { useEffect, RefObject, MouseEventHandler } from "react";

function useClickOutside(
  ref: RefObject<HTMLElement | null>,
  onClickOutside: MouseEventHandler<HTMLButtonElement>,
  condition: boolean = true,
): void {
  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        condition
      ) {
        onClickOutside(event);
      }
    }
    setTimeout(() => {
      // used to ensure that the condition is updated prior to attaching the DOM event. This ensures that the modal, in this instance, does not automatically close.
      document.addEventListener("click", handleClickOutside);
    }, 250);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export default useClickOutside;
