import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import { GetPostPartsVars } from '../../types/reducers/post';
import routes from '../../navigation/routes';
import useViewPost from './api/useViewPost';
import usePostComments from './api/usePostComments';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const useGetPostParts = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const navigate = useCustomNavigate();
  const viewPost = useViewPost(state, dispatch);
  const postComments = usePostComments(state, dispatch);

  return ({ postId, uuid, post }: GetPostPartsVars) => {
    if (postId && !uuid && !post) {
      // fetch post comments and post by postUUID
      viewPost({ postUuid: postId });
      postComments({ postUuid: postId });
    } else if (uuid != null && post) {
      // fetch comments by passed post record.
      postComments({ postUuid: uuid });
    } else {
      // there is nothing, error out and return to feed.
      dispatch({
        parent: 'app',
        type: 'setErrorMessage',
        payload: state.app.systemMessages?.errors.post
          .doesNotExist as string,
      });
      navigate(routes.feed);
    }
  };
};

export default useGetPostParts;
