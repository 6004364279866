import React, { MouseEventHandler } from 'react';
import { Button } from './index';
import { ButtonVariant } from '../../types/_ui/button';
import {
  colorsOptions,
  monoColorOptions,
} from '../../types/theme/colors';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_ui/pill.scss';

interface PillProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title: React.ReactNode;
  variant?: Exclude<ButtonVariant, 'transparent'>;
  color?: colorsOptions | monoColorOptions;
  imageStart?: React.ReactElement;
  imageEnd?: React.ReactElement;
  iconStart?: IconDefinition;
  iconEnd?: IconDefinition;
  active?: boolean;
}

const Pill: React.FC<PillProps> = ({
  onClick,
  title,
  variant,
  color,
  imageStart,
  imageEnd,
  iconStart,
  iconEnd,
  active = false,
}) => {
  return (
    <Button
      size={'sm'}
      className={'pill'}
      active={active}
      onClick={onClick}
      variant={variant}
      shape={'round'}
      color={color}
      imageStart={imageStart}
      imageEnd={imageEnd}
      iconProps={{
        position: (iconEnd && 'end') || (iconStart && 'start'),
        icon: iconEnd || iconStart,
      }}
    >
      {title}
    </Button>
  );
};

export default Pill;
