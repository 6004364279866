import {
  Button,
  Card,
  Divider,
  FlexRow,
  Icon,
  Loader,
  Text,
} from '../../components/_ui';
import {
  faCheckCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { SyntheticEvent } from 'react';
import routes from '../../navigation/routes';
import '../../styles/pages/chats.scss';
import { AvatarAndName } from '../../components/_common';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useStore } from '../../hooks/useStore';
import { getNotificationDateStamp } from '../../utils/dateUtils';
import { ChatStatusEnumType } from '../../__generated__/graphql';
import isEmpty from '../../utils/utilityFunctions/isEmpty';

const Chats = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();
  const navigateToChat =
    (chatId: string) => (e: SyntheticEvent) => {
      navigate(routes.chat, [chatId]);
    };

  const handleNavigateNewChat = () => {
    actions.chat.clearChatTranscript();
    navigate(routes.newChat);
  };

  return (
    <Card>
      {state.chat.chats.loading && (
        <FlexRow>
          <Loader position={'center'} />
        </FlexRow>
      )}
      {/*<MyFamilySelection*/}
      {/*  fetchOnCategoryChange={fetchOnCategoryChange}*/}
      {/*  fetchOnFamilyMemberChange={fetchOnFamilyMemberChange}*/}
      {/*/>*/}
      {!state.chat.chats.loading &&
        isEmpty(state.chat.chats.data) && (
          <FlexRow column centerY centerX>
            <Text variant={'h4'} color={'grey400'}>
              You're not chatting with anyone yet!
            </Text>
            <Text variant={'p'} color={'grey800'}>
              Click the plus button to chat with connections
            </Text>
          </FlexRow>
        )}
      {(state.chat.chats.data || []).map((chat) => {
        if (!chat) {
          return null;
        }
        const myProfileUUID =
          state.profile.familyProfile.data?.profile.uuid;
        const isSender = myProfileUUID === chat.sender_uuid;
        const profile = isSender
          ? {
              uuid: chat.receiver_uuid,
              image_url: chat.receiver_image_url,
              nickname: chat.receiver_nickname,
            }
          : {
              uuid: chat.sender_uuid,
              image_url: chat.sender_image_url,
              nickname: chat.sender_nickname,
            };
        const isBlocked =
          (
            state.common.blockedUsersUUIDS.blockedUserUUIDs || []
          ).indexOf(profile.uuid) > -1;
        return (
          <FlexRow
            column
            className={'chatMessage'}
            onClick={navigateToChat(profile.uuid)}
            key={chat.chat_uuid}
          >
            <FlexRow inline className={'chatMeta'}>
              <FlexRow inline>
                <AvatarAndName
                  url={profile.image_url}
                  alt={isBlocked ? 'Blocked' : profile.nickname}
                  name={isBlocked ? 'Blocked' : profile.nickname}
                  textVariant={'h5'}
                />
                {!chat.read && !isSender && (
                  <Text
                    variant={'span'}
                    color={'primary'}
                    weight={800}
                  >
                    new
                  </Text>
                )}
              </FlexRow>
              <FlexRow inline>
                {chat.status === ChatStatusEnumType.Sent && (
                  <Icon
                    icon={faCheckCircle}
                    color={'quaternary'}
                  />
                )}
                {/*{chat.status === ChatStatusEnumType.Ignored && (*/}
                {/*  <Icon icon={faEyeSlash} color={'grey200'} />*/}
                {/*)}*/}
                <Text variant={'span'} color={'grey200'}>
                  {getNotificationDateStamp(chat.created_at)}
                </Text>
              </FlexRow>
            </FlexRow>
            <Text variant={'p'} color={'grey800'}>
              {isSender
                ? `${chat.message.slice(0, 50)} ${chat.message.length >= 50 ? '...' : ''}`
                : 'Blocked'}
            </Text>
            <Divider color={'grey50'} />
          </FlexRow>
        );
      })}
      <FlexRow centerY centerX>
        <Button
          variant={'transparent'}
          onClick={handleNavigateNewChat}
        >
          <Icon
            icon={faPlusCircle}
            color={'primary'}
            height={45}
            width={45}
          />
        </Button>
      </FlexRow>
    </Card>
  );
};

export default Chats;
