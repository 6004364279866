import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateNewProfileMutation,
  CreateNewProfileMutationVariables,
} from '../../../__generated__/graphql';
import api from '../../../api';
import routes from '../../../navigation/routes';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const useCreateNewProfile = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const [password, setPassword] = useState<string | null>(null);
  const parent = 'profile';
  const type = 'createNewProfile';
  const navigate = useCustomNavigate();

  const handleOnCompleted = (data: CreateNewProfileMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        ...data.profile.createNewProfile,
        loading: false,
      },
    });
    navigate(routes.signIn, null, {
      state: {
        username:
          data.profile.createNewProfile.email ||
          data.profile.createNewProfile.nickname,
        password,
      },
    });
    setPassword(null);
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createProfile] = useMutation(
    api.mutation.profile.CREATE_NEW_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createNewProfile',
        },
      },
    },
  );

  return (args: CreateNewProfileMutationVariables) => {
    if (!state.profile.createNewProfile.loading) {
      createProfile({
        variables: args,
      });
      setPassword(args.password);
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateNewProfile;
