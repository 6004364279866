import { gql } from '../../../__generated__';

const NOTIFICATION_BY_PROFILE = gql(`
  query Notification_content($since: String, $refine: sortAndFilterBaseArgs) {
    notification {
      notificationByProfile(since: $since, refine: $refine) {
        data {
          notification_content {
            ... on notificationCommentLikeObjectType {
              comment_type
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              original_comment
              comment_uuid
              nickname
              comment_create_date
              post_uuid
            }
            ... on notificationCommentReplyObjectType {
              comment_type
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              reply
              comment_uuid
              original_comment
              nickname
              comment_create_date
              post_uuid
            }
            ... on notificationFamilyObjectType {
              family_type
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
            }
            ... on notificationPostCommentObjectType {
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              comment_uuid
              comment
              nickname
              post_uuid
              post_type
              community_name
              community_uuid
              post_content {
                uuid
                title
                subtitle
              }
            }
            ... on notificationPostLikeObjectType {
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              like_uuid
              nickname
              post_uuid
              post_type
              community_name
              community_uuid
              post_content {
                uuid
                title
                subtitle
              }
            }
            ... on communityRequestObjectType {
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              name
              community_status
              community_uuid
              role
            }
            ... on communityInviteObjectType {
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              name
              community_status
              community_uuid
              role
              nickname
            }
            ... on notificationConnectionObjectType {
              connection_type
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
              connection_status
              match_criteria
              name
            }
            ... on baseNotificationObjectType {
              uuid
              created_at
              updated_at
              type
              status
              notification_type
              notification_status
            }
          }
        }
      }
    }
  }
`);

const notification = {
  NOTIFICATION_BY_PROFILE,
};

export default notification;
