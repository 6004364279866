import { Button, Div, FlexRow, Image, Text } from '../_ui';
import {
  faArrowLeft,
  faBars,
  faClose,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import routes from '../../navigation/routes';
import '../../styles/components/_common/appHeader.scss';
import { useStore } from '../../hooks/useStore';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useNavigate, useParams } from 'react-router-dom';
import { AppPagesEnum } from '../../types/pages';

const AppHeader = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();
  const nativeNavigate = useNavigate();
  const params = useParams();
  const toggleSideBar = () => {
    if (state.app.sideBarOpen) {
      actions.app.closeSideNav();
    } else {
      actions.app.openSideNav();
    }
  };

  const profileImage =
    state.profile.familyProfile.data?.family_member
      .profile_image_url ||
    '/defaultImages/defaultFamilyProfileImage.jpg';
  const profileImageAlt =
    state.profile.familyProfile.data?.family_member.first_name;
  const canNavigateBack = state.app.prevPages.length > 0;

  const navigateBack = () => {
    if (canNavigateBack) {
      const route =
        routes[
          state.app.prevPages[state.app.prevPages.length - 1]
        ];
      if (route.name === AppPagesEnum.signIn) {
        return;
      }
      const previousUrl =
        state.app.prevUrls[state.app.prevUrls.length - 1];

      nativeNavigate(previousUrl);
      actions.app.navigateBack(route.name);
    }
  };

  return (
    <Div className={`appHeader ${state.app.themeMode}`}>
      <FlexRow inline className={'menuButton leftAppHeader'}>
        {!state.app.sideBarOpen &&
          // appState.prevPage != null &&
          canNavigateBack && (
            <Button
              shape={'round'}
              variant={'transparent'}
              color={'grey800'}
              className={'appBackButton'}
              iconProps={{
                position: 'start',
                icon: faArrowLeft,
              }}
              onClick={navigateBack}
            />
          )}
        {state.app.mobile.isMobileWidth && (
          <Button
            shape={'round'}
            variant={'transparent'}
            color={'grey800'}
            iconProps={{
              position: 'start',
              icon: state.app.sideBarOpen ? faClose : faBars,
            }}
            onClick={toggleSideBar}
          />
        )}
      </FlexRow>
      <Div className={'pageHeader'}>
        <Text variant={'h5'} color={'grey800'}>
          {routes[state.app.currentPage].displayName}
        </Text>
      </Div>
      <FlexRow
        inline
        className={'appBarProfileMessaging rightAppHeader'}
      >
        <Button
          shape={'round'}
          variant={'transparent'}
          color={'grey800'}
          iconProps={{
            position: 'start',
            icon: faComment,
          }}
          onClick={() => navigate(routes.chats)}
        />
        <Button
          variant={'transparent'}
          shape={'round'}
          onClick={() => navigate(routes.profile)}
        >
          <Image
            className={[
              state.app.currentPage === 'profile'
                ? 'active'
                : '',
              'profileImage',
            ].join(' ')}
            height={25}
            width={25}
            src={profileImage}
            alt={`${profileImageAlt} profile image`}
            withBorderRadius
          />
        </Button>
      </FlexRow>
    </Div>
  );
};

export default AppHeader;
