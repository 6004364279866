import { Div, Icon, Button } from "../_ui";
import { faClose, faHand } from "@fortawesome/free-solid-svg-icons";
import React, { SyntheticEvent } from "react";
import { useStore } from "../../hooks/useStore";

const ConnectWithFamilyRow: React.FC<{
  handlePass: (e: SyntheticEvent) => void;
  handleConnect: (e: SyntheticEvent) => void;
}> = ({ handlePass, handleConnect }) => {
  return (
    <Div className={"flexRow"}>
      <Button
        iconOnly
        onClick={handlePass}
        variant={"contained"}
        color={"white"}
      >
        <Icon icon={faClose} color={"primary"} height={40} width={40} />
      </Button>
      <Button
        iconOnly
        onClick={handleConnect}
        variant={"contained"}
        color={"white"}
      >
        <Icon icon={faHand} color={"primary"} height={40} width={40} />
      </Button>
    </Div>
  );
};

export default ConnectWithFamilyRow;
