import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  ReportUserMutation,
  ReportUserMutationVariables,
} from '../../../__generated__/graphql';

const useReportUser = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'reportUser';

  const handleOnCompleted = (data: ReportUserMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        success: !!data.common.reportUser.success,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [reportUser] = useMutation(
    api.mutation.common.REPORT_USER,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: ReportUserMutationVariables) => {
    if (!state.common.reportUser.loading) {
      reportUser({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useReportUser;
