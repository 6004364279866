import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  UpdateCommunityMutation,
  UpdateCommunityMutationVariables,
} from '../../../__generated__/graphql';

const useUpdateCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'updateCommunity';

  const handleOnCompleted = (data: UpdateCommunityMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.updateCommunity,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createCommunity] = useMutation(
    api.mutation.community.UPDATE_COMMUNITY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createCommunity',
        },
      },
    },
  );

  return (args: UpdateCommunityMutationVariables) => {
    if (!state.community.updateCommunity.loading) {
      createCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useUpdateCommunity;
