import {
  CommonState,
  CommonStoreActions,
} from '../../types/reducers/common';

export const commonState: CommonState = {
  likeType: {},
  city: {},
  country: {},
  gender: {},
  generateImage: {},
  language: {},
  state: {},
  tag: {
    limit: 20,
    offset: 0,
  },
  timezone: {},
  school: {},
  schoolByUUID: {},
  reportUser: {},
  reportTypes: {},
  blockUser: {},
  blockedUsersUUIDS: {},
  blockedUsersProfiles: {},
  unblockUser: {},
};

export const commonActions: CommonStoreActions = {
  query: {
    likeType: () => null,
    timezone: () => null,
    tag: () => null,
    state: () => null,
    language: () => null,
    gender: () => null,
    country: () => null,
    city: () => null,
    school: () => null,
    schoolByUUID: () => null,
    reportTypes: () => null,
    blockedUsersUUIDS: () => null,
    blockedUsersProfiles: () => null,
  },
  mutation: {
    generateImage: () => null,
    reportUser: () => null,
    blockUser: () => null,
    unblockUser: () => null,
  },
};
