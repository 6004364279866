import { gql } from '../../../__generated__';

const CHAT_TRANSCRIPT = gql(`
  query ChatTranscript($profileUuid: String!, $refine: sortAndFilterBaseArgs) {
    chat {
      chatTranscript(profile_uuid: $profileUuid, refine: $refine) {
        data {
          chat_uuid
          chat_record_uuid
          message
          status
          receiver_nickname
          receiver_uuid
          receiver_family_member_uuid
          receiver_image_url
          sender_nickname
          sender_uuid
          sender_image_url
          sender_family_member_uuid
          read
          created_at
          updated_at
          deleted_at
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const NEW_TRANSCRIPT_MESSAGES = gql(`
  query NewTranscriptMessages($lastMessageUuid: String!) {
    chat {
      newTranscriptMessages(last_message_uuid: $lastMessageUuid) {
        chat_uuid
        chat_record_uuid
        message
        status
        receiver_nickname
        receiver_uuid
        receiver_image_url
        sender_nickname
        sender_uuid
        sender_image_url
        read
        created_at
        updated_at
        deleted_at
      }
    }
  }
`);

const CHATS = gql(`
  query Chats($refine: sortAndFilterBaseArgs, $lastMessageUuid: String) {
    chat {
      chats(refine: $refine, last_message_uuid: $lastMessageUuid) {
        data {
          chat_uuid
          chat_record_uuid
          message
          status
          receiver_nickname
          receiver_uuid
          receiver_image_url
          sender_nickname
          sender_uuid
          sender_image_url
          read
          created_at
          updated_at
          deleted_at
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const CHAT_CONNECTIONS = gql(`
  query ChatConnections {
    chat {
      chatConnections {
        profile_uuid
        imageUrl
        nickname
        last_name
        first_name
        logged_in
        relation_type
      }
    }
  }
`);

const chat = {
  CHAT_TRANSCRIPT,
  NEW_TRANSCRIPT_MESSAGES,
  CHATS,
  CHAT_CONNECTIONS,
};

export default chat;
