import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useRemoveActiveConnection from './api/useRemoveActiveConnection';
import useIgnoreConnection from './api/useIgnoreConnection';
import useCreateConnection from './api/useCreateConnection';
import useApproveConnection from './api/useApproveConnection';
import useMeetFamilyCards from './api/useMeetFamilyCards';
import useConnectionProfile from './api/useConnectionProfile';
import useConnectionByFamily from './api/useConnectionByFamily';
import { ConnectionStoreActions } from '../../types/reducers/connection';
import useConnectionRequestsByFamily from './api/useConnectionRequestsByFamily';
import useConnectionInvitesByFamily from './api/useConnectionInvitesByFamily';
import useRevokeConnectionInvite from './api/useRevokeConnectionInvite';

export const useConnectionActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): ConnectionStoreActions => {
  return {
    query: {
      meetFamilyCards: useMeetFamilyCards(state, dispatch),
      connectionProfile: useConnectionProfile(state, dispatch),
      connectionByFamily: useConnectionByFamily(state, dispatch),
      connectionRequestsByFamily: useConnectionRequestsByFamily(
        state,
        dispatch,
      ),
      connectionInvitesByFamily: useConnectionInvitesByFamily(
        state,
        dispatch,
      ),
    },
    mutation: {
      removeActiveConnection: useRemoveActiveConnection(
        state,
        dispatch,
      ),
      ignoreConnection: useIgnoreConnection(state, dispatch),
      revokeConnectionInvite: useRevokeConnectionInvite(
        state,
        dispatch,
      ),
      createConnection: useCreateConnection(state, dispatch),
      approveConnection: useApproveConnection(state, dispatch),
    },
    setViewFamilyUUID: (familyUUID: string | null) => {
      dispatch({
        parent: 'connection',
        type: 'setViewFamilyUUID',
        payload: {
          uuid: familyUUID,
        },
      });
    },
  };
};

export default useConnectionActions;
