import { FlexRow, Text } from "../../_ui";
import React, { MouseEventHandler } from "react";
import { Maybe, Scalars } from "../../../__generated__/graphql";
import "../../../styles/components/_common/reactionRow.scss";

interface ReactionRowProps {
  handleClick: MouseEventHandler<HTMLDivElement>;
  reactions: Maybe<Array<Maybe<Scalars["String"]["output"]>>> | undefined;
  uuidKey: Maybe<string>;
}
const ReactionRow: React.FC<ReactionRowProps> = ({
  handleClick,
  reactions,
  uuidKey,
}) => {
  return (
    <FlexRow inline onClick={handleClick} className={"reactionRow"}>
      {reactions?.map((reaction, index) => (
        <Text variant={"span"} key={`${reaction}-${uuidKey}`}>
          {reaction}
        </Text>
      ))}
    </FlexRow>
  );
};

export default ReactionRow;
