import { AppStoreState, ReducerActions } from "../../types/reducers";
import { Dispatch } from "react";
import { AppPagesEnum } from "../../types/pages";

const useNavigateForwardToRoute = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  return (path: AppPagesEnum, windowPath: string) => {
    dispatch({
      parent: "app",
      type: "navigateForwardToRoute",
      payload: {
        currentPage: path,
        prevPage: state.app.currentPage,
        windowPath
      },
    });
  };
};
export default useNavigateForwardToRoute;
