import { Div } from '../_ui';
import { Outlet } from 'react-router-dom';
import { AppHeader, NavBar, SideNav } from './index';
import '../../styles/components/_common/pageLayout.scss';
import '../../styles/nativeApp/index.scss';
import { useStore } from '../../hooks/useStore';
import useFeedFetcher from '../../hooks/useFeedFetcher';
import get from '../../utils/utilityFunctions/get';

const PageLayout = () => {
  const { state } = useStore();
  // This is why the feed is being fetched on other pages. This might be causing an issue. checking if current page is "feed" but need to look into a better solution. Does not work on the Feed.tsx page I assume because its a scrollref and scroll is not being applied on anything but pageLayout via CSS
  const feedRef = useFeedFetcher();

  return (
    <Div
      className={[
        'pageLayout',
        state.app.themeMode,
        state.app.sideBarOpen ? 'open' : 'closed',
        `${state.app.currentPage}-container`,
        get(state, 'app.mobile.isNative', false)
          ? 'nativeApp'
          : 'webApp',
      ].join(' ')}
    >
      <SideNav />
      <Div className={'appContainer'}>
        <AppHeader />
        <Div className={'pageContentContainer'} ref={feedRef}>
          <Outlet />
        </Div>
        <NavBar />
      </Div>
    </Div>
  );
};

export default PageLayout;
