import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import {
  SchoolByUuidQuery,
  SchoolByUuidQueryVariables,
} from '../../../__generated__/graphql';

const useSchoolByUUID = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'schoolByUUID';

  const handleOnCompleted = (data: SchoolByUuidQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.schoolByUUID,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [schoolByUUID] = useLazyQuery(
    api.query.common.SCHOOL_BY_UUID,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: SchoolByUuidQueryVariables) => {
    if (!state.common.schoolByUUID.loading) {
      schoolByUUID({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useSchoolByUUID;
