import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  RespondToCommunityInvitationMutation,
  RespondToCommunityInvitationMutationVariables,
} from '../../../__generated__/graphql';

const useInviteManyConnectionsToCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'respondToCommunityInvitation';

  const handleOnCompleted = (
    data: RespondToCommunityInvitationMutation,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.respondToCommunityInvitation,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [respondToCommunityInvitation] = useMutation(
    api.mutation.community.RESPOND_TO_COMMUNITY_INVITATION,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name':
            'respondToCommunityInvitation',
        },
      },
    },
  );

  return (
    args: RespondToCommunityInvitationMutationVariables,
  ) => {
    if (!state.community.respondToCommunityInvitation.loading) {
      respondToCommunityInvitation({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useInviteManyConnectionsToCommunity;
