import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  MeetFamilyCardsQuery,
  MeetFamilyCardsQueryVariables,
} from "../../../__generated__/graphql";

const useMeetFamilyCards = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "meetFamilyCards";

  const handleOnCompleted = (data: MeetFamilyCardsQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.connection.meetFamilyCards,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [meetFamilyCards] = useLazyQuery(
    api.query.connection.MEET_FAMILY_CARDS,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  return (args: MeetFamilyCardsQueryVariables) => {
    if (!state.connection.meetFamilyCards.loading) {
      meetFamilyCards({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useMeetFamilyCards;
