import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  InviteToCommunityMutation,
  InviteToCommunityMutationVariables,
} from '../../../__generated__/graphql';

const useInviteToCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'inviteToCommunity';

  const handleOnCompleted = (
    data: InviteToCommunityMutation,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.inviteToCommunity,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [inviteToCommunity] = useMutation(
    api.mutation.community.INVITE_TO_COMMUNITY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: InviteToCommunityMutationVariables) => {
    if (!state.community.inviteToCommunity.loading) {
      inviteToCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useInviteToCommunity;
