import {
  ProfileState,
  ProfileStoreActions,
} from '../../types/reducers/profile';

export const profileState: ProfileState = {
  selectedProfile: undefined,
  editFamily: false,
  familyMemberOrFamilyUUID: '',
  chosenInterests: [],
  chosenCommunities: [],
  profile: {},
  login: {},
  logout: {},
  deleteAccount: {},
  createNewProfile: {},
  updateProfile: {},
  familyProfile: {},
  familyMemberProfile: {},
  checkIfVerified: {},
  checkIfOnboarded: {},
  viewPublicProfile: {},
};

export const profileActions: ProfileStoreActions = {
  setCurrentProfile: () => null,
  setEditFamily: () => null,
  setFamilyMemberOrFamilyUUID: () => null,
  resetInterests: () => null,
  addInterest: () => null,
  removeInterest: () => null,
  resetCommunities: () => null,
  addCommunity: () => null,
  removeCommunity: () => null,
  query: {
    checkIfOnboarded: () => null,
    familyProfile: () => null,
    familyMemberProfile: () => null,
    checkIfVerified: () => null,
    viewPublicProfile: () => null,
  },
  mutation: {
    login: () => null,
    createNewProfile: () => null,
    logout: () => null,
    updateProfile: async () => undefined,
    deleteAccount: () => null,
  },
};
