import {
  Button,
  Div,
  Divider,
  FlexRow,
  GrowableInput,
  Loader,
  Text,
} from '../../components/_ui';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useRef } from 'react';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { AvatarAndName } from '../../components/_common';
import '../../styles/pages/chat.scss';
import { useStore } from '../../hooks/useStore';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import routes from '../../navigation/routes';
import {
  getHumanReadableDateFromUnix,
  getNotificationDateStamp,
} from '../../utils/dateUtils';
import get from '../../utils/utilityFunctions/get';
import ChatTranscriptProvider from '../../providers/ChatTranscriptProvider';
import {
  ChatObjectType,
  ReportUserEnum,
} from '../../__generated__/graphql';
import useReportUser from '../../hooks/useReportUser';
import isEmpty from '../../utils/utilityFunctions/isEmpty';

const Chat = () => {
  const { chatProfileId } = useParams() as {
    chatProfileId: string;
  };
  const navigate = useCustomNavigate();
  const chatTranscriptRef = useRef<HTMLDivElement>();
  const { actions, state } = useStore();
  const reportUser = useReportUser();

  const myProfileUUID =
    state.profile.familyProfile.data?.profile.uuid;
  const profileId1 = get(
    state,
    'chat.chatTranscript.data[0].sender_uuid',
    myProfileUUID,
  );
  const profileId2 = chatProfileId;

  useEffect(() => {
    if (!chatProfileId) {
      actions.app.setErrorMessage(
        state.app.systemMessages?.errors.chat.chatDoesNotExist ||
          'Chat does not exist',
      );
      navigate(routes.chats);
      return;
    }
    actions.chat.clearChatTranscript();
    actions.chat.query.chatTranscript({
      refine: {
        limit: state.chat.chatTranscript.limit || 10,
        offset: 0,
      },
      profileUuid: chatProfileId,
    });
    // this should work to do on load of chats.
    if (chatTranscriptRef.current) {
      chatTranscriptRef.current.scrollTop =
        chatTranscriptRef?.current?.scrollHeight;
    }
  }, []);

  const showReportModal = () => {
    if ((state.chat.chatTranscript.data || []).length > 0)
      reportUser(
        chatProfileId,
        ReportUserEnum.Message,
        'report',
        get(
          state,
          'chat.chatTranscript.data[0].chat_uuid',
          'N/A',
        ),
      );
  };

  const sendChatMessage = (message: string) => {
    if (!isEmpty(message)) {
      actions.chat.mutation.sendChat({
        recipientProfileUuid:
          myProfileUUID === profileId1 ? profileId2 : profileId1,
        message,
      });
    } else {
      actions.app.setErrorMessage(
        "Can't send an empty message you silly goose.",
      );
    }
  };

  const isNative = state.app.mobile.isNative;
  const firstChatRecord: null | ChatObjectType = get(
    state,
    'chat.chatTranscript.data[0]',
    null,
  );
  const isFirstRecordSender = firstChatRecord
    ? myProfileUUID === firstChatRecord.sender_uuid
    : null;

  const isBlocked = useMemo(() => {
    const chats = state.chat.chatTranscript.data;
    if (!chats || isEmpty(chats)) return false;
    const firstChat: ChatObjectType | null = get(
      chats,
      '[0]',
      null,
    );
    const isSender = myProfileUUID === firstChat?.sender_uuid;

    return (
      (
        state.common.blockedUsersUUIDS.blockedUserUUIDs || []
      ).indexOf(
        isSender
          ? firstChat!.receiver_uuid
          : firstChat!.sender_uuid,
      ) > -1
    );
  }, [
    state.chat.chatTranscript.data,
    state.common.blockedUsersUUIDS.blockedUserUUIDs,
  ]);

  return (
    <ChatTranscriptProvider>
      <Div
        className={['chat', isNative ? 'nativeChat' : ''].join(
          ' ',
        )}
      >
        <Div
          className={[
            'transcript card',
            state.app.themeMode,
            isNative ? 'nativeTranscript' : '',
          ].join(' ')}
          ref={chatTranscriptRef}
        >
          {firstChatRecord && (
            <FlexRow
              centerX
              centerY
              column
              className={'chatHeader'}
            >
              <Divider color={'transparent'} />
              {isBlocked ? (
                <Text variant={'p'} color={'grey400'}>
                  Chat Redacted
                </Text>
              ) : (
                <Text variant={'p'} color={'grey400'}>
                  Chat With{' '}
                  {isFirstRecordSender
                    ? firstChatRecord.receiver_nickname
                    : firstChatRecord.sender_nickname}
                </Text>
              )}
              <Text variant={'span'} color={'grey100'}>
                created on{' '}
                {getHumanReadableDateFromUnix(
                  firstChatRecord.created_at,
                )}
              </Text>
              {/*// family selection here possibly.*/}
              <FlexRow
                centerX
                centerY
                inline
                className={'chatActionRow'}
              >
                {/*<Icon*/}
                {/*  icon={faShieldHalved}*/}
                {/*  color={'secondary'}*/}
                {/*  // onClick={showSecurityModal}*/}
                {/*  height={20}*/}
                {/*  width={20}*/}
                {/*/>*/}
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  iconProps={{
                    color: 'primary',
                    icon: faFlag,
                    position: 'end',
                  }}
                  onClick={showReportModal}
                  size={'sm'}
                >
                  <Text variant={'span'} color={'grey400'}>
                    Report
                  </Text>
                </Button>
              </FlexRow>
              <Divider color={'grey50'} />
            </FlexRow>
          )}
          {(state.chat.chatTranscript.data || []).map(
            (chat, index) => {
              if (!chat) return null;
              const isSender =
                myProfileUUID === chat.sender_uuid;
              return (
                <Div
                  className={[
                    isSender ? 'sent' : 'received',
                    'chatMsg',
                    state.app.themeMode,
                  ].join(' ')}
                  key={chat.chat_record_uuid}
                >
                  <FlexRow>
                    {isBlocked && !isSender ? (
                      <Text variant={'span'} color={'grey400'}>
                        Blocked
                      </Text>
                    ) : (
                      <AvatarAndName
                        url={chat.sender_image_url}
                        alt={chat.sender_nickname}
                        name={chat.sender_nickname}
                        navigateToProfile={!isSender}
                        familyMemberUUID={
                          chat.sender_family_member_uuid
                        }
                      />
                    )}
                    <FlexRow column inline>
                      <Text variant={'span'} color={'grey200'}>
                        {getNotificationDateStamp(
                          chat.updated_at,
                        )}
                      </Text>
                      {chat.created_at !== chat.updated_at && (
                        <Text variant={'span'} color={'grey200'}>
                          edited
                        </Text>
                      )}
                    </FlexRow>
                  </FlexRow>
                  <Text
                    variant={'p'}
                    color={'grey800'}
                    className={'message'}
                  >
                    {isBlocked && !isSender
                      ? 'Message Blocked'
                      : chat.message}
                  </Text>
                </Div>
              );
            },
          )}
          {state.chat.chatTranscript.loading && (
            <Loader position={'center'} color={'primary'} />
          )}
          {state.chat.chatTranscript.data &&
            state.chat.chatTranscript.data.length === 0 &&
            !state.chat.chatTranscript.loading && (
              <FlexRow centerX centerY inline>
                <Text variant={'p'} color={'grey800'}>
                  New Chat
                </Text>
              </FlexRow>
            )}
          {state.chat.sendChat.loading && (
            <Loader position={'center'} />
          )}
        </Div>
        {!isBlocked && (
          <Div className={'sendMessageInput'}>
            <GrowableInput
              saveMessage={sendChatMessage}
              loading={false}
            />
          </Div>
        )}
      </Div>
    </ChatTranscriptProvider>
  );
};

export default Chat;
