import {
  NotificationState,
  NotificationStoreActions,
} from '../../types/reducers/notification';

export const notificationState: NotificationState = {
  notificationByProfile: {
    limit: 15,
    data: [],
    viewed: true,
  },
};

export const notificationActions: NotificationStoreActions = {
  mutation: {},
  query: {
    notificationByProfile: () => null,
  },
  viewNotifications: () => null,
};
