import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  LeaveCommunityMutation,
  LeaveCommunityMutationVariables,
} from "../../../__generated__/graphql";

const useLeaveCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "leaveCommunity";

  const handleOnCompleted = (data: LeaveCommunityMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.leaveCommunity,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [leaveCommunity] = useMutation(api.mutation.community.LEAVE_COMMUNITY, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: LeaveCommunityMutationVariables) => {
    if (!state.community.leaveCommunity.loading) {
      leaveCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useLeaveCommunity;
