import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  LikeCommentMutation,
  LikeCommentMutationVariables,
  PostCommentsObjectType,
} from '../../../__generated__/graphql';

const useLikeComment = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'post';
  const type = 'likeComment';

  const handleOnCompleted = (data: LikeCommentMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
      },
    });
    // TODO fetch single comment and and apply that to the state.
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [likeComment] = useMutation(
    api.mutation.post.LIKE_COMMENT,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (
    args: LikeCommentMutationVariables,
    comment: PostCommentsObjectType,
    commentPath: string,
  ) => {
    if (!state.post.likeComment.loading) {
      likeComment({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
        comment,
        commentPath,
      },
    });
  };
};

export default useLikeComment;
