import React, { useState } from "react";
import { Button, Input } from "../_ui";

const AddComment = ({
  saveComment,
  loading,
}: {
  saveComment: (val: string) => void;
  loading: boolean;
}) => {
  const [comment, setComment] = useState<string>("");

  return (
    <>
      <Input
        className={"commentInput"}
        type={"text"}
        value={comment}
        onChange={(e) => {
          e.stopPropagation();
          setComment(e.target.value);
        }}
        maxLength={120}
        textSize={"sm"}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          if (comment) saveComment(comment);
        }}
        variant={"contained"}
        color={"secondary"}
        shape={"rounded"}
        size={"sm"}
      >
        {loading ? "Saving" : "Comment"}
      </Button>
    </>
  );
};

export default AddComment;
