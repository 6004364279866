import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SplashScreen } from '@capacitor/splash-screen';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// If the reducer is running twice and you need to ensure testing accurate reducer actions, remove React.StrictMode temporarily to ensure it only runs once. This will not happen in prod, its a failsafe to ensure bug finding in development mode.
// https://stackoverflow.com/a/61208638
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

document.addEventListener('DOMContentLoaded', () => {
  // Call hide after ensuring the app is fully loaded
  SplashScreen.hide();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
