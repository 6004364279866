import {
  Div,
  Divider,
  FlexRow,
  Icon,
  List,
  ListItem,
  Loader,
  Switch,
  Text,
} from '../_ui';
import { useStore } from '../../hooks/useStore';
import { useEffect, useState } from 'react';
import get from '../../utils/utilityFunctions/get';
import {
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

const ReportUser = () => {
  const { state, actions } = useStore();
  const [sectionsToHide, setSectionsToHide] = useState<string[]>(
    [],
  );

  useEffect(() => {
    if (!get(state, 'common.reportTypes.data', null)) {
      // fetch report types as they are not available yet.
      // store them in storage for use later.
      actions.common.query.reportTypes();
    }
  }, []);

  const showHideSection = (uuid: string) => {
    if (sectionsToHide.indexOf(uuid) > -1) {
      setSectionsToHide(
        sectionsToHide.filter((u) => u !== uuid),
      );
    } else {
      setSectionsToHide([...sectionsToHide, uuid]);
    }
  };

  const reportTypes = state.common.reportTypes.data || [];

  return (
    <Div>
      {state.common.reportTypes.loading && <Loader />}
      {reportTypes.length > 0 &&
        reportTypes.map((reportType) => (
          <Div>
            <FlexRow inline spreadX>
              <Text variant={'h4'} color={'grey800'}>
                {reportType.name}
              </Text>
              <Icon
                icon={
                  sectionsToHide.indexOf(reportType.uuid || '') >
                  -1
                    ? faChevronDown
                    : faChevronUp
                }
                onClick={() =>
                  showHideSection(reportType.uuid as string)
                }
              />
            </FlexRow>
            <Divider />
            {sectionsToHide.indexOf(reportType.uuid || '') >
            -1 ? null : (
              <List>
                {(reportType.sub_types || []).map(
                  (reportSubType) =>
                    reportSubType && (
                      <ListItem>
                        <FlexRow inline spreadX>
                          <Text variant={'h6'} color={'grey400'}>
                            {reportSubType?.name}
                          </Text>
                          <Switch
                            value={
                              get(
                                state,
                                'app.userToReport.reportSubTypeUUID',
                                null,
                              ) === reportSubType.uuid
                            }
                            onChange={() =>
                              actions.app.setUserToReport({
                                reportTypeUUID:
                                  reportType.uuid as string,
                                reportSubTypeUUID:
                                  reportSubType.uuid as string,
                              })
                            }
                          />
                        </FlexRow>
                      </ListItem>
                    ),
                )}
              </List>
            )}
          </Div>
        ))}
    </Div>
  );
};

export default ReportUser;
