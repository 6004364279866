import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  JoinCommunitiesMutation,
  JoinCommunitiesMutationVariables,
} from '../../../__generated__/graphql';

const useJoinCommunities = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'joinCommunities';

  const handleOnCompleted = (data: JoinCommunitiesMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.joinCommunities,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [joinCommunities] = useMutation(
    api.mutation.community.JOIN_COMMUNITIES,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: JoinCommunitiesMutationVariables) => {
    if (!state.community.joinCommunities.loading) {
      joinCommunities({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useJoinCommunities;
