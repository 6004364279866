import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import {
  ChatConnectionsQuery,
  ChatConnectionsQueryVariables,
} from '../../../__generated__/graphql';

const useChatConnections = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'chat';
  const type = 'chatConnections';

  const handleOnCompleted = (data: ChatConnectionsQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        // @ts-ignore
        data: data?.chat?.chatConnections || [],
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [chatConnections] = useLazyQuery(
    api.query.chat.CHAT_CONNECTIONS,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: ChatConnectionsQueryVariables) => {
    if (!state.chat.chatConnections.loading) {
      chatConnections({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useChatConnections;
