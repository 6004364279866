import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import {
  ChatTranscriptQuery,
  ChatTranscriptQueryVariables,
} from '../../../__generated__/graphql';

const useChatTranscript = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'chat';
  const type = 'chatTranscript';

  const handleOnCompleted = (data: ChatTranscriptQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.chat?.chatTranscript,
        additive:
          (state.chat.chatTranscript.data?.length || 0) > 0,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [chatTranscript] = useLazyQuery(
    api.query.chat.CHAT_TRANSCRIPT,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: ChatTranscriptQueryVariables) => {
    if (!state.chat.chatTranscript.loading) {
      chatTranscript({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useChatTranscript;
