import React from "react";
import { Div, Text, Icon, Modal, Switch } from "../_ui";
import { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ManageSecurityModal: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState<
    "monitor" | "selfArchive" | "monitorAlerts" | null
  >(null);
  const [canMonitor, setCanMonitor] = useState<boolean>(false);
  const [canSelfArchive, setCanSelfArchive] = useState<boolean>(false);
  const [canMonitorAlerts, setCanMonitorAlerts] = useState<boolean>(false);

  const isMonitor = activeAccordion === "monitor";
  const isSelfArchive = activeAccordion === "selfArchive";
  const isMonitorAlerts = activeAccordion === "monitorAlerts";

  return (
    <Div className={"manageSecurity"}>
      <Div></Div>
      <Div>
        <Div className={[isMonitor && "open"].join(" ")}>
          <Div className={"flexRow"}>
            <Icon
              icon={isMonitor ? faChevronUp : faChevronDown}
              color={"grey100"}
              height={15}
              width={15}
            />
            <Text>Monitor Alerts</Text>
            <Switch
              value={canMonitor}
              onChange={() => setCanMonitor(!canMonitor)}
              asSwitch
            />
          </Div>
          <Text className={"description"}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
            ea quis ratione repudiandae. Aut facere odio officiis quidem quos
            repellat repellendus reprehenderit voluptates? Accusamus atque
            perferendis possimus? Eveniet, optio, quasi.
          </Text>
        </Div>
        <Div className={[isSelfArchive && "open"].join(" ")}>
          <Div className={"flexRow"}>
            <Icon
              icon={isSelfArchive ? faChevronUp : faChevronDown}
              color={"grey100"}
              height={15}
              width={15}
            />
            <Text>Monitor Alerts</Text>
            <Switch
              value={canSelfArchive}
              onChange={() => setCanSelfArchive(!canSelfArchive)}
              asSwitch
            />
          </Div>
          <Text className={"description"}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
            ea quis ratione repudiandae. Aut facere odio officiis quidem quos
            repellat repellendus reprehenderit voluptates? Accusamus atque
            perferendis possimus? Eveniet, optio, quasi.
          </Text>
        </Div>
        <Div className={[isMonitorAlerts && "open"].join(" ")}>
          <Div className={"flexRow"}>
            <Icon
              icon={isMonitorAlerts ? faChevronUp : faChevronDown}
              color={"grey100"}
              height={15}
              width={15}
            />
            <Text>Monitor Alerts</Text>
            <Switch
              value={canMonitorAlerts}
              onChange={() => setCanMonitorAlerts(!canMonitorAlerts)}
              asSwitch
            />
          </Div>
          <Text className={"description"}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
            ea quis ratione repudiandae. Aut facere odio officiis quidem quos
            repellat repellendus reprehenderit voluptates? Accusamus atque
            perferendis possimus? Eveniet, optio, quasi.
          </Text>
        </Div>
      </Div>
    </Div>
  );
};

export default ManageSecurityModal;
