import { AppPagesEnum } from '../types/pages';

export type BaseRoute = {
  url: string;
  dynamicUrl: <Type>(id: Type, secondId?: Type) => string;
  name: AppPagesEnum;
  displayName: string;
  permissions: string | null;
};

// type RouteWithChild<ChildRoutes> = {
//   children?: ChildRoutes;
// } & BaseRoute;
//
// type FeedRoutes = {
//   post: BaseRoute;
//   createPost: BaseRoute;
//   editPost: BaseRoute;
// };
//
// type CommunityRoutes = {
//   community: BaseRoute;
//   all: BaseRoute;
//   create: BaseRoute;
//   createPost: BaseRoute;
//   editPost: BaseRoute;
//   edit: BaseRoute;
//   inviteToCommunity: BaseRoute;
// };
//
// type FamilyRoutes = {
//   meet: BaseRoute;
//   sentRequests: BaseRoute;
//   receivedRequests: BaseRoute;
//   profile: BaseRoute;
// };
//
// type ChatsRoutes = {
//   chat: BaseRoute;
// };
//
// type ProfileRoutes = {
//   edit: BaseRoute;
//   addFamilyMember: BaseRoute;
//   editFamily: BaseRoute;
// };

// exclude these routes for now as they are not complete yet. Add back in as needed
export type WTRoutes = {
  [K in Exclude<
    keyof typeof AppPagesEnum,
    'dependentChat' | 'dependentNotifications'
  >]: BaseRoute;
};

// export type WTRoutes = {
//   home: BaseRoute;
//   createProfile: BaseRoute;
//   signIn: BaseRoute;
//   feed: RouteWithChild<FeedRoutes>;
//   notifications: BaseRoute;
//   communities: RouteWithChild<CommunityRoutes>;
//   families: RouteWithChild<FamilyRoutes>;
//   chats: RouteWithChild<ChatsRoutes>;
//   profile: RouteWithChild<ProfileRoutes>;
// };

// these are all route level because nesting them didnt help with any structure so thought fuck it, easier traversal with root level objects

const routes: WTRoutes = {
  [AppPagesEnum.home]: {
    url: '/',
    dynamicUrl: () => '/',
    displayName: 'Home',
    name: AppPagesEnum.home,
    permissions: null,
  },
  [AppPagesEnum.createProfile]: {
    url: '/createProfile',
    dynamicUrl: () => '/createProfile',
    displayName: 'Create Profile',
    name: AppPagesEnum.createProfile,
    permissions: null,
  },
  [AppPagesEnum.signIn]: {
    url: '/signIn',
    dynamicUrl: () => '/signIn',
    displayName: 'Sign In',
    name: AppPagesEnum.signIn,
    permissions: null,
  },
  // feed start
  [AppPagesEnum.feed]: {
    url: '/feed',
    dynamicUrl: () => '/feed',
    name: AppPagesEnum.feed,
    displayName: 'Happenings',
    permissions: null,
  },
  [AppPagesEnum.feedPost]: {
    url: '/feed/:postId',
    dynamicUrl: (id) => `/feed/${id}`,
    displayName: 'Feed Post',
    name: AppPagesEnum.feedPost,
    permissions: null,
  },
  [AppPagesEnum.createFeedPost]: {
    url: '/feed/create',
    dynamicUrl: () => '/feed/create',
    displayName: 'Create Post',
    name: AppPagesEnum.createFeedPost,
    permissions: null,
  },
  [AppPagesEnum.editFeedPost]: {
    url: '/feed/:postId/edit',
    dynamicUrl: (id) => `/feed/${id}/edit`,
    displayName: 'Edit Post',
    name: AppPagesEnum.editFeedPost,
    permissions: null,
  },
  // feed end
  [AppPagesEnum.notifications]: {
    url: '/notifications',
    dynamicUrl: () => '/notifications',
    name: AppPagesEnum.notifications,
    displayName: 'Notifications',
    permissions: null,
  },
  // end notifications
  // start communities
  [AppPagesEnum.communities]: {
    url: '/communities',
    dynamicUrl: () => '/communities',
    name: AppPagesEnum.communities,
    displayName: 'Communities',
    permissions: null,
  },
  [AppPagesEnum.community]: {
    url: '/communities/:communityId',
    dynamicUrl: (id) => `/communities/${id}`,
    displayName: 'Community',
    name: AppPagesEnum.community,
    permissions: null,
  },
  [AppPagesEnum.communityPost]: {
    url: '/communities/:communityId/:postId',
    dynamicUrl: (communityId, id) =>
      `/communities/${communityId}/${id}`,
    displayName: 'Community Post',
    name: AppPagesEnum.communityPost,
    permissions: null,
  },
  [AppPagesEnum.createCommunity]: {
    url: '/communities/create',
    dynamicUrl: () => '/communities/create',
    displayName: 'Create Community',
    name: AppPagesEnum.createCommunity,
    permissions: null,
  },
  [AppPagesEnum.editCommunity]: {
    url: '/communities/edit/:communityId',
    dynamicUrl: (id) => `/communities/edit/${id}`,
    displayName: 'Edit Community',
    name: AppPagesEnum.editCommunity,
    permissions: null,
  },
  [AppPagesEnum.createCommunityPost]: {
    url: '/communities/:communityId/post/create',
    dynamicUrl: (id) => `/communities/${id}/post/create`,
    displayName: 'Create Community post',
    name: AppPagesEnum.createCommunityPost,
    permissions: null,
  },
  [AppPagesEnum.editCommunityPost]: {
    url: '/communities/:communityId/post/:postId/edit',
    dynamicUrl: (communityId, postId) =>
      `/communities/${communityId}/post/${postId}/edit`,
    displayName: 'Edit Community post',
    name: AppPagesEnum.editCommunityPost,
    permissions: null,
  },
  [AppPagesEnum.allCommunities]: {
    url: '/communities/all/:categoryId',
    dynamicUrl: (id) => `/communities/all/${id}`,
    displayName: 'All Communities',
    name: AppPagesEnum.allCommunities,
    permissions: null,
  },
  [AppPagesEnum.inviteToCommunity]: {
    url: '/communities/:communityId/invite',
    dynamicUrl: (id) => `/communities/${id}/invite`,
    displayName: 'Invite to Community',
    name: AppPagesEnum.inviteToCommunity,
    permissions: null,
  },
  // finish communities
  // start families
  [AppPagesEnum.families]: {
    url: '/families',
    dynamicUrl: () => '/families',
    name: AppPagesEnum.families,
    displayName: 'Families',
    permissions: null,
  },
  [AppPagesEnum.meetFamilies]: {
    url: '/families/meet',
    dynamicUrl: () => '/families/meet',
    displayName: 'Meet Families',
    name: AppPagesEnum.meetFamilies,
    permissions: null,
  },
  [AppPagesEnum.sentRequests]: {
    url: '/families/sentRequests',
    dynamicUrl: () => '/families/sentRequests',
    displayName: 'Sent Requests',
    name: AppPagesEnum.sentRequests,
    permissions: null,
  },
  [AppPagesEnum.receivedRequests]: {
    url: '/families/receivedRequests',
    dynamicUrl: () => '/families/receivedRequests',
    displayName: 'Received Requests',
    name: AppPagesEnum.receivedRequests,
    permissions: null,
  },
  [AppPagesEnum.familyProfile]: {
    url: '/families/profile/:profileId',
    dynamicUrl: (id) => `/families/profile/${id}`,
    displayName: 'Family Profile',
    name: AppPagesEnum.familyProfile,
    permissions: null,
  },
  // end families
  [AppPagesEnum.chats]: {
    url: '/chat',
    dynamicUrl: () => '/chat',
    name: AppPagesEnum.chats,
    displayName: 'Chats',
    permissions: null,
  },
  [AppPagesEnum.chat]: {
    url: '/chat/:chatProfileId',
    dynamicUrl: (id) => `/chat/${id}`,
    name: AppPagesEnum.chat,
    displayName: 'Chat',
    permissions: null,
  },
  [AppPagesEnum.newChat]: {
    url: '/chat/new',
    dynamicUrl: () => '/chat/new',
    name: AppPagesEnum.newChat,
    displayName: 'Chat',
    permissions: null,
  },
  [AppPagesEnum.profile]: {
    url: '/profile',
    dynamicUrl: (id) => `/profile/${id}`,
    name: AppPagesEnum.profile,
    displayName: 'Profile',
    permissions: null,
  },
  [AppPagesEnum.editProfile]: {
    url: '/profile/:familyMemberUUID/edit',
    dynamicUrl: (familyMemberUUID) =>
      `/profile/${familyMemberUUID}/edit`,
    displayName: 'Edit Profile',
    name: AppPagesEnum.editProfile,
    permissions: null,
  },
  [AppPagesEnum.editFamilyProfile]: {
    url: '/profile/family/:familyUUID/edit',
    dynamicUrl: (familyUUID) =>
      `/profile/family/${familyUUID}/edit`,
    displayName: 'Edit Family Profile',
    name: AppPagesEnum.editFamilyProfile,
    permissions: null,
  },
  [AppPagesEnum.addFamilyMember]: {
    url: '/profile/addFamilyMember',
    dynamicUrl: () => '/profile/addFamilyMember',
    displayName: 'Add Family Member',
    name: AppPagesEnum.addFamilyMember,
    permissions: null,
  },
  // this is a general view for looking at others' profile pages.
  [AppPagesEnum.viewPublicProfile]: {
    url: `/viewPublicProfile/:familyMemberUUID`,
    dynamicUrl: (uuid) => `/viewPublicProfile/${uuid}`,
    name: AppPagesEnum.profile,
    displayName: 'Profile',
    permissions: null,
  },
  [AppPagesEnum.settings]: {
    url: `/settings`,
    dynamicUrl: () => `/settings`,
    name: AppPagesEnum.settings,
    displayName: 'Settings',
    permissions: null,
  },
  [AppPagesEnum.blockedUsers]: {
    url: `/settings/blockedUsers`,
    dynamicUrl: () => `/blockedUsers`,
    name: AppPagesEnum.blockedUsers,
    displayName: 'Blocked Users',
    permissions: null,
  },
};

export default routes;
