import useProfileActions from './profile';
import usePostActions from './post';
import {
  AppStoreActions,
  AppStoreState,
  ReducerActions,
} from '../types/reducers';
import { Dispatch } from 'react';
import useCommonActions from './common';
import useCommunityActions from './community';
import useConnectionActions from './connection';
import useFamilyActions from './family';
import useNotificationActions from './notification';
import useTagActions from './tag';
import useAppBaseActions from './app';
import useChatActions from './chat';

const useAppActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): AppStoreActions => {
  return {
    app: useAppBaseActions(state, dispatch),
    common: useCommonActions(state, dispatch),
    community: useCommunityActions(state, dispatch),
    connection: useConnectionActions(state, dispatch),
    family: useFamilyActions(state, dispatch),
    notification: useNotificationActions(state, dispatch),
    post: usePostActions(state, dispatch),
    profile: useProfileActions(state, dispatch),
    tag: useTagActions(state, dispatch),
    chat: useChatActions(state, dispatch),
  };
};

export default useAppActions;
