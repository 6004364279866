import { gql } from "../../../__generated__";

const REMOVE_ACTIVE_CONNECTION = gql(`
  mutation RemoveActiveConnection($connectionUuid: String!) {
    connection {
      removeActiveConnection(connection_uuid: $connectionUuid) {
        success
        uuid
      }
    }
  }
`);

const IGNORE_CONNECTION = gql(`
  mutation IgnoreConnection($connectionUuid: String!) {
    connection {
      ignoreConnection(connection_uuid: $connectionUuid) {
        success
        uuid
      }
    }
  }
`);

const CREATE_CONNECTION = gql(`
  mutation CreateConnection(
    $familyUuid: String!,
    $connect: Boolean!
  ) {
    connection {
      createConnection(
        family_uuid: $familyUuid, 
        connect: $connect
      ) {
        family1_uuid
        family2_uuid
        connection_profile_image_url
        connection_name
        family_members
        status
        match_criteria
        updated_at
        interests {
          uuid
          name
          tag_icon
        }
      }
    }
  }
`);

const APPROVE_CONNECTION = gql(`
  mutation ApproveConnection($connectionUuid: String!) {
    connection {
      approveConnection(connection_uuid: $connectionUuid) {
        family1_uuid
        family2_uuid
        connection_profile_image_url
        connection_name
        family_members
        status
        match_criteria
        updated_at
        interests {
          uuid
          name
          tag_icon
        }
        uuid
      }
    }
  }
`);

const REVOKE_CONNECTION_INVITE = gql(`
  mutation RevokeConnectionInvite($connectionUuid: String!) {
    connection {
      revokeConnectionInvite(connection_uuid: $connectionUuid) {
        success
        uuid
      }
    }
  }
`);

const connection = {
  REMOVE_ACTIVE_CONNECTION,
  IGNORE_CONNECTION,
  CREATE_CONNECTION,
  APPROVE_CONNECTION,
  REVOKE_CONNECTION_INVITE,
};

export default connection;
