import {
  Div,
  FlexRow,
  Icon,
  Input,
  Loader,
  Text,
} from './index';
import React, {
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import '../../styles/components/_ui/select.scss';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import get from '../../utils/utilityFunctions/get';
import useDebounce from '../../hooks/useDebounce';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { useStore } from '../../hooks/useStore';

export type ValueOptions =
  | string
  | number
  | undefined
  | ReactNode;
export type SelectOptionsProps = {
  value: ValueOptions;
  name: ValueOptions;
};

type SelectProps = {
  disabled?: boolean;
  options: SelectOptionsProps[];
  onChange: (option: SelectOptionsProps) => void;
  value: ValueOptions;
  label?: string;
  error?: string;
  name: string;
  className?: string;
  defaultValue?: string;
  selectStyleProps?: {
    attached: boolean;
  };
  searchable?: boolean;
  actionOnSearchComplete?: (searchTerm: string) => void;
  loading?: boolean;
};

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  value,
  label,
  error,
  name,
  disabled = false,
  className,
  defaultValue,
  selectStyleProps,
  searchable = false,
  actionOnSearchComplete,
  loading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] =
    useState<SelectOptionsProps | null>(null);
  const [filteredOptions, setFilteredOptions] =
    useState(options);
  const [searchTerm, setSearchTerm] = useState<
    undefined | string
  >(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { state } = useStore();

  const setOption =
    (option: SelectOptionsProps) => (e: SyntheticEvent) => {
      e.stopPropagation();
      setIsOpen(false);
      setActiveOption(option);
      onChange(option);
    };

  useEffect(() => {
    if (!searchable) return;
    if (debouncedSearchTerm) {
      const filteredOptions = options.filter((opt) =>
        `${opt.name}`
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase()),
      );
      setFilteredOptions(filteredOptions);

      if (actionOnSearchComplete && isEmpty(filteredOptions)) {
        actionOnSearchComplete(debouncedSearchTerm);
      }
    }
  }, [debouncedSearchTerm]);

  // This is used for new search terms coming in
  useEffect(() => {
    setSearchTerm(undefined);
    setFilteredOptions(options);
  }, [options]);

  const onClick = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const { attached = false } = selectStyleProps || {};

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Div
      className={`selectContainer ${className} ${disabled ? 'disabled' : ''} ${state.app.themeMode}`}
    >
      {label && (
        <Text
          className={'inputLabel'}
          variant={'p'}
          color={'grey400'}
        >
          {label}
        </Text>
      )}
      <FlexRow
        centerY
        inline
        spreadX
        className={`select ${state.app.themeMode}`}
        onClick={onClick}
      >
        <Text
          variant={'h3'}
          color={'grey800'}
          className={'selectValue'}
          weight={400}
        >
          {get(value, 'name', null) ||
            value ||
            defaultValue ||
            'Select...'}
        </Text>
        <Div
          className={[
            'selectChevron',
            isOpen ? 'chevronActive' : 'chevronInactive',
          ].join(' ')}
        >
          {!disabled && <Icon icon={faChevronDown} />}
        </Div>
        <Div
          className={[
            'customOptions',
            attached ? 'attached' : 'default',
            isOpen ? 'active' : '',
            state.app.themeMode,
          ].join(' ')}
        >
          {isOpen && (
            <Div>
              {searchable && (
                <FlexRow>
                  <Input
                    type={'text'}
                    onChange={handleSearch}
                    value={searchTerm}
                    label={'search'}
                  />
                </FlexRow>
              )}
              {loading && (
                <Loader position={'center'} color={'grey200'} />
              )}
              {filteredOptions.map((opt, index) => (
                <Div
                  key={`${opt.name}-${opt.value}-${index}`}
                  onClick={setOption(opt)}
                  className={[
                    'option',
                    activeOption?.value === opt.value
                      ? 'active'
                      : '',
                  ].join(' ')}
                >
                  {opt.name || opt.value}
                </Div>
              ))}
            </Div>
          )}
        </Div>
      </FlexRow>
      {error && (
        <Text variant={'h5'} color={'error'}>
          {error}
        </Text>
      )}
    </Div>
  );
};

export default Select;
