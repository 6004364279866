import { AppStoreState } from '../types/reducers';
import { ChatReducerActions } from '../types/reducers/chat/chatActions';
import isEmpty from '../utils/utilityFunctions/isEmpty';

const chatReducer = (
  state: AppStoreState,
  action: ChatReducerActions,
) => {
  switch (action.type) {
    case 'deleteMessage':
      return state;
    case 'sendChat':
      state.chat.sendChat.loading = !!action.payload.loading;
      if (action.payload.data) {
        state.chat.chatTranscript.data?.push(
          action.payload.data,
        );
      }
      return state;
    case 'chats':
      const chats = [...(state.chat.chats.data || [])];
      // need to check action.payload.additive to adjust the existing payload here.
      state.chat.chats.loading = !!action.payload.loading;
      if (action.payload.data && !isEmpty(action.payload.data)) {
        // get chat ids
        const existingChatIds = chats?.map(
          (c) => c && c.chat_uuid,
        );

        if (!isEmpty(existingChatIds)) {
          // chat ids are not empty,
          // if the current chat ids exists, remove the current records and replace them with the new ones at the top.
          const newChatIds = action.payload.data.map(
            (c) => c && c.chat_uuid,
          ) as string[];

          const chatUUIDSToRemove = (newChatIds || []).reduce<
            string[]
          >((acc, curCId) => {
            if (existingChatIds?.indexOf(curCId) >= 0) {
              return [...acc, curCId];
            }
            return acc;
          }, []);

          const filteredChats = chats.filter(
            (chat) =>
              chat &&
              !chatUUIDSToRemove.includes(chat.chat_uuid),
          );

          state.chat.chats.data = [
            ...action.payload.data,
            ...filteredChats,
          ];
        } else {
          // chatIds is empty, it is the first chat that is going into the state array;
          state.chat.chats.offset = action.payload.offset;
          state.chat.chats.total = action.payload.total;
          state.chat.chats.limit = action.payload.limit;
          state.chat.chats.data = action.payload.data;
        }
      }
      return state;
    case 'chatTranscript':
      state.chat.chatTranscript.loading =
        !!action.payload.loading;
      if (action.payload.error) {
        state.chat.chatTranscript.data = [];
      }
      if (action.payload.data) {
        // if (action.payload.additive) {
        // filter out chat_records that are not a part of this current transcript (Chat)
        // state.chat.chatTranscript.data?.unshift(
        //   ...action.payload.data,
        // );
        // const currentChatUUID: string = get(
        //   action,
        //   'payload.data[0].chat_uuid',
        //   null,
        // );
        // state.chat.chatTranscript.data = (
        //   state.chat.chatTranscript.data || []
        // ).filter(
        //   (chat) => chat && chat.chat_uuid !== currentChatUUID,
        // );
        // } else {
        state.chat.chatTranscript.data = action.payload.data;
        // }
        state.chat.chatTranscript.limit = action.payload.limit;
        state.chat.chatTranscript.offset = action.payload.offset;
        state.chat.chatTranscript.total = action.payload.total;
        state.chat.chatTranscript.phrase = action.payload.phrase;
      }
      return state;
    case 'newTranscriptMessages':
      if (action.payload.data) {
        // filter existing transcript for unique uuids because sendChat adds to this but will be retrieved whenever this fires.

        const newChatUUIDs = action.payload.data.map(
          (i) => i && i.chat_record_uuid,
        );
        // .map(i => i && i.uuid)

        state.chat.chatTranscript.data = [
          ...(state.chat.chatTranscript.data || []).filter(
            (chat) =>
              chat &&
              !newChatUUIDs.includes(chat.chat_record_uuid),
          ),
          ...action.payload.data,
        ];
      }
      return state;
    case 'chatConnections':
      state.chat.chatConnections.loading =
        action.payload.loading;
      if (action.payload.called)
        state.chat.chatConnections.called =
          action.payload.called;
      if (action.payload.data)
        state.chat.chatConnections.data = action.payload.data;
      return state;
    case 'clearChatTranscript':
      state.chat.chatTranscript.data = [];
      state.chat.chatTranscript.called = false;
      state.chat.chatTranscript.total = 0;
      state.chat.chatTranscript.limit = 15;
      state.chat.chatTranscript.offset = 0;
      return state;
    default:
      return state;
  }
};

export default chatReducer;
