import {
  PostState,
  PostStoreActions,
} from '../../types/reducers/post';

export const postState: PostState = {
  viewPost: {},
  myPosts: {},
  postsByCommunity: {
    limit: 20,
    offset: 0,
    data: [],
  },
  postFeedItemById: {},
  postFeed: {
    limit: 20,
    offset: 0,
    data: [],
    scrollPosition: 0,
  },
  postComments: {
    limit: 20,
    offset: 0,
    data: [],
  },
  commentComments: {
    limit: 10,
    offset: 0,
    data: [],
  },
  genericPostFeed: {
    limit: 20,
    offset: 0,
    data: [],
  },
  updateComment: {},
  replyToPost: {},
  replyToComment: {},
  likeComment: {},
  likePost: {},
  deleteComment: {},
  createComment: {},
  createPost: {},
  updatePost: {},
};

export const postActions: PostStoreActions = {
  query: {
    viewPost: () => null,
    postsByCommunity: () => null,
    postFeedItemById: () => null,
    postFeed: () => null,
    postComments: () => null,
    commentComments: () => null,
    genericPostFeed: () => null,
    myPosts: () => null,
  },
  mutation: {
    updateComment: () => null,
    replyToPost: () => null,
    replyToComment: () => null,
    deleteComment: () => null,
    createComment: () => null,
    likeComment: () => null,
    likePost: () => null,
    createPost: () => null,
    updatePost: () => null,
  },
  getPostParts: () => null,
  setReplyToCommentId: () => null,
  cancelReplyToComment: () => null,
  setPostFeedScrollPosition: () => null,
};
