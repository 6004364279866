import {
  Button,
  Div,
  Divider,
  FlexRow,
  Image,
  Loader,
  Text,
} from '../../components/_ui';
import { useParams } from 'react-router-dom';
import routes from '../../navigation/routes';
import { useEffect, useState } from 'react';
import { CommunityPostRenderer } from '../../components/communities';
import '../../styles/pages/community.scss';
import { useStore } from '../../hooks/useStore';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const Community = () => {
  const [showCommunityDetail, setShowCommunityDetail] =
    useState<boolean>(false);
  const { communityId } = useParams();
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();

  // fetch initial data
  useEffect(() => {
    // if id is not present in the url, should never hit this technically.
    if (communityId) {
      actions.community.query.community({
        communityUuid: communityId,
      });
    }
    // else {
    //   actions.app.setErrorMessage(
    //     state.app.systemMessages?.errors.community
    //       .notAvailable || 'Error',
    //   );
    //   navigate(routes.communities);
    // }
  }, [communityId]);

  // if error on fetch, navigate out
  useEffect(() => {
    if (state.community.community.error) {
      actions.app.setServerError(
        state.community.community.error,
      );
      navigate(routes.communities);
    }
  }, [state.community.community.error]);

  const navigateToInviteMember = () => {
    navigate<string>(routes.inviteToCommunity, [
      communityId as string,
    ]);
  };

  const navigateToEditCommunity = () => {
    navigate<string>(routes.editCommunity, [
      communityId as string,
    ]);
  };

  const community = state.community.community.data;

  return (
    <Div>
      {state.community.community.loading ? (
        <Loader position={'center'} color={'white'} />
      ) : (
        <Div
          className={[
            'communityHeader',
            state.app.themeMode,
            showCommunityDetail && 'showCommunityDetail',
          ].join(' ')}
        >
          <Div className={'headerImageContainer'}>
            <Image
              src={
                community?.image_url ||
                '/defaultCommunityImage.jpg'
              }
              alt={`${community?.name} Cover photo`}
            />
          </Div>
          <FlexRow
            column
            inline
            className={`communityDetail ${state.app.themeMode}`}
          >
            <Text variant={'h3'} color={'grey800'}>
              {community?.name}
            </Text>
            <FlexRow
              spreadX
              start={false}
              className={'communityMetaRow'}
            >
              <Text variant={'p'} color={'grey200'}>
                {community?.member_count} members |{' '}
                {community?.member_count_online || 0} online
              </Text>
              <Button
                variant={'transparent'}
                color={'primary'}
                onClick={() =>
                  setShowCommunityDetail(!showCommunityDetail)
                }
                size={'xs'}
              >
                {showCommunityDetail ? 'Hide' : 'View'} Details
              </Button>
            </FlexRow>
          </FlexRow>
          <Div
            className={`communityDescription ${
              showCommunityDetail ? 'show' : ''
            }`}
            style={{
              height: showCommunityDetail
                ? ((community?.description.length || 0) / 45) *
                    20 +
                  60 // 60 equals flexRow height + divider
                : 0,
              minHeight: showCommunityDetail ? 30 : 0,
            }}
          >
            <Text color={'grey400'}>
              {community?.description}
            </Text>
            <Divider color={'transparent'} />
            <FlexRow spreadX={false} centerY>
              <Button
                size={'sm'}
                variant={'outlined'}
                color={'primary'}
                onClick={navigateToInviteMember}
              >
                Invite Members
              </Button>
              {community?.role === 'admin' && (
                <Button
                  size={'sm'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={navigateToEditCommunity}
                >
                  Edit
                </Button>
              )}
            </FlexRow>
          </Div>
        </Div>
      )}
      <CommunityPostRenderer />
    </Div>
  );
};

export default Community;
