import { ChatState } from '../../types/reducers/chat/chatState';
import { ChatStoreActions } from '../../types/reducers/chat/chatActions';

export const chatState: ChatState = {
  deleteMessage: {},
  sendChat: {},
  chats: {
    data: [],
    offset: 0,
    total: 0,
  },
  chatTranscript: {
    data: [],
  },
  chatConnections: {
    data: null,
  },
};

export const chatActions: ChatStoreActions = {
  clearChatTranscript: () => null,
  query: {
    chatTranscript: () => null,
    chatConnections: () => null,
  },
  mutation: {
    deleteMessage: () => null,
    sendChat: () => null,
  },
};
