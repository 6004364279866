import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import storage from '../utils/storage';
import routes from '../navigation/routes';
import { jwtDecode } from 'jwt-decode';
import { Token } from '../types/system';
import { useStore } from '../hooks/useStore';
import { AppPagesEnum } from '../types/pages';
import useCustomNavigate from '../hooks/useCustomNavigate';

const AuthenticationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { state, actions } = useStore();

  const { errorMessage, serverError } = state.app.messages;

  const logoutUser = (errorMessage?: string) => {
    storage.clearStorage().then(() => {
      if (location.pathname.indexOf(AppPagesEnum.signIn) < 0) {
        navigate(routes.signIn);
        actions.app.resetMessages();
        actions.profile.mutation.logout({});
        actions.app.setErrorMessage(
          errorMessage || 'You have been logged out',
        );
      }
    });
  };

  // checks token on load
  useEffect(() => {
    storage.getFromStorage('token').then((token) => {
      if (!token) {
        logoutUser(
          state.app.systemMessages?.errors.common.notSignedIn,
        );
        return;
      }
      const { exp } = jwtDecode(token, {}) as Token;

      if (new Date(exp * 1000) < new Date()) {
        logoutUser(
          state.app.systemMessages?.errors.common
            .tokenExpiration,
        );
        return;
      }

      if (
        [
          routes.home.url,
          routes.signIn.url,
          routes.createProfile.url,
        ].includes(window.location.pathname)
      ) {
        navigate(routes.feed);
      }
    });
  }, []);

  // check authentication whenever error responses are returned
  useEffect(() => {
    const msg = errorMessage || serverError;
    if (msg && msg.indexOf('FF_FORCE_LOGOUT') > -1) {
      logoutUser(
        state.app.systemMessages?.errors.common.tokenExpiration,
      );
    }
  }, [errorMessage, serverError]);

  return children;
};

export default AuthenticationProvider;
