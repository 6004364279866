import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  CheckIfOnboardedQuery,
  CheckIfOnboardedQueryVariables,
} from '../../../__generated__/graphql';
import api from '../../../api';

const useCheckIfOnboarded = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'checkIfOnboarded';
  const handleOnCompleted = (data: CheckIfOnboardedQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.profile.checkIfOnboarded,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: { loading: false, error: error.name },
    });
  };

  const [checkIfOnboarded] = useLazyQuery(
    api.query.profile.CHECK_IF_ONBOARDED,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: CheckIfOnboardedQueryVariables) => {
    if (!state.profile.checkIfOnboarded.loading) {
      checkIfOnboarded({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCheckIfOnboarded;
