import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  ApproveConnectionMutation,
  ApproveConnectionMutationVariables,
} from "../../../__generated__/graphql";

const useApproveConnection = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "approveConnection";

  const handleOnCompleted = (data: ApproveConnectionMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.connection.approveConnection,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [approveConnection] = useMutation(
    api.mutation.connection.APPROVE_CONNECTION,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: ApproveConnectionMutationVariables) => {
    if (!state.connection.approveConnection.loading) {
      approveConnection({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
        connectionUUID: args.connectionUuid,
      },
    });
  };
};

export default useApproveConnection;
