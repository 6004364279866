import { Div } from '../../components/_ui';
import '../../styles/pages/createPost.scss';
import {
  CreatePostMutationVariables,
  PostTypeEnumType,
} from '../../__generated__/graphql';
import { useStore } from '../../hooks/useStore';
import PostForm, {
  CreateEditPostInputs,
} from '../../forms/feed/PostForm';

const CreatePost = () => {
  const { actions, state } = useStore();

  const onSubmit = (formValues: CreateEditPostInputs) => {
    const postType = formValues.type.toLowerCase();
    if (postType == null) {
      actions.app.setErrorMessage(
        'post type is required to create a post.',
      );
      return;
    } else {
      const baseCreateArgs: Partial<CreatePostMutationVariables> =
        {
          draft: formValues.draft,
          postType: postType as PostTypeEnumType,
          title: formValues.title,
          content: formValues.description,
        };
      if (
        formValues.link &&
        postType === PostTypeEnumType.Link
      ) {
        baseCreateArgs.link = formValues.link;
      }
      if (
        // postType === PostTypeEnumType.Video ||
        postType === PostTypeEnumType.Image &&
        formValues.file
      ) {
        baseCreateArgs.files = formValues.file;
      }
      actions.post.mutation.createPost(
        baseCreateArgs as CreatePostMutationVariables,
      );
    }
  };

  return (
    <Div>
      <PostForm
        onSubmit={onSubmit}
        error={state.post.createPost.error}
        loading={state.post.createPost.loading || false}
      />
    </Div>
  );
};

export default CreatePost;
