import React, { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { useLazyQuery } from '@apollo/client';
import api from '../api';
import { ChatsQueryVariables } from '../__generated__/graphql';
import get from '../utils/utilityFunctions/get';

const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { state, actions, dispatch } = useStore();
  const parent = 'chat';
  const type = 'chats';
  // const intervalTiming = 60000; // every 60 seconds
  const intervalTiming = 30000; // every 60 seconds

  const [fetchChats] = useLazyQuery(api.query.chat.CHATS, {
    onCompleted: (data) => {
      dispatch({
        parent,
        type,
        payload: {
          loading: false,
          ...data.chat?.chats,
        },
      });
    },
    onError: (error) => {
      dispatch({
        parent,
        type,
        payload: {
          loading: false,
          error: error.message,
        },
      });
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const getChats = () => {
      const queryVars: ChatsQueryVariables = {
        refine: {
          limit: 15,
          offset: 0,
        },
      };

      const lastChatUUID = get(
        state,
        'chat.chats.data[0].chat_record_uuid',
        null,
      );
      if (lastChatUUID) {
        queryVars.lastMessageUuid = lastChatUUID;
      }
      fetchChats({
        variables: queryVars,
      });
      dispatch({
        parent,
        type,
        payload: {
          loading: (state.chat.chats.data || []).length === 0,
        },
      });
    };
    // this is just in there so it doesn't fetch on refresh during development.
    if (state.chat.chats.data?.length === 0) {
      getChats();
    }
    const interval = setInterval(
      () => getChats(),
      intervalTiming,
    );
    return () => clearInterval(interval);
  }, []);

  return children;
};

export default ChatProvider;
