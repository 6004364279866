import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import storage from '../../utils/storage';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

const useSwitchTheme = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  return () => {
    {
      storage.getFromStorage('themeMode').then((data) => {
        const themeMode =
          data && data === 'light' ? 'dark' : 'light';
        storage.saveToStorage('themeMode', themeMode);

        if (Capacitor.isNativePlatform()) {
          StatusBar.setStyle({
            style:
              themeMode === 'light' ? Style.Light : Style.Dark,
          });
        }
      });

      dispatch({
        parent: 'app',
        type: 'toggleTheme',
      });
    }
  };
};

export default useSwitchTheme;
