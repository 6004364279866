import { gql } from '../../../__generated__';

const CREATE_FAMILY_TAGS = gql(`
  mutation CreateFamilyTags($tagUuids: [String]!) {
    tag {
      createFamilyTags(tagUUIDS: $tagUuids) {
        uuid
        tag_uuid
        family_uuid
        tag_icon
        tag_name
      }
    }
  }
`);

const CREATE_FAMILY_TAG = gql(`
  mutation CreateFamilyTag($tagUuid: String!) {
    tag {
      createFamilyTag(tagUUID: $tagUuid) {
        uuid
        tag_uuid
        family_uuid
        tag_icon
        tag_name
      }
    }
  }
`);

const CREATE_PROFILE_TAGS = gql(`
  mutation CreateProfileTags($tagUuids: [String]!) {
    tag {
      createProfileTags(tagUUIDS: $tagUuids) {
        uuid
        tag_uuid
        profile_uuid
        tag_icon
        tag_name
      }
    }
  }
`);

const tag = {
  CREATE_FAMILY_TAGS,
  CREATE_FAMILY_TAG,
  CREATE_PROFILE_TAGS,
};
export default tag;
