import {
  Button,
  Card,
  Divider,
  FlexRow,
  Input,
  List,
  ListItem,
  Loader,
  Text,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { Fragment, useEffect, useState } from 'react';
import Avatar from '../../components/_common/Avatar';
import { ChatConnectionObjectType } from '../../__generated__/graphql';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import routes from '../../navigation/routes';

const NewChat = () => {
  const { state, actions } = useStore();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (!state.chat.chatConnections.data) {
      actions.chat.query.chatConnections({});
    }
  }, []);

  const handleNewChat = (
    connection: ChatConnectionObjectType,
  ) => {
    navigate(routes.chat, [connection.profile_uuid]);
  };

  return (
    <Card>
      {state.chat.chatConnections.loading && (
        <Loader position={'center'} />
      )}
      <Input
        type={'text'}
        label={'Filter Connections'}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <List className={'newChatRow'}>
        {(state.chat.chatConnections.data || [])
          .filter((connection) =>
            searchQuery
              ? `${connection.first_name}${connection.last_name}${connection.nickname}`
                  .toLowerCase()
                  .indexOf(searchQuery.toLowerCase()) >= 0
              : true,
          )
          ?.map((connection) => (
            <Fragment key={connection.profile_uuid}>
              <Divider color={'transparent'} />
              <ListItem
                className={'flexRow'}
                onClick={() => handleNewChat(connection)}
              >
                <FlexRow inline centerX>
                  <Avatar
                    url={connection.imageUrl}
                    alt={`${connection.first_name}`}
                  />
                  <FlexRow inline column>
                    <Text variant={'h5'} color={'grey400'}>
                      {connection.first_name}{' '}
                      {connection.last_name}
                    </Text>
                    <Text variant={'span'} color={'grey200'}>
                      {connection.relation_type} - (
                      {connection.nickname})
                    </Text>
                  </FlexRow>
                </FlexRow>
                <Button
                  color={'primary'}
                  size={'sm'}
                  shape={'round'}
                >
                  Chat
                </Button>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
      </List>
    </Card>
  );
};

export default NewChat;
