import {
  Button,
  Div,
  Divider,
  FlexRow,
  Form,
  Image,
  Switch,
  Text,
  TextArea,
} from '../../components/_ui';
import { Controller, useForm } from 'react-hook-form';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommunityObjectType } from '../../__generated__/graphql';
import { useEffect, useState } from 'react';
import '../../styles/forms/index.scss';
import { UploadImage } from '../../components/_common';

export type CreateEditCommunityInputs = {
  name: string;
  description: string;
  image: string;
  isPrivate?: boolean;
};

type CreateCommunityProps = {
  onSubmit: (args: CreateEditCommunityInputs) => void;
  error?: string;
  loading: boolean;
  existingCommunity?: CommunityObjectType;
};

const CommunityForm = ({
  onSubmit,
  error,
  loading,
  existingCommunity,
}: CreateCommunityProps) => {
  const [tempFile, setTempFile] = useState<
    string | ArrayBuffer | null
  >(null);
  const {
    formState,
    control,
    setValue,
    register,
    handleSubmit,
    getValues,
  } = useForm<CreateEditCommunityInputs>();
  const { errors, isValid } = formState;

  useEffect(() => {
    if (existingCommunity) {
      setValue('name', existingCommunity.name);
      setValue('description', existingCommunity.description);
      // setValue("image", existingCommunity.image_url || "");
      if (existingCommunity.image_url) {
        setTempFile(existingCommunity.image_url);
      }
      setValue('isPrivate', existingCommunity.private);
    }
  }, [existingCommunity]);

  // if (getValues().image) {
  //   console.log(getValues().image);
  //   const reader = new FileReader();
  //   // @ts-ignore
  //   const url = reader.readAsDataURL(getValues().image[0]);
  //   console.log("url");
  //   console.log(url);
  //   reader.onloadend = () => {
  //     console.log(reader.result);
  //   };
  // }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextArea
        label={'title'}
        textSize={'md'}
        rows={3}
        cols={1}
        disabled={loading}
        error={errors.name?.message}
        {...register('name', {
          required: 'Community Name is required',
        })}
      />
      <TextArea
        label={'description'}
        textSize={'md'}
        rows={3}
        cols={1}
        disabled={loading}
        error={errors.description?.message}
        {...register('description', {
          required: 'Community Description is required',
        })}
      />
      <Divider color={'transparent'} />
      {existingCommunity && !tempFile && (
        <Div className={'filePreview'}>
          <Image
            alt={`${existingCommunity.name} image`}
            src={
              existingCommunity.image_url ||
              '/defaultImage/defaultCommunityImage.jpg'
            }
            // height={250}
            // width={400}
          />
        </Div>
      )}
      {tempFile && (
        <Div className={'filePreview'}>
          <Image
            alt={`Create Community image`}
            src={tempFile as string}
            // height={250}
            // width={400}
          />
        </Div>
      )}
      <Divider color={'transparent'} />
      <UploadImage
        control={control}
        name={'image'}
        setFile={setTempFile}
      />

      <Controller
        name="isPrivate"
        control={control}
        render={({ field, ...rest }) => (
          <Switch
            label={'Make Private?'}
            {...field}
            value={!!field.value}
            asSwitch
          />
        )}
      />
      <Divider color={'transparent'} />
      {error && (
        <Text variant={'p'} color={'error'}>
          {error}
        </Text>
      )}
      <Divider color={'transparent'} />
      <FlexRow centerX centerY>
        <Button
          type={'submit'}
          variant={'contained'}
          color={'primary'}
          size={'md'}
          disabled={!isValid || loading}
          iconProps={
            loading
              ? {
                  position: 'end',
                  icon: faSpinner,
                  animationStyle: {
                    spin: true,
                  },
                  color: 'white',
                }
              : null
          }
        >
          {existingCommunity ? 'Save' : 'Create'}
        </Button>
      </FlexRow>
    </Form>
  );
};

export default CommunityForm;
