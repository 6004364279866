import { useStore } from './useStore';
import { useEffect, useRef, useState } from 'react';

export const useFeedFetcher = () => {
  const { state, actions } = useStore();
  const [shouldFetchPosts, setShouldFetchPosts] =
    useState<boolean>(false);
  const isLoading = useRef(state.post.postFeed.loading);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state.app.currentPage === 'feed') {
      const handleLazyLoad = (e: any) => {
        const { clientHeight, scrollTop, scrollHeight } =
          e.target;

        if (
          // clientHeight * 4 + scrollTop > scrollHeight &&
          scrollHeight - (scrollTop + clientHeight * 2) <= 0 &&
          !isLoading.current &&
          !shouldFetchPosts
        ) {
          setShouldFetchPosts(true);
        }
        // set scroll top for scrollToPage whenever returning to the page. increments of 100 px. to eliminate constant setting
        if (scrollTop % 100 === 0) {
          actions.post.setPostFeedScrollPosition(scrollTop);
        }
      };

      if (
        scrollRef.current &&
        state.post.postFeed.scrollPosition &&
        scrollRef.current.scrollTop === 0
      ) {
        scrollRef?.current.scrollTo({
          top: state.post.postFeed.scrollPosition,
          behavior: 'smooth',
        });
      }

      const div = scrollRef?.current;
      div?.addEventListener('scroll', handleLazyLoad);

      return () => {
        div?.removeEventListener('scroll', handleLazyLoad);
      };
    }
  }, [
    shouldFetchPosts,
    state.app.currentPage,
    state.post.postFeed,
  ]);

  useEffect(() => {
    if (
      shouldFetchPosts &&
      !isLoading.current &&
      (state.post.postFeed.total || 0) >=
        (state.post.postFeed.data || []).length
    ) {
      actions.post.query.postFeed({
        offset: state.post.postFeed.offset,
        limit: state.post.postFeed.limit,
      });
      setShouldFetchPosts(false);
    }
  }, [shouldFetchPosts]);

  return scrollRef;
};

export default useFeedFetcher;
