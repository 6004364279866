import {
  Button,
  Div,
  Divider,
  FlexRow,
  Icon,
  Image,
  Pill,
  Text,
} from '../_ui';
import {
  faClose,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useStore } from '../../hooks/useStore';
import routes from '../../navigation/routes';
import { AppPagesEnum } from '../../types/pages';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const FamilyProfile = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();

  const timezones = state.common.timezone.data;
  const states = state.common.state.data;
  const countries = state.common.country.data;
  const cities = state.common.city.data;
  const family = state.profile.familyProfile.data?.family;
  const interests = family?.interests || [];
  const defaultImage =
    '/defaultImages/defaultFamilyProfileImage.jpg';

  const buildAddress = () => {
    const address = [];
    if (family?.city_uuid && cities) {
      const city = cities.filter(
        (city) => city?.uuid === family.city_uuid,
      );
      address.push(city && city[0] ? city[0].name : '');
    }
    if (family?.state_uuid && states) {
      const state = states.filter(
        (state) => state?.uuid === family.state_uuid,
      );
      address.push(state && state[0] ? state[0].name : '');
    }
    address.push(family?.zip ? family.zip : '');
    if (family?.country_uuid && countries) {
      const country = countries.filter(
        (country) => country?.uuid === family.country_uuid,
      );
      address.push(country && country[0] ? country[0].name : '');
    }
    return address.flat().join(' ');
  };

  const getTimezone = () => {
    if (family?.timezone_uuid && timezones) {
      const tz = timezones.filter(
        (tz) => tz?.uuid === family.timezone_uuid,
      );
      return tz[0] ? tz[0].timezone_name : '';
    }
    return 'N/A';
  };

  const handleEditProfile = () => {
    navigate<string>(routes.editFamilyProfile, [
      family?.uuid as string,
    ]);
  };

  return (
    <>
      <FlexRow className={'familyProfileImage'} column>
        <Text variant={'h6'} color={'grey400'}>
          Primary profile image
        </Text>
        <Image
          className={'profileImage'}
          src={family?.primary_profile_image_url || defaultImage}
          alt={`${family?.name} main profile image`}
          width={398}
          height={250}
        />
      </FlexRow>
      <FlexRow center centerX>
        <Text variant={'span'} color={'grey100'}>
          The more you fill this out, the better your experience
          will be.
        </Text>
      </FlexRow>
      <FlexRow>
        <Div>
          <Text variant={'h3'} color={'grey800'}>
            {family?.name}
          </Text>
        </Div>
        <Button
          iconOnly
          color={'primary'}
          variant={'transparent'}
          onClick={handleEditProfile}
        >
          <Icon icon={faEdit} />
        </Button>
      </FlexRow>
      <Text variant={'p'} color={'grey400'}>
        {family?.bio ||
          'Biography does not yet exist, add one to improve matches and catered content.'}
      </Text>
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <FlexRow column>
        <Text variant={'h6'} color={'grey400'}>
          Location
        </Text>
        <Text variant={'h3'} color={'grey800'}>
          {buildAddress()}
        </Text>
      </FlexRow>
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <FlexRow column>
        <Text variant={'h6'} color={'grey400'}>
          Timezone
        </Text>
        <Text variant={'h3'} color={'grey800'}>
          {getTimezone()}
        </Text>
      </FlexRow>
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <Div>
        {family?.secondary_profile_image_url && (
          <Div className={'familyProfileImage'}>
            <Text variant={'h6'} color={'grey400'}>
              Secondary profile image
            </Text>
            <Image
              className={'profileImage'}
              src={
                family?.primary_profile_image_url || defaultImage
              }
              alt={`${family?.name} secondary profile image`}
              width={398}
              height={250}
            />
          </Div>
        )}
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        <Div className={'profileSectionContainer'}>
          <Text variant={'h6'} color={'grey800'}>
            Interested In:
          </Text>
          <Divider color={'transparent'} />
          <FlexRow wrap spreadX={false}>
            {interests?.map(
              (interest) =>
                interest && (
                  <Pill
                    key={interest.uuid}
                    title={interest.name}
                    variant={'outlined'}
                    color={'primary'}
                    iconEnd={faClose}
                    imageStart={<Text>{interest.tag_icon}</Text>}
                  />
                ),
            )}
          </FlexRow>
          <Button
            variant={'transparent'}
            color={'primary'}
            size={'xs'}
          >
            Edit Interests
          </Button>
        </Div>
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        {family?.tertiary_profile_image_url && (
          <Div className={'familyProfileImage'}>
            <Text variant={'h6'} color={'grey400'}>
              Tertiary profile image
            </Text>
            <Image
              className={'profileImage'}
              src={
                family?.tertiary_profile_image_url ||
                defaultImage
              }
              alt={`${family?.name} tertiary profile image`}
              width={398}
              height={250}
            />
          </Div>
        )}
      </Div>
    </>
  );
};

export default FamilyProfile;
