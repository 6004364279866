import {
  FamilyState,
  FamilyStoreActions,
} from '../../types/reducers/family';

export const familyState: FamilyState = {
  createFamily: {},
  updateFamily: {},
  createFamilyMember: {},
  familyRelationType: {},
  familyMemberPermissions: {},
  myPermissions: {},
  saveFamilyInterests: {},
};
export const familyActions: FamilyStoreActions = {
  mutation: {
    createFamilyMember: () => null,
    createFamily: () => null,
    updateFamily: () => null,
    saveFamilyInterests: () => null,
  },
  query: {
    familyRelationType: () => null,
    familyMemberPermissions: () => null,
    myPermissions: () => null,
  },
};
