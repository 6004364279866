import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  CommunityByFamilyQuery,
  CommunityByFamilyQueryVariables,
} from "../../../__generated__/graphql";

const useCommunityByFamily = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "communityByFamily";

  const handleOnCompleted = (data: CommunityByFamilyQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.communityByFamily,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [communityByFamily] = useLazyQuery(
    api.query.community.COMMUNITY_BY_FAMILY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: CommunityByFamilyQueryVariables) => {
    if (!state.community.communityByFamily.loading) {
      communityByFamily({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCommunityByFamily;
