import { AppStoreState } from '../types/reducers';
import { FamilyReducerActions } from '../types/reducers/family';

const familyReducer = (
  state: AppStoreState,
  action: FamilyReducerActions,
) => {
  switch (action.type) {
    case 'familyRelationType':
      state.family.familyRelationType = action.payload;
      return state;
    case 'familyMemberPermissions':
      state.family.familyMemberPermissions = action.payload;
      return state;
    case 'myPermissions':
      state.family.myPermissions = action.payload;
      return state;
    case 'saveFamilyInterests':
      state.family.saveFamilyInterests = action.payload;
      if (
        action.payload.data &&
        action.payload.data.length > 0
      ) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.family.interestsSaved;
      }
      return state;
    case 'createFamily':
      state.family.createFamilyMember.loading =
        action.payload.loading;
      if (action.payload.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.family.familyCreated;
      }
      return state;
    case 'updateFamily':
      state.family.updateFamily.loading = action.payload.loading;
      if (action.payload.data?.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.family.familyUpdated;
      }
      return state;
    case 'createFamilyMember':
      state.family.createFamilyMember.loading =
        action.payload.loading;
      if (action.payload.data?.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.family.familyMemberCreated(
            action.payload.data.nickname,
          );
      }
      return state;
    default:
      return state;
  }
};
export default familyReducer;
