import {
  Button,
  Div,
  Divider,
  FlexRow,
  Form,
  Icon,
  Image,
  Input,
  Select,
  TextArea,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { Controller, useForm } from 'react-hook-form';
import {
  City,
  Country,
  InputMaybe,
  State,
  Tag,
  Timezone,
  UpdateFamilyMutationVariables,
} from '../../__generated__/graphql';
import { useEffect, useMemo, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { SelectOptionsProps } from '../../components/_ui/Select';
import routes from '../../navigation/routes';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import '../../styles/pages/editFamilyProfile.scss';
import { ChooseInterests } from '../../components/_common/profile';
import { isValidZipCode } from '../../utils/stringUtils';
import useCustomNavigate from '../../hooks/useCustomNavigate';

type FamilyProfileInputs = {
  name: string;
  bio: string | null;
  city: SelectOptionsProps | null;
  country: SelectOptionsProps | null;
  primary_profile_image_url: string | null;
  new_primary_profile_image_url: FileList | null;
  secondary_profile_image_url: string | null;
  new_secondary_profile_image_url: FileList | null;
  state: SelectOptionsProps | null;
  tertiary_profile_image_url: string | null;
  new_tertiary_profile_image_url: FileList | null;
  timezone: SelectOptionsProps | null;
  zip: string | null;
};

const EditFamilyProfile = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();
  const [tempPrimaryFile, setTempPrimaryFile] = useState<
    string | ArrayBuffer | null
  >(null);
  const [tempSecondaryFile, setTempSecondaryFile] = useState<
    string | ArrayBuffer | null
  >(null);
  const [tempTertiaryFile, setTempTertiaryFile] = useState<
    string | ArrayBuffer | null
  >(null);
  const [activeProfileImage, setActiveProfileImage] = useState<
    'primary' | 'secondary' | 'tertiary'
  >('primary');

  const family = state.profile.familyProfile.data?.family;
  const timezones = state.common.timezone.data;
  const states = state.common.state.data;
  const countries = state.common.country.data;
  const cities = state.common.city.data;

  const timezoneOptions = useMemo(
    () =>
      timezones
        ?.map(
          (tz) =>
            tz && {
              value: tz.uuid,
              name: tz.timezone_name,
            },
        )
        .filter((i) => i != null),
    [timezones],
  );

  const stateOptions = useMemo(
    () =>
      states
        ?.map(
          (state) =>
            state && {
              value: state.uuid,
              name: state.name,
            },
        )
        .filter((i) => i != null),
    [states],
  );

  const countryOptions = useMemo(
    () =>
      countries
        ?.map(
          (country) =>
            country && {
              value: country.uuid,
              name: country.name,
            },
        )
        .filter((i) => i != null),
    [countries],
  );

  const cityOptions = useMemo(
    () =>
      cities
        ?.map(
          (city) =>
            city && {
              value: city.uuid,
              name: city.name,
            },
        )
        .filter((i) => i != null),
    [cities],
  );

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    getValues,
    setValue,
  } = useForm<FamilyProfileInputs>();
  const { errors, dirtyFields } = formState;

  useEffect(() => {
    if (family) {
      const city =
        family.city_uuid &&
        cities?.filter(
          (city) => city && city.uuid === family.city_uuid,
        );
      const state =
        family.state_uuid &&
        states?.filter(
          (state) => state && state.uuid === family.state_uuid,
        );
      const country =
        family.country_uuid &&
        countries?.filter(
          (country) =>
            country && country.uuid === family.country_uuid,
        );
      const timezone =
        family.timezone_uuid &&
        timezones?.filter(
          (timezone) =>
            timezone && timezone.uuid === family.timezone_uuid,
        );

      const existingCountry = country?.[0] as Maybe<Country>;
      const existingState = state?.[0] as Maybe<State>;
      const existingCity = city?.[0] as Maybe<City>;
      const existingTimezone = timezone?.[0] as Maybe<Timezone>;

      if (family.primary_profile_image_url) {
        setTempPrimaryFile(family.primary_profile_image_url);
      }
      if (family.primary_profile_image_url) {
        setTempPrimaryFile(family.primary_profile_image_url);
      }
      if (family.primary_profile_image_url) {
        setTempPrimaryFile(family.primary_profile_image_url);
      }

      actions.profile.resetInterests();
      const familyInterests = family.interests || [];
      if (family.interests) {
        actions.profile.addInterest(familyInterests as Tag[]);
      }

      reset({
        name: family.name,
        bio: family.bio,
        primary_profile_image_url:
          family.primary_profile_image_url,
        new_primary_profile_image_url: null,
        secondary_profile_image_url:
          family.secondary_profile_image_url,
        new_secondary_profile_image_url: null,
        tertiary_profile_image_url:
          family.tertiary_profile_image_url,
        new_tertiary_profile_image_url: null,
        zip: family.zip,
        city: existingCity
          ? {
              name: existingCity.name,
              value: existingCity.uuid,
            }
          : null,
        state: existingState
          ? {
              name: existingState.name,
              value: existingState.uuid,
            }
          : null,
        country: existingCountry
          ? {
              name: existingCountry.name,
              value: existingCountry.uuid,
            }
          : null,
        timezone: existingTimezone
          ? {
              name: existingTimezone.timezone_name,
              value: existingTimezone.uuid,
            }
          : null,
      });
    }
  }, [family]);

  const getTempFile = () => {
    switch (activeProfileImage) {
      case 'primary':
        return tempPrimaryFile;
      case 'secondary':
        return tempSecondaryFile;
      case 'tertiary':
        return tempTertiaryFile;
      default:
        return '/defaultImages/defaultFamilyProfileImage.jpg';
    }
  };

  const onSubmit = (formValues: FamilyProfileInputs) => {
    if (!family?.uuid) {
      actions.app.setErrorMessage(
        'Your family is not found, please try again',
      );
      return;
    }
    const baseUpdateValues: Partial<UpdateFamilyMutationVariables> =
      {
        uuid: family.uuid,
      };
    if (dirtyFields.bio) baseUpdateValues.bio = formValues.bio;
    if (dirtyFields.name)
      baseUpdateValues.name = formValues.name;
    if (dirtyFields.timezone)
      baseUpdateValues.timezoneUuid = formValues.timezone
        ?.value as string;
    if (dirtyFields.city)
      baseUpdateValues.cityUuid = formValues.city
        ?.value as string;
    if (dirtyFields.country)
      baseUpdateValues.countryUuid = formValues.country
        ?.value as string;
    if (dirtyFields.state)
      baseUpdateValues.stateUuid = formValues.state
        ?.value as string;
    if (dirtyFields.zip) baseUpdateValues.zip = formValues.zip;

    if (formValues.new_primary_profile_image_url)
      baseUpdateValues.primaryProfileImage =
        formValues.new_primary_profile_image_url[0];
    if (formValues.new_secondary_profile_image_url)
      baseUpdateValues.secondaryProfileImage =
        formValues.new_secondary_profile_image_url[0];
    if (formValues.new_tertiary_profile_image_url)
      baseUpdateValues.tertiaryProfileImage =
        formValues.new_tertiary_profile_image_url[0];

    baseUpdateValues.interestUuids =
      state.profile.chosenInterests.map(
        (interest) => interest?.uuid as InputMaybe<string>,
      );

    actions.family.mutation.updateFamily(
      baseUpdateValues as UpdateFamilyMutationVariables,
    );
  };

  const formValues = getValues();

  return (
    <Form className={'card'} onSubmit={handleSubmit(onSubmit)}>
      <Div>
        {family && (
          <>
            <FlexRow className={'familyProfileImage'}>
              <Image
                src={getTempFile() as string}
                height={250}
                width={398}
                alt={`the ${family.name}'s profile image`}
              />
            </FlexRow>
            <Div>
              <FlexRow
                className={'profileOtherImages'}
                spreadX={false}
                center
              >
                {(family?.primary_profile_image_url ||
                  formValues.new_primary_profile_image_url) && (
                  <Div
                    onClick={() => {
                      setTempPrimaryFile(
                        tempPrimaryFile ||
                          (family.primary_profile_image_url as string),
                      );
                      setActiveProfileImage('primary');
                    }}
                    className={
                      activeProfileImage === 'primary'
                        ? 'active'
                        : ''
                    }
                  >
                    <Image
                      src={
                        (tempPrimaryFile ||
                          family?.primary_profile_image_url) as string
                      }
                      height={50}
                      width={50}
                      alt={`the ${family.name}'s secondary profile image`}
                    />
                  </Div>
                )}
                {(family?.secondary_profile_image_url ||
                  formValues.new_secondary_profile_image_url) && (
                  <Div
                    onClick={() => {
                      setTempSecondaryFile(
                        tempSecondaryFile ||
                          (family.secondary_profile_image_url as string),
                      );
                      setActiveProfileImage('secondary');
                    }}
                    className={
                      activeProfileImage === 'secondary'
                        ? 'active'
                        : ''
                    }
                  >
                    <Image
                      src={
                        (tempSecondaryFile ||
                          family?.secondary_profile_image_url) as string
                      }
                      height={50}
                      width={50}
                      alt={`the ${family.name}'s secondary profile image`}
                    />
                  </Div>
                )}
                {(family?.tertiary_profile_image_url ||
                  formValues.new_tertiary_profile_image_url) && (
                  <Div
                    onClick={() => {
                      setTempTertiaryFile(
                        tempTertiaryFile ||
                          (family.tertiary_profile_image_url as string),
                      );
                      setActiveProfileImage('tertiary');
                    }}
                    className={
                      activeProfileImage === 'tertiary'
                        ? 'active'
                        : ''
                    }
                  >
                    <Image
                      src={
                        (tempTertiaryFile ||
                          family?.tertiary_profile_image_url) as string
                      }
                      height={50}
                      width={50}
                      alt={`the ${family.name}'s tertiary profile image`}
                    />
                  </Div>
                )}
                <Div className={'chooseFileContainer'}>
                  <Controller
                    control={control}
                    name={`new_${activeProfileImage}_profile_image_url`}
                    render={({ field }) => (
                      <Input
                        className={'hiddenFileInput'}
                        type={'file'}
                        onChange={(e) => {
                          if (
                            e.target.files &&
                            e.target.files[0]
                          ) {
                            const reader = new FileReader();
                            reader.readAsDataURL(
                              e.target.files[0],
                            );
                            reader.onloadend = () => {
                              if (
                                activeProfileImage === 'primary'
                              ) {
                                setTempPrimaryFile(
                                  reader.result,
                                );
                                setValue(
                                  'new_primary_profile_image_url',
                                  e.target.files,
                                );
                              }
                              if (
                                activeProfileImage ===
                                'secondary'
                              ) {
                                setTempSecondaryFile(
                                  reader.result,
                                );
                                setValue(
                                  'new_secondary_profile_image_url',
                                  e.target.files,
                                );
                              }
                              if (
                                activeProfileImage === 'tertiary'
                              ) {
                                setTempTertiaryFile(
                                  reader.result,
                                );
                                setValue(
                                  'new_tertiary_profile_image_url',
                                  e.target.files,
                                );
                              }
                            };
                          }
                        }}
                      />
                    )}
                  />
                  <Icon
                    icon={faCamera}
                    height={35}
                    width={35}
                    color={'primary'}
                  />
                </Div>
              </FlexRow>
            </Div>
          </>
        )}
      </Div>

      <Input
        type={'text'}
        label={'Name'}
        error={errors.name?.message}
        {...register('name', { required: 'Name is required' })}
      />
      <TextArea
        textSize={'sm'}
        label={'bio'}
        error={errors.bio?.message}
        {...register('bio')}
      />
      <Controller
        name={'city'}
        control={control}
        render={({ field }) => (
          <Select
            label={'city'}
            error={errors.city?.message}
            {...register('city')}
            onChange={(option) => {
              field.onChange(option);
            }}
            value={field.value?.name}
            // @ts-ignore
            options={cityOptions}
            searchable
          />
        )}
      />
      <Controller
        name={'state'}
        control={control}
        render={({ field }) => (
          <Select
            label={'state'}
            error={errors.state?.message}
            {...register('state')}
            onChange={(option) => {
              field.onChange(option);
            }}
            value={field.value?.name}
            // @ts-ignore
            options={stateOptions}
            searchable
          />
        )}
      />
      <Input
        type={'text'}
        label={'zip'}
        error={errors.zip?.message}
        {...register('zip', {
          required: 'Zip code is required',
          validate: (zip) =>
            isValidZipCode(`${zip}`)
              ? true
              : 'Zip code is invalid',
        })}
      />
      <Controller
        name={'country'}
        control={control}
        render={({ field }) => (
          <Select
            label={'country'}
            error={errors.country?.message}
            {...register('country')}
            onChange={(option) => {
              field.onChange(option);
            }}
            value={field.value?.name}
            // @ts-ignore
            options={countryOptions}
          />
        )}
      />
      <Controller
        name={'timezone'}
        control={control}
        render={({ field }) => (
          <Select
            label={'timezone'}
            error={errors.timezone?.message}
            {...register('timezone')}
            onChange={(option) => {
              field.onChange(option);
            }}
            value={field.value?.name}
            // @ts-ignore
            options={timezoneOptions}
            searchable
          />
        )}
      />
      <Divider color={'transparent'} />
      <ChooseInterests />
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <FlexRow className="pinnedBottom" spreadX centerY>
        <Button
          onClick={() => navigate(routes.profile)}
          disabled={state.family.updateFamily.loading}
        >
          Cancel
        </Button>
        <Button
          type={'submit'}
          disabled={state.family.updateFamily.loading}
        >
          {state.family.updateFamily.loading ? 'Saving' : 'Save'}
        </Button>
      </FlexRow>
    </Form>
  );
};

export default EditFamilyProfile;
