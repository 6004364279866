import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  CreateFamilyMemberMutation,
  CreateFamilyMemberMutationVariables,
} from '../../../__generated__/graphql';
import routes from '../../../navigation/routes';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import useFamilyProfile from '../../profile/api/useFamilyProfile';
import useFamilyMemberProfile from '../../profile/api/useFamilyMemberProfile';

const useCreateFamilyMember = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'family';
  const type = 'createFamilyMember';
  const navigate = useCustomNavigate();
  const reFetchFamilyProfile = useFamilyProfile(state, dispatch);
  const fetchFamilyMemberProfile = useFamilyMemberProfile(
    state,
    dispatch,
  );

  const handleOnCompleted = (
    data: CreateFamilyMemberMutation,
  ) => {
    reFetchFamilyProfile({});
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.family.createFamilyMember,
      },
    });
    if (data.family.createFamilyMember.family_member?.uuid) {
      navigate<string>(routes.editProfile, [
        data.family.createFamilyMember.family_member.uuid,
      ]);
      fetchFamilyMemberProfile({
        uuid: data.family.createFamilyMember.family_member.uuid,
      });
      dispatch({
        parent: 'profile',
        type: 'setFamilyMemberOrFamilyUUID',
        payload:
          data.family.createFamilyMember.family_member.uuid,
      });
      dispatch({
        parent: 'profile',
        type: 'setCurrentProfile',
        payload: undefined,
      });
    }
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createFamilyMember] = useMutation(
    api.mutation.family.CREATE_FAMILY_MEMBER,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createFamilyMember',
        },
      },
    },
  );

  return (args: CreateFamilyMemberMutationVariables) => {
    if (!state.family.createFamilyMember.loading) {
      createFamilyMember({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateFamilyMember;
