import React from 'react';
import { Card, Div, FlexRow, Text } from '../_ui';
import { NotificationTypeUnionType } from '../../__generated__/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { RenderNotification } from './index';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useStore } from '../../hooks/useStore';

const NotificationItem: React.FC<
  Maybe<NotificationTypeUnionType>
> = (notification) => {
  const navigate = useCustomNavigate();
  const { state } = useStore();
  return (
    notification && (
      <RenderNotification
        notification={notification}
        render={({
          header,
          time,
          title,
          subtitle,
          meta,
          // metaIcons
          navigateArgs: [
            route = null,
            routeArgs = undefined,
            navigateState,
          ] = [],
        }) => (
          <Card
            className={[
              notification?.status === 'read' ? '' : 'new',
              'notificationItem',
              state.app.themeMode,
            ].join(' ')}
            onClick={(e) => {
              e.stopPropagation();
              if (route) {
                navigate(
                  route,
                  routeArgs as any,
                  navigateState || {},
                );
              }
            }}
          >
            <FlexRow spreadX centerY>
              <Text variant={'span'} color={'grey200'}>
                {header}
              </Text>
              <Text
                variant={'span'}
                color={'grey400'}
                weight={800}
              >
                {time}
              </Text>
            </FlexRow>
            <Text variant={'h4'} color={'grey800'}>
              {title}
            </Text>
            <FlexRow spreadX centerY inline>
              <Text variant={'p'} color={'grey400'}>
                {subtitle}
              </Text>
              <Div>{meta}</Div>
            </FlexRow>
          </Card>
        )}
      />
    )
  );
};

export default NotificationItem;
