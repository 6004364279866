import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  CommunityQuery,
  CommunityQueryVariables,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "community";

  const handleOnCompleted = (data: CommunityQuery) => {
    storage.updateRecentlyVisitedCommunities(data.community.community);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.community,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [community] = useLazyQuery(api.query.community.COMMUNITY, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: CommunityQueryVariables) => {
    if (!state.community.community.loading) {
      community({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCommunity;
