import React, { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { useLazyQuery } from '@apollo/client';
import api from '../api';

// this is used to provide new messages to state whenever a user is on the chats transcript page for latest message accuracy. Separated from the ChatProvider as that gets latest messages as a whole but not individual ones.
const ChatTranscriptProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { state, dispatch } = useStore();
  const intervalTiming = 30000;
  const parent = 'chat';
  const type = 'newTranscriptMessages';

  const [newTranscriptMessages] = useLazyQuery(
    api.query.chat.NEW_TRANSCRIPT_MESSAGES,
    {
      onCompleted: (data) => {
        dispatch({
          parent,
          type,
          payload: {
            data: data.chat?.newTranscriptMessages,
            loading: false,
          },
        });
      },
      onError: (error) => {
        dispatch({
          parent,
          type,
          payload: {
            error: error.message,
            loading: false,
          },
        });
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    const getLatestMessages = () => {
      const transcript = state.chat.chatTranscript.data;
      if (transcript && transcript.length) {
        newTranscriptMessages({
          variables: {
            // checking it in the if statement above...sooooo its there.
            lastMessageUuid:
              // @ts-ignore
              transcript[transcript.length - 1].chat_record_uuid,
          },
        });
        dispatch({
          parent,
          type,
          payload: {
            loading: true,
          },
        });
      }
    };

    const interval = setInterval(
      getLatestMessages,
      intervalTiming,
    );
    return () => clearInterval(interval);
  }, []);

  return children;
};

export default ChatTranscriptProvider;
