import {
  Button,
  Card,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../_ui';
import React, { useState } from 'react';
import { useStore } from '../../hooks/useStore';

const ChooseFamilyInterests: React.FC = () => {
  const [tags, setTags] = useState<string[]>([]);
  const { state, actions } = useStore();
  const [limit, setLimit] = useState(12);

  const handleAddTag = (uuid: string) => () => {
    if (tags.indexOf(uuid) > -1) {
      setTags(tags.filter((tagId) => tagId !== uuid));
    } else {
      setTags([...tags, uuid]);
    }
  };

  const saveTags = async () => {
    actions.tag.mutation.createFamilyTags({ tagUuids: tags });
    actions.profile.query.checkIfOnboarded({});
  };

  if (state.common.tag.loading || !state.common.tag.data) {
    return null;
  }

  const isLoading =
    state.tag.createFamilyTags.loading ||
    state.common.tag.loading;

  const tagsData = state.common.tag.data;

  return (
    <Card>
      {!tagsData ? (
        <Loader />
      ) : (
        <>
          <Text variant={'h3'} color={'primary'}>
            Some things your family might like!
          </Text>
          <Text color={'grey800'} variant={'span'}>
            Choose some interests and we can cater the app to
            your liking!
          </Text>
          <Divider color={'transparent'} />
          <FlexRow className={'tagRow'} wrap spreadX={false}>
            {tagsData.slice(0, limit).map((tag) => (
              <Button
                key={tag?.uuid as string}
                shape={'rounded'}
                color={'primary'}
                variant={'outlined'}
                active={tags.indexOf(tag?.uuid as string) > -1}
                onClick={handleAddTag(tag!.uuid as string)}
                size={'sm'}
              >
                {tag?.tag_icon} {tag?.name}
              </Button>
            ))}
          </FlexRow>
          {limit < tagsData.length && (
            <FlexRow center centerX centerY>
              <Button
                onClick={() => setLimit(limit + 12)}
                variant={'transparent'}
                color={'primary'}
                size={'xs'}
              >
                Show More
              </Button>
            </FlexRow>
          )}
          <Divider color={'transparent'} />
          <Button
            onClick={saveTags}
            variant={'outlined'}
            color={'secondary'}
            disabled={isLoading}
            className={'flexRow'}
          >
            {isLoading && <Loader />}
            Save Interests
          </Button>
        </>
      )}
    </Card>
  );
};

export default ChooseFamilyInterests;
