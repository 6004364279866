import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  FamilyRelationTypeQuery,
  FamilyRelationTypeQueryVariables,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useFamilyRelationType = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "family";
  const type = "familyRelationType";

  const handleOnCompleted = (data: FamilyRelationTypeQuery) => {
    storage.saveToStorage("familyRelationType", data.family.familyRelationType);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.family.familyRelationType,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [familyRelationType] = useLazyQuery(
    api.query.family.FAMILY_RELATION_TYPE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  return (args: FamilyRelationTypeQueryVariables) => {
    if (!state.family.familyRelationType.loading) {
      familyRelationType({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useFamilyRelationType;
