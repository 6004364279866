import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  CreateFamilyTagMutation,
  CreateFamilyTagMutationVariables,
} from "../../../__generated__/graphql";

const useCreateFamilyTag = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "tag";
  const type = "createFamilyTag";

  const handleOnCompleted = (data: CreateFamilyTagMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.tag.createFamilyTag,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createFamilyTag] = useMutation(api.mutation.tag.CREATE_FAMILY_TAG, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: CreateFamilyTagMutationVariables) => {
    if (!state.tag.createFamilyTag.loading) {
      createFamilyTag({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateFamilyTag;
