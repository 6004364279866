import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  DeleteCommentMutation,
  DeleteCommentMutationVariables,
} from "../../../__generated__/graphql";

const useDeleteComment = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "deleteComment";

  const handleOnCompleted = (data: DeleteCommentMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.comment.deleteComment,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [deleteComment] = useMutation(api.mutation.post.DELETE_COMMENT, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: DeleteCommentMutationVariables) => {
    if (!state.post.deleteComment.loading) {
      deleteComment({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useDeleteComment;
