import { Div } from "../_ui";
import "../../styles/components/_ui/flexRow.scss";
import React from "react";
import { DivProps } from "./Div";

const FlexRow = ({
  children,
  inline,
  wrap = false,
  className = "",
  column = false,
  center = true,
  start = true,
  end = false,
  spreadX = true,
  centerY = false,
  centerX = false,
  fullWidth = false,
  ...rest
}: {
  children: React.ReactNode;
  inline?: boolean;
  wrap?: boolean;
  className?: string;
  column?: boolean;
  center?: boolean;
  start?: boolean;
  end?: boolean;
  spreadX?: boolean;
  centerY?: boolean;
  centerX?: boolean;
  fullWidth?: boolean;
} & DivProps) => {
  const isCenter = centerY && centerX;
  return (
    <Div
      className={[
        "flexRow",
        inline ? "inline" : "",
        wrap ? "wrap" : "",
        column ? "column" : "",
        center && !isCenter ? "center" : "",
        start && !isCenter ? "start" : "",
        end ? "end" : "",
        spreadX && !isCenter ? "spreadX" : "",
        centerX ? "centerX" : "",
        centerY ? "centerY" : "",
        fullWidth ? "fullWidth" : "",
        className,
      ].join(" ")}
      {...rest}
    >
      {children}
    </Div>
  );
};

export default FlexRow;
