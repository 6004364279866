import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import {
  CheckIfVerifiedQuery,
  CheckIfVerifiedQueryVariables,
} from "../../../__generated__/graphql";
import api from "../../../api";

const useCheckIfVerified = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "profile";
  const type = "checkIfVerified";

  const handleOnCompleted = (data: CheckIfVerifiedQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        data: data.profile.checkIfVerified,
        loading: false,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  };

  const [apiFunction] = useLazyQuery(api.query.profile.CHECK_IF_VERIFIED, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: CheckIfVerifiedQueryVariables) => {
    if (!state.profile.checkIfVerified.loading) {
      apiFunction({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCheckIfVerified;
