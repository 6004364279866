import { Div } from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import PostForm, {
  CreateEditPostInputs,
} from '../../forms/feed/PostForm';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../navigation/routes';
import get from '../../utils/utilityFunctions/get';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const EditPost = () => {
  const { state, actions } = useStore();
  const params = useParams();
  const navigate = useCustomNavigate();

  const onSubmit = (args: CreateEditPostInputs) => {
    actions.post.mutation.updatePost({
      postUuid: state.post.viewPost.data?.uuid as string,
      draft: args.draft,
      title: args.title,
      content: args.description,
    });
  };

  useEffect(() => {
    if (
      !state.post.createPost.data &&
      get(state, 'post.createPost.data.uuid', null) !==
        params.postId
    ) {
      // edit post query here
      if (params.postId) {
        actions.post.query.viewPost({
          postUuid: params.postId,
        });
      } else {
        // did not navigate from create post and does not have id in url, navigate away.
        actions.app.setErrorMessage(
          state.app.systemMessages?.errors.post.doesNotExist ||
            'The post is not available, please try again',
        );
        navigate(routes.feed);
      }
    }
  }, []);

  return (
    <Div>
      <PostForm
        onSubmit={onSubmit}
        loading={
          state.post.viewPost.loading ||
          state.post.updatePost.loading ||
          false
        }
        existingPost={
          state.post.updatePost.data ||
          state.post.createPost.data ||
          state.post.viewPost.data
        }
      />
    </Div>
  );
};

export default EditPost;
