import { Button, Div, Image } from '../_ui';
import React from 'react';
import { Maybe, Scalars } from '../../__generated__/graphql';
import '../../styles/components/_common/avatarAndName.scss';

// TODO make these required and fix usages
export interface AvatarProps {
  handleClick?: () => void;
  url?: Maybe<Scalars['String']['output']> | string | undefined;
  alt?: string;
  size?: number;
}

const defaultAvatarUrl =
  process.env.REACT_APP_AVATAR_DEFAULT ||
  ('https://picsum.photos/25/25' as any);

const Avatar: React.FC<AvatarProps> = ({
  handleClick = () => null,
  url = defaultAvatarUrl,
  alt = '',
  size = 28,
}) => {
  return (
    <Div className={'avatarAndName'}>
      <Button
        className={'image avatarAndNameImage'}
        variant={'transparent'}
        shape={'round'}
        onClick={handleClick}
      >
        <Image
          height={size}
          src={url}
          alt={`${alt} profile picture`}
          withBorderRadius
        />
      </Button>
    </Div>
  );
};

export default Avatar;
