import {
  Div,
  Divider,
  FlexRow,
  Icon,
  Image,
  Pill,
  Text,
} from '../_ui';
import React from 'react';
import {
  faChartSimple,
  faFlag,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import {
  Maybe,
  ReportUserEnum,
  SuggestedConnectionObjectType,
  Tag,
} from '../../__generated__/graphql';
import '../../styles/components/_common/profileBrief.scss';
import { useStore } from '../../hooks/useStore';
import useReportUser from '../../hooks/useReportUser';
import get from '../../utils/utilityFunctions/get';

interface ProfileBrief {
  handleShowMetrics?: () => void;
  handleEdit?: () => void;
  family: Maybe<SuggestedConnectionObjectType>;
}
const FamilyProfileBrief: React.FC<ProfileBrief> = ({
  handleShowMetrics,
  handleEdit,
  family,
}) => {
  const { state } = useStore();
  const reportUser = useReportUser();
  const systemMessages = state.app.systemMessages;

  if (!family) {
    return (
      <Div>
        <Text>
          {systemMessages?.errors.family.familyNotAvailable}
        </Text>
      </Div>
    );
  }

  const showReportModal = () => {
    reportUser(
      get(family, 'family.admin_profile_uuids[0]'),
      ReportUserEnum.Family,
      'report',
      get(family, 'family.uuid', 'N/A'),
    );
  };

  return (
    <Div>
      <Div className={'familyProfileImageContainer'}>
        <Image
          src={
            family.family.primary_profile_image_url ||
            '/defaultImages/defaultFamilyProfileImage.jpg'
          }
          alt={'family alt'}
          height={250}
          width={398}
          withBorderRadius
        />
        <Div className={'reportFamily'}>
          <Icon
            icon={faFlag}
            color={'primary'}
            onClick={showReportModal}
            height={20}
            width={20}
          />
        </Div>
      </Div>
      <Div className={'flexRow col-2'}>
        <Div>
          <Text variant={'h4'} color={'grey800'}>
            {family.family.name}
          </Text>
          <Text variant={'span'} color={'grey800'}>
            {family.family_members.join(', ')}
          </Text>
        </Div>
        {handleEdit || handleShowMetrics ? (
          <Div>
            {handleShowMetrics && (
              <Icon
                onClick={handleShowMetrics}
                icon={faChartSimple}
              />
            )}
            {handleEdit && (
              <Icon onClick={handleEdit} icon={faPenToSquare} />
            )}
          </Div>
        ) : (
          <FlexRow>
            {family.interests?.map((interest: Maybe<Tag>) => (
              <Text color={'grey800'} key={interest?.uuid}>
                {/*{String.fromCodePoint(interest.unicode as unknown as number)}*/}
                {interest?.tag_icon}
              </Text>
            ))}
          </FlexRow>
        )}
      </Div>
      <Divider color={'transparent'} />
      <Text variant={'p'} color={'grey800'}>
        {family.family.bio ||
          systemMessages?.errors.family.bioNotAvailable}
      </Text>
      <Divider color={'transparent'} />
      <Div>
        <Text variant={'p'} color={'grey800'} weight={800}>
          Interests
        </Text>
        <Divider color={'transparent'} />
        <FlexRow wrap start spreadX={false}>
          {family.interests?.map((interest: Maybe<Tag>) => (
            <Pill
              title={interest?.name}
              variant={'outlined'}
              key={interest?.name}
            />
          ))}
        </FlexRow>
      </Div>
      <Divider color={'transparent'} />
    </Div>
  );
};

export default FamilyProfileBrief;
