import { FlexRow, Text } from '../../components/_ui';
import { NotificationItem } from '../../components/notifications';
import React, { useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import '../../styles/pages/notifications.scss';
import isEmpty from '../../utils/utilityFunctions/isEmpty';

const Notifications = () => {
  const { state, actions, dispatch } = useStore();

  useEffect(() => {
    dispatch({
      parent: 'notification',
      type: 'viewNotifications',
      payload: {
        viewed: true,
      },
    });
  }, []);

  return (
    <FlexRow column fullWidth>
      {/*<MyFamilySelection*/}
      {/*  fetchOnFamilyMemberChange={fetchOnFamilyMemberChange}*/}
      {/*  fetchOnCategoryChange={fetchOnCategoryChange}*/}
      {/*/>*/}
      {isEmpty(state.notification.notificationByProfile.data) &&
        !state.notification.notificationByProfile.loading && (
          <FlexRow fullWidth centerX centerY>
            <Text variant={'h5'} color={'grey400'}>
              No notifications at this time
            </Text>
          </FlexRow>
        )}
      {(state.notification.notificationByProfile.data || []).map(
        (notification, index) =>
          notification &&
          notification.notification_content && (
            <NotificationItem
              key={`${notification.notification_content.uuid}-${index}`}
              {...notification.notification_content}
            />
          ),
      )}
    </FlexRow>
  );
};

export default Notifications;
