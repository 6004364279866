import routes from '../../navigation/routes';
import {
  Button,
  Card,
  Div,
  Divider,
  FlexRow,
  Image,
  Loader,
  Text,
} from '../_ui';
import { ConnectionQueryObjectType } from '../../__generated__/graphql';
import { useStore } from '../../hooks/useStore';
import React, { SyntheticEvent } from 'react';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import get from '../../utils/utilityFunctions/get';

const FamilyProfileSmallCard = ({
  connection,
  ignore,
  accept,
  revoke,
}: {
  connection: ConnectionQueryObjectType;
  ignore?: boolean;
  accept?: boolean;
  revoke?: boolean;
}) => {
  const navigate = useCustomNavigate();
  const { state, actions, dispatch } = useStore();

  const handleIgnoreInvite = (e: SyntheticEvent) => {
    e.stopPropagation();
    actions.connection.mutation.ignoreConnection({
      connectionUuid: connection.uuid,
    });
  };
  const handleAcceptInvite = (e: SyntheticEvent) => {
    e.stopPropagation();
    actions.connection.mutation.approveConnection({
      connectionUuid: connection.uuid,
    });
  };
  const handleRevokeInvite = (e: SyntheticEvent) => {
    e.stopPropagation();
    actions.connection.mutation.revokeConnectionInvite({
      connectionUuid: connection.uuid,
    });
  };

  const ignoreLoading =
    state.connection.ignoreConnection.loading &&
    state.connection.ignoreConnection.connectionUUID ===
      connection.uuid;
  const acceptLoading =
    state.connection.approveConnection.loading &&
    state.connection.approveConnection.connectionUUID ===
      connection.uuid;
  const revokeLoading =
    state.connection.revokeConnectionInvite.loading &&
    state.connection.revokeConnectionInvite.connectionUUID ===
      connection.uuid;

  const isFamily1 =
    connection.family1_uuid ===
    state.profile.familyProfile.data?.family?.uuid;

  const routeUrl: string = isFamily1
    ? connection.family2_uuid
    : connection.family1_uuid;
  const imageSrc = isFamily1
    ? connection.family2_profile_image_url
    : connection.family1_profile_image_url;
  const connectionName = isFamily1
    ? connection.family2_name
    : connection.family1_name;
  const familyMembers = isFamily1
    ? connection.family2_members
    : connection.family1_members;
  const interests = isFamily1
    ? connection.family2_interests
    : connection.family1_interests;

  return (
    <Card
      onClick={() => {
        dispatch({
          parent: 'connection',
          type: 'connectionProfile',
          payload: {
            data: null,
          },
        });
        // reset state.connection.connectionProfile.data to ensure double loading is not happening on familyProfile.
        navigate<string>(routes.familyProfile, [routeUrl], {
          state: {
            isConnected: true,
          },
        });
      }}
    >
      <Image
        src={`${imageSrc}`}
        alt={`${connectionName}`}
        width={180}
        height={120}
        className={'connectionProfileImage'}
      />
      <Div className={'familyDetails'}>
        <Text variant={'h4'} color={'grey800'}>
          {connectionName}
        </Text>
        <Text variant={'span'} color={'grey800'}>
          {[familyMembers || []].join(', ')}
        </Text>
      </Div>
      <Divider color={'transparent'} />
      <FlexRow start spreadX={false}>
        {interests?.map((interest) => (
          <Text>{interest?.tag_icon}</Text>
        ))}
      </FlexRow>
      <Divider color={'transparent'} />
      <FlexRow inline>
        <FlexRow column inline>
          <Text variant={'span'} color={'grey100'}>
            Friends since
          </Text>
          <Text variant={'span'} color={'grey800'}>
            {new Date(
              Number(connection.updated_at),
            ).toDateString()}
          </Text>
        </FlexRow>
        <Button
          variant={'transparent'}
          color={'primary'}
          size={'xs'}
        >
          <Text variant={'span'} weight={800}>
            View
          </Text>
        </Button>
      </FlexRow>
      {(ignore || accept || revoke) && (
        <>
          <Divider color={'transparent'} />
          <FlexRow inline>
            {ignore && (
              <Button
                variant={'contained'}
                color={'primary'}
                size={'xs'}
                onClick={handleIgnoreInvite}
              >
                {ignoreLoading && <Loader color={'white'} />}
                <Text variant={'span'} color={'white'}>
                  Ignore
                </Text>
              </Button>
            )}
            {accept && (
              <Button
                variant={'contained'}
                color={'secondary'}
                size={
                  get(state, 'app.mobile.isNative', false)
                    ? 'md'
                    : 'xs'
                }
                onClick={handleAcceptInvite}
              >
                {acceptLoading && (
                  <Loader color={'white'} size={'xs'} />
                )}
                <Text variant={'span'} color={'white'}>
                  Accept
                </Text>
              </Button>
            )}
            {revoke && (
              <Button
                variant={'contained'}
                color={'primary'}
                size={
                  get(state, 'app.mobile.isNative', false)
                    ? 'md'
                    : 'xs'
                }
                onClick={handleRevokeInvite}
              >
                {revokeLoading && (
                  <Loader color={'white'} size={'xs'} />
                )}
                <Text variant={'span'} color={'white'}>
                  Cancel
                </Text>
              </Button>
            )}
          </FlexRow>
        </>
      )}
    </Card>
  );
};

export default FamilyProfileSmallCard;
