import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  FamilyProfileQuery,
  FamilyProfileQueryVariables,
} from '../../../__generated__/graphql';
import api from '../../../api';

const useFamilyProfile = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'familyProfile';
  const handleOnCompleted = (data: FamilyProfileQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.profile.familyProfile,
      },
    });
    // console.log('***************');
    // console.log('useFamilyProfile');
    // dispatch({
    //   parent,
    //   type: 'setCurrentProfile',
    //   payload: data.profile.familyProfile,
    // });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [apiFunction] = useLazyQuery(
    api.query.profile.FAMILY_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: FamilyProfileQueryVariables) => {
    if (!state.profile.familyProfile.loading) {
      apiFunction({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useFamilyProfile;
