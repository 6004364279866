import { Button, FlexRow, Pill, Text } from "../../_ui";
import { useStore } from "../../../hooks/useStore";
import { CommunityObjectType, Maybe } from "../../../__generated__/graphql";
import { SyntheticEvent } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const ChooseCommunities = () => {
  const { state, actions } = useStore();

  const removeCommunity =
    (community: Maybe<CommunityObjectType>) => (e: SyntheticEvent) => {
      if (!community) return null;
      e.stopPropagation();
      actions.profile.removeCommunity(community);
    };

  return (
    <FlexRow column>
      <Text variant={"h4"} color={"grey800"}>
        Choose Suggested Communities
      </Text>
      <FlexRow spreadX={false} wrap>
        {state.profile.chosenCommunities.map((community) => (
          <Pill
            onClick={removeCommunity(community)}
            title={community?.name}
            key={community?.uuid}
            variant={"outlined"}
            color={"primary"}
            iconEnd={faClose}
          />
        ))}
      </FlexRow>
      <Button
        variant={"outlined"}
        size={"md"}
        color={"primary"}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          e.preventDefault();
          actions.app.setModal({
            open: true,
            title: "Suggested Communities",
            type: "choose-communities",
          });
        }}
      >
        Edit Communities
      </Button>
    </FlexRow>
  );
};

export default ChooseCommunities;
