import {
  Button,
  Card,
  Div,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import { useEffect } from 'react';
import { AvatarAndName } from '../../components/_common';
import { Maybe } from '../../__generated__/graphql';

const ManageBlockedUsers = () => {
  const { state, actions } = useStore();

  useEffect(() => {
    actions.common.query.blockedUsersProfiles();
  }, []);

  const unblockUser = (
    profileUuid: Maybe<string> | undefined,
  ) => {
    if (profileUuid) {
      actions.common.mutation.unblockUser({
        profileUuid,
      });
    } else {
      actions.app.setErrorMessage(
        'There was a minor error, please navigate away and back to this page and try again',
      );
    }
  };

  return (
    <Card>
      {state.common.blockedUsersProfiles.loading ||
        (state.common.unblockUser.loading && (
          <Loader variant={'overlay'} position={'center'} />
        ))}
      <Div>
        {state.common.blockedUsersProfiles.data?.map(
          (blockedUser, index) =>
            blockedUser && (
              <>
                {index !== 0 && (
                  <Div>
                    <Divider color={'transparent'} />
                    <Divider />
                    <Divider color={'transparent'} />
                  </Div>
                )}
                <FlexRow centerY>
                  <FlexRow inline column>
                    <AvatarAndName
                      name={`${blockedUser.first_name} ${blockedUser.last_name}`}
                      alt={`${blockedUser.first_name} ${blockedUser.last_name}`}
                      url={blockedUser.profile_image_url}
                    />
                    <Divider color={'transparent'} />
                    <Text variant={'h5'} color={'grey800'}>
                      Reason Blocked
                    </Text>
                    <Text variant={'p'} color={'grey400'}>
                      {blockedUser.report_type_name}
                    </Text>
                    <Text variant={'span'} color={'grey200'}>
                      {blockedUser.report_subtype_name}
                    </Text>
                  </FlexRow>
                  <Button
                    size={'xs'}
                    onClick={() =>
                      unblockUser(blockedUser.profile_uuid)
                    }
                  >
                    Unblock
                  </Button>
                </FlexRow>
              </>
            ),
        )}
      </Div>
    </Card>
  );
};

export default ManageBlockedUsers;
