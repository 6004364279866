import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  RemoveActiveConnectionMutation,
  RemoveActiveConnectionMutationVariables,
} from "../../../__generated__/graphql";

const useRemoveActiveConnection = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "removeActiveConnection";

  const handleOnCompleted = (data: RemoveActiveConnectionMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.connection.removeActiveConnection,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [removeActiveConnection] = useMutation(
    api.mutation.connection.REMOVE_ACTIVE_CONNECTION,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: RemoveActiveConnectionMutationVariables) => {
    if (!state.connection.removeActiveConnection.loading) {
      removeActiveConnection({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useRemoveActiveConnection;
