import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  IgnoreConnectionMutation,
  IgnoreConnectionMutationVariables,
} from "../../../__generated__/graphql";

const useIgnoreConnection = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "ignoreConnection";

  const handleOnCompleted = (data: IgnoreConnectionMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.connection.ignoreConnection,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [ignoreConnection] = useMutation(
    api.mutation.connection.IGNORE_CONNECTION,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: IgnoreConnectionMutationVariables) => {
    if (!state.connection.ignoreConnection.loading) {
      ignoreConnection({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
        connectionUUID: args.connectionUuid,
      },
    });
  };
};

export default useIgnoreConnection;
