import {
  Button,
  Div,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../_ui';
import { useParams } from 'react-router-dom';
import routes from '../../navigation/routes';
import { FeedPost } from '../feed';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import { useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import { AppPagesEnum } from '../../types/pages';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const CommunityPostRenderer = () => {
  const { communityId } = useParams();
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();

  const navigateOnError = () => {
    actions.app.setServerError(
      state.post.postsByCommunity.error ||
        'Error retrieving community posts',
    );
    navigate(routes.communities);
  };

  useEffect(() => {
    if (communityId) {
      actions.post.query.postsByCommunity({
        communityUuid: communityId,
      });
    } else {
      navigateOnError();
    }
  }, []);

  useEffect(() => {
    if (state.post.postsByCommunity.error) navigateOnError();
  }, [state.post.postsByCommunity.error]);

  if (state.post.postsByCommunity.loading) {
    return (
      <Div>
        <Loader position={'center'} color={'white'} />
      </Div>
    );
  }

  const posts = state.post.postsByCommunity.data || null;

  if (isEmpty(posts) || !posts) {
    return (
      <Div className={'card noPostsForCommunity'}>
        <Text variant={'h3'} color={'grey800'}>
          There are no posts for this community yet
        </Text>
        <Text variant={'p'} color={'grey400'}>
          Be the first to create one!
        </Text>
        <Divider color={'transparent'} />
        <FlexRow inline>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              // This should not be null because we check for it above in use effect.
              navigate<string>(routes.createCommunityPost, [
                communityId as string,
              ]);
            }}
          >
            Create Post
          </Button>
        </FlexRow>
      </Div>
    );
  }

  return (
    <Div>
      {(posts || []).map((post, i: number) => (
        <FeedPost
          key={post?.uuid || i}
          type={post?.post_type}
          // TODO refine this type once everything is working again
          // @ts-ignore
          post={post}
          index={i}
          shouldNavigateOnClick
          isCommunityPost
        />
      ))}
    </Div>
  );
};

export default CommunityPostRenderer;
