import {
  Button,
  Div,
  FlexRow,
  Icon,
  Image,
  Input,
  Text,
} from '../_ui';
import {
  Control,
  Controller,
  FieldValues,
  Path,
} from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_common/manageImageUpload.scss';
import {
  InputMaybe,
  Scalars,
} from '../../__generated__/graphql';

type UploadImageProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  error?: string;
  existingImage?:
    | string
    | null
    | InputMaybe<Scalars['Upload']['input']>;
};

const ManageImageUpload = <T extends FieldValues>({
  control,
  name,
  error,
  existingImage = null,
}: UploadImageProps<T>) => {
  const [tempFile, setTempFile] = useState<
    string | ArrayBuffer | null
  >(existingImage);

  console.log('existingImage');
  console.log(existingImage);
  console.log('tempFile');
  console.log(tempFile);

  useEffect(() => {
    // this ensures that its not the uploaded value
    if (existingImage && typeof existingImage === 'string')
      setTempFile(existingImage);
  }, [existingImage]);

  return (
    <>
      <Div
        color={'grey50'}
        className={[
          'manageImageUpload',
          tempFile ? 'active' : '',
        ].join(' ')}
      >
        {tempFile && (
          <Image
            wrapperClass={'uploadedImage'}
            src={tempFile as string}
            alt={'Temp alt tag'}
          />
        )}
        <FlexRow className={'uploadImageActions'}>
          {!tempFile && (
            <Icon
              icon={faImage}
              height={38}
              width={38}
              color={'grey100'}
            />
          )}
          <Div className={'hiddenFileContainer'}>
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <Input
                  containerClassName={'hiddenFileInput'}
                  type={'file'}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      const reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = () => {
                        setTempFile(reader.result);
                        field.onChange(e.target.files);
                      };
                    }
                  }}
                />
              )}
            />
            <Button
              size={'sm'}
              shape={'round'}
              color={'primary'}
              variant={'contained'}
            >
              Upload {tempFile && 'New'}
            </Button>
          </Div>
        </FlexRow>
      </Div>
      {error && (
        <Text variant={'h5'} color={'error'}>
          {error}
        </Text>
      )}
    </>
  );
};

export default ManageImageUpload;
