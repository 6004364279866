import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  CreatePostMutation,
  CreatePostMutationVariables,
} from '../../../__generated__/graphql';
import routes from '../../../navigation/routes';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import usePostFeed from './usePostFeed';

const useCreatePost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'post';
  const type = 'createPost';
  const navigate = useCustomNavigate();
  const feePost = usePostFeed(state, dispatch);

  const handleOnCompleted = (data: CreatePostMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.post.createPost,
      },
    });
    if (data.post.createPost.community?.uuid) {
      // navigate to edit community post
      navigate<string>(routes.editCommunityPost, [
        data.post.createPost.community.uuid,
        data.post.createPost.uuid,
      ]);
    } else {
      // navigate to edit feed post.
      navigate<string>(routes.editFeedPost, [
        data.post.createPost.uuid,
      ]);
      const { limit, offset } = state.post.postFeed;
      feePost({
        limit,
        offset: 0,
      });
    }
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createPost, { called, loading }] = useMutation(
    api.mutation.post.CREATE_POST,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createPost',
        },
      },
    },
  );

  useEffect(() => {
    // This is used to help with lazy loading inside useFeedFetcher.
    dispatch({
      parent: 'post',
      type: 'createPost',
      payload: {
        called,
        loading,
      },
    });
  }, [called, loading]);

  return (args: CreatePostMutationVariables) => {
    if (!state.post.createPost.loading) {
      createPost({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreatePost;
