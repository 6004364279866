import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  DeleteCommunityMutation,
  DeleteCommunityMutationVariables,
} from "../../../__generated__/graphql";

const useDeleteCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "deleteCommunity";

  const handleOnCompleted = (data: DeleteCommunityMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.deleteCommunity,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [deleteCommunity] = useMutation(
    api.mutation.community.DELETE_COMMUNITY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: DeleteCommunityMutationVariables) => {
    if (!state.community.deleteCommunity.loading) {
      deleteCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useDeleteCommunity;
