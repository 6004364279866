import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useCreateFamilyMember from './api/useCreateFamilyMember';
import useCreateFamily from './api/useCreateFamily';
import useFamilyRelationType from './api/useFamilyRelationType';
import useUpdateFamily from './api/useUpdateFamily';
import useFamilyMemberPermissions from './api/useFamilyMemberPermissions';
import useMyPermissions from './api/useMyPermissions';
import { FamilyStoreActions } from '../../types/reducers/family';
import useSaveFamilyInterests from './api/useSaveFamilyInterests';

export const useFamilyActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): FamilyStoreActions => {
  return {
    query: {
      familyRelationType: useFamilyRelationType(state, dispatch),
      familyMemberPermissions: useFamilyMemberPermissions(
        state,
        dispatch,
      ),
      myPermissions: useMyPermissions(state, dispatch),
    },
    mutation: {
      createFamilyMember: useCreateFamilyMember(state, dispatch),
      createFamily: useCreateFamily(state, dispatch),
      updateFamily: useUpdateFamily(state, dispatch),
      saveFamilyInterests: useSaveFamilyInterests(
        state,
        dispatch,
      ),
    },
  };
};

export default useFamilyActions;
