import { gql } from '../../../__generated__';

const CITY = gql(`
  query City {
    common {
      city {
        uuid
        name
      }
    }
  }
`);

const COUNTRY = gql(`
query Country {
  common {
    country {
      uuid
      name
      code
    }
  }
}`);

const GENDER = gql(`
query Gender {
  common {
    gender {
      uuid
      name
    }
  }
}`);

const LANGUAGE = gql(`
query Language {
  common {
    language {
      uuid
      name
      code
    }
  }
}
`);

const STATE = gql(`
query State {
  common {
    state {
      uuid
      name
      code
    }
  }
}`);

const LIKE_TYPE = gql(`
query LikeType {
  common {
    likeType {
      uuid
      unicode
    }
  }
}`);

const TAG = gql(`
query Tag($refine: sortAndFilterBaseArgs) {
  common {
    tag(refine: $refine) {
      data {
        uuid
        name
        tag_icon
      }
      limit
      offset
      total
      phrase
    }
  }
}`);

const TIMEZONE = gql(`
query Timezone {
  common {
    timezone {
      uuid
      timezone_name
      utc_offset
    }
  }
}`);

const SCHOOL = gql(`
  query School($refine: sortAndFilterBaseArgs) {
    common {
      school(refine: $refine) {
        data {
          uuid
          name
          description
          district
          address
          city_uuid
          state_uuid
          country_uuid
          zip
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const SCHOOL_BY_UUID = gql(`
  query SchoolByUUID($schoolUuid: String) {
    common {
      schoolByUUID(school_uuid: $schoolUuid) {
        uuid
        name
        description
        district
        address
        city_uuid
        state_uuid
        country_uuid
        zip
      }
    }
  }
`);

const REPORT_TYPES = gql(`
  query ReportTypes {
    common {
      reportTypes {
        uuid
        type
        name
        sub_types {
          uuid
          name
          type
        }
      }
    }
  }
`);

const BLOCKED_USERS_UUIDS = gql(`
  query Common {
    common {
      blockedUsersUUIDS
    }
  }
`);

const BLOCKED_USERS_PROFILES = gql(`
  query BlockedUsersProfiles($refine: sortAndFilterBaseArgs) {
    common {
      blockedUsersProfiles(refine: $refine) {
        data {
          profile_uuid
          profile_image_url
          first_name
          last_name
          blocked_user_uuid
          report_type_uuid
          report_type_type
          report_type_name
          report_subtype_uuid
          report_subtype_type
          report_subtype_name
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const common = {
  LIKE_TYPE,
  CITY,
  COUNTRY,
  GENDER,
  LANGUAGE,
  STATE,
  TAG,
  TIMEZONE,
  SCHOOL,
  SCHOOL_BY_UUID,
  REPORT_TYPES,
  BLOCKED_USERS_UUIDS,
  BLOCKED_USERS_PROFILES,
};

export default common;
