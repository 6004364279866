import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  colorsOptions,
  monoColorOptions,
} from '../../types/theme/colors';
import Div from './Div';
import '../../styles/components/_ui/icon.scss';
import { useStore } from '../../hooks/useStore';

export type IconAnimationStyle = {
  spin?: boolean;
  bounce?: boolean;
  pulse?: boolean;
  beat?: boolean;
  // flip?: boolean;
  shake?: boolean;
} | null;

interface IconProps {
  icon: IconProp;
  color?: colorsOptions | monoColorOptions;
  height?: number;
  width?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  animationStyle?: IconAnimationStyle;
}

const Icon: React.FC<IconProps> = ({
  icon,
  color = 'primary',
  height,
  width,
  onClick,
  className,
  animationStyle,
}) => {
  const { state } = useStore();
  return (
    <Div
      onClick={onClick}
      className={[
        'icon',
        state.app.themeMode,
        color,
        (height || width) && 'adjustForCustomWidth',
        className,
      ].join(' ')}
      style={{
        height,
        width,
      }}
    >
      <FontAwesomeIcon icon={icon} {...(animationStyle || {})} />
    </Div>
  );
};

export default Icon;
