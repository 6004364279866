import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  LikePostMutation,
  LikePostMutationVariables,
} from "../../../__generated__/graphql";
import usePostFeedItemById from "./usePostFeedItemById";

const useLikePost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "likePost";
  const postFeedItemById = usePostFeedItemById(state, dispatch);

  const handleOnCompleted = async (data: LikePostMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.post.likePost,
      },
    });
    postFeedItemById({
      postUuid: data.post.likePost.uuid,
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [likePost] = useMutation(api.mutation.post.LIKE_POST, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: LikePostMutationVariables) => {
    if (!state.post.likePost.loading) {
      likePost({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useLikePost;
