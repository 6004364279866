import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
} from 'react';

export type DivProps = {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  className?: string;
  layoutType?: 'row' | 'grid';
  style?: React.CSSProperties;
  id?: string;
} & HTMLAttributes<HTMLDivElement>;

const Div = forwardRef<any, DivProps>(
  (
    {
      children,
      onClick,
      className = '',
      layoutType = '',
      style = {},
      id,
    },
    ref: ForwardedRef<HTMLDivElement>,
    ...rest // used for gestures
  ) => {
    return (
      <div
        id={id}
        ref={ref}
        className={`${className} ${layoutType}`}
        onClick={onClick}
        style={style}
        {...(rest || {})}
      >
        {children}
      </div>
    );
  },
);

export default Div;
