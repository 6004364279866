import { Div, FlexRow, Icon, Text } from '../../_ui';
import React, { SyntheticEvent } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import JoinCommunityButton from './JoinCommunityButton';
import {
  FamilyMemberPartialObjectType,
  Maybe,
} from '../../../__generated__/graphql';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import routes from '../../../navigation/routes';
import { useStore } from '../../../hooks/useStore';
import storage from '../../../utils/storage';

const GeneralPostHeader = ({
  title,
  actionText,
  icon,
  canJoin,
  isMember = false,
  communityUUID,
  familyMember,
}: {
  title: string;
  actionText?: string;
  icon?: IconProp;
  canJoin?: boolean;
  isMember?: boolean;
  communityUUID?: string;
  familyMember: Maybe<FamilyMemberPartialObjectType> | undefined;
}) => {
  const { state } = useStore();
  const navigate = useCustomNavigate();
  const navigateToProfile = (e: SyntheticEvent) => {
    if (familyMember) {
      storage
        .getFromStorage('family_member_uuid')
        .then((data) => {
          if (data !== familyMember.uuid) {
            // only navigate in certain situations as well as only whenever the profile trying to navigate to is not yours.
            e.stopPropagation();
            e.preventDefault();
            navigate(routes.viewPublicProfile, [
              familyMember.uuid,
            ]);
          }
        });
    }
  };
  return (
    <FlexRow inline>
      {icon && <Icon icon={icon} />}
      <Div onClick={navigateToProfile}>
        <Text variant={'span'} color={'grey800'}>
          {title}
        </Text>
      </Div>
      <FlexRow inline>
        <Text variant={'span'} color={'grey200'}>
          {actionText}
        </Text>
        {communityUUID && !isMember && (
          <JoinCommunityButton
            communityUUID={communityUUID}
            canJoin={canJoin || false}
          />
        )}
      </FlexRow>
    </FlexRow>
  );
};

export default GeneralPostHeader;
