import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  SendChatMutation,
  SendChatMutationVariables,
} from '../../../__generated__/graphql';

const useSendChat = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'chat';
  const type = 'sendChat';

  const handleOnCompleted = (data: SendChatMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.chat.sendChat,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [deleteMessage] = useMutation(
    api.mutation.chat.SEND_CHAT,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'sendChat',
        },
      },
    },
  );

  return (args: SendChatMutationVariables) => {
    if (!state.chat.sendChat.loading) {
      deleteMessage({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useSendChat;
