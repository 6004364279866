import { Divider, FlexRow, Text } from "../components/_ui";

const NotFound = () => {
  return (
    <FlexRow className={"card"} column inline centerX>
      <Text variant={"h2"} color={"grey800"}>
        Oh No!
      </Text>
      <Divider color={"transparent"} />
      <Text variant={"h4"} color={"grey800"}>
        The Page you are looking for cannot be found
      </Text>
      <Text variant={"p"} color={"grey200"}>
        We're sorry about this and will look into it!
      </Text>
    </FlexRow>
  );
};

export default NotFound;
