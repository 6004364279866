import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  CreateCommunityMutation,
  CreateCommunityMutationVariables,
} from '../../../__generated__/graphql';
import routes from '../../../navigation/routes';
import useNavigateForwardToRoute from '../../app/useNavigateForwardToRoute';
import { AppPagesEnum } from '../../../types/pages';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const useCreateCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'community';
  const type = 'createCommunity';
  const navigate = useCustomNavigate();

  const handleOnCompleted = (data: CreateCommunityMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.createCommunity,
      },
    });
    navigate<string>(routes.editCommunity, [
      data.community.createCommunity.uuid,
    ]);
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createCommunity] = useMutation(
    api.mutation.community.CREATE_COMMUNITY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'createCommunity',
        },
      },
    },
  );

  return (args: CreateCommunityMutationVariables) => {
    if (!state.community.createCommunity.loading) {
      createCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateCommunity;
