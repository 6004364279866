import React from 'react';
import {
  CommunityInviteObjectType,
  CommunityRequestObjectType,
  CommunityStatusEnumType,
  NotificationCommentLikeObjectType,
  NotificationCommentReplyObjectType,
  NotificationConnectionEnumType,
  NotificationConnectionObjectType,
  NotificationMethodEnumType,
  NotificationPostCommentObjectType,
  NotificationPostLikeObjectType,
  NotificationPostPostContentObjectType,
  NotificationTypeEnumType,
  NotificationTypeUnionType,
} from '../../__generated__/graphql';
import { getNotificationDateStamp } from '../../utils/dateUtils';
import get from '../../utils/utilityFunctions/get';
import { Button, FlexRow, Text } from '../_ui';
import routes, { BaseRoute } from '../../navigation/routes';
import { useStore } from '../../hooks/useStore';

const RenderNotification: React.FC<{
  notification: NotificationTypeUnionType;
  render: ({
    header,
    title,
    subtitle,
    meta,
    time,
    navigateArgs,
    // metaIcons
  }: {
    header: React.ReactNode | string;
    time: string;
    title: React.ReactNode | string;
    subtitle: React.ReactNode | string;
    meta: React.ReactNode | string | null;
    navigateArgs?: [
      route: BaseRoute,
      [id1?: string, id2?: string] | undefined,
      Object?,
    ];
    // metaIcons
  }) => React.ReactNode;
}> = ({ render, notification: n }): React.ReactNode => {
  const time = getNotificationDateStamp(n.created_at || '');
  const { state, actions } = useStore();

  const baseNotificationType = () =>
    render({
      header: `New Notification`,
      title: n.type || '',
      subtitle: n.notification_type || '',
      time,
      meta: null,
    });

  switch (n.notification_type) {
    case NotificationTypeEnumType.Comment:
      switch (n.type) {
        case NotificationMethodEnumType.Like: {
          const notification =
            n as NotificationCommentLikeObjectType;
          return render({
            header: (
              <>
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.nickname}
                </Text>{' '}
                liked your comment
              </>
            ),
            title: notification.original_comment,
            subtitle: '',
            time,
            meta: null,
            navigateArgs: [
              routes.feedPost,
              [notification.post_uuid as string],
              {
                state: {
                  comment_uuid: notification.comment_uuid,
                },
              },
            ],
          });
        }
        case NotificationMethodEnumType.Reply: {
          const notification =
            n as NotificationCommentReplyObjectType;
          return render({
            header: (
              <>
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.nickname}
                </Text>{' '}
                replied to your comment
              </>
            ),
            title: notification.reply,
            subtitle: notification.original_comment,
            time,
            meta: null,
            navigateArgs: [
              routes.feedPost,
              [notification.post_uuid as string],
              {
                state: {
                  comment_uuid: notification.comment_uuid,
                },
              },
            ],
          });
        }
        default:
          return baseNotificationType();
      }
    case NotificationTypeEnumType.Post:
      switch (n.type) {
        case NotificationMethodEnumType.Comment: {
          const notification =
            n as NotificationPostCommentObjectType;
          const postContent = get(
            notification,
            'post_content[0]',
            {},
          ) as NotificationPostPostContentObjectType;
          return render({
            header: (
              <>
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.nickname}
                </Text>{' '}
                commented on your post
              </>
            ),
            title: notification.comment || '',
            subtitle: `post: ${postContent.title}`,
            time,
            meta: null,
            navigateArgs: [
              routes.feedPost,
              [notification.post_uuid as string],
              {
                state: {
                  comment_uuid: notification.comment_uuid,
                },
              },
            ],
          });
        }
        case NotificationMethodEnumType.Like: {
          const notification =
            n as NotificationPostLikeObjectType;
          const postContent = get(
            notification,
            'post_content[0]',
            {},
          ) as NotificationPostPostContentObjectType;
          return render({
            header: (
              <>
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.nickname}
                </Text>{' '}
                liked on your post
              </>
            ),
            title: postContent.title,
            subtitle: postContent.subtitle,
            time,
            meta: null,
            navigateArgs: [
              routes.feedPost,
              [notification.post_uuid as string],
            ],
          });
        }
        default:
          return baseNotificationType();
      }
    case NotificationTypeEnumType.Community:
      switch (n.type) {
        case NotificationMethodEnumType.RequestRejected:
        case NotificationMethodEnumType.RequestConfirmed: {
          const notification = n as CommunityRequestObjectType;
          const didJoin =
            notification.community_status ===
              CommunityStatusEnumType.Active ||
            notification.community_status ===
              CommunityStatusEnumType.Joined;
          return render({
            header: (
              <>
                request to join{' '}
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.name}
                </Text>
              </>
            ),
            title: `${didJoin ? 'Welcome!' : 'Sorry'}`,
            subtitle: didJoin
              ? 'Thanks for joining!'
              : "We don't believe you to be a good fit for this community.",
            time,
            meta: null,
            navigateArgs: [
              routes.community,
              [notification.community_uuid as string],
            ],
          });
        }
        case NotificationMethodEnumType.InviteAccepted: {
          const notification = n as CommunityInviteObjectType;
          const didAccept =
            notification.community_status ===
            CommunityStatusEnumType.Joined;
          return render({
            header: (
              <>
                <Text
                  variant={'span'}
                  weight={800}
                  color={'grey400'}
                >
                  {notification.nickname}
                </Text>{' '}
                {didAccept ? 'accepted' : 'declined'} your invite
              </>
            ),
            title: `${notification.nickname} ${didAccept ? 'joined' : 'declined to join'} ${notification.name}`,
            subtitle: '',
            time,
            meta: null,
            navigateArgs: [
              routes.community,
              [notification.community_uuid as string],
            ],
          });
        }
        case NotificationMethodEnumType.InviteConfirmed: {
          const notification = n as CommunityInviteObjectType;
          return render({
            header: `invite received`,
            title: (
              <>
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.nickname}
                </Text>{' '}
                received your invite to{' '}
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.name}
                </Text>
              </>
            ),
            subtitle: '',
            time,
            meta: null,
          });
        }
        case NotificationMethodEnumType.InviteRejected: {
          const notification = n as CommunityInviteObjectType;
          return render({
            header: `invite rejected`,
            title: (
              <>
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.nickname}
                </Text>{' '}
                did not accept your invite to{' '}
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.name}
                </Text>
              </>
            ),
            subtitle: '',
            time,
            meta: null,
          });
        }
        case NotificationMethodEnumType.InviteRequested: {
          const notification = n as CommunityInviteObjectType;
          return render({
            header: `you've been invited`,
            title: (
              <>
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.nickname}
                </Text>{' '}
                wants you to join{' '}
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.name}
                </Text>
              </>
            ),
            subtitle: 'Tap to manage invite.',
            time,
            // TODO add buttons here to accept/reject community invite
            meta:
              !notification.role &&
              get(
                state,
                'community.respondToCommunityInvitation.communitiesRespondedTo',
                [],
              ).indexOf(notification.community_uuid) < 0 ? (
                <FlexRow inline>
                  <Button
                    disabled={
                      state.community
                        .respondToCommunityInvitation.loading
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.community.mutation.respondToCommunityInvitation(
                        {
                          communityUuid: notification!
                            .community_uuid as string,
                          join: true,
                        },
                      );
                    }}
                    variant={'contained'}
                    color={'primary'}
                    size={'xs'}
                  >
                    Accept
                  </Button>
                  <Button
                    disabled={
                      state.community
                        .respondToCommunityInvitation.loading
                    }
                    variant={'outlined'}
                    color={'primary'}
                    size={'xs'}
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.community.mutation.respondToCommunityInvitation(
                        {
                          communityUuid: notification!
                            .community_uuid as string,
                          join: false,
                        },
                      );
                    }}
                  >
                    Decline
                  </Button>
                </FlexRow>
              ) : (
                <Text variant={'span'} color={'grey400'}>
                  Already Responded
                </Text>
              ),
          });
        }
        default:
          return baseNotificationType();
      }
    case NotificationTypeEnumType.Connection: {
      const notification = n as NotificationConnectionObjectType;
      switch (notification.connection_type) {
        case NotificationConnectionEnumType.Approve: {
          return render({
            header: `Hooray!`,
            title: (
              <>
                The{' '}
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.name}'s
                </Text>{' '}
                connected with you!
              </>
            ),
            subtitle: '',
            time,
            meta: null,
            navigateArgs: [routes.families, undefined],
          });
        }
        case NotificationConnectionEnumType.Request: {
          return render({
            header: `Ohh boy!`,
            title: (
              <>
                The{' '}
                <Text variant={'inherit'} color={'grey400'}>
                  {notification.name}'s
                </Text>{' '}
                want to connect with your family!
              </>
            ),
            subtitle: 'Tap to manage friendship',
            time,
            meta: null,
            navigateArgs: [routes.receivedRequests, undefined],
          });
        }
        case NotificationConnectionEnumType.Message:
        case NotificationConnectionEnumType.Recommendation:
        default:
          return baseNotificationType();
      }
    }
    case NotificationTypeEnumType.Family:
    default:
      return baseNotificationType();
  }
};

export default RenderNotification;
