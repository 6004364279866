import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import { ReportTypesQuery } from '../../../__generated__/graphql';
import get from '../../../utils/utilityFunctions/get';

const useReportTypes = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'reportTypes';

  const handleOnCompleted = (data: ReportTypesQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: get(data, 'common.reportTypes', []),
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [reportTypes] = useLazyQuery(
    api.query.common.REPORT_TYPES,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return () => {
    if (!state.common.reportTypes.loading) {
      reportTypes();
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useReportTypes;
