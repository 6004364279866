import { gql } from '../../../__generated__';

const LEAVE_COMMUNITY = gql(`
  mutation LeaveCommunity($communityUuid: String!) {
    community {
      leaveCommunity(community_uuid: $communityUuid) {
        success
        uuid
      }
    }
  }
`);

const JOIN_COMMUNITY = gql(`
  mutation JoinCommunity($communityUuid: String!) {
    community {
      joinCommunity(communityUUID: $communityUuid) {
        uuid
        community_uuid
        profile_uuid
        family_uuid
        role
        status
        invite_date
        invite_profile {
          uuid
          email
          nickname
          last_login
          logged_in
        }
      }
    }
  }
`);

const JOIN_COMMUNITIES = gql(`
  mutation JoinCommunities($communityUuiDs: [String]!) {
    community {
      joinCommunities(communityUUIDs: $communityUuiDs) {
        uuid
        community_uuid
        profile_uuid
        family_uuid
        role
        status
        invite_date
        invite_profile {
          uuid
          email
          nickname
          last_login
          logged_in
        }
      }
    }
  }
`);

const INVITE_TO_COMMUNITY = gql(`
  mutation InviteToCommunity(
    $communityUuid: String!
    $profileUuid: String
    $phoneNumber: String
    $name: String
  ) {
    community {
      inviteToCommunity(
        community_uuid: $communityUuid
        profile_uuid: $profileUuid
        phone_number: $phoneNumber
        name: $name
      ) {
        success
        uuid
      }
    }
  }
`);

const DELETE_COMMUNITY = gql(`
  mutation DeleteCommunity($communityUuid: String!) {
    community {
      deleteCommunity(community_uuid: $communityUuid) {
        success
        uuid
      }
    }
  }
`);

const CREATE_COMMUNITY = gql(`
  mutation CreateCommunity(
    $name: String!
    $description: String!
    $image: Upload!
    $isPrivate: Boolean
  ) {
    community {
      createCommunity(
        name: $name
        description: $description
        image: $image
        isPrivate: $isPrivate
      ) {
        uuid
        name
        description
        zip
        image_url
        private
        member_count
        member_count_online
        is_member
        role
      }
    }
  }
`);

const UPDATE_COMMUNITY = gql(`
  mutation UpdateCommunity(
    $uuid: String!
    $name: String
    $image: Upload
    $isPrivate: Boolean
    $description: String
  ) {
    community {
      updateCommunity(
        uuid: $uuid
        name: $name
        image: $image
        isPrivate: $isPrivate
        description: $description
      ) {
        uuid
        name
        description
        zip
        image_url
        private
        member_count
        member_count_online
        my_member_status
        is_member
        role
      }
    }
  }
`);

const INVITE_MANY_CONNECTIONS_TO_COMMUNITY = gql(`
  mutation InviteManyConnectionsToCommunity(
    $communityUuid: String!
    $connectionUuids: [String]!
  ) {
    community {
      inviteManyConnectionsToCommunity(
        community_uuid: $communityUuid
        connection_uuids: $connectionUuids
      ) {
        success
        uuid
      }
    }
  }
`);

const INVITE_BY_PHONE = gql(`
  mutation InviteByPhone(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $communityUuid: String!
  ) {
    community {
      inviteByPhone(
        first_name: $firstName
        last_name: $lastName
        phone: $phone
        community_uuid: $communityUuid
      ) {
        success
        uuid
      }
    }
  }
`);

const RESPOND_TO_COMMUNITY_INVITATION = gql(`
  mutation RespondToCommunityInvitation($communityUuid: String!, $join: Boolean!) {
  community {
    respondToCommunityInvitation(community_uuid: $communityUuid, join: $join) {
      success
      uuid
    }
  }
}
`);

const community = {
  LEAVE_COMMUNITY,
  JOIN_COMMUNITY,
  JOIN_COMMUNITIES,
  INVITE_TO_COMMUNITY,
  DELETE_COMMUNITY,
  CREATE_COMMUNITY,
  UPDATE_COMMUNITY,
  INVITE_MANY_CONNECTIONS_TO_COMMUNITY,
  INVITE_BY_PHONE,
  RESPOND_TO_COMMUNITY_INVITATION,
};
export default community;
