import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  CreateFamilyTagsMutation,
  CreateFamilyTagsMutationVariables,
} from "../../../__generated__/graphql";

const useCreateFamilyTags = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "tag";
  const type = "createFamilyTags";

  const handleOnCompleted = (data: CreateFamilyTagsMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        tags: {
          ...data.tag.createFamilyTags,
        },
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createFamilyTags] = useMutation(api.mutation.tag.CREATE_FAMILY_TAGS, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: CreateFamilyTagsMutationVariables) => {
    if (!state.tag.createFamilyTags.loading) {
      createFamilyTags({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateFamilyTags;
