import { gql } from '../../../__generated__';

const SEND_CHAT = gql(`
 mutation SendChat($recipientProfileUuid: String!, $message: String!) {
  chat {
    sendChat(recipient_profile_uuid: $recipientProfileUuid, message: $message) {
      chat_uuid
      chat_record_uuid
      message
      status
      receiver_nickname
      receiver_uuid
      receiver_image_url
      sender_nickname
      sender_uuid
      sender_image_url
      read
      created_at
      updated_at
      deleted_at
    }
  }
}
`);

const DELETE_MESSAGE = gql(`
  mutation DeleteMessage($messageUuid: String!) {
    chat {
      deleteMessage(message_uuid: $messageUuid) {
        success
        uuid
      }
    }
  }
`);

const chat = {
  SEND_CHAT,
  DELETE_MESSAGE,
};

export default chat;
