import {
  Button,
  Div,
  FlexRow,
  Icon,
  Loader,
  Text,
} from '../../components/_ui';
import React, { SyntheticEvent, useEffect } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Swiper, {
  RenderPropArgs,
} from '../../components/_common/Swiper';
import { FamilyProfileBrief } from '../../components/_common';
import { ConnectWithFamilyRow } from '../../components/families';
import {
  Maybe,
  SuggestedConnectionObjectType,
} from '../../__generated__/graphql';
import '../../styles/pages/meetFamilies.scss';
import { ViewConnectionProfile } from './index';
import { useStore } from '../../hooks/useStore';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import get from '../../utils/utilityFunctions/get';

const MeetFamilies: React.FC = () => {
  const { actions, state } = useStore();

  useEffect(() => {
    if (!state.connection.meetFamilyCards.data)
      actions.connection.query.meetFamilyCards({});
  }, []);

  const families = state.connection.meetFamilyCards.data || [];
  const loading = state.connection.meetFamilyCards.loading;

  const handleFilter = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const handlePass = async (
    card: Maybe<SuggestedConnectionObjectType>,
  ) => {
    if (card) {
      actions.connection.mutation.createConnection({
        familyUuid: card.family.uuid,
        connect: false,
      });
    }
  };

  const handleConnect = async (
    card: Maybe<SuggestedConnectionObjectType>,
  ) => {
    if (card) {
      actions.connection.mutation.createConnection({
        familyUuid: card.family.uuid,
        connect: true,
      });
    }
  };

  const renderSwiperPage = (
    page: Maybe<SuggestedConnectionObjectType>,
  ) => {
    return (
      <Div
        className={'familyCard'}
        onClick={(e) => {
          e.stopPropagation();
          if (page?.family.uuid) {
            actions.connection.setViewFamilyUUID(
              page?.family.uuid,
            );
            actions.connection.query.connectionProfile({
              familyUuid: page?.family.uuid,
            });
          } else {
            actions.app.setErrorMessage(
              state.app.systemMessages?.errors.family
                .familyNotAvailable as string,
            );
          }
        }}
      >
        <FamilyProfileBrief family={page} />
      </Div>
    );
  };

  const handleOnDragEnd = async (
    page: Maybe<SuggestedConnectionObjectType>,
    direction: number,
  ) => {
    if (direction < 0) {
      // decline connection
      handlePass(page);
    } else {
      // accept connection
      handleConnect(page);
    }
  };

  const renderManualSwipe = ({
    card,
    handleSwipeLeft,
    handleSwipeRight,
    index,
  }: RenderPropArgs<Maybe<SuggestedConnectionObjectType>>) => {
    return loading ? null : (
      <Div
        className={[
          'swiperActions',
          get(state, 'app.mobile.isNative', false)
            ? 'nativeApp'
            : '',
        ].join(' ')}
      >
        <ConnectWithFamilyRow
          handleConnect={async () => {
            handleSwipeRight();
            await handleConnect(card);
          }}
          handlePass={async () => {
            handleSwipeLeft();
            await handlePass(card);
          }}
        />
      </Div>
    );
  };

  const filters = state.connection.filters;
  const activeFamilyUUID = state.connection.viewFamilyUUID;

  return (
    <Div
      className={[
        'meetFamilies',
        get(state, 'app.mobile.isNative', false)
          ? 'nativeApp'
          : '',
        loading && 'loadingContainerCenter',
      ].join(' ')}
    >
      {activeFamilyUUID && (
        <Div className={'familyProfileOverlay'}>
          <Button
            className={'closeFamilyProfileOverlay'}
            shape={'round'}
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              actions.connection.setViewFamilyUUID(null)
            }
          >
            <Icon icon={faClose} color={'white'} />
          </Button>
          <ViewConnectionProfile
            handleConnect={handleConnect}
            handlePass={handlePass}
          />
        </Div>
      )}
      {isEmpty(families) || loading ? (
        <Loader />
      ) : (
        <Swiper<Maybe<SuggestedConnectionObjectType>>
          pages={families}
          renderPage={renderSwiperPage}
          handleOnDragEnd={handleOnDragEnd}
          renderManualSwipeProps={(args) =>
            renderManualSwipe(args)
          }
          renderComplete={() => (
            <FlexRow center className={'noMoreCardsContainer'}>
              <Text variant={'h5'} color={'grey800'}>
                Those are all the families, please broaden your
                search to see more!
              </Text>
            </FlexRow>
          )}
        />
      )}
      {/*<FlexRow className="filterRow fullWidth" inline spreadX>*/}
      {/*  <FlexRow className={"activeFilters"} inline>*/}
      {/*    {Object.keys(filters).map((filterKey) => (*/}
      {/*      <Pill*/}
      {/*        key={filterKey}*/}
      {/*        title={filterKey}*/}
      {/*        variant={"contained"}*/}
      {/*        color={"white"}*/}
      {/*        iconEnd={faClose}*/}
      {/*        onClick={() => console.log("hitting filter icon")}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </FlexRow>*/}
      {/*  <Icon*/}
      {/*    icon={faFilter}*/}
      {/*    color={"primary"}*/}
      {/*    height={40}*/}
      {/*    width={40}*/}
      {/*    onClick={() => {*/}
      {/*      actions.app.setModal({*/}
      {/*        open: true,*/}
      {/*        handleSuccess: () => {*/}
      {/*          console.log("trigger filter stuffs");*/}
      {/*        },*/}
      {/*        title: "Family Filters",*/}
      {/*      });*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</FlexRow>*/}
    </Div>
  );
};
export default MeetFamilies;
