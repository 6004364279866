import React, { memo, SyntheticEvent, useRef } from 'react';
import { Button, Div, Text } from './index';
import '../../styles/components/_ui/modal.scss';
import useClickOutside from '../../hooks/useClickOutside';
import { useStore } from '../../hooks/useStore';
import ModalChildren from '../modals/ModalChildren';

// TODO check ModalProvider to view what a potential animation would look like.

const Modal = memo(() => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { state, actions } = useStore();
  const {
    open,
    handleClose,
    successButtonText = 'ok',
    cancelButtonText = 'cancel',
    handleSuccess,
    title,
    type,
    data,
  } = state.app.modalProps;

  const protectedHandleClose = () => {
    if (handleClose) {
      handleClose();
    }
    actions.app.closeModal();
  };

  const protectedHandleSuccess = (e: SyntheticEvent) => {
    if (handleSuccess) {
      handleSuccess(e);
    }
    actions.app.closeModal();
  };

  useClickOutside(modalRef, protectedHandleClose, open);

  return (
    <Div
      className={`modal ${open ? 'open' : 'closed'} ${state.app.themeMode}`}
    >
      <Div className={'modalContent'} ref={modalRef}>
        <Text
          variant={'h3'}
          className={'modalTitle'}
          color={'grey800'}
        >
          {title}
        </Text>
        <Div className={'modalChildrenContainer'}>
          <ModalChildren type={type} data={data} />
        </Div>
        <Div className={'modalButtonRow'}>
          <Button
            variant={'outlined'}
            onClick={protectedHandleClose}
          >
            {cancelButtonText}
          </Button>
          <Button onClick={protectedHandleSuccess}>
            {successButtonText}
          </Button>
        </Div>
      </Div>
    </Div>
  );
});

export default Modal;
