import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useCommunity from './api/useCommunity';
import useCommunities from './api/useCommunities';
import useCommunityByFamily from './api/useCommunityByFamily';
import useLeaveCommunity from './api/useLeaveCommunity';
import useJoinCommunity from './api/useJoinCommunity';
import useInviteToCommunity from './api/useInviteToCommunity';
import useDeleteCommunity from './api/useDeleteCommunity';
import useCreateCommunity from './api/useCreateCommunity';
import useUpdateCommunity from './api/useUpdateCommunity';
import useInviteManyConnectionsToCommunity from './api/useInviteManyConnectionsToCommunity';
import useInviteByPhone from './api/useInviteByPhone';
import useRespondToCommunityInvitation from './api/useRespondToCommunityInvitation';
import useSuggestedCommunitiesForNewFamilyMember from './api/useSuggestedCommunitiesForNewFamilyMember';
import { CommunityStoreActions } from '../../types/reducers/community';
import useAllCommunities from './api/useAllCommunities';
import useJoinCommunities from './api/useJoinCommunities';

export const useCommunityActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): CommunityStoreActions => {
  return {
    manageConnectionInvites: () => {
      const removeConnectionFromQueue = (uuid: string) => {
        dispatch({
          parent: 'community',
          type: 'removeConnectionFromCommunityQueue',
          payload: uuid,
        });
      };
      const addConnectionToQueue = (uuid: string) => {
        dispatch({
          parent: 'community',
          type: 'addConnectionToCommunityQueue',
          payload: uuid,
        });
      };
      return {
        addConnectionToQueue,
        removeConnectionFromQueue,
      };
    },
    query: {
      communityByFamily: useCommunityByFamily(state, dispatch),
      community: useCommunity(state, dispatch),
      communities: useCommunities(state, dispatch),
      suggestedCommunitiesForNewFamilyMember:
        useSuggestedCommunitiesForNewFamilyMember(
          state,
          dispatch,
        ),
      allCommunities: useAllCommunities(state, dispatch),
    },
    mutation: {
      leaveCommunity: useLeaveCommunity(state, dispatch),
      joinCommunity: useJoinCommunity(state, dispatch),
      joinCommunities: useJoinCommunities(state, dispatch),
      inviteToCommunity: useInviteToCommunity(state, dispatch),
      deleteCommunity: useDeleteCommunity(state, dispatch),
      createCommunity: useCreateCommunity(state, dispatch),
      updateCommunity: useUpdateCommunity(state, dispatch),
      inviteManyConnectionsToCommunity:
        useInviteManyConnectionsToCommunity(state, dispatch),
      inviteByPhone: useInviteByPhone(state, dispatch),
      respondToCommunityInvitation:
        useRespondToCommunityInvitation(state, dispatch),
    },
  };
};

export default useCommunityActions;
