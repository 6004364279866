import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  LanguageQuery,
  LanguageQueryVariables,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useLanguage = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "common";
  const type = "language";

  const handleOnCompleted = (data: LanguageQuery) => {
    storage.saveToStorage("language", data.common.language);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.language,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [language] = useLazyQuery(api.query.common.LANGUAGE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: LanguageQueryVariables) => {
    if (!state.common.language.loading) {
      language({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useLanguage;
