import { AppStoreState } from '../types/reducers';
import { PostReducerActions } from '../types/reducers/post';

const postReducer = (
  state: AppStoreState,
  action: PostReducerActions,
) => {
  switch (action.type) {
    case 'postFeed':
      state.post.postFeed = {
        ...state.post.postFeed,
        ...action.payload,
      };
      return state;
    case 'addPostsToExistingFeed':
      // this is lazy loading posts from the api on scroll. Set response values to store
      state.post.postFeed.offset = action.payload.offset;
      state.post.postFeed.loading = action.payload.loading;
      state.post.postFeed.total = action.payload.total;
      state.post.postFeed.limit = action.payload.limit;
      // TODO BUG for some reason this is hitting twice and I dont know why, check for amount in the payload if we should add it or not.
      if (
        state.post.postFeed.data &&
        state.post.postFeed.data.length +
          (action.payload?.offset || 0) !==
          state.post.postFeed.data.length
      )
        state.post.postFeed.data.push(
          ...(action.payload.data || []),
        );
      return state;
    case 'postFeedScrollPosition':
      const location = window.location.pathname;
      const parts = location.split('/');
      // parts === 2 because state doesnt update quick enough and scrollPos gets reset on render of feedPost render. Basically ensures that the page is feed and not children of feed.
      if (parts.length === 2) {
        state.post.postFeed.scrollPosition =
          action.payload.scrollPosition;
      }
      return state;
    case 'myPosts':
      state.post.myPosts = action.payload;
      return state;
    case 'postFeedAPIParams':
      state.post.postFeed.called = action.payload.called;
      state.post.postFeed.loading = action.payload.loading;
      return state;
    case 'postComments':
      state.post.postComments = action.payload;
      return state;
    case 'commentComments':
      if (action.payload.path) {
        state.post.commentComments.path = action.payload.path;
        // check if current path has comments, if it does, reset offset.
      }

      state.post.commentComments.loading =
        action.payload.loading;
      const pathString = state.post.commentComments.path || '';
      if (action.payload.data) {
        // Usage Example
        state.post.commentComments.limit = action.payload.limit;
        // state.post.commentComments.offset =
        // action.payload.offset;
        state.post.commentComments.total = action.payload.total;

        const path = pathString.split('|').map(Number);

        let currentLevel = state.post.postComments.data || [];
        for (let i = 0; i < path.length; i++) {
          const cur = currentLevel[path[i]];
          // sometimes replies does not exist on the response probably because its stringified in gql outbound
          if (cur && !cur.replies) {
            cur.replies = [];
          }
          // @ts-ignore
          currentLevel = currentLevel[path[i]].replies;
        }
        currentLevel.push(...action.payload.data);

        state.post.commentComments.path = undefined;
      }
      return state;
    case 'postsByCommunity':
      state.post.postsByCommunity = action.payload;
      return state;
    case 'likePost':
      state.post.likePost.loading = action.payload.loading;
      return state;
    case 'postFeedItemById':
      const feed = state.post.postFeed.data;
      state.post.postFeedItemById.loading =
        action.payload.loading;
      const updatedPostId = action.payload.data?.uuid;
      const oldPostIndex = feed?.findIndex(
        (post) => post?.uuid === updatedPostId,
      );
      if (oldPostIndex && action.payload.data && feed) {
        feed.splice(oldPostIndex, 1, action.payload.data);
        state.post.postFeed.data = feed;
      }
      return state;
    case 'replyToComment':
      state.post.replyToComment.replyToCommentUUID =
        action.payload.replyToCommentUUID;
      state.post.replyToComment.replyToCommentName =
        action.payload.replyToCommentName;
      state.post.commentComments.offset = 0;
      return state;
    case 'cancelReplyToComment':
      state.post.replyToComment.replyToCommentUUID = null;
      state.post.replyToComment.replyToCommentName = null;
      return state;
    case 'likeComment':
      const commentPath = action.payload.commentPath;
      state.post.likeComment.loading = action.payload.loading;
      if (action.payload.commentPath) {
        state.post.likeComment.commentPath =
          action.payload.commentPath;
      }
      const comment = action.payload.comment;
      if (comment && commentPath) {
        const path = commentPath.split('|').map(Number);

        let currentLevel = state.post.postComments.data || [];
        for (let i = 0; i < path.length; i++) {
          if (path.length - 1 === i) {
            // @ts-ignore
            currentLevel = currentLevel[path[i]];
          } else {
            // @ts-ignore
            currentLevel = currentLevel[path[i]].replies;
          }
        }
        // @ts-ignore
        currentLevel.profile_did_like = true;
        action.payload.commentPath = undefined;
      }
      return state;
    case 'viewPost':
      state.post.viewPost = action.payload;
      return state;
    case 'createPost':
      state.post.createPost = action.payload;
      if (action.payload.data?.uuid) {
        state.app.messages.successMessage =
          state.app.systemMessages?.success.post.postCreated ||
          'Post Created!';
        state.post.createPost.data = undefined;
      }
      return state;
    case 'updateComment':
      state.post.createPost = action.payload;
      return state;
    case 'replyToPost':
    case 'deleteComment':
    case 'createComment':
    case 'genericPostFeed':
      return state;
    default:
      return state;
  }
};
export default postReducer;
