import {
  Button,
  Card,
  Divider,
  Link,
  List,
  ListItem,
} from '../../components/_ui';
import {
  faFile,
  faLock,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import React, { SyntheticEvent } from 'react';
import routes from '../../navigation/routes';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useStore } from '../../hooks/useStore';

const Settings = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();

  const handleDeleteProfile = (e: SyntheticEvent) => {
    e.stopPropagation();
    actions.app.setModal({
      open: true,
      title: 'Delete Profile',
      type: 'delete-profile',
      handleSuccess: () => {
        actions.profile.mutation.deleteAccount();
      },
    });
    e.preventDefault();
  };

  return (
    <Card>
      <List className={'sideNavContent'}>
        <ListItem>
          <Button
            variant={'transparent'}
            color={'grey800'}
            iconProps={{
              position: 'start',
              icon: faLock,
              color: 'grey200',
            }}
            size={'sm'}
            onClick={() => {
              navigate(routes.blockedUsers);
            }}
          >
            Manage Blocked Users
          </Button>
        </ListItem>
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        <ListItem>
          <Button
            variant={'transparent'}
            color={'grey800'}
            iconProps={{
              position: 'start',
              icon: faTrash,
              color: 'grey200',
            }}
            size={'sm'}
            onClick={handleDeleteProfile}
          >
            Delete Account
          </Button>
        </ListItem>
        <Divider color={'transparent'} />
        <ListItem>
          <Button
            variant={'transparent'}
            color={'grey800'}
            iconProps={{
              position: 'start',
              icon: faFile,
              color: 'grey200',
            }}
            size={'sm'}
          >
            <Link to={`${process.env.REACT_APP_EULA_AGREEMENT}`}>
              Privacy Policy
            </Link>
          </Button>
        </ListItem>
      </List>
    </Card>
  );
};

export default Settings;
