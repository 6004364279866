export const getFormDateFromUnix = (date: string) =>
  new Date(Number(date)).toISOString().slice(0, 10);

export const getHumanReadableDateFromUnix = (date: string) => {
  const d = new Date(Number(date));

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = months[d.getUTCMonth()];
  const day = d.getUTCDate();
  const year = d.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const getNotificationDateStamp = (date: string) => {
  const now: Date = new Date();
  const past: Date = new Date(Number(date));
  const msDiff = now.getTime() - past.getTime();
  const seconds = msDiff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (seconds < 60) {
    return 'just now';
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minute${Math.floor(minutes) !== 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hour${Math.floor(hours) !== 1 ? 's' : ''} ago`;
  } else {
    return `${Math.floor(days)} day${Math.floor(days) !== 1 ? 's' : ''} ago`;
  }
};
