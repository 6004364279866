import { gql } from "../../../__generated__";

const FAMILY_RELATION_TYPE = gql(`
  query FamilyRelationType {
    family {
      familyRelationType {
        uuid
        name
      }
    }
  }
`);

const FAMILY_MEMBER_PERMISSIONS = gql(`
  query FamilyMemberPermissions($familyMemberUuid: String) {
    family {
      familyMemberPermissions(family_member_uuid: $familyMemberUuid) {
        uuid
        updated_at
        profile_uuid
        family_uuid
        permissions {
          post {
            create
            like
            comment
          }
          community {
            join
            create
            post
            comment
            like
            invite
          }
          chat {
            send
            receive
          }
        }
      }
    }
  }
`);

const family = {
  FAMILY_RELATION_TYPE,
  FAMILY_MEMBER_PERMISSIONS,
};

export default family;
