import { Div, Divider, Loader, Text } from '../_ui';
import { Comment } from './index';
import React from 'react';
import {
  Maybe,
  PostCommentsObjectType,
} from '../../__generated__/graphql';
import { useStore } from '../../hooks/useStore';

const Comments: React.FC<{
  loading: boolean;
  error: string | undefined;
  data: Array<Maybe<PostCommentsObjectType>> | undefined;
}> = ({ loading, error, data }) => {
  const { state } = useStore();
  if (loading) {
    return (
      <Div>
        <Loader />
      </Div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Div className={`comments ${state.app.themeMode}`}>
      <Text variant={'span'} color={'grey100'}>
        Comments
      </Text>
      <Divider color={'transparent'} />
      {data?.map(
        (comment, i) =>
          comment &&
          comment.uuid && (
            <Comment
              {...comment}
              key={`${comment!.uuid}`}
              index={i}
            />
          ),
      )}
    </Div>
  );
};

export default Comments;
