import { FlexRow, Text } from "../../_ui";
import React from "react";
import { Maybe, Scalars } from "../../../__generated__/graphql";

const MediaTitleOverlay: React.FC<{
  title: Maybe<Scalars["String"]["output"]> | undefined;
  content: Maybe<Scalars["String"]["output"]> | undefined;
}> = ({ title, content }) => {
  return (
    <FlexRow column>
      <Text variant={"h4"} color={"grey800"}>
        {title}
      </Text>
      <Text variant={"span"} color={"grey200"}>
        {content}
      </Text>
    </FlexRow>
  );
};

export default MediaTitleOverlay;
