import { Button, Card, Divider, Text } from '../_ui';
import '../../styles/components/feed/finishFamilySelection.scss';
import routes from '../../navigation/routes';
import { useStore } from '../../hooks/useStore';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const FinishFamilySection = () => {
  const { actions } = useStore();
  const navigate = useCustomNavigate();
  const navToAddFamilyMember = () => {
    actions.profile.setCurrentProfile(undefined);
    navigate(routes.addFamilyMember);
  };

  return (
    <Card className={'finishFamilySelectionPrompt'}>
      <Text variant={'h3'} color={'primary'}>
        Finish setting up your family
      </Text>
      <Text color={'grey800'} variant={'p'}>
        In order to see their posts and interact with them, you
        will need to create family members!
      </Text>
      <Divider color={'transparent'} />
      <Button
        onClick={navToAddFamilyMember}
        variant={'outlined'}
        color={'primary'}
      >
        Set up family
      </Button>
    </Card>
  );
};

export default FinishFamilySection;
