import {
  TagState,
  TagStoreActions,
} from '../../types/reducers/tag';

export const tagState: TagState = {
  createFamilyTag: {},
  createFamilyTags: {},
  createProfileTags: {},
};

export const tagActions: TagStoreActions = {
  query: {},
  mutation: {
    createFamilyTag: () => null,
    createFamilyTags: () => null,
    createProfileTags: () => null,
  },
};
