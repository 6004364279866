import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  UpdateCommentMutation,
  UpdateCommentMutationVariables,
} from "../../../__generated__/graphql";

const useUpdateComment = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "updateComment";

  const handleOnCompleted = (data: UpdateCommentMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.comment.updateComment,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [updateComment] = useMutation(api.mutation.post.UPDATE_COMMENT, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: UpdateCommentMutationVariables) => {
    if (!state.post.updateComment.loading) {
      updateComment({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useUpdateComment;
