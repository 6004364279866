import { useNavigate } from 'react-router-dom';
import { useStore } from './useStore';
import { BaseRoute } from '../navigation/routes';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { actions } = useStore();

  return <Type>(
    page: BaseRoute,
    routeArgs?: [id: Type, secondId?: Type] | null,
    state: Object = {},
  ) => {
    actions.app.navigateForwardToRoute(
      page.name,
      window.location.pathname,
    );

    if (routeArgs) {
      navigate(page.dynamicUrl(...routeArgs), state);
    } else {
      navigate(page.url, state);
    }
  };
};

export default useCustomNavigate;
