import { useContext } from 'react';
import { StoreContext } from '../providers/storeProvider';

export const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error(
      'useStore must be used within a StoreContext',
    );
  }
  return context;
};
