import { Div, FlexRow, Text } from '../../_ui';
import React, { SyntheticEvent, useState } from 'react';
import { ReactionRow } from './index';
import { EmojiActionRow } from '../index';
import {
  Maybe,
  PostTypeEnumType,
  ReportUserEnum,
  Scalars,
} from '../../../__generated__/graphql';
import routes from '../../../navigation/routes';
import '../../../styles/components/_common/postMeta.scss';
import { useStore } from '../../../hooks/useStore';
import UserActionRow, {
  PostActionTypeEnumType,
} from './UserActionRow';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import { EmojiActionRowProps } from '../EmojiActionRow';
import useReportUser from '../../../hooks/useReportUser';

const PostMeta: React.FC<{
  reactions:
    | Maybe<Array<Maybe<Scalars['String']['output']>>>
    | undefined;
  commentCount: Maybe<Scalars['Int']['output']> | undefined;
  postType: Maybe<PostTypeEnumType> | undefined;
  postUUID: Maybe<Scalars['String']['output']> | undefined;
  singlePage?: boolean;
  profile_uuid: string;
}> = ({
  commentCount,
  reactions,
  postUUID,
  postType,
  singlePage = false,
  profile_uuid,
}) => {
  const { actions, state } = useStore();
  const [showComment, setShowComment] = useState<boolean>(false);
  const [showLikeTypes, setShowLikeTypes] =
    useState<boolean>(false);
  const navigate = useCustomNavigate();
  const reportUser = useReportUser();
  const handleClickEmoji = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('clicking emojis');
  };
  // short circuit if postId does not exist, should not get here.
  if (!postUUID) return null;

  const handleClickComments = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!singlePage)
      navigate<string>(routes.feedPost, [postUUID as string]);
  };

  const emojiActionRowHandlers = {
    handleReportContent: () => {
      reportUser(
        profile_uuid,
        ReportUserEnum.Post,
        'report',
        postUUID,
      );
    },
  } as Partial<EmojiActionRowProps> &
    Pick<EmojiActionRowProps, 'handleReportContent'>;

  const handleClickCalendar = async () =>
    console.log('handleClickCalendar');
  const handleClickAlert = async () =>
    console.log('handleClickAlert');
  const handleClickComment = async () => {
    setShowComment(!showComment);
    setShowLikeTypes(false);
  };
  const handleClickLike = async () => {
    setShowLikeTypes(!showLikeTypes);
    setShowComment(false);
  };

  const handleClickReply = async () =>
    console.log('handleClickReply');
  const handleClickApprove = async () =>
    console.log('handleClickApprove');
  const handleClickDisapprove = async () =>
    console.log('handleClickDisapprove');

  switch (postType) {
    // case PostTypeEnumType.Event:
    //   emojiActionRowHandlers.handleClickComment = handleClickComment;
    //   emojiActionRowHandlers.handleClickLike = handleClickLike;
    //   emojiActionRowHandlers.handleClickCalendar = handleClickCalendar;
    //   emojiActionRowHandlers.handleClickAlert = handleClickAlert;
    //   break;
    case PostTypeEnumType.Image:
    case PostTypeEnumType.Link:
    case PostTypeEnumType.Text:
      // case PostTypeEnumType.Video:
      // case PostTypeEnumType.Slider:
      emojiActionRowHandlers.handleClickComment =
        handleClickComment;
      emojiActionRowHandlers.handleClickLike = handleClickLike;
      break;
    // case PostTypeEnumType.Poll:
    //   break;

    // case PostTypeEnumType.Survey:
    //   break;
  }

  const getActionType = () => {
    if (showLikeTypes) return PostActionTypeEnumType.Like;
    if (showComment) return PostActionTypeEnumType.Comment;
    return PostActionTypeEnumType.Comment;
  };

  const handleActionResponse = (response: string) => {
    if (showComment) {
      setShowComment(false);
      actions.post.mutation.replyToPost({
        postUuid: postUUID,
        comment: response,
      });
    }
    if (showLikeTypes) {
      setShowLikeTypes(false);
      actions.post.mutation.likePost({
        postUuid: postUUID,
        likeTypeUuid: response,
      });
    }
  };

  return (
    <>
      <FlexRow
        inline
        spreadX
        center
        className={[
          'postMeta',
          singlePage && 'isSinglePage',
        ].join(' ')}
      >
        <FlexRow inline>
          <ReactionRow
            handleClick={handleClickEmoji}
            reactions={reactions}
            uuidKey={postUUID}
          />
          <Div onClick={handleClickComments}>
            <Text
              variant={'span'}
              color={'primary'}
              weight={800}
            >
              {commentCount || 0} comments
            </Text>
          </Div>
        </FlexRow>
        {!singlePage && (
          <EmojiActionRow {...emojiActionRowHandlers} />
        )}
      </FlexRow>
      {!singlePage && (
        <UserActionRow
          show={showComment || showLikeTypes}
          type={getActionType()}
          onClick={handleActionResponse}
          uuidKey={postUUID}
        />
      )}
    </>
  );
};

export default PostMeta;
