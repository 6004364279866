import React, { FormEventHandler } from 'react';
import { useStore } from '../../hooks/useStore';
import '../../styles/components/_ui/form.scss';

const Form = ({
  onSubmit,
  children,
  className = '',
}: {
  onSubmit: FormEventHandler<HTMLFormElement>;
  children: React.ReactNode;
  className?: string;
}) => {
  const { state } = useStore();
  return (
    <form
      className={['form', className, state.app.themeMode].join(
        ' ',
      )}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};

export default Form;
