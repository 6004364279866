import { AppStoreState, ReducerActions } from "../../types/reducers";
import { Dispatch } from "react";
import useLikePost from "./api/useLikePost";
import useLikeComment from "./api/useLikeComment";
import useCreatePost from "./api/useCreatePost";
import useViewPost from "./api/useViewPost";
import usePostsByCommunity from "./api/usePostsByCommunity";
import usePostFeedItemById from "./api/usePostFeedItemById";
import usePostFeed from "./api/usePostFeed";
import usePostComments from "./api/usePostComments";
import useGenericPostFeed from "./api/useGenericPostFeed";
import useUpdateComment from "./api/useUpdateComment";
import useReplyToPost from "./api/useReplyToPost";
import useReplyToComment from "./api/useReplyToComment";
import useDeleteComment from "./api/useDeleteComment";
import useCreateComment from "./api/useCreateComment";
import useGetPostParts from "./useGetPostParts";
import useUpdatePost from "./api/useUpdatePost";
import { PostStoreActions } from "../../types/reducers/post";
import useMyPosts from "./api/useMyPosts";
import useCommentComments from './api/useCommentComments';

const usePostActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): PostStoreActions => {
  return {
    query: {
      postsByCommunity: usePostsByCommunity(state, dispatch),
      postFeedItemById: usePostFeedItemById(state, dispatch),
      postFeed: usePostFeed(state, dispatch),
      postComments: usePostComments(state, dispatch),
      commentComments: useCommentComments(state, dispatch),
      viewPost: useViewPost(state, dispatch),
      genericPostFeed: useGenericPostFeed(state, dispatch),
      myPosts: useMyPosts(state, dispatch),
    },
    mutation: {
      likePost: useLikePost(state, dispatch),
      likeComment: useLikeComment(state, dispatch),
      createPost: useCreatePost(state, dispatch),
      updatePost: useUpdatePost(state, dispatch),
      updateComment: useUpdateComment(state, dispatch),
      replyToPost: useReplyToPost(state, dispatch),
      replyToComment: useReplyToComment(state, dispatch),
      deleteComment: useDeleteComment(state, dispatch),
      createComment: useCreateComment(state, dispatch),
    },
    getPostParts: useGetPostParts(state, dispatch),
    setReplyToCommentId: (commentUUID: string, name: string) => {
      dispatch({
        parent: "post",
        type: "replyToComment",
        payload: {
          replyToCommentUUID: commentUUID,
          replyToCommentName: name,
        },
      });
    },
    cancelReplyToComment: () => {
      dispatch({
        parent: "post",
        type: "cancelReplyToComment",
      });
    },
    setPostFeedScrollPosition: (scrollPosition: number) => {
      dispatch({
        parent: "post",
        type: "postFeedScrollPosition",
        payload: {
          scrollPosition,
        },
      });
    },
  };
};
export default usePostActions;
