import { Div } from '../_ui';
import '../../styles/components/_ui/card.scss';
import React from 'react';
import { DivProps } from './Div';
import { useStore } from '../../hooks/useStore';

const Card = ({
  children,
  className,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
} & DivProps) => {
  const { state } = useStore();
  return (
    <Div
      {...rest}
      className={['card', className, state.app.themeMode].join(
        ' ',
      )}
    >
      {children}
    </Div>
  );
};

export default Card;
