import React, {
  ChangeEventHandler,
  forwardRef,
  MouseEventHandler,
} from 'react';
import { Div, FlexRow, Icon, Text } from './';
import { capitalizeChar0 } from '../../utils/stringUtils';
import { BaseInputProps } from '../../types/_ui';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  colorsOptions,
  monoColorOptions,
} from '../../types/theme/colors';
import '../../styles/components/_ui/input.scss';
import { InputSize } from '../../types/_ui/button';
import { useStore } from '../../hooks/useStore';

export type InputTypeProps =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';
export interface InputProps extends BaseInputProps {
  type: InputTypeProps;
  onChange: ChangeEventHandler<HTMLInputElement>;
  iconStart?: IconDefinition;
  iconEnd?: IconDefinition;
  iconProps?: {
    color: colorsOptions | monoColorOptions;
    onClick: MouseEventHandler<HTMLDivElement>;
  };
  error?: string;
  maxLength?: number;
  placeholder?: string;
  textSize?: InputSize;
  autoHeight?: boolean;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  accepts?: string;
}

const Input = forwardRef<any, InputProps>(
  (
    {
      label,
      type,
      className,
      value,
      onChange,
      containerClassName,
      disabled,
      iconStart,
      iconEnd,
      iconProps = {},
      error,
      maxLength,
      placeholder,
      textSize = 'lg',
      autoHeight = false,
      containerStyle = {},
      inputStyle = {},
      ...additionalInputProps
    },
    ref,
  ): React.ReactElement => {
    const { state } = useStore();
    const handleOnChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      e.stopPropagation();
      onChange(e);
    };

    return (
      <Div
        className={[
          'inputContainer',
          `input${capitalizeChar0(type)}Container`,
          containerClassName,
          iconEnd || iconEnd ? 'withIcon' : '',
          iconEnd ? 'iconEnd' : '',
          iconStart ? 'iconStart' : '',
          state.app.themeMode,
        ].join(' ')}
      >
        {label && (
          <Text
            className={'inputLabel'}
            variant={'p'}
            color={'grey400'}
          >
            {label}
          </Text>
        )}
        <FlexRow
          style={containerStyle}
          inline
          className={[
            iconStart && 'iconStartContainer',
            iconEnd && 'iconEndContainer',
          ].join(' ')}
        >
          {iconStart && (
            <Icon
              {...iconProps}
              icon={iconStart}
              height={20}
              width={20}
            />
          )}
          <input
            placeholder={placeholder}
            ref={ref}
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
            onChange={handleOnChange}
            type={type}
            autoComplete={type === 'password' ? 'on' : 'off'}
            className={[
              'input',
              `input${capitalizeChar0(type)}`,
              className,
              textSize,
              autoHeight ? 'autoHeight' : '',
            ]
              .filter(
                (className: string | undefined) => className,
              )
              .join(' ')}
            value={value}
            maxLength={maxLength}
            style={inputStyle}
            {...additionalInputProps}
          />
          {autoHeight && (
            <Text
              className={'mockInput'}
              variant={'p'}
              color={'grey800'}
            >
              {value !== '' && value}
            </Text>
          )}
          {iconEnd && (
            <Icon
              {...iconProps}
              icon={iconEnd}
              height={20}
              width={20}
            />
          )}
        </FlexRow>
        {error && (
          <Text variant={'h5'} color={'error'}>
            {error}
          </Text>
        )}
        {maxLength && typeof value === 'string' && (
          <Text
            variant={'span'}
            color={'grey200'}
            className={'inputCharacterCounter'}
          >
            {maxLength - value?.length} / {maxLength}
          </Text>
        )}
      </Div>
    );
  },
);

export default Input;
