import React, { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { useLazyQuery } from '@apollo/client';
import api from '../api';
import { QNotificationNotificationByProfileArgs } from '../__generated__/graphql';
import { AppPagesEnum } from '../types/pages';

const NotificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { dispatch, state } = useStore();
  const parent = 'notification';
  const type = 'notificationByProfile';
  const intervalTiming = 60000;

  const [notificationByProfile] = useLazyQuery(
    api.query.notification.NOTIFICATION_BY_PROFILE,
    {
      onCompleted: (data) => {
        dispatch({
          parent,
          type,
          payload: {
            loading: false,
            ...data.notification.notificationByProfile,
          },
        });
      },
      onError: (error) => {
        dispatch({
          parent,
          type,
          payload: {
            loading: false,
            error: error.message,
          },
        });
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    const getNotifications = (since?: Date) => {
      const queryVars: QNotificationNotificationByProfileArgs = {
        refine: {
          limit: 30,
          offset: 0,
        },
      };

      if (since) {
        queryVars.since = `${since.getTime() - 60000 * 5}`;
      }

      notificationByProfile({
        // some weirdness going on here with generated types, this is correct I believe.
        // @ts-ignore
        variables: queryVars,
      });
      dispatch({
        parent,
        type,
        payload: {
          loading: true,
        },
      });
    };

    // get initial notifications without a start date so users get all their previous notifications.
    getNotifications();
    const interval = setInterval(
      () => getNotifications(new Date()),
      intervalTiming,
    );

    return () => clearInterval(interval);
  }, []);

  return children;
};

export default NotificationProvider;
