import { gql } from "../../../__generated__";

const LIKE_POST = gql(`
mutation LikePost($postUuid: String!, $likeTypeUuid: String!) {
  post {
    likePost(post_uuid: $postUuid, like_type_uuid: $likeTypeUuid) {
      uuid
      profile_uuid
      post_type
      comment_count
      post_likes
      draft
      post_content {
        ... on postSliderObjectType {
          uuid
          post_uuid
          link
          title
          subtitle
          content         
          post_slide_images {
            uuid
            url
            index
          }
        }
        ... on postTypeObjectType {
          uuid
          post_uuid
          link
          title
          subtitle
          content
        }
      }
      created_at
      family {
        uuid
        name
        primary_profile_image_url
      }
      community {
        uuid
        name
        description
        zip
        image_url
        private
        member_count
        member_count_online
        is_member
        role
      }
    }
  }
}
`);

const LIKE_COMMENT = gql(`
  mutation LikeComment($commentUuid: String!, $likeTypeUuid: String) {
    post {
      likeComment(comment_uuid: $commentUuid, like_type_uuid: $likeTypeUuid) {
        uuid
        reactions {
          uuid
          unicode
        }
      }
    }
  }
`);

const CREATE_POST = gql(`
  mutation CreatePost(
    $postType: postTypeEnumType!
    $draft: Boolean!
    $title: String!
    $files: [Upload]
    $link: String
    $subtitle: String
    $content: String
    $communityUuid: String
  ) {
    post {
      createPost(
        post_type: $postType
        draft: $draft
        title: $title
        files: $files
        link: $link
        subtitle: $subtitle
        content: $content
        community_uuid: $communityUuid
      ) {
        uuid
        profile_uuid
        post_type
        comment_count
        post_likes
        draft
        post_content {
          ... on postSliderObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
            post_slide_images {
              uuid
              url
              index
            }
          }
          ... on postTypeObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
          }
        }
        created_at
        family {
          uuid
          name
          primary_profile_image_url
        }
        community {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          is_member
          role
        }
      }
    }
  }
`);

const UPDATE_COMMENT = gql(`
  mutation UpdateComment($commentUuid: String!, $comment: String!) {
    post {
      comment {
        updateComment(comment_uuid: $commentUuid, comment: $comment) {
          uuid
          post_uuid
          comment_uuid
          profile_uuid
          family_uuid
          comment
          created_at
          updated_at
        }
      }
    }
  }
`);

const REPLY_TO_POST = gql(`
  mutation ReplyToPost($postUuid: String!, $comment: String!) {
    post {
      comment {
        replyToPost(post_uuid: $postUuid, comment: $comment) {
          uuid
          post_uuid
          comment_uuid
          profile_uuid
          family_uuid
          comment
          created_at
          updated_at
        }
      }
    }
  }
`);

const REPLY_TO_COMMENT = gql(`
  mutation ReplyToComment($commentUuid: String!, $comment: String!) {
    post {
      comment {
        replyToComment(comment_uuid: $commentUuid, comment: $comment) {
          uuid
          post_uuid
          comment_uuid
          profile_uuid
          family_uuid
          comment
          created_at
          updated_at
        }
      }
    }
  }
`);

const DELETE_COMMENT = gql(`
  mutation DeleteComment($commentUuid: String!) {
    post {
      comment {
        deleteComment(comment_uuid: $commentUuid) {
          success
          uuid
        }
      }
    }
  }
`);

const CREATE_COMMENT = gql(`
  mutation CreateComment(
    $comment: String!
    $postUuid: String!
    $commentUuid: String
  ) {
    post {
      comment {
        createComment(
          comment: $comment
          post_uuid: $postUuid
          comment_uuid: $commentUuid
        ) {
          uuid
          post_uuid
          comment_uuid
          profile_uuid
          family_uuid
          comment
          created_at
          updated_at
        }
      }
    }
  }
`);

const UPDATE_POST = gql(`
  mutation UpdatePost(
    $postUuid: String!
    $draft: Boolean
    $title: String
    $subtitle: String
    $content: String
  ) {
    post {
      updatePost(
        post_uuid: $postUuid
        draft: $draft
        title: $title
        subtitle: $subtitle
        content: $content
      ) {
        uuid
        profile_uuid
        post_type
        comment_count
        post_likes
        post_content {
          ... on postSliderObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
            post_slide_images {
              uuid
              url
              index
            }
          }
          ... on postTypeObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
          }
        }
        created_at
        draft
        family {
          uuid
          name
          primary_profile_image_url
        }
        community {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          my_member_status
          is_member
          role
        }
      }
    }
  }
`);

const post = {
  LIKE_POST,
  LIKE_COMMENT,
  CREATE_POST,
  UPDATE_POST,
  UPDATE_COMMENT,
  REPLY_TO_POST,
  REPLY_TO_COMMENT,
  DELETE_COMMENT,
  CREATE_COMMENT,
};

export default post;
