import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import { NotificationStoreActions } from '../../types/reducers/notification';

export const useNotificationActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): NotificationStoreActions => {
  return {
    query: {},
    mutation: {},
    viewNotifications: (viewed) =>
      dispatch({
        parent: 'notification',
        type: 'viewNotifications',
        payload: {
          viewed,
        },
      }),
  };
};

export default useNotificationActions;
