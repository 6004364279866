import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  DeleteMessageMutation,
  DeleteMessageMutationVariables,
} from '../../../__generated__/graphql';

const useDeleteMessage = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'chat';
  const type = 'deleteMessage';

  const handleOnCompleted = (data: DeleteMessageMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.chat.deleteMessage,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [deleteMessage] = useMutation(
    api.mutation.chat.DELETE_MESSAGE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  return (args: DeleteMessageMutationVariables) => {
    if (!state.chat.deleteMessage.loading) {
      deleteMessage({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useDeleteMessage;
