import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CreateCommentMutation,
  CreateCommentMutationVariables,
} from "../../../__generated__/graphql";
import api from "../../../api";

const useCreateComment = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "createComment";

  const handleOnCompleted = (data: CreateCommentMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.comment.createComment,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [createComment] = useMutation(api.mutation.post.CREATE_COMMENT, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: CreateCommentMutationVariables) => {
    if (!state.post.createComment.loading) {
      createComment({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateComment;
