import React, { useState } from "react";
import { useStore } from "../../../hooks/useStore";
import { Button, FlexRow, Input } from "../../_ui";
import { AddComment } from "../../feed";
import { Maybe } from "graphql/jsutils/Maybe";

export enum PostActionTypeEnumType {
  Comment = "comment",
  Like = "like",
}
const UserActionRow = ({
  show,
  type,
  onClick: handleClick,
  uuidKey,
}: {
  show: boolean;
  type: PostActionTypeEnumType;
  onClick: (val: string) => void;
  uuidKey: Maybe<string>;
}) => {
  const { state } = useStore();

  return (
    <FlexRow
      center
      inline
      className={[
        show && "showComment",
        "commentContainer",
        "userActionRow",
      ].join(" ")}
    >
      {type === PostActionTypeEnumType.Comment && (
        <AddComment
          loading={state.post.replyToPost.loading || false}
          saveComment={handleClick}
        />
      )}
      {type === PostActionTypeEnumType.Like &&
        (state.common.likeType.data || []).map(
          (lt) =>
            lt && (
              <Button
                key={`${lt.uuid}-${uuidKey}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(lt.uuid);
                }}
                size={"sm"}
                variant={"transparent"}
                shape={"round"}
              >
                {lt.unicode}
              </Button>
            ),
        )}
    </FlexRow>
  );
};

export default UserActionRow;
