import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import { SuggestCommunitiesForNewFamilyMemberQuery } from "../../../__generated__/graphql";

const useSuggestedCommunitiesForNewFamilyMember = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "suggestedCommunitiesForNewFamilyMember";

  const handleOnCompleted = (
    data: SuggestCommunitiesForNewFamilyMemberQuery,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.suggestCommunitiesForNewFamilyMember,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [getSuggestedCommunities] = useLazyQuery(
    api.query.community.SUGGESTED_COMMUNITIES_FOR_NEW_FAMILY_MEMBER,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  return () => {
    if (!state.community.suggestedCommunitiesForNewFamilyMember.loading) {
      getSuggestedCommunities();
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useSuggestedCommunitiesForNewFamilyMember;
