import { useMutation } from '@apollo/client';
import api from '../../../api';
import storage from '../../../utils/storage';
import {
  LoginMutationVariables,
  LoginType,
} from '../../../__generated__/graphql';
import routes from '../../../navigation/routes';
import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const useLogin = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'login';
  const navigate = useCustomNavigate();

  const [login] = useMutation(api.mutation.profile.LOGIN, {
    onCompleted: async (data) => {
      await storage.saveLoginToStorage(
        data.profile?.login as LoginType,
      );
      dispatch({
        parent,
        type,
        payload: {
          loading: false,
          ...data.profile.login,
        },
      });
      navigate(routes.feed);
    },
    onError: (error) => {
      dispatch({
        parent,
        type,
        payload: {
          error: error.message,
        },
      });
    },
  });

  return (args: LoginMutationVariables) => {
    if (!state.profile.login.loading)
      login({
        variables: args,
      });
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useLogin;
