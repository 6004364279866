import 'normalize.css';
import './styles/variables/cssVariables.scss';
import React from 'react';
import Router from './navigation/Router';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import './styles/base/index.scss';
import { setContext } from '@apollo/client/link/context';
import {
  loadDevMessages,
  loadErrorMessages,
} from '@apollo/client/dev';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setupMobilePlatform } from './mobile';
// import { BatchHttpLink } from "@apollo/client/link/batch-http";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${JSON.parse(token)}` : '',
    },
  };
});

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

// 207.178.111.42
// 10.4.0.184

const client = new ApolloClient({
  // cache: new InMemoryCache({
  //   dataIdFromObject(responseObject) {
  //     switch (responseObject.__typename) {
  //       case "qConnection":
  //         const connectionByFamily = responseObject.connectionByFamily;
  //         const meetFamilyCards = responseObject.meetFamilyCards;
  //         if (connectionByFamily) {
  //           // @ts-ignore
  //           return `qConnection:${
  //             // @ts-ignore
  //             connectionByFamily.offset
  //             // @ts-ignore
  //           }-${connectionByFamily.data.map((d) => d.uuid).join("-")}`;
  //         }
  //         if (meetFamilyCards) {
  //           return `qConnection:meetFamilyCards-${meetFamilyCards
  //             // @ts-ignore
  //             .map((m) => m.uuid)
  //             .join("-")}`;
  //         }
  //         if (responseObject.connectionProfile) {
  //           return "qConnection:connectionProfile";
  //         }
  //         return defaultDataIdFromObject(responseObject);
  //       default:
  //         return defaultDataIdFromObject(responseObject);
  //     }
  //   },
  //   typePolicies: {
  //     Query: {
  //       fields: {
  //         connection: {
  //           keyArgs: false,
  //           // @ts-ignore
  //           merge(existing = [], incoming, { offset = 0 }) {
  //             const merged = existing ? existing.slice(0) : [];
  //             for (let i = 0; i < incoming.length; ++i) {
  //               merged[offset + i] = incoming[i];
  //             }
  //             console.log({ existing, incoming, merged });
  //             return merged;
  //           },
  //         },
  //       },
  //     },
  //   },
  // }),
  cache: new InMemoryCache(),
  // @ts-ignore
  link: authLink.concat(httpLink),
});

setupMobilePlatform();

function App() {
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
}

export default App;
