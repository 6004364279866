import React, { useEffect } from 'react';
import { Div, FlexRow, Icon, Image, Pill } from '../_ui';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../hooks/useStore';
import routes from '../../navigation/routes';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const relationShipDefaults = {
  admin: 'admin',
  family: 'family',
  mother: 'mother',
  father: 'father',
};

const MyFamilySelection: React.FC = () => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();

  const setSelectedFamilyMember =
    actions.profile.setFamilyMemberOrFamilyUUID;
  const selectedFamilyMember =
    state.profile.familyMemberOrFamilyUUID;

  const myProfile =
    state.profile.familyProfile.data?.family_member;
  const familyMembers =
    state.profile.familyProfile.data?.family_members || [];
  const family = state.profile.familyProfile.data?.family;

  const handleChangeFamilyMember = async (
    uuid: string,
    isFamily: boolean,
  ) => {
    setSelectedFamilyMember(uuid);
    if (uuid === myProfile?.uuid) {
      actions.profile.setEditFamily(false);
      actions.profile.setCurrentProfile(
        state.profile.familyProfile.data,
      );
    } else if (isFamily) {
      actions.profile.setEditFamily(true);
    } else {
      actions.profile.setEditFamily(false);
      actions.profile.query.familyMemberProfile({ uuid });
      actions.family.query.familyMemberPermissions({
        familyMemberUuid: uuid,
      });
    }
  };

  useEffect(() => {
    if (selectedFamilyMember === '') {
      setSelectedFamilyMember(myProfile?.uuid as string);
    }
  }, []);

  const navigateToCreateFamilyMember = () => {
    actions.profile.setCurrentProfile(undefined);
    navigate(routes.addFamilyMember);
  };

  const renderSelectionPill = ({
    name,
    uuid,
    image_url,
    relationship,
  }: {
    name: string;
    uuid: string;
    image_url: string | null | undefined;
    relationship: string;
  }) => {
    const isFamily =
      relationship === relationShipDefaults.family;
    const imageSrc = () => {
      if (isFamily) {
        return (
          image_url ||
          '/defaultImages/defaultFamilyProfileImage.jpg'
        );
      }
      if (
        relationship === relationShipDefaults.admin ||
        [
          relationShipDefaults.father,
          relationShipDefaults.mother,
        ].includes(relationship.toLowerCase())
      ) {
        return (
          image_url ||
          '/defaultImages/defaultParentFamily}ProfileImage.jpg'
        );
      }

      return (
        image_url ||
        '/defaultImages/defaultChildFamily}ProfileImage.jpg'
      );
    };

    return (
      <Pill
        title={isFamily ? `The ${name}'s` : name}
        onClick={() => handleChangeFamilyMember(uuid, isFamily)}
        variant={'outlined'}
        active={selectedFamilyMember === uuid}
        imageStart={
          <Image
            withBorderRadius
            height={25}
            width={25}
            src={imageSrc()}
            alt={`${name} profile image`}
          />
        }
        // for development purposes because small window of uuids when testing causing duplicate keys
        key={`${uuid}-${name}`}
      />
    );
  };

  return (
    <Div className={'familySelection'}>
      <FlexRow spreadX={false}>
        {renderSelectionPill({
          name: 'Mine',
          uuid: myProfile!.uuid,
          image_url: myProfile?.profile_image_url,
          relationship: 'me',
        })}
        {family &&
          renderSelectionPill({
            name: family.name,
            uuid: family.uuid,
            image_url: family.primary_profile_image_url,
            relationship: relationShipDefaults.family,
          })}
        {familyMembers &&
          familyMembers.map((familyMemberProfile) =>
            familyMemberProfile
              ? renderSelectionPill({
                  name: familyMemberProfile.name,
                  uuid: familyMemberProfile.uuid,
                  image_url: familyMemberProfile.image_url,
                  relationship: familyMemberProfile.relationship,
                })
              : null,
          )}
        <Pill
          title={<Icon color={'white'} icon={faPlus} />}
          variant={'contained'}
          onClick={navigateToCreateFamilyMember}
        />
      </FlexRow>
    </Div>
  );
};

export default MyFamilySelection;
