import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  FamilyMemberProfileQuery,
  FamilyMemberProfileQueryVariables,
} from '../../../__generated__/graphql';
import api from '../../../api';

const useFamilyMemberProfile = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'familyMemberProfile';

  const handleOnCompleted = (data: FamilyMemberProfileQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.profile.familyMemberProfile,
      },
    });
    dispatch({
      parent: 'profile',
      type: 'setCurrentProfile',
      payload: data.profile.familyMemberProfile,
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [apiFunction] = useLazyQuery(
    api.query.profile.FAMILY_MEMBER_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: FamilyMemberProfileQueryVariables) => {
    apiFunction({
      variables: args,
    });
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useFamilyMemberProfile;
