import { Div, Text, Button } from '../_ui';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import get from '../../utils/utilityFunctions/get';

const ReportModal: React.FC = () => {
  const { state, actions } = useStore();

  useEffect(() => {
    if (!get(state, 'common.reportTypes.data', null)) {
      // fetch report types as they are not available yet.
    }
  }, []);

  const [selected, setSelected] = useState<null | string>(null);

  return (
    <Div>
      <Div className={'reportModal'}>
        <Button
          variant={
            selected === '123jjas' ? 'contained' : 'outlined'
          }
          color={selected === '123jjas' ? 'primary' : 'grey800'}
          onClick={() => setSelected('123jjas')}
        >
          Block senderName
        </Button>
        <Button
          variant={
            selected === 'fakeAccount' ? 'contained' : 'outlined'
          }
          color={
            selected === 'fakeAccount' ? 'primary' : 'grey800'
          }
          onClick={() => setSelected('fakeAccount')}
        >
          Report fake account
        </Button>
      </Div>
      <Div>
        <Text>Report sender to their parent</Text>
        <Button
          variant={
            selected === 'inappropriate'
              ? 'contained'
              : 'outlined'
          }
          color={
            selected === 'inappropriate' ? 'primary' : 'grey800'
          }
          onClick={() => setSelected('inappropriate')}
        >
          senderName is messaging inappropriately
        </Button>
        <Button
          variant={
            selected === 'harassing' ? 'contained' : 'outlined'
          }
          color={
            selected === 'harassing' ? 'primary' : 'grey800'
          }
          onClick={() => setSelected('harassing')}
        >
          senderName is harassing my child
        </Button>
        <Button
          variant={
            selected === 'douchebag' ? 'contained' : 'outlined'
          }
          color={
            selected === 'douchebag' ? 'primary' : 'grey800'
          }
          onClick={() => setSelected('douchebag')}
        >
          senderName is a douchebag!
        </Button>
      </Div>
    </Div>
  );
};

export default ReportModal;
