import { FlexRow, Text } from '../_ui';
import { useStore } from '../../hooks/useStore';

const BlockedPost = () => {
  const { state } = useStore();
  return (
    <FlexRow
      className={['feedPostWrapper', state.app.themeMode].join(
        ' ',
      )}
      centerX
      centerY
      inline
    >
      <Text variant={'h5'} color={'grey400'}>
        This content has been blocked
      </Text>
    </FlexRow>
  );
};

export default BlockedPost;
