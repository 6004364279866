import { Div, Input } from './index';
import {
  faPaperPlane,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import React, { SyntheticEvent, useState } from 'react';
import '../../styles/components/_ui/growableInput.scss';
import { InputSize } from '../../types/_ui/button';
import { useStore } from '../../hooks/useStore';
import get from '../../utils/utilityFunctions/get';

const GrowableInput = ({
  saveMessage,
  maxLength = 144,
  loading,
  label,
  render,
  containerClassName = '',
  textSize = 'sm',
  error,
  placeholder,
}: {
  saveMessage: (msg: string) => void;
  maxLength?: number;
  loading: boolean;
  label?: string;
  render?: () => React.ReactElement | null;
  containerClassName?: string;
  textSize?: InputSize;
  error?: string;
  placeholder?: string;
}) => {
  const [message, setMessage] = useState<string>('');
  const { state, actions } = useStore();
  const handleTextInput = (
    e: React.SyntheticEvent<HTMLInputElement>,
  ) => {
    setMessage((e.target as HTMLInputElement)?.value);
  };

  const handleSave = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (message.length === 0) {
      actions.app.setWarnMessage('Empty value');
    } else {
      saveMessage(message);
      setMessage('');
    }
  };

  return (
    <Div
      className={[
        'growableInput',
        containerClassName,
        state.app.themeMode,
        get(state, 'app.mobile.isNative', false)
          ? 'nativeApp'
          : '',
      ].join(' ')}
    >
      {render && render()}
      <Input
        label={label}
        type={'text'}
        onChange={handleTextInput}
        value={message}
        iconEnd={loading ? faSpinner : faPaperPlane}
        iconProps={{ color: 'primary', onClick: handleSave }}
        placeholder={placeholder || 'Message'}
        maxLength={maxLength}
        textSize={textSize}
        autoHeight
        containerStyle={{
          height: Math.floor(message.length / 45) * 10 + 60,
        }}
        inputStyle={{
          height: Math.floor(message.length / 45) * 10 + 57,
        }}
        error={error}
      />
    </Div>
  );
};

export default GrowableInput;
