import { gql } from '../../../__generated__';

// NOTE opted to not use fragments on this because it was not returning the data correctly and spent too much time debugging
// could get them correctly, but they're just not worth the bloat on this file

const POST_FEED = gql(`
  query PostFeed($refine: customSortAndFilterBase_postFeed) {
  post {
    postFeed(refine: $refine) {
      data {
        ... on postObjectType {
          uuid
          profile_uuid
          post_type
          comment_count
          post_likes
          draft
          post_content {
            ... on postSliderObjectType {
              uuid
              post_uuid
              link
              title
              subtitle
              content
              post_slide_images {
                uuid
                url
                index
              }
            }
            ... on postTypeObjectType {
              uuid
              post_uuid
              link
              title
              subtitle
              content
            }
          }
          created_at
          family {
            uuid
            name
            primary_profile_image_url
          }
          family_member {
            uuid
            first_name
            last_name
            profile_image_url
          }
          community {
            uuid
            name
            description
            zip
            image_url
            private
            member_count
            member_count_online
            is_member
            role
          }
        }
        ... on postRecommendationObjectType {
          community {
            uuid
            name
            description
            zip
            image_url
            private
            member_count
            member_count_online
            is_member
            role
          }
          uuid
          profile_uuid
          post_likes
          created_at
        }
      }
      limit
      offset
      total
    }
  }
}
`);

const VIEW_POST = gql(`
  query ViewPost($postUuid: String!) {
    post {
      viewPost(post_uuid: $postUuid) {
        uuid
        profile_uuid
        post_type
        comment_count
        post_likes
        draft
        post_content {
          ... on postSliderObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
            post_slide_images {
              uuid
              url
              index
            }
          }
          ... on postTypeObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
          }
        }
        created_at
        family {
          uuid
          name
          primary_profile_image_url
        }
        family_member {
          uuid
          first_name
          last_name
          profile_image_url
        }
        community {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          is_member
          role
        }
      }
    }
  }
`);

const GENERIC_POST_FEED = gql(`
query GenericPostFeed($refine: sortAndFilterBaseArgs) {
  post {
    genericPostFeed(refine: $refine) {
      data {
        uuid
        profile_uuid
        post_type
        comment_count
        post_likes
        draft
        post_content {
          ... on postSliderObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
            post_slide_images {
              uuid
              url
              index
            }
          }
          ... on postTypeObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
          }
        }
        created_at
        family {
          uuid
          name
          primary_profile_image_url
        }
        family_member {
          uuid
          first_name
          last_name
          profile_image_url
        }
        community {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          is_member
          role
        }
      }
      limit
      offset
      total
      phrase
    }
  }
}
`);

const POST_COMMENTS = gql(`
  query PostComments(
    $postUuid: String!, 
    $refine: customSortAndFilterBase_postComments
  ) {
    post {
      postComments(post_uuid: $postUuid, refine: $refine) {
        data {
          family_member {
            uuid
            first_name
            last_name
            profile_image_url
          }
          profile {
            uuid
          }
          uuid
          comment
          created_at
          updated_at
          reply_uuids
          replies {
            uuid
            updated_at
            reactions {
              uuid
              unicode
            }
            profile_did_like
            profile {
              uuid
            }
            family_member {
              uuid
              first_name
              last_name
              profile_image_url
            }
            created_at
            comment
            reply_uuids
            replies {
              uuid
              reply_uuids
            }
          }
          reactions {
            uuid
            unicode
          }
          profile_did_like
        }
        limit
        offset
        total
      }
    }
  }
`);

const COMMENT_COMMENTS = gql(`
  query CommentComments(
    $commentUuid: String!
    $refine: customSortAndFilterBase_commentComments
  ) {
    post {
      commentComments(comment_uuid: $commentUuid, refine: $refine) {
        data {
          family_member {
            uuid
            first_name
            last_name
            profile_image_url
          }
          profile {
            uuid
          }
          uuid
          comment
          created_at
          updated_at
          reply_uuids
          replies {
            family_member {
              uuid
              first_name
              last_name
              profile_image_url
            }
            profile {
              uuid
            }
            uuid
            comment
            created_at
            updated_at
            reply_uuids
            reactions {
              uuid
              unicode
            }
            profile_did_like
          }
          reactions {
            uuid
            unicode
          }
          profile_did_like
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`)

const POST_BY_COMMUNITY = gql(`
  query PostsByCommunity($communityUuid: String!, $refine: customSortAndFilterBase_postsByCommunity) {
    post {
      postsByCommunity(community_uuid: $communityUuid, refine: $refine) {
        data {
          uuid
          profile_uuid
          post_type
          comment_count
          post_likes
          draft
          post_content {
            ... on postSliderObjectType {
              uuid
              post_uuid
              link
              title
              subtitle
              content
              post_slide_images {
                uuid
                url
                index
              }
            }
            ... on postTypeObjectType {
              uuid
              post_uuid
              link
              title
              subtitle
              content
            }
          }
          created_at
          family {
            uuid
            name
            primary_profile_image_url
          }
          family_member {
            uuid
            first_name
            last_name
            profile_image_url
          }
          community {
            uuid
            name
            description
            zip
            image_url
            private
            member_count
            member_count_online
            is_member
            role
          }
          community_uuid
        }
        limit
        offset
        total
      }
    }
  }
`);

const POST_FEED_ITEM_BY_ID = gql(`
  query PostFeedItemById($postUuid: String!) {
    post {
      postFeedItemById(post_uuid: $postUuid) {
        uuid
        profile_uuid
        post_type
        comment_count
        post_likes
        draft
        post_content {
          ... on postSliderObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
            post_slide_images {
              uuid
              url
              index
            }
          }
          ... on postTypeObjectType {
            uuid
            post_uuid
            link
            title
            subtitle
            content
          }
        }
        created_at
        family {
          uuid
          name
          primary_profile_image_url
        }
        family_member {
          uuid
          first_name
          last_name
          profile_image_url
        }
        community {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          is_member
          role
        }
      }
    }
  }
`);

const MY_POSTS = gql(`
  query MyPosts($profileUuid: String!, $refine: customSortAndFilterBase_myPosts) {
    post {
      myPosts(profile_uuid: $profileUuid, refine: $refine) {
        data {
          ... on postObjectType {
            uuid
            profile_uuid
            post_type
            comment_count
            post_likes
            post_content {
              ... on postSliderObjectType {
                uuid
                post_uuid
                link
                title
                subtitle
                content
                post_slide_images {
                  uuid
                  url
                  index
                }
              }
              ... on postTypeObjectType {
                uuid
                post_uuid
                link
                title
                subtitle
                content
              }
            }
            created_at
            draft
            family {
              uuid
              name
              primary_profile_image_url
            }
            family_member {
              uuid
              first_name
              last_name
              profile_image_url
            }
            community {
              uuid
              name
              description
              zip
              image_url
              private
              member_count
              member_count_online
              my_member_status
              is_member
              role
            }
          }
          ... on postRecommendationObjectType {
            community {
              uuid
              name
              description
              zip
              image_url
              private
              member_count
              member_count_online
              my_member_status
              is_member
              role
            }
            uuid
            profile_uuid
            post_likes
            created_at
          }
        }
        limit
        offset
        total
      }
    }
  }
`);

const post = {
  POST_FEED,
  GENERIC_POST_FEED,
  POST_COMMENTS,
  COMMENT_COMMENTS,
  VIEW_POST,
  POST_BY_COMMUNITY,
  POST_FEED_ITEM_BY_ID,
  MY_POSTS,
};

export default post;
