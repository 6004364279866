import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  ReplyToPostMutation,
  ReplyToPostMutationVariables,
} from "../../../__generated__/graphql";
import usePostFeedItemById from "./usePostFeedItemById";
import usePostComments from "./usePostComments";

const useReplyToPost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "replyToPost";
  const postFeedItemById = usePostFeedItemById(state, dispatch);
  const postComments = usePostComments(state, dispatch);

  const handleOnCompleted = (data: ReplyToPostMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.comment.replyToPost,
      },
    });
    postFeedItemById({ postUuid: data.post.comment.replyToPost.post_uuid });
    postComments({ postUuid: data.post.comment.replyToPost.post_uuid });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [replyToPost] = useMutation(api.mutation.post.REPLY_TO_POST, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: ReplyToPostMutationVariables) => {
    if (!state.post.replyToPost.loading) {
      replyToPost({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useReplyToPost;
