import { Button, Card, FlexRow, GrowableInput, Text } from '../../components/_ui';
import { Comments, FeedPost } from '../../components/feed';
import { useLocation, useParams } from 'react-router-dom';
import { PostObjectType } from '../../__generated__/graphql';
import { useEffect } from 'react';
import { useStore } from '../../hooks/useStore';

const Post = () => {
  const location = useLocation();
  const { uuid, post } = (location.state = {} as {
    uuid: string;
    post: PostObjectType;
  });
  const params = useParams();
  const { state, actions } = useStore();

  useEffect(() => {
    actions.post.getPostParts({
      postId: params?.postId as string,
      uuid,
      post,
    });
  }, []);

  const replyUUID = state.post.replyToComment.replyToCommentUUID;

  const replyToPost = (msg: string) => {
    if (replyUUID) {
      actions.post.mutation.replyToComment({
        comment: msg,
        commentUuid: replyUUID,
      });
    } else {
      actions.post.mutation.replyToPost({
        postUuid: params?.postId as string,
        comment: msg,
      });
    }
  };

  const cancelReplyToComment = () => {
    actions.post.cancelReplyToComment();
  };

  return (
    <Card>
      <FeedPost
        type={
          post?.post_type || state.post.viewPost.data?.post_type
        }
        post={post || state.post.viewPost.data}
        singlePage
      />
      <Comments
        loading={!!state.post.postComments.loading}
        error={state.post.postComments.error}
        data={state.post.postComments.data}
      />
      <>
        <GrowableInput
          saveMessage={replyToPost}
          maxLength={500}
          placeholder={'Comment'}
          loading={state.post.replyToPost.loading || false}
          containerClassName={'replyToCommentContainer'}
          render={() =>
            replyUUID ? (
              <FlexRow
                className={'replyToCommentTextContainer'}
                spreadX
                center
              >
                <Text variant={'span'} color={'grey800'}>
                  Replying to{' '}
                  {state.post.replyToComment.replyToCommentName}
                </Text>
                <Button
                  size={'xs'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={cancelReplyToComment}
                >
                  cancel
                </Button>
              </FlexRow>
            ) : null
          }
        />
      </>
    </Card>
  );
};

export default Post;
