import { Div, FlexRow, Link, Text } from '../../_ui';
import { AvatarAndName, DateTime } from '../index';
import { GeneralPostHeader, PostMeta } from './index';
import React from 'react';
import {
  PostObjectType,
  PostTypeEnumType,
} from '../../../__generated__/graphql';
import '../../../styles/components/feed/textPost.scss';

const TextPost: React.FC<{
  post: PostObjectType;
  singlePage: boolean;
}> = ({ post, singlePage }) => {
  if (singlePage) {
    return (
      <FlexRow column className={'post textPost'}>
        <FlexRow spreadX center className={'fullWidth'}>
          <AvatarAndName />
          <PostMeta
            reactions={post.post_likes}
            commentCount={post.comment_count}
            postType={post.post_type}
            postUUID={post.uuid}
            singlePage
            profile_uuid={post.profile_uuid}
          />
        </FlexRow>
        <Text variant={'h4'} color={'grey800'}>
          {post.post_content?.title}
        </Text>
        <Text variant={'p'} color={'grey200'}>
          {post.post_content?.content}
        </Text>
      </FlexRow>
    );
  }

  return (
    <Div className={'post textPost'}>
      <GeneralPostHeader
        actionText={post.community?.name || ''}
        title={`${post.family_member!.last_name} Family | ${post.family_member!.first_name}`}
        canJoin={
          !!post.community?.uuid && // if community exists
          !post.community?.private && // if community not private
          !post.community?.is_member // if not currently member
        }
        isMember={post.community?.is_member}
        communityUUID={post.community?.uuid}
        familyMember={post.family_member}
      />
      <Text variant={'h4'} color={'grey800'}>
        {post.post_content?.title}
      </Text>
      {post.post_content?.link &&
        post.post_type === PostTypeEnumType.Link && (
          <Link to={post.post_content?.link}>
            {post.post_content?.link.slice(0, 50)}
          </Link>
        )}
      <FlexRow inline className={'textPostContent'}>
        <AvatarAndName
          url={
            post.family_member?.profile_image_url ||
            post.family.primary_profile_image_url
          }
          name={
            post.family_member?.first_name || post.family.name
          }
          familyMemberUUID={post.family_member?.uuid}
          navigateToProfile
        />
        <DateTime date={post.created_at} />
      </FlexRow>
      <PostMeta
        reactions={post.post_likes}
        commentCount={post.comment_count}
        postType={post.post_type}
        postUUID={post.uuid}
        profile_uuid={post.profile_uuid}
      />
    </Div>
  );
};

export default TextPost;
