import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from '../../../__generated__/graphql';

const useUpdatePost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'profile';
  const type = 'updateProfile';

  const handleOnCompleted = (data: UpdateProfileMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.profile.updateProfile,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [updateProfile] = useMutation(
    api.mutation.profile.UPDATE_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'updateProfile',
        },
      },
    },
  );

  return async (args: UpdateProfileMutationVariables) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
    await updateProfile({
      variables: args,
    });
  };
};

export default useUpdatePost;
