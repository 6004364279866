import React, { ReactNode, SyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../styles/components/_ui/link.scss';
import { useStore } from '../../hooks/useStore';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from './index';

interface LinkProps {
  children: ReactNode;
  to: string;
  className?: string;
  routerLink?: boolean;
  routerLinkProps?: any;
}

const Link: React.FC<LinkProps> = ({
  children,
  to,
  className,
  routerLink = false,
}) => {
  const { state } = useStore();

  if (routerLink)
    return (
      <RouterLink
        to={to}
        className={['link', className, state.app.themeMode].join(
          ' ',
        )}
      >
        {children}
      </RouterLink>
    );

  const handleLinkClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <a
      href={to}
      target={'_blank'}
      className={['link', className, state.app.themeMode].join(
        ' ',
      )}
      onClick={handleLinkClick}
    >
      {children}
      <Icon
        height={15}
        width={15}
        icon={faArrowUpRightFromSquare}
        color={'secondary'}
      />
    </a>
  );
};

export default Link;
