import storage from "../utils/storage";
import languages from "./languages";
import { A11YStructure, Languages } from "../types/a11y";

const systemMessages = async () => {
  // TODO maybe get this based on current location?
  // will cause issues but will be largely country based
  // which could be nice upon expansion
  const language = ((await storage.getFromStorage("chosenLanguage")) ||
    "en") as Languages;

  return {
    errors: languages[language]?.errors,
    success: languages[language]?.success,
    warn: languages[language]?.warn,
    general: languages[language]?.general,
  } as A11YStructure;
};

export default systemMessages;
