import { Div, Divider, FlexRow, Switch, Text } from "../_ui";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type ManagePermissionsProps<T extends FieldValues> = {
  control: Control<T>;
  canMakeAdmin: boolean;
};

const ManagePermissions = <T extends FieldValues>({
  canMakeAdmin,
  control,
}: ManagePermissionsProps<T>) => {
  return (
    <Div>
      <Text variant={"h2"} color={"grey800"}>
        Manage Access
      </Text>
      <FlexRow>
        {canMakeAdmin && (
          <Controller
            name={"family_member.is_admin" as Path<T>}
            control={control}
            render={({ field }) => (
              <Switch {...field} asSwitch label={"Is family Admin"} />
            )}
          />
        )}
      </FlexRow>
      <Text variant={"h4"} color={"grey800"}>
        Chat access
      </Text>
      <FlexRow>
        <Controller
          name={"permissions.chat.receive" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can receive chat messages"} />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.chat.send" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can send chat messages"} />
          )}
        />
      </FlexRow>
      <Divider color={"transparent"} />
      <Text variant={"h4"} color={"grey800"}>
        Community access
      </Text>
      <FlexRow>
        <Controller
          name={"permissions.community.comment" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              asSwitch
              label={"Can Comment on community posts"}
            />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.community.create" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can create communities"} />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.community.invite" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can invite to communities"} />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.community.join" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              asSwitch
              label={"Can join communities without approval"}
            />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.community.post" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              asSwitch
              label={"Can post in communities without approval"}
            />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.community.like" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              asSwitch
              label={"Can like community activities"}
            />
          )}
        />
      </FlexRow>
      <Divider color={"transparent"} />
      <Text variant={"h4"} color={"grey800"}>
        Feed access
      </Text>
      <FlexRow>
        <Controller
          name={"permissions.post.comment" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can comment on posts"} />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.post.create" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can create posts"} />
          )}
        />
      </FlexRow>
      <FlexRow>
        <Controller
          name={"permissions.post.like" as Path<T>}
          control={control}
          render={({ field }) => (
            <Switch {...field} asSwitch label={"Can react to posts"} />
          )}
        />
      </FlexRow>
    </Div>
  );
};

export default ManagePermissions;
