import React, { MouseEventHandler } from "react";

interface ListItemProps {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLLIElement | HTMLOListElement>;
  className?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <li onClick={onClick} className={className}>
      {children}
    </li>
  );
};

export default ListItem;
