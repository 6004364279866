import { gql } from '../../../__generated__';

const CHECK_IF_ONBOARDED = gql(`
  query CheckIfOnboarded {
    profile {
      checkIfOnboarded {
        family_complete
        family_member_complete
        family_members_complete
        communities_complete
        interests_complete
        school_complete
      }
    }
  }
`);

const FAMILY_PROFILE = gql(`
  query FamilyProfile {
    profile {
      familyProfile {
        profile {
          uuid
          email
          nickname
          last_login
          logged_in
        }
        my_communities {
          name
          uuid
        }
        my_interests {
          uuid
          name
          tag_icon
        }
        family_members {
          uuid
          name
          image_url
          relationship
          is_admin
        }
        family_member {
          uuid
          family_uuid
          profile_uuid
          gender_uuid
          school_uuid
          is_admin
          first_name
          last_name
          bio
          profile_image_url
          date_of_birth
          relation_type_uuid
        }
        family {
          uuid
          name
          bio
          timezone_uuid
          city_uuid
          state_uuid
          country_uuid
          zip
          primary_profile_image_url
          secondary_profile_image_url
          tertiary_profile_image_url
          admin_profile_uuids
          interests {
            uuid
            name
            tag_icon
          }
        }
      }
    }
  }
`);

const FAMILY_MEMBER_PROFILE = gql(`
  query FamilyMemberProfile($uuid: String!) {
    profile {
      familyMemberProfile(uuid: $uuid) {
        profile {
          uuid
          email
          nickname
          last_login
          logged_in
        }
        family_member {
          uuid
          family_uuid
          profile_uuid
          gender_uuid
          school_uuid
          is_admin
          first_name
          last_name
          bio
          profile_image_url
          date_of_birth
          relation_type_uuid
        }
        my_communities {
          name
          uuid
        }
        my_interests {
          uuid
          name
          tag_icon
        }
      }
    }
  }
`);

const VIEW_PUBLIC_PROFILE = gql(`
  query ViewPublicProfile($uuid: String!) {
    profile {
      viewPublicProfile(uuid: $uuid) {
        profile {
          uuid
          email
          nickname
          last_login
          logged_in
        }
        family_member {
          uuid
          family_uuid
          profile_uuid
          gender_uuid
          school_uuid
          is_admin
          first_name
          last_name
          bio
          profile_image_url
          date_of_birth
          relation_type_uuid
        }
        my_communities {
          name
          uuid
        }
        my_interests {
          uuid
          name
          tag_icon
        }
      }
    }
  }
`);

const CHECK_IF_VERIFIED = gql(`
query CheckIfVerified {
  profile {
    checkIfVerified {
      verified
      profile_uuid
    }
  }
}
`);

const profile = {
  CHECK_IF_ONBOARDED,
  FAMILY_PROFILE,
  FAMILY_MEMBER_PROFILE,
  CHECK_IF_VERIFIED,
  VIEW_PUBLIC_PROFILE,
};

export default profile;
