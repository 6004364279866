import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  SchoolQuery,
  SortAndFilterBaseArgs,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";
import isEmpty from "../../../utils/utilityFunctions/isEmpty";

const useSchool = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "common";
  const type = "school";
  const updateType = "updateSchool";

  const handleOnCompleted = (data: SchoolQuery) => {
    storage.saveToStorage("school", data.common.school.data);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.common.school,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [school] = useLazyQuery(api.query.common.SCHOOL, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: Partial<SortAndFilterBaseArgs>) => {
    if (!state.common.school.loading) {
      school({
        variables: { refine: args },
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useSchool;
