import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  ConnectionProfileQuery,
  ConnectionProfileQueryVariables,
} from "../../../__generated__/graphql";

const useConnectionProfile = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "connection";
  const type = "connectionProfile";

  const handleOnCompleted = (data: ConnectionProfileQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.connection.connectionProfile,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [connectionProfile] = useLazyQuery(
    api.query.connection.CONNECTION_PROFILE,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  return (args: ConnectionProfileQueryVariables) => {
    if (!state.connection.connectionProfile.loading) {
      connectionProfile({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useConnectionProfile;
