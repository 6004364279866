import React, { useState } from 'react';
import { animated, useSprings } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import '../../styles/components/_common/swiper.scss';
import { useStore } from '../../hooks/useStore';

// Current usage for easy transition to React Native
// https://use-gesture.netlify.app/
// https://github.com/pmndrs/react-spring

// OTHER ALTERNATIVES
// https://www.npmjs.com/package/react-native-deck-swiper
// https://www.npmjs.com/package/react-tinder-card
export type RenderPropArgs<T> = {
  card: T;
  handleSwipeLeft: () => void;
  handleSwipeRight: () => void;
  index: number;
};
type SwiperProps<T> = {
  pages: T[];
  renderPage: (page: T) => JSX.Element;
  handleOnDragStart?: (page: T, direction: number) => void;
  handleOnDragEnd?: (page: T, direction: number) => void;
  renderManualSwipeProps: (
    args: RenderPropArgs<T>,
  ) => React.ReactElement | null;
  renderComplete: () => React.ReactElement;
};
// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (_i: number) => ({
  x:
    _i % 2 === 0
      ? window.innerWidth + 200
      : (window.innerWidth + 200) * -1,
  rot: 0,
  scale: 1.5,
  y: 250,
  delay: _i * 100,
});

const Swiper = <T,>({
  pages,
  renderPage,
  handleOnDragStart,
  handleOnDragEnd,
  renderManualSwipeProps,
  renderComplete,
}: SwiperProps<T>) => {
  const {
    state: { app },
  } = useStore();
  const [gone] = useState(() => new Set());
  const [activeIndex, setActiveIndex] = useState<number>(
    pages.length - 1,
  );
  const [complete, setComplete] = useState(false);
  const [props, api] = useSprings(pages.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useGesture({
    onDrag: ({
      args: [index],
      down,
      movement: [mx],
      direction: [xDir],
      velocity,
    }) => {
      // trigger if the card is swiped 100 px past the center of the screen
      const trigger = mx < -150 || mx > 150;
      // Direction should either point left or right
      const dir = xDir < 0 ? -1 : 1;
      if (!down && trigger) {
        gone.add(index);
      }
      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        // pixel count outside of screen to move
        const x = isGone
          ? window.innerWidth * dir
          : down
            ? mx
            : 0;
        return {
          x,
          delay: undefined,
          config: {
            friction: 50,
            tension: down ? 800 : isGone ? 200 : 500,
          },
        };
      });
      if (!down && gone.size === pages.length) {
        setComplete(true);
      }
    },
    onDragStart: ({ args: [index], direction: [direction] }) => {
      const isGone = gone.has(index);
      if (handleOnDragStart && isGone) {
        handleOnDragStart(pages[index], direction);
      }
    },
    onDragEnd: ({ args: [index], direction: [direction] }) => {
      const isGone = gone.has(index);
      if (handleOnDragEnd && isGone) {
        handleOnDragEnd(pages[index], direction);
        setActiveIndex(index - 1);
      }
    },
  });

  const handleSwipeLeft = () => {
    if (activeIndex === 0) {
      setComplete(true);
    }
    api.start((i) => {
      if (i === activeIndex) {
        setActiveIndex(activeIndex - 1);
        return {
          x: window.innerWidth * -2,
          delay: undefined,
          config: { friction: 50, tension: 200 },
        };
      }
    });
  };

  const handleSwipeRight = () => {
    if (activeIndex === 0) {
      setComplete(true);
    }
    api.start((i) => {
      if (i === activeIndex) {
        setActiveIndex(activeIndex - 1);
        return {
          x: window.innerWidth * 2,
          delay: undefined,
          config: { friction: 50, tension: 200 },
        };
      }
    });
  };

  return (
    <div className="swiperContainer">
      {props.map(({ x, y }, i) => (
        <animated.div
          key={i}
          className={'card-swiper card'}
          style={{
            x,
            y,
          }}
        >
          <animated.div {...bind(i)}>
            {renderPage(pages[i])}
          </animated.div>
        </animated.div>
      ))}
      {complete && renderComplete()}
      {!complete &&
        renderManualSwipeProps({
          card: pages[activeIndex],
          handleSwipeLeft,
          handleSwipeRight,
          index: activeIndex,
        })}
    </div>
  );
};

export default Swiper;
