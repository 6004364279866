import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  GenericPostFeedQuery,
  GenericPostFeedQueryVariables,
} from "../../../__generated__/graphql";

const useGenericPostFeed = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "genericPostFeed";

  const handleOnCompleted = (data: GenericPostFeedQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.genericPostFeed,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [genericPostFeed] = useLazyQuery(api.query.post.GENERIC_POST_FEED, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: GenericPostFeedQueryVariables) => {
    if (!state.post.genericPostFeed.loading) {
      genericPostFeed({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useGenericPostFeed;
