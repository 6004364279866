import React from 'react';
import { IPages } from '../../pages';
import {
  CreateNewProfileObjectType,
  LoginType,
  ReportUserEnum,
} from '../../../__generated__/graphql';
import { A11YStructure } from '../../a11y';

export const modalEnumKeys = {
  default: 'default',
  chatReportUser: 'chat-report-user',
  chooseInterests: 'choose-interests',
  chooseCommunities: 'choose-communities',
  chatManageSecurity: 'chat-manage-security',
  reportUser: 'report-user',
  deleteProfile: 'delete-profile',
};

export const modalEnum = Object.values(modalEnumKeys);

export type UserToReportArgs = {
  profileUUID?: string;
  type?: ReportUserEnum;
  reportTypeUUID?: string;
  reportSubTypeUUID?: string;
  referenceUUID?: string;
};

export type ModalProps = {
  open: boolean;
  handleClose?: (() => void) | null;
  successButtonText?: string;
  cancelButtonText?: string;
  handleSuccess?: null | ((a: any) => void);
  title: string | null | React.ReactNode;
  type?: (typeof modalEnum)[keyof typeof modalEnum];
  data?: { message: string; subMessage?: string };
};

export type GeneralAppState = {
  token: string | null;
  currentPage: IPages;
  prevPages: IPages[];
  prevUrls: string[];
  sideBarOpen: boolean;
  themeMode: 'dark' | 'light';
  profile: (LoginType | CreateNewProfileObjectType) | null;
  systemMessages: A11YStructure | null;
  modalProps: ModalProps;
  messages: {
    serverError?: string | null;
    errorMessage?: string | null;
    warnMessage?: string | null;
    successMessage?: string | null;
  };
  mobile: {
    isMobileWidth: boolean;
    isNative: boolean;
    platform: string; // android, ios, or web.
  };
  userToReport: UserToReportArgs;
};
