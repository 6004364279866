import {
  Button,
  Div,
  FlexRow,
  Image,
  Input,
  Loader,
  Text,
} from '../components/_ui';
import React, { ChangeEvent, useState } from 'react';
import routes from '../navigation/routes';
import '../styles/pages/signIn.scss';
import { useSpring } from 'react-spring';
import { useStore } from '../hooks/useStore';
import { useLocation } from 'react-router-dom';
import get from '../utils/utilityFunctions/get';

const SignIn = () => {
  const [formError, setFormError] = useState<
    'pass' | 'username' | null
  >(null);
  const { actions, state } = useStore();
  const location = useLocation();
  const [emailNickname, setEmailNickname] = useState(
    get(location, 'state.username', ''),
  );
  const [pass, setPass] = useState(
    get(location, 'state.password', ''),
  );

  const [styles, sApi] = useSpring(() => ({
    from: {
      width: 0,
      height: 0,
    },
    to: {
      width: 175,
      height: 175,
    },
  }));

  const handleEmailNicknameChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setEmailNickname(e.target.value);
  };

  const handlePassChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setPass(e.target.value);
  };

  const signIn = async () => {
    if (emailNickname === '' || pass === '') {
      setFormError(emailNickname === '' ? 'username' : 'pass');
      return;
    } else {
      setFormError(null);
    }
    const isEmail = emailNickname.indexOf('@') > -1;
    actions.profile.mutation.login({
      email: isEmail ? emailNickname : null,
      nickname: !isEmail ? emailNickname : null,
      password: pass,
    });
  };

  const ErrorLoggingIn = () => {
    if (formError == null) return;
    // if (formError == null && !error) return;
    let errorText = '';

    if (formError === 'pass') {
      errorText = 'Password cannot be empty';
    }

    if (formError === 'username') {
      errorText = 'Email or Nickname must be provided';
    }

    return (
      <Text color={'primary'} variant={'h6'}>
        {errorText || state.profile.login.error}
      </Text>
    );
  };

  const loading = state.profile.login.loading;

  return (
    <Div className={'signInContainer'}>
      <Image
        animateStyles={styles}
        className={'logo'}
        height={175}
        width={175}
        src={'/logo-familyFriend@3x.png'}
        alt={'Family Friend Logo'}
      />
      <Div>
        <Input
          type={'email'}
          label={'email or nickname'}
          value={emailNickname}
          onChange={handleEmailNicknameChange}
        />
        <Input
          type={'password'}
          label={'password'}
          value={pass}
          onChange={handlePassChange}
        />
      </Div>
      <FlexRow
        className={'buttonRow'}
        column
        center
        start={false}
      >
        <Button
          className={`signInButton ${loading && 'iconStart'}`}
          disabled={
            loading || emailNickname === '' || pass === ''
          }
          onClick={signIn}
          variant={'contained'}
          color={'primary'}
        >
          {loading && <Loader color={'white'} />} Sign In
        </Button>
        <ErrorLoggingIn />
        <Text variant={'span'} color={'grey200'}>
          Don't have a profile yet?
        </Text>
        <Button
          className={'createAccountButton'}
          routeTo={routes.createProfile}
          variant={'outlined'}
          color={'primary'}
        >
          Create One
        </Button>
      </FlexRow>
    </Div>
  );
};

export default SignIn;
