import {
  Card,
  Div,
  Divider,
  FlexRow,
  Image,
  Text,
} from '../_ui';
import React, { MouseEventHandler } from 'react';
import {
  CommunityObjectType,
  CommunityStatusEnumType,
} from '../../__generated__/graphql';
import { JoinCommunityButton } from '../_common/post';

const CommunityCard: React.FC<
  CommunityObjectType & {
    onClick: (uuid: string) => MouseEventHandler<HTMLDivElement>;
    isMember: boolean;
  }
> = ({
  name,
  description = '',
  member_count,
  member_count_online,
  image_url,
  private: isPrivate,
  uuid,
  zip,
  onClick,
  isMember,
  my_member_status,
}) => {
  const canClick = () => {
    if (
      [
        CommunityStatusEnumType.Joined,
        CommunityStatusEnumType.Active,
      ].indexOf(my_member_status as CommunityStatusEnumType) <
        -1 &&
      isPrivate
    ) {
      return false;
    }
    if (isMember || !isPrivate) return true;
  };

  return (
    <Card
      className={'communityCard'}
      onClick={canClick() ? onClick(uuid) : () => null}
    >
      <Div>
        <FlexRow start spreadX={false} inline>
          <Image
            src={
              image_url ||
              '/defaultImages/defaultCommunityImage.jpg'
            }
            alt={`community – ${name}`}
            width={25}
            height={25}
            withBorderRadius
          />
          <Div>
            <Text variant={'h4'} color={'grey800'} weight={800}>
              {name}
            </Text>
            <Text variant={'p'} color={'grey200'}>
              {member_count_online} online | {member_count}{' '}
              members
            </Text>
          </Div>
        </FlexRow>
        <Divider color={'transparent'} />
        <Div className={'memberProfiles'}>
          {/*{members.map((member) => (*/}
          {/*  <Image*/}
          {/*    src={member.image_url}*/}
          {/*    key={member.image_url}*/}
          {/*    alt={"member alt"}*/}
          {/*    height={20}*/}
          {/*    width={20}*/}
          {/*  />*/}
          {/*))}*/}
        </Div>
      </Div>
      <Text variant={'p'} color={'grey800'}>
        {description}
      </Text>
      <Divider color={'transparent'} />
      {my_member_status !== 'joined' ? (
        <Text>{my_member_status}</Text>
      ) : null}
      {!isMember && (
        <JoinCommunityButton
          communityUUID={uuid}
          canJoin={!isPrivate}
        />
      )}
    </Card>
  );
};

export default CommunityCard;
