import {
  ConnectionState,
  ConnectionStoreActions,
} from '../../types/reducers/connection';

export const connectionState: ConnectionState = {
  meetFamilyCards: {},
  connectionProfile: {},
  connectionByFamily: {
    limit: 20,
    data: [],
    offset: 0,
  },
  connectionRequestsByFamily: {
    limit: 10,
    data: [],
    offset: 0,
  },
  connectionInvitesByFamily: {
    limit: 20,
    offset: 0,
    data: [],
  },
  removeActiveConnection: {},
  ignoreConnection: {},
  revokeConnectionInvite: {},
  createConnection: {},
  approveConnection: {},
  filters: {
    zipCode: null,
    radius: null,
    schoolDistrict: null,
    interestUUID: [],
  },
  viewFamilyUUID: null,
};
export const connectionActions: ConnectionStoreActions = {
  query: {
    meetFamilyCards: () => null,
    connectionProfile: () => null,
    connectionByFamily: () => null,
    connectionRequestsByFamily: () => null,
    connectionInvitesByFamily: () => null,
  },
  mutation: {
    removeActiveConnection: () => null,
    ignoreConnection: () => null,
    revokeConnectionInvite: () => null,
    createConnection: () => null,
    approveConnection: () => null,
  },
  setViewFamilyUUID: () => null,
};
