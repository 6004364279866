import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  BlockUserMutation,
  BlockUserMutationVariables,
} from '../../../__generated__/graphql';
import useBlockedUsersUUIDS from './useBlockedUsersUUIDS';

const useBlockUser = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'blockUser';
  const refetchBlockedUsers = useBlockedUsersUUIDS(
    state,
    dispatch,
  );

  const handleOnCompleted = (data: BlockUserMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.common.blockUser,
      },
    });
    refetchBlockedUsers();
    // need to refetch certain things here.
    // check first to see if you actually do, may be fine with no-cache settings.
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [blockUser] = useMutation(
    api.mutation.common.BLOCK_USER,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return (args: BlockUserMutationVariables) => {
    blockUser({
      variables: args,
    });
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useBlockUser;
