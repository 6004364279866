import { Div } from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import PostForm, {
  CreateEditPostInputs,
} from '../../forms/feed/PostForm';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import routes from '../../navigation/routes';
import {
  CreatePostMutationVariables,
  PostTypeEnumType,
} from '../../__generated__/graphql';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const CreateCommunityPost = () => {
  const { actions, state } = useStore();
  const { communityId } = useParams();
  const navigate = useCustomNavigate();

  const checkIfCommunityExists = () => {
    if (!communityId) {
      actions.app.setErrorMessage(
        'Community id is not available, please try again',
      );
      navigate(routes.communities);
    }
  };

  useEffect(() => {
    checkIfCommunityExists();
  }, []);

  const onSubmit = (formValues: CreateEditPostInputs) => {
    const postType = formValues.type.toLowerCase();
    if (postType == null) {
      actions.app.setErrorMessage(
        'post type is required to create a post.',
      );
      return;
    }
    if (communityId == null) {
      checkIfCommunityExists();
      return;
    }
    const baseCreateArgs: Partial<CreatePostMutationVariables> =
      {
        draft: formValues.draft,
        postType: postType as PostTypeEnumType,
        title: formValues.title,
        content: formValues.description,
      };
    if (formValues.link && postType === PostTypeEnumType.Link) {
      baseCreateArgs.link = formValues.link;
    }
    if (
      // postType === PostTypeEnumType.Video ||
      postType === PostTypeEnumType.Image
    ) {
      baseCreateArgs.files = formValues.file;
    }

    actions.post.mutation.createPost({
      ...baseCreateArgs,
      communityUuid: communityId,
    } as CreatePostMutationVariables);
  };

  return (
    <Div className={'fullWidth card'}>
      <PostForm
        onSubmit={onSubmit}
        loading={state.post.createPost.loading || false}
        error={state.post.createPost.error}
      />
    </Div>
  );
};

export default CreateCommunityPost;
