import {
  Button,
  Div,
  Divider,
  FlexRow,
  Form,
  Image,
  Input,
  Select,
  Text,
  TextArea,
} from '../../components/_ui';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  PostObjectType,
  PostTypeEnumType,
  PostTypeObjectType,
  Scalars,
} from '../../__generated__/graphql';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../styles/forms/index.scss';
import { UploadImage } from '../../components/_common';

export type CreateEditPostInputs = Pick<
  PostTypeObjectType,
  'title' | 'link'
> & {
  uuid?: Scalars['String']['output'];
  description?: Scalars['String']['output'];
  type: PostTypeEnumType;
  file?: string;
  draft?: boolean;
};

type CreateEditPostProps = {
  onSubmit: (args: CreateEditPostInputs) => void;
  onError?: () => void;
  existingPost?: PostObjectType;
  loading: boolean;
  error?: string;
};

const testURlPattern =
  /^(https?:\/\/)?(www\.)?[^\/\s]+\.[^\s\/]+(\/[^\s]*)?$/i;
const PostForm = ({
  onSubmit,
  onError,
  existingPost,
  loading,
  error,
}: CreateEditPostProps) => {
  const [tempFile, setTempFile] = useState<
    string | ArrayBuffer | null
  >(null);
  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
  } = useForm<CreateEditPostInputs>({
    mode: 'onChange',
    defaultValues: {
      draft: false,
      type: PostTypeEnumType.Text,
    },
  });
  const { errors, isValid } = formState;
  const [type, setType] = useState<PostTypeEnumType | null>(
    PostTypeEnumType.Text,
  );

  useEffect(() => {
    if (existingPost) {
      setValue('title', existingPost.post_content?.title || '');
      setValue('type', existingPost.post_type);
      setValue('draft', existingPost?.draft || false);
      setValue('uuid', existingPost.uuid);
      setValue(
        'description',
        existingPost.post_content?.content || '',
      );
      setValue('link', existingPost.post_content?.link);
      setType(existingPost.post_type);
    }
  }, [existingPost]);

  // const generatePostContent = () => {
  //   console.log('hitting generatePostContent');
  // };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextArea
        label={'title'}
        textSize={'md'}
        rows={3}
        cols={1}
        // disabled={loading}
        error={errors.title?.message}
        {...register('title', {
          required: 'Title is required',
        })}
      />
      <Controller
        name={'type'}
        control={control}
        render={({ field }) => {
          return (
            <Select
              disabled={existingPost != null}
              label={'type'}
              error={errors.type?.message}
              {...field}
              onChange={(option) => {
                const key = option.value as PostTypeEnumType;
                setType(key.toLowerCase() as PostTypeEnumType);
                field.onChange(option.value);
              }}
              value={field.value as unknown as string}
              options={Object.keys(PostTypeEnumType).map(
                (key) => ({
                  value: key as string,
                  name: key,
                }),
              )}
            />
          );
        }}
      />
      {tempFile && (
        <>
          <Divider color={'transparent'} />
          <Div className={'filePreview'}>
            <Image
              alt={`Create post image`}
              src={tempFile as string}
              // height={250}
              // width={400}
            />
          </Div>
          <Divider color={'transparent'} />
        </>
      )}
      {type === PostTypeEnumType.Image && (
        <UploadImage
          control={control}
          setFile={setTempFile}
          name={'file'}
          large
        />
      )}
      {/*{type === PostTypeEnumType.Video && (*/}
      {/*  <Input*/}
      {/*    label={"choose video"}*/}
      {/*    type={"file"}*/}
      {/*    error={errors.file?.message}*/}
      {/*    accepts={".mp4,.mov,video/*"}*/}
      {/*    {...register("file")}*/}
      {/*  />*/}
      {/*)}*/}
      {type === PostTypeEnumType.Link && (
        <Input
          label={'link'}
          type={'text'}
          error={errors.link?.message}
          {...register('link', {
            validate: (a = '') => {
              return (
                testURlPattern.test(`${a}`) || 'incorrect url'
              );
            },
          })}
        />
      )}
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      <Divider color={'transparent'} />
      {/*<Controller*/}
      {/*  name="draft"*/}
      {/*  control={control}*/}
      {/*  render={({ field, ...rest }) => (*/}
      {/*    <Switch*/}
      {/*      label={'save as draft'}*/}
      {/*      {...field}*/}
      {/*      value={!!field.value}*/}
      {/*      asSwitch*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
      {/*{type === PostTypeEnumType.request && ()}*/}
      {/*{type === PostTypeEnumType.slider && ()}*/}
      <TextArea
        label={'content'}
        textSize={'md'}
        error={errors.description?.message}
        {...register('description')}
      />
      <Divider color={'transparent'} />
      {error && (
        <Text variant={'p'} color={'error'}>
          {error}
        </Text>
      )}
      <Divider color={'transparent'} />
      <FlexRow centerX centerY>
        {/*{!existingPost && (*/}
        {/*  <Button*/}
        {/*    onClick={generatePostContent}*/}
        {/*    variant={'contained'}*/}
        {/*    color={'secondary'}*/}
        {/*    size={'md'}*/}
        {/*    type={'button'}*/}
        {/*    disabled={loading}*/}
        {/*  >*/}
        {/*    Generate Content*/}
        {/*  </Button>*/}
        {/*)}*/}
        <Button
          type={'submit'}
          variant={'contained'}
          color={'secondary'}
          size={'md'}
          disabled={!isValid || loading}
          iconProps={
            loading
              ? {
                  position: 'end',
                  icon: faSpinner,
                  animationStyle: {
                    spin: true,
                  },
                  color: 'white',
                }
              : null
          }
        >
          {existingPost ? 'Save' : 'Create'}
        </Button>
      </FlexRow>
    </Form>
  );
};

export default PostForm;
