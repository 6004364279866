import {
  Button,
  Div,
  Divider,
  FlexRow,
  Icon,
  Image,
  Pill,
  Text,
} from '../_ui';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import routes from '../../navigation/routes';
import { FeedPost } from '../feed';
import get from '../../utils/utilityFunctions/get';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import {
  FamilyMemberObjectType,
  Maybe,
  MyCommunitiesObjectType,
  ProfileObjectType,
  Tag,
} from '../../__generated__/graphql';

const FamilyMemberProfile = ({
  familyMember,
  communities,
  interests,
  doesOwn = false,
  profile,
}: {
  familyMember: FamilyMemberObjectType | undefined;
  communities:
    | Maybe<MyCommunitiesObjectType>[]
    | null
    | undefined;
  interests: Maybe<Tag>[] | null | undefined;
  doesOwn: boolean;
  profile?: ProfileObjectType | null;
}) => {
  const { state, actions } = useStore();
  const navigate = useCustomNavigate();
  // TODO move this to the store for easier management
  const [showMetrics, setShowMetrics] = useState<boolean>(false);

  const defaultImage = familyMember?.is_admin
    ? '/defaultImages/defaultParentProfileImage.jpg'
    : '/defaultImages/defaultChildProfileImage.jpg';

  useEffect(() => {
    const currentProfileUUID =
      state.profile.selectedProfile?.profile.uuid;
    if (
      currentProfileUUID &&
      currentProfileUUID !==
        get(state, 'post.myPosts.data[0].profile_uuid', null)
    ) {
      actions.post.query.myPosts({
        profileUuid: currentProfileUUID,
        refine: {
          limit: 10,
        },
      });
    }
  }, [state.profile.selectedProfile?.profile.uuid]);

  const handleEditProfile = () => {
    navigate<string>(routes.editProfile, [
      familyMember?.uuid as string,
    ]);
  };

  const showNickname = profile && ` | (${profile.nickname})`;

  return (
    <>
      <Image
        className={'profileImage'}
        src={familyMember?.profile_image_url || defaultImage}
        alt={`${familyMember?.first_name} profile`}
        width={398}
        height={250}
      />
      <FlexRow spreadX>
        <Div>
          <Text variant={'h3'} color={'grey800'}>
            {familyMember?.first_name} {familyMember?.last_name}
            {showNickname}
          </Text>
        </Div>
        {doesOwn && (
          <Button
            iconOnly
            color={'primary'}
            variant={'transparent'}
            onClick={handleEditProfile}
          >
            <Icon icon={faEdit} />
          </Button>
        )}
        {/*TODO need to flesh this out on api and app*/}
        {/*{!familyMember?.is_admin && myProfile?.is_admin && (*/}
        {/*  <Button iconOnly color={"primary"} variant={"transparent"}>*/}
        {/*    <Icon icon={faChartSimple} />*/}
        {/*  </Button>*/}
        {/*)}*/}
      </FlexRow>
      <Text variant={'p'} color={'grey400'}>
        {get(
          familyMember,
          'bio',
          doesOwn
            ? 'Biography does not yet exist, add one to improve matches and catered content.'
            : 'N/A',
        )}
      </Text>
      <Divider color={'transparent'} />
      <Div className={'profileSectionContainer'}>
        <Text variant={'h6'} color={'grey800'}>
          Involved In:
        </Text>
        <Divider color={'transparent'} />
        <FlexRow wrap spreadX={false}>
          {isEmpty(communities) ? (
            <Text>Nothing here yet</Text>
          ) : (
            (communities || []).map(
              (community, index: number) =>
                community && (
                  <Pill
                    color={'grey800'}
                    key={`${community.uuid}-${index}`}
                    title={community.name}
                    variant={'outlined'}
                  />
                ),
            )
          )}
        </FlexRow>
      </Div>
      <Div className={'profileSectionContainer'}>
        <Text variant={'h6'} color={'grey800'}>
          Interested In:
        </Text>
        <Divider color={'transparent'} />
        <FlexRow wrap spreadX={false}>
          {isEmpty(interests) ? (
            <Text>Nothing here yet</Text>
          ) : (
            interests?.map(
              (interest) =>
                interest && (
                  <Pill
                    color={'grey800'}
                    key={interest.uuid}
                    title={interest.name}
                    variant={'outlined'}
                    imageStart={<Text>{interest.tag_icon}</Text>}
                  />
                ),
            )
          )}
        </FlexRow>
      </Div>
      <Div>
        <Text variant={'h6'} color={'grey800'}>
          {familyMember?.first_name}'s posts
        </Text>
        <Divider color={'transparent'} />
        {isEmpty(state.post.myPosts.data) ? (
          <Text>No posts yet</Text>
        ) : (
          (state.post.myPosts.data || []).map((post, i) => (
            <FeedPost
              key={`${post?.uuid}-${i}`}
              // @ts-ignore
              type={post?.post_type}
              post={post}
              index={i}
              shouldNavigateOnClick
            />
          ))
        )}
      </Div>
    </>
  );
};

export default FamilyMemberProfile;
