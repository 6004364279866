import React from 'react';
import { Div } from './index';
import { animated } from '@react-spring/web';
import '../../styles/components/_ui/image.scss';

interface ImageProps {
  src: string;
  alt: string;
  height?: number;
  width?: number;
  className?: string;
  animateStyles?: any;
  withBorderRadius?: boolean;
  wrapperClass?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  height,
  width,
  className,
  animateStyles,
  withBorderRadius = false,
  wrapperClass = '',
}) => {
  const bdrds = withBorderRadius ? 'withBorderRadius' : '';
  const classNames = ['image', className, bdrds].join(' ');

  if (animateStyles) {
    return (
      <animated.img
        src={src}
        alt={alt}
        width={width}
        className={classNames}
        style={{
          minHeight: height,
          width: '100%',
          ...animateStyles,
        }}
      />
    );
  }
  return (
    <Div
      className={`imageWrapper ${bdrds} ${wrapperClass}`}
      style={{
        height,
        width,
      }}
    >
      <img
        src={src}
        alt={alt}
        width={width}
        className={classNames}
        style={{
          width: '100%',
          minHeight: height,
        }}
      />
    </Div>
  );
};

export default Image;
