import { Button } from '../../_ui';
import { useStore } from '../../../hooks/useStore';
import React, { SyntheticEvent } from 'react';

const JoinCommunityButton = ({
  communityUUID,
  canJoin,
}: {
  communityUUID: string;
  canJoin: boolean;
}) => {
  const { state, actions } = useStore();

  if (!communityUUID) return null;

  const hasJoinedCommunity =
    state.community.joinCommunity.data?.community_uuid ===
      communityUUID ||
    (
      state.community.joinCommunity.communitiesJoined || []
    ).includes(communityUUID);

  const renderCommunityTitle = () => {
    if (
      state.community.joinCommunity.joiningCommunityUUID ===
      communityUUID
    ) {
      return 'Joining';
    }
    if (
      (
        state.community.joinCommunity.communitiesJoined || []
      ).includes(communityUUID)
    ) {
      return canJoin ? 'Joined' : 'Requested';
    }
    return canJoin ? 'Join' : 'Request to Join';
  };

  const handleJoinCommunity = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (communityUUID)
      actions.community.mutation.joinCommunity({
        communityUuid: communityUUID,
      });
    else
      actions.app.setErrorMessage(
        'The community no longer exists',
      );
  };

  return (
    <Button
      size={'xs'}
      shape={'round'}
      variant={'outlined'}
      onClick={handleJoinCommunity}
      className={[
        hasJoinedCommunity && 'joined',
        'joinCommunityButton',
      ].join(' ')}
      color={hasJoinedCommunity ? 'success' : 'primary'}
    >
      {renderCommunityTitle()}
    </Button>
  );
};

export default JoinCommunityButton;
