import {
  CommunityState,
  CommunityStoreActions,
} from '../../types/reducers/community';

export const communityState: CommunityState = {
  allCommunities: {},
  communities: {},
  community: {},
  suggestedCommunitiesForNewFamilyMember: {},
  communityByFamily: {},
  leaveCommunity: {},
  joinCommunity: {
    communitiesJoined: [],
  },
  joinCommunities: {},
  inviteToCommunity: {},
  deleteCommunity: {},
  createCommunity: {},
  connectionsToAddToCommunity: [],
  updateCommunity: {},
  inviteManyConnectionsToCommunity: {},
  inviteByPhone: {},
  respondToCommunityInvitation: {
    communitiesRespondedTo: [],
  },
};
export const communityActions: CommunityStoreActions = {
  manageConnectionInvites: () => ({
    addConnectionToQueue: () => null,
    removeConnectionFromQueue: () => null,
  }),
  query: {
    allCommunities: () => null,
    communities: () => null,
    community: () => null,
    communityByFamily: () => null,
    suggestedCommunitiesForNewFamilyMember: () => null,
  },
  mutation: {
    leaveCommunity: () => null,
    joinCommunity: () => null,
    joinCommunities: () => null,
    inviteToCommunity: () => null,
    deleteCommunity: () => null,
    createCommunity: () => null,
    updateCommunity: () => null,
    inviteManyConnectionsToCommunity: () => null,
    inviteByPhone: () => null,
    respondToCommunityInvitation: () => null,
  },
};
