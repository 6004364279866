import { AppStoreState } from '../types/reducers';
import { TagReducerActions } from '../types/reducers/tag';

const tagReducer = (
  state: AppStoreState,
  action: TagReducerActions,
): AppStoreState => {
  switch (action.type) {
    case 'createFamilyTag':
      state.tag.createFamilyTag = action.payload;
      return state;
    case 'createFamilyTags':
      state.tag.createFamilyTags = action.payload;
      return state;
    case 'createProfileTags':
      state.tag.createProfileTags = action.payload;
      return state;
    default:
      return state;
  }
};

export default tagReducer;
