import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useCreateFamilyTag from './api/useCreateFamilyTag';
import useCreateFamilyTags from './api/useCreateFamilyTags';
import { TagStoreActions } from '../../types/reducers/tag';
import useCreateProfileTags from './api/useCreateProfileTags';

const useTagActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): TagStoreActions => {
  return {
    mutation: {
      createFamilyTag: useCreateFamilyTag(state, dispatch),
      createFamilyTags: useCreateFamilyTags(state, dispatch),
      createProfileTags: useCreateProfileTags(state, dispatch),
    },
    query: {},
  };
};

export default useTagActions;
