import {
  Button,
  Card,
  Div,
  Divider,
  FlexRow,
  Loader,
  Text,
} from '../../components/_ui';
import '../../styles/pages/feed.scss';
import { useStore } from '../../hooks/useStore';
import isEmpty from '../../utils/utilityFunctions/isEmpty';
import get from '../../utils/utilityFunctions/get';
import {
  ChooseFamilyInterests,
  FinishFamilySection,
} from '../../components/onboarding';
import routes from '../../navigation/routes';
import { BlockedPost, FeedPost } from '../../components/feed';
import { useEffect } from 'react';

const Feed = () => {
  const { actions, state } = useStore();
  const postsAreEmpty = isEmpty(
    get(state, 'post.postFeed.data', []),
  );

  useEffect(() => {
    if (postsAreEmpty) {
      // only fetch on initial load if data does not yet exist.
      actions.post.query.postFeed({
        offset: state.post.postFeed.offset || 0,
        limit: state.post.postFeed.limit || 20,
      });
    }
  }, []);

  const promptPickFamilyInterests =
    !state.profile.checkIfOnboarded.data?.interests_complete;
  const promptFinishFamily =
    !state.profile.checkIfOnboarded.data?.family_complete;

  const isLoading =
    state.profile.checkIfOnboarded.loading ||
    state.post.postFeed.loading;
  // // fetch posts in a lazy fashion
  // // need to check for scroll position here and fetch at distance to bottom
  const renderPosts = () => {
    // This displays the family creation prompt if not yet created in lieu of the below instance in the render method.
    // If the family has been created, prompts the user to start creating posts or joining communities if no posts exist.
    // otherwise renders the posts as they come in.
    if (isLoading && postsAreEmpty)
      return <Loader position={'center'} color={'white'} />;
    if (postsAreEmpty) {
      return (
        <Card>
          {promptFinishFamily ? (
            <FinishFamilySection />
          ) : (
            <Card>
              <Text variant={'h6'} color={'grey400'}>
                Start Creating posts or join communities to see
                activity
              </Text>
              <Divider color={'transparent'} />
              <FlexRow>
                <Button
                  routeTo={routes.communities}
                  variant={'outlined'}
                  color={'primary'}
                >
                  Communities
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  routeTo={routes.createFeedPost}
                >
                  Create Post
                </Button>
              </FlexRow>
            </Card>
          )}
          <Text variant={'h5'} color={'grey800'}>
            No Posts in your area.
          </Text>
          <Text variant={'p'} color={'grey400'}>
            Please check back again or create some to start the
            conversation!
          </Text>
        </Card>
      );
    }

    return (state.post.postFeed.data || []).map(
      (post, i: number) =>
        post &&
        (
          state.common.blockedUsersUUIDS.blockedUserUUIDs || []
        ).indexOf(post.profile_uuid) >= 0 ? (
          <BlockedPost key={`${post?.uuid}-${i}`} />
        ) : (
          <FeedPost
            key={`${post?.uuid}-${i}`}
            // TODO refine this type once everything is working again
            // @ts-ignore
            type={post.post_type}
            post={post}
            index={i}
            shouldNavigateOnClick
          />
        ),
    );
  };

  return (
    <Div className={'feed'}>
      {promptPickFamilyInterests && <ChooseFamilyInterests />}
      {promptFinishFamily &&
        !isEmpty(state.post.postFeed.data) && (
          <FinishFamilySection />
        )}
      {renderPosts()}
      {!postsAreEmpty && state.post.postFeed.loading && (
        <Loader position={'center'} />
      )}
      {!postsAreEmpty &&
        (state.post.postFeed.data?.length || 0) >=
          (state.post.postFeed.total || 0) && (
          <Card>
            <Text variant={'h5'} color={'grey400'}>
              That appears to be all for now. Join some other
              communities to view some other posts.
            </Text>
          </Card>
        )}
    </Div>
  );
};

export default Feed;
