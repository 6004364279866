import React from 'react';
import { Outlet } from 'react-router-dom';
import NotificationProvider from '../providers/NotificationProvider';
import ChatProvider from '../providers/ChatProvider';

const AuthenticatedRoute = () => {
  // check if user is authenticated here and route if not.

  return (
    <NotificationProvider>
      <ChatProvider>
        <Outlet />
      </ChatProvider>
    </NotificationProvider>
  );
};

export default AuthenticatedRoute;
