import {
  Button,
  Div,
  Divider,
  FlexRow,
  Icon,
  Loader,
  Text,
} from '../_ui';
import React, { SyntheticEvent, useState } from 'react';
import { AvatarAndName } from '../_common';
import { ReactionRow } from '../_common/post';
import {
  faFlag,
  faHeart,
  faMinusCircle,
  faPlusCircle,
  faReply,
} from '@fortawesome/free-solid-svg-icons';
import {
  Maybe,
  PostCommentsObjectType,
  ReportUserEnum,
} from '../../__generated__/graphql';
import '../../styles/components/feed/comment.scss';
import { useStore } from '../../hooks/useStore';
import useReportUser from '../../hooks/useReportUser';
import { getNotificationDateStamp } from '../../utils/dateUtils';

const commentsAtPath = (
  comments: Maybe<PostCommentsObjectType>[],
  commentPath: string,
) => {
  const path = commentPath.split('|').map(Number);

  let currentLevel = comments;
  for (let i = 0; i < path.length; i++) {
    if (
      currentLevel != null ||
      !currentLevel[path[i]] ||
      // @ts-ignore
      !currentLevel[path[i]].replies
    ) {
      return null;
    }
    // @ts-ignore
    currentLevel = currentLevel[path[i]].replies;
  }

  return currentLevel;
};

const Comment: React.FC<
  {
    index: number | string;
  } & Maybe<PostCommentsObjectType>
> = ({ index: commentIndex, ...comment }) => {
  const [showComments, setShowComments] =
    useState<boolean>(false);
  const { actions, state } = useStore();
  const reportUser = useReportUser();
  const levelsDeep = commentIndex.toString().split('|').length;

  const handleLikeComment = async (e: SyntheticEvent) => {
    // TODO would be nice to bust this out to many different like types for availability. But just heart for now.
    actions.post.mutation.likeComment(
      {
        commentUuid: comment.uuid,
      },
      comment,
      commentIndex.toString(),
    );
  };

  const showReportModal = () => {
    reportUser(
      comment.profile.uuid,
      ReportUserEnum.Comment,
      'report',
      comment.uuid,
    );
  };

  const handleClickReaction = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickReaction');
  };

  const loadMoreComments = (e: SyntheticEvent) => {
    e.stopPropagation();
    const commentsAtPathEx = commentsAtPath(
      state.post.commentComments.data || [],
      commentIndex.toString(),
    );

    actions.post.query.commentComments(
      {
        commentUuid: comment.uuid,
        refine: {
          // dynamically check this as it can change whenever fetching nested comments.
          offset: commentsAtPathEx ? commentsAtPathEx.length : 0,
          limit: state.post.commentComments.limit || 1,
        },
      },
      commentIndex.toString(),
    );
  };

  const replyToComment = () => {
    actions.post.setReplyToCommentId(
      comment.uuid,
      `${comment.family_member.first_name}`,
    );
  };

  const commentReplies = comment?.replies || [];
  const replyUUIDS = comment?.reply_uuids || [];

  const showAndFetchChildComments = () => {
    setShowComments(!showComments);
  };

  const commentIsBlocked =
    (
      state.common.blockedUsersUUIDS.blockedUserUUIDs || []
    ).indexOf(comment.profile.uuid) >= 0;

  const commentPosterName = () => {
    if (comment.family_member?.first_name)
      return `${comment.family_member?.first_name} ${comment.family_member.last_name}`;

    return `${comment.family_member.last_name}`;
  };

  return (
    <FlexRow
      column
      start
      className={[
        'fullWidth comment',
        showComments ? 'active' : false,
      ].join(' ')}
      inline
    >
      <FlexRow className={'fullWidth commentHeader'} spreadX>
        <FlexRow start inline spreadX={false}>
          {commentIsBlocked ? (
            <Text variant={'span'} color={'grey400'}>
              Blocked
            </Text>
          ) : (
            <AvatarAndName
              textVariant={'span'}
              size={20}
              name={levelsDeep > 3 ? '' : commentPosterName()}
              url={comment.family_member?.profile_image_url}
              familyMemberUUID={comment.family_member?.uuid}
              navigateToProfile
            />
          )}
          {comment.reactions.length > 0 && (
            <ReactionRow
              handleClick={handleClickReaction}
              reactions={comment.reactions?.map(
                (reaction) => reaction?.unicode as string,
              )}
              uuidKey={comment.uuid}
            />
          )}
          <Text variant={'span2'} color={'grey800'}>
            {getNotificationDateStamp(comment.created_at)}
          </Text>
        </FlexRow>
        <FlexRow inline>
          <Icon
            icon={faFlag}
            color={'primary'}
            onClick={showReportModal}
            height={15}
            width={30}
          />
          {!commentIsBlocked && (
            <Button
              variant={'transparent'}
              color={
                state.post.replyToComment.replyToCommentUUID ===
                comment.uuid
                  ? 'primary'
                  : 'grey200'
              }
              iconProps={{
                position: 'start',
                icon: faReply,
              }}
              className={'replyToCommentButton'}
              size={'sm'}
              onClick={replyToComment}
            />
          )}
        </FlexRow>
      </FlexRow>
      <FlexRow
        className={'fullWidth commentContent'}
        start
        spreadX={false}
        inline
      >
        <Button
          variant={'transparent'}
          onClick={handleLikeComment}
          size={'sm'}
          className={'noPad'}
          disabled={commentIsBlocked}
        >
          <Icon
            icon={faHeart}
            color={
              comment.profile_did_like ? 'primary' : 'grey200'
            }
          />
        </Button>
        <Text variant={'p2'} color={'grey800'}>
          {commentIsBlocked ? 'Blocked' : comment.comment}
        </Text>
      </FlexRow>
      <Divider color={'transparent'} />
      {commentReplies.length > 0 && (
        <FlexRow
          column
          center
          inline
          spreadX={false}
          start
          className={'commentReplies fullWidth'}
        >
          <FlexRow>
            <Button
              onClick={showAndFetchChildComments}
              color={'primary'}
              variant={'transparent'}
              size={'sm'}
              className={'noPad'}
              iconProps={{
                position: 'start',
                icon: showComments
                  ? faMinusCircle
                  : faPlusCircle,
              }}
            >
              <Text variant={'span'}>
                {commentReplies.length} replies
              </Text>
            </Button>
          </FlexRow>
          {/*TODO need to fetch this from api.old on click*/}
          {showComments && commentReplies.length > 0 && (
            <Div className={'nestedComments'}>
              {commentReplies.map(
                (nestedComment, nestedIndex) =>
                  nestedComment && (
                    <Comment
                      {...nestedComment}
                      index={`${commentIndex}|${nestedIndex}`}
                      key={`${nestedComment.uuid}`}
                    />
                  ),
              )}
            </Div>
          )}
        </FlexRow>
      )}
      {commentReplies.length !== replyUUIDS.length && (
        <FlexRow center centerY centerX fullWidth>
          {state.post.commentComments.loading ? (
            <Loader />
          ) : (
            <Button
              variant={'transparent'}
              size={'xs'}
              color={'primary'}
              onClick={loadMoreComments}
            >
              Load Replies
            </Button>
          )}
        </FlexRow>
      )}
    </FlexRow>
  );
};

export default Comment;
