import profileReducer from './profileReducer';
import postReducer from './postReducer';
import notificationReducer from './notificationReducer';
import familyReducer from './familyReducer';
import connectionReducer from './connectionReducer';
import communityReducer from './communityReducer';
import commonReducer from './commonReducer';
import tagReducer from './tagReducer';
import generalAppReducer from './appReducer';
import chatReducer from './chatReducer';
import { AppStoreState, ReducerActions } from '../types/reducers';
import { ProfileReducerActions } from '../types/reducers/profile';
import { PostReducerActions } from '../types/reducers/post';
import { GeneralAppReducerActions } from '../types/reducers/app';
import { NotificationReducerActions } from '../types/reducers/notification';
import { FamilyReducerActions } from '../types/reducers/family';
import { ConnectionReducerActions } from '../types/reducers/connection';
import { CommunityReducerActions } from '../types/reducers/community';
import { CommonReducerActions } from '../types/reducers/common';
import { ChatReducerActions } from '../types/reducers/chat/chatActions';
import { TagReducerActions } from '../types/reducers/tag';
import get from '../utils/utilityFunctions/get';

const appReducer = (
  state: AppStoreState,
  action: ReducerActions,
): AppStoreState => {
  const newState = { ...state };

  const errorMessage = get(action, 'payload.error', null);
  const serverError = get(action, 'payload.serverError', null);
  const successMessage = get(
    action,
    'payload.successMessage',
    null,
  );
  const warnMessage = get(action, 'payload.warnMessage', null);

  if (errorMessage) {
    newState.app.messages.errorMessage = errorMessage;
  }
  if (serverError) {
    newState.app.messages.errorMessage = serverError;
  }
  if (successMessage) {
    newState.app.messages.errorMessage = successMessage;
  }
  if (warnMessage) {
    newState.app.messages.errorMessage = warnMessage;
  }

  switch (action.parent) {
    case 'app':
      return generalAppReducer(
        newState,
        action as GeneralAppReducerActions,
      );
    case 'profile':
      return profileReducer(
        newState,
        action as ProfileReducerActions,
      );
    case 'post':
      return postReducer(newState, action as PostReducerActions);
    case 'notification':
      return notificationReducer(
        newState,
        action as NotificationReducerActions,
      );
    case 'family':
      return familyReducer(
        newState,
        action as FamilyReducerActions,
      );
    case 'connection':
      return connectionReducer(
        newState,
        action as ConnectionReducerActions,
      );
    case 'community':
      return communityReducer(
        newState,
        action as CommunityReducerActions,
      );
    case 'common':
      return commonReducer(
        newState,
        action as CommonReducerActions,
      );
    case 'chat':
      return chatReducer(newState, action as ChatReducerActions);
    case 'tag':
      return tagReducer(newState, action as TagReducerActions);
    default:
      return newState;
  }
};

export default appReducer;
