import React, { ChangeEventHandler } from "react";
import { Div, Text } from "./";
import "../../styles/components/_ui/switch.scss";
import { AllColorOptions, colorsOptions } from "../../types/theme/colors";

interface SwitchProps {
  className?: string;
  value: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  asSwitch?: boolean;
  containerClassName?: string;
  color?: colorsOptions;
  labelColor?: AllColorOptions;
}

const Switch: React.FC<SwitchProps> = ({
  className,
  value,
  onChange,
  label,
  asSwitch = false,
  labelColor = "grey400",
  containerClassName,
  color = "primary",
}) => {
  return (
    <Div
      className={`${asSwitch ? "switch" : "checkbox"} ${containerClassName}`}
    >
      {label && (
        <Text
          variant={"p"}
          className={asSwitch ? "switchLabel" : "checkboxLabel"}
          color={labelColor}
        >
          {label}
        </Text>
      )}
      <Div className={asSwitch ? "switchContainer" : ""}>
        <input
          onChange={onChange}
          type={"checkbox"}
          className={`input${asSwitch ? "Switch" : "Checkbox"} ${className} ${
            value ? "checked" : "unchecked"
          }`}
          checked={value}
        />
        <Div className={"switchSlider"} />
      </Div>
    </Div>
  );
};

export default Switch;
