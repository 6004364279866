import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import { Maybe, Scalars } from '../../../__generated__/graphql';

const useBlockedUsersUUIDS = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'blockedUsersUUIDS';

  const handleOnCompleted = (data: {
    common: {
      blockedUsersUUIDS: Array<
        Maybe<Scalars['String']['output']>
      >;
    };
  }) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        blockedUserUUIDs: data.common.blockedUsersUUIDS,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [blockedUsersUUIDS] = useLazyQuery(
    api.query.common.BLOCKED_USERS_UUIDS,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return () => {
    blockedUsersUUIDS();
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useBlockedUsersUUIDS;
