import React from 'react';
import { Div } from '../_ui';
import { MediaPost, TextPost } from '../_common/post';
import {
  Maybe,
  PostObjectType,
  PostObjectTypeUnionType,
  PostRecommendationObjectType,
  PostTypeEnumType,
} from '../../__generated__/graphql';
import routes from '../../navigation/routes';
import CommunityRecommendation from './CommunityRecommendation';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { useStore } from '../../hooks/useStore';

interface FeedPostProps {
  type: Maybe<PostTypeEnumType> | undefined;
  post: Maybe<PostObjectTypeUnionType> | undefined;
  index?: number;
  shouldNavigateOnClick?: boolean;
  singlePage?: boolean;
  isCommunityPost?: boolean;
}

const FeedPost: React.FC<FeedPostProps> = ({
  type,
  post,
  index = 0,
  shouldNavigateOnClick = false,
  singlePage = false,
  isCommunityPost = false,
}) => {
  const { state } = useStore();
  const navigate = useCustomNavigate();
  if (post == null) {
    return null;
  }

  const postIsRecommendation =
    type == null && post.community?.uuid;

  const renderPost = () => {
    if (postIsRecommendation) {
      // this is a recommendation post
      return (
        <CommunityRecommendation
          post={post as PostRecommendationObjectType}
        />
      );
    }

    switch (type) {
      case PostTypeEnumType.Image:
        // case PostTypeEnumType.Video:
        // case PostTypeEnumType.Slider:
        return (
          <MediaPost
            post={post as PostObjectType}
            singlePage={singlePage}
          />
        );
      case PostTypeEnumType.Text:
      case PostTypeEnumType.Link:
        return (
          <TextPost
            post={post as PostObjectType}
            singlePage={singlePage}
          />
        );
      // case PostTypeEnumType.Survey:
      //   return <Div>Survey</Div>;
      // case PostTypeEnumType.Poll:
      //   return <Div>Poll</Div>;
      // case PostTypeEnumType.Event:
      //   return <Div>Event</Div>;
      default:
        return <Div>Post N/A</Div>;
    }
  };

  return (
    <Div
      className={[
        'feedPostWrapper',
        state.app.themeMode,
        singlePage && 'viewPostHeaderWrapper',
      ].join(' ')}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (shouldNavigateOnClick && !postIsRecommendation)
          navigate<string>(
            routes.feedPost,
            [post?.uuid as string],
            {
              state: {
                uuid: post.uuid,
                post,
              },
            },
          );
      }}
    >
      {renderPost()}
    </Div>
  );
};

export default FeedPost;
