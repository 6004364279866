import { AppStoreState } from '../types/reducers';
import { ProfileReducerActions } from '../types/reducers/profile';
import { AppPagesEnum } from '../types/pages';

const profileReducer = (
  state: AppStoreState,
  action: ProfileReducerActions,
): AppStoreState => {
  switch (action.type) {
    // UI actions
    case 'setEditFamily':
      state.profile.editFamily = action.payload;
      return state;
    case 'setFamilyMemberOrFamilyUUID':
      state.profile.familyMemberOrFamilyUUID = action.payload;
      return state;
    case 'resetInterests':
      state.profile.chosenInterests = [];
      return state;
    case 'addInterest':
      const interests = new Set(state.profile.chosenInterests);
      if (Array.isArray(action.payload)) {
        action.payload.map((interest) =>
          interests.add(interest),
        );
      } else {
        interests.add(action.payload);
      }
      state.profile.chosenInterests = [...interests];
      return state;
    case 'removeInterest':
      state.profile.chosenInterests =
        state.profile.chosenInterests.filter(
          (interest) => interest?.uuid !== action.payload.uuid,
        );
      return state;
    case 'resetCommunities':
      state.profile.chosenCommunities = [];
      return state;
    case 'addCommunity':
      const communities = new Set(
        state.profile.chosenCommunities,
      );
      communities.add(action.payload);
      state.profile.chosenCommunities = [...communities];
      return state;
    case 'removeCommunity':
      state.profile.chosenCommunities =
        state.profile.chosenCommunities.filter(
          (community) => community?.uuid !== action.payload.uuid,
        );
      return state;
    // mutation actions
    case 'login':
      if (action.payload.token) {
        state.app.token = action.payload.token;
      }
      state.profile.profile = action.payload;
      state.profile.login.loading = action.payload.loading;
      state.app.prevPages = [];
      state.app.prevUrls = [];
      state.app.currentPage = 'feed';
      return state;
    case 'logout':
      state.profile.logout.loading = action.payload.loading;
      state.app.token = null;
      state.app.currentPage = AppPagesEnum.signIn;
      return state;
    case 'createNewProfile':
      state.profile.profile = action.payload;
      state.profile.createNewProfile.loading =
        action.payload.loading;
      return state;
    // query actions
    case 'familyProfile':
      state.profile.familyProfile = action.payload;
      return state;
    case 'setCurrentProfile':
      state.profile.selectedProfile = action.payload;
      return state;
    case 'familyMemberProfile':
      state.profile.familyMemberProfile = action.payload;
      return state;
    case 'viewPublicProfile':
      const { data, loading, called, error } = action.payload;
      state.profile.viewPublicProfile.loading = loading;
      state.profile.viewPublicProfile.error = error;
      state.profile.viewPublicProfile.called = called;
      if (data) {
        state.profile.viewPublicProfile.data = data;
      } else {
        state.profile.viewPublicProfile.data = undefined;
      }
      return state;
    case 'checkIfVerified':
      state.profile.checkIfVerified = action.payload;
      return state;
    case 'checkIfOnboarded':
      state.profile.checkIfOnboarded = action.payload;
      return state;
    case 'updateProfile':
      state.profile.updateProfile.loading =
        !!action.payload.loading;
      if (action.payload.data && action.payload.data.nickname) {
        state.app.messages.successMessage = `${action.payload.data.nickname} updated!`;
      }
      return state;
    default:
      return state;
  }
};

export default profileReducer;
