import { Icon, Div } from './index';
import React from 'react';
import {
  colorsOptions,
  monoColorOptions,
} from '../../types/theme/colors';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/_ui/loader.scss';
import { LoaderSize } from '../../types/_ui/button';
import { useStore } from '../../hooks/useStore';

interface LoaderProps {
  type?: 'spinner' | 'bar';
  className?: string;
  color?: colorsOptions | monoColorOptions;
  variant?: 'default' | 'overlay';
  position?: 'center' | 'default';
  size?: LoaderSize;
}

const Loader: React.FC<LoaderProps> = ({
  type = 'spinner',
  className,
  color = 'grey100',
  variant = 'default',
  position = 'default',
  size = 'md',
}) => {
  const { state } = useStore();
  return (
    <Div
      className={[
        variant,
        position,
        size,
        'loaderContainer',
        state.app.themeMode,
      ].join(' ')}
    >
      <Icon
        className={['loader', type, className, color].join(' ')}
        color={color as colorsOptions | monoColorOptions}
        icon={faSpinner}
      />
    </Div>
  );
};

export default Loader;
