import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  InviteByPhoneMutation,
  InviteByPhoneMutationVariables,
} from "../../../__generated__/graphql";

const useInviteManyConnectionsToCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "inviteByPhone";

  const handleOnCompleted = (data: InviteByPhoneMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.inviteByPhone,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [inviteByPhone] = useMutation(api.mutation.community.INVITE_BY_PHONE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    context: {
      headers: {
        "x-apollo-operation-name": "createCommunity",
      },
    },
  });

  return (args: InviteByPhoneMutationVariables) => {
    if (!state.community.inviteByPhone.loading) {
      inviteByPhone({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useInviteManyConnectionsToCommunity;
