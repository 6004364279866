import React, { ChangeEventHandler, forwardRef } from 'react';
import { BaseInputProps } from '../../types/_ui';
import { Div, Text } from './index';
import { InputSize } from '../../types/_ui/button';
import '../../styles/components/_ui/textarea.scss';
import { useStore } from '../../hooks/useStore';

interface TextAreaProps extends BaseInputProps {
  rows?: number;
  cols?: number;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  placeholder?: string;
  error?: string;
  textSize?: InputSize;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}

const TextArea = forwardRef<any, TextAreaProps>(
  (
    {
      label,
      rows = 5,
      cols = 10,
      value,
      onChange,
      disabled = false,
      maxLength = 500,
      placeholder,
      containerClassName,
      className,
      error,
      textSize = 'lg',
      containerStyle = {},
      inputStyle = {},
      ...otherFormProps
    },
    ref,
  ): React.ReactElement => {
    const { state } = useStore();
    return (
      <Div
        className={`inputContainer inputTextAreaContainer ${containerClassName}}`}
      >
        {label && (
          <Text
            className={'inputLabel'}
            variant={'p'}
            color={'grey400'}
          >
            {label}
          </Text>
        )}
        <textarea
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          className={`textarea ${className} ${textSize} ${state.app.themeMode}`}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={rows}
          cols={cols}
          {...otherFormProps}
        />
        {error && (
          <Text variant={'h5'} color={'error'}>
            {error}
          </Text>
        )}
      </Div>
    );
  },
);

export default TextArea;
