import { Div, Text } from '../_ui';
import React from 'react';
import {
  modalEnumKeys,
  ModalProps,
} from '../../types/reducers/app';
import ChooseCommunities from './ChooseCommunities';
import ChooseInterests from './ChooseInterests';
import ReportModal from './ReportModal';
import ManageSecurityModal from './ManageSecurityModal';
import ReportUser from './ReportUser';
import DeleteProfile from './DeleteProfile';

type ModalChildProps = Pick<ModalProps, 'data' | 'type'>;
const ModalChildren: React.FC<ModalChildProps> = ({
  type,
  data,
}) => {
  switch (type) {
    case modalEnumKeys.chatReportUser:
      return <ReportModal />;
    case modalEnumKeys.reportUser:
      return <ReportUser />;
    case modalEnumKeys.chatManageSecurity:
      return <ManageSecurityModal />;
    case modalEnumKeys.chooseInterests:
      return <ChooseInterests />;
    case modalEnumKeys.chooseCommunities:
      return <ChooseCommunities />;
    case modalEnumKeys.deleteProfile:
      return <DeleteProfile />
    case modalEnumKeys.default:
    default:
      return (
        <Div>
          <Text variant={'p'} color={'grey800'}>
            {data?.message}
          </Text>
          <Text variant={'span'} color={'grey200'}>
            {data?.subMessage}
          </Text>
        </Div>
      );
  }
};

export default ModalChildren;
