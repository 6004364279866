import { AppStoreState } from '../types/reducers';
import { GeneralAppReducerActions } from '../types/reducers/app';
import { IPages } from '../types/pages';

const appReducer = (
  state: AppStoreState,
  action: GeneralAppReducerActions,
): AppStoreState => {
  switch (action.type) {
    case 'setToken':
      state.app.token = action.payload;
      return state;
    case 'setModal':
      state.app.modalProps = action.payload;
      return state;
    case 'closeModal':
      state.app.modalProps.open = false;
      return state;
    case 'navigateForwardToRoute':
      const { currentPage, prevPage, windowPath } =
        action.payload;
      state.app.currentPage = currentPage as IPages;
      if (
        state.app.prevPages[state.app.prevPages.length - 1] !==
        prevPage
      ) {
        // this duplicates for some reason so only do it if the prevPage does not equal the last index of prevPages
        // this duplicates because its ran on both forward and back navigation.
        // example: navigate forward, add args to these two. navigate back, add args to these two, remove args from these two.
        // because navigateBack and navigateForward are ran simultaneously on the back arrow click.
        state.app.prevPages.push(prevPage as IPages);
        state.app.prevUrls.push(windowPath);
      }
      return state;
    case 'navigateBack':
      state.app.prevPages = state.app.prevPages.slice(
        0,
        state.app.prevPages.length - 1,
      );
      state.app.prevUrls = state.app.prevUrls.slice(
        0,
        state.app.prevUrls.length - 1,
      );
      state.app.currentPage = action.payload
        .currentPage as IPages;
      // state.app.prevPages.pop();
      // state.app.prevUrls.pop();
      return state;
    case 'openSideNav':
      state.app.sideBarOpen = true;
      return state;
    case 'closeSideNav':
      state.app.sideBarOpen = false;
      return state;
    case 'getSystemMessages':
      state.app.systemMessages = action.payload;
      return state;
    case 'setErrorMessage':
      return {
        ...state,
        app: {
          ...state.app,
          messages: {
            errorMessage: action.payload,
          },
        },
      };
    case 'setServerError':
      return {
        ...state,
        app: {
          ...state.app,
          messages: {
            serverError: action.payload,
          },
        },
      };
    case 'setSuccessMessage':
      return {
        ...state,
        app: {
          ...state.app,
          messages: {
            successMessage: action.payload,
          },
        },
      };
    case 'setWarnMessage':
      return {
        ...state,
        app: {
          ...state.app,
          messages: {
            warnMessage: action.payload,
          },
        },
      };
    case 'resetMessages':
      return {
        ...state,
        app: {
          ...state.app,
          messages: {},
        },
      };
    case 'setUserToReport': {
      // reset fields
      if (action.payload == null) {
        state.app.userToReport = {};
        return state;
      }
      if (action.payload.reportTypeUUID) {
        state.app.userToReport.reportTypeUUID =
          action.payload.reportTypeUUID;
      }
      if (action.payload.referenceUUID) {
        state.app.userToReport.referenceUUID =
          action.payload.referenceUUID;
      }
      if (action.payload.reportSubTypeUUID) {
        state.app.userToReport.reportSubTypeUUID =
          action.payload.reportSubTypeUUID;
      }
      if (action.payload.type) {
        state.app.userToReport.type = action.payload.type;
      }
      if (action.payload.profileUUID) {
        state.app.userToReport.profileUUID =
          action.payload.profileUUID;
      }
      return state;
    }
    case 'toggleTheme':
      if (state.app.themeMode === 'light') {
        state.app.themeMode = 'dark';
        return state;
      } else {
        state.app.themeMode = 'light';
        return state;
      }
    default:
      return state;
  }
};

export default appReducer;
