import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  ReplyToCommentMutation,
  ReplyToCommentMutationVariables,
} from "../../../__generated__/graphql";
import usePostComments from "./usePostComments";

const useReplyToComment = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "replyToComment";
  const postComments = usePostComments(state, dispatch);

  const handleOnCompleted = (data: ReplyToCommentMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.comment.replyToComment,
      },
    });
    postComments({ postUuid: data.post.comment.replyToComment.post_uuid });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [replyToComment] = useMutation(api.mutation.post.REPLY_TO_COMMENT, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: ReplyToCommentMutationVariables) => {
    if (!state.post.replyToComment.loading) {
      replyToComment({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useReplyToComment;
