import { FlexRow, Text } from "../../_ui";
import { School } from "../../../__generated__/graphql";

const RenderSchoolSelect = (school: School) => {
  return (
    <FlexRow column>
      <Text variant={"h5"} color={"grey800"}>
        {school?.name}
      </Text>
      <Text variant={"span"} color={"grey800"}>
        {school?.address} {school?.zip}
      </Text>
      <Text variant={"span"} color={"grey800"}>
        {school?.district}
      </Text>
    </FlexRow>
  );
};

export default RenderSchoolSelect;
