import { gql } from '../../../__generated__';

const CREATE_FAMILY_MEMBER = gql(`
  mutation CreateFamilyMember(
    $profile: createProfileProfileInputType!
    $communityUuids: [String]
    $interestUuids: [String]
    $permissions: updateProfilePermissionsInputType
    $familyMember: updateProfileFamilyMemberInputType
  ) {
    family {
      createFamilyMember(
        profile: $profile
        community_uuids: $communityUuids
        interest_uuids: $interestUuids
        permissions: $permissions
        family_member: $familyMember
      ) {
        uuid
        email
        nickname
        last_login
        logged_in
        family {
          uuid
          name
          zip
        }
        family_member {
          uuid
          last_name
          is_admin
        }
      }
    }
  }
`);

const CREATE_FAMILY = gql(`
  mutation CreateFamily(
    $lastName: String!
    $firstName: String!
    $dateOfBirth: String!
    $zip: String!
    $cityUuid: String!
    $stateUuid: String!
    $countryUuid: String!
    $genderUuid: String!
    $relationTypeUuid: String!
    $primaryProfileImage: Upload!
    $bio: String
    $secondaryProfileImage: Upload
    $tertiaryProfileImage: Upload
  ) {
    family {
      createFamily(
        lastName: $lastName
        firstName: $firstName
        dateOfBirth: $dateOfBirth
        zip: $zip
        cityUUID: $cityUuid
        stateUUID: $stateUuid
        countryUUID: $countryUuid
        genderUUID: $genderUuid
        relationTypeUUID: $relationTypeUuid
        primary_profile_image: $primaryProfileImage
        bio: $bio
        secondary_profile_image: $secondaryProfileImage
        tertiary_profile_image: $tertiaryProfileImage
      ) {
        uuid
        name
        bio
        timezone_uuid
        city_uuid
        state_uuid
        country_uuid
        primary_profile_image_url
        secondary_profile_image_url
        tertiary_profile_image_url
        family_member {
          uuid
          first_name
          last_name
          gender_uuid
          is_admin
          bio
          profile_image_url
          date_of_birth
          relation_type_uuid
        }
      }
    }
  }
`);

const UPDATE_FAMILY = gql(`
  mutation UpdateFamily(
    $uuid: String!
    $name: String
    $bio: String
    $zip: String
    $timezoneUuid: String
    $cityUuid: String
    $stateUuid: String
    $countryUuid: String
    $primaryProfileImage: Upload
    $secondaryProfileImage: Upload
    $tertiaryProfileImage: Upload
    $interestUuids: [String]
  ) {
    family {
      updateFamily(
        uuid: $uuid
        name: $name
        bio: $bio
        zip: $zip
        timezoneUUID: $timezoneUuid
        cityUUID: $cityUuid
        stateUUID: $stateUuid
        countryUUID: $countryUuid
        primary_profile_image: $primaryProfileImage
        secondary_profile_image: $secondaryProfileImage
        tertiary_profile_image: $tertiaryProfileImage
        interest_uuids: $interestUuids
      ) {
        uuid
        name
        bio
        timezone_uuid
        city_uuid
        state_uuid
        country_uuid
        primary_profile_image_url
        secondary_profile_image_url
        tertiary_profile_image_url
        family_member {
          uuid
          first_name
          last_name
          gender_uuid
          is_admin
          bio
          profile_image_url
          date_of_birth
          relation_type_uuid
        }
      }
    }
  }
`);

const SAVE_FAMILY_INTERESTS = gql(`
  mutation SaveFamilyInterests($interestUuids: [String]!) {
    family {
      saveFamilyInterests(interest_uuids: $interestUuids) {
        uuid
        tag_uuid
        family_uuid
        tag_icon
        tag_name
      }
    }
  }
`);

const family = {
  CREATE_FAMILY_MEMBER,
  CREATE_FAMILY,
  UPDATE_FAMILY,
  SAVE_FAMILY_INTERESTS,
};
export default family;
