import { CommonReducerActions } from '../types/reducers/common';
import { AppStoreState } from '../types/reducers';

const commonReducer = (
  state: AppStoreState,
  action: CommonReducerActions,
) => {
  switch (action.type) {
    case 'gender':
      state.common.gender = action.payload;
      return state;
    case 'likeType':
      state.common.likeType = action.payload;
      return state;
    case 'city':
      state.common.city = action.payload;
      return state;
    case 'country':
      state.common.country = action.payload;
      return state;
    case 'language':
      state.common.language = action.payload;
      return state;
    case 'timezone':
      state.common.timezone = action.payload;
      return state;
    case 'tag':
      state.common.tag = action.payload;
      return state;
    case 'state':
      state.common.state = action.payload;
      return state;
    case 'school':
      state.common.school = action.payload;
      return state;
    case 'schoolByUUID':
      state.common.schoolByUUID = action.payload;
      return state;
    case 'reportTypes':
      state.common.reportTypes = action.payload;
      return state;
    case 'reportUser':
      state.common.reportUser = action.payload;
      if (action.payload.success) {
        state.app.messages.successMessage =
          'Report has been logged and we will look into it.';
      }
      return state;
    case 'blockUser':
      state.common.blockUser.loading = action.payload.loading;
      state.common.blockUser.called = action.payload.called;
      if (action.payload.success) {
        state.app.messages.successMessage =
          'User has been blocked. manage blocked users in your settings.';
      }
      return state;
    case 'blockedUsersUUIDS':
      state.common.blockedUsersUUIDS = action.payload;
      return state;
    case 'blockedUsersProfiles':
      state.common.blockedUsersProfiles.loading =
        action.payload.loading;
      if (action.payload.data) {
        state.common.blockedUsersProfiles.data =
          action.payload.data || [];
        state.common.blockedUsersProfiles.limit =
          action.payload.limit;
        state.common.blockedUsersProfiles.offset =
          action.payload.offset;
        state.common.blockedUsersProfiles.total =
          action.payload.total;
      }
      return state;
    case 'unblockUser':
      state.common.unblockUser.loading = action.payload.loading;
  }
  return state;
};

export default commonReducer;
