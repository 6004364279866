import React from "react";
import { Text } from "../_ui";
import { Maybe, Scalars } from "../../__generated__/graphql";

const DateTime: React.FC<{
  date: Maybe<Scalars["String"]["output"]> | undefined;
}> = ({ date = "" }) => {
  return (
    <Text variant={"span"} color={"grey200"}>
      {new Date(Number(date)).toDateString()}
    </Text>
  );
};

export default DateTime;
