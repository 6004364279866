import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  AllCommunitiesQuery,
  AllCommunitiesQueryVariables,
  SortAndFilterBaseArgs,
} from "../../../__generated__/graphql";

const useAllCommunities = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "allCommunities";

  const handleOnCompleted = (data: AllCommunitiesQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.community.allCommunities,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [allCommunities] = useLazyQuery(api.query.community.ALL_COMMUNITIES, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: Partial<SortAndFilterBaseArgs>) => {
    if (!state.community.allCommunities.loading) {
      allCommunities({
        variables: { refine: args },
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useAllCommunities;
