import { Div } from '../../components/_ui';
import { useStore } from '../../hooks/useStore';
import CommunityForm, {
  CreateEditCommunityInputs,
} from '../../forms/community/CommunityForm';
import get from '../../utils/utilityFunctions/get';

const CreateCommunity = () => {
  const { actions, state } = useStore();

  const onSubmit = (formValues: CreateEditCommunityInputs) => {
    const communityImage = get(formValues, 'image[0]', null);
    if (!communityImage) {
      actions.app.setErrorMessage(
        'An image must be provided to create a community.',
      );
      return;
    }
    const createCommunityArgs = {
      ...formValues,
      image: get(formValues, 'image[0]', null),
    };
    actions.community.mutation.createCommunity(
      createCommunityArgs,
    );
  };

  return (
    <Div>
      <CommunityForm
        onSubmit={onSubmit}
        error={state.community.createCommunity.error}
        loading={
          state.community.createCommunity.loading || false
        }
      />
    </Div>
  );
};

export default CreateCommunity;
