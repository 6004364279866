export const TextOptionsEnum = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  p2: 'p2',
  span: 'span',
  span2: 'span2',
  inherit: 'inherit',
};

export type TextOptions =
  (typeof TextOptionsEnum)[keyof typeof TextOptionsEnum];

export const TextAlignmentEnum = {
  center: 'center',
  left: 'left',
  right: 'right',
};

export type TextAlignmentOptions =
  (typeof TextAlignmentEnum)[keyof typeof TextAlignmentEnum];
