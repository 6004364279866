import React from 'react';
import { Div } from './index';
import {
  colorsOptions,
  monoColorOptions,
} from '../../types/theme/colors';
import '../../styles/components/_ui/divider.scss';
import { useStore } from '../../hooks/useStore';

interface DividerProps {
  color?:
    | colorsOptions
    | monoColorOptions
    | 'white'
    | 'black'
    | 'transparent';
  variant?: 'solid' | 'dashed';
}

const Divider: React.FC<DividerProps> = ({
  color = 'grey100',
  variant = 'solid',
}) => {
  const { state } = useStore();
  return (
    <Div
      className={[
        'divider',
        color,
        variant,
        state.app.themeMode,
      ].join(' ')}
    />
  );
};

export default Divider;
