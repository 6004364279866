import { Button, Div, Icon } from '../_ui/';
import {
  faBell,
  faHouse,
  faPeopleRoof,
  faPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import routes from '../../navigation/routes';
import React from 'react';
import '../../styles/components/_common/navBar.scss';
import { AppPagesEnum } from '../../types/pages';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const NavBar = () => {
  const { state } = useStore();
  const navigate = useCustomNavigate();
  const params = useParams();
  const currentPage = state.app.currentPage;

  const feedIsActive = [
    AppPagesEnum.feed,
    AppPagesEnum.feedPost,
  ].includes(currentPage as AppPagesEnum);
  const familiesIsActive = [
    AppPagesEnum.familyProfile,
    AppPagesEnum.families,
  ].includes(currentPage as AppPagesEnum);
  const communitiesIsActive = [
    // AppPagesEnum.communityPost,
    AppPagesEnum.communities,
  ].includes(currentPage as AppPagesEnum);
  const notificationsIsActive = [
    AppPagesEnum.notifications,
    // AppPagesEnum.dependentNotifications,
  ].includes(currentPage as AppPagesEnum);

  const navigateToCreateByRoute = () => {
    switch (currentPage) {
      case AppPagesEnum.communities:
        navigate(routes.createCommunity);

        return;
      // case AppPagesEnum.communityPost:
      case AppPagesEnum.editCommunity:
      case AppPagesEnum.inviteToCommunity:
      case AppPagesEnum.community:
        if (params.communityId) {
          navigate<string>(routes.createCommunityPost, [
            params.communityId,
          ]);
        }
        return;
      case AppPagesEnum.chat:
        navigate(routes.newChat);
        return;
      default:
        navigate(routes.createFeedPost);
    }
  };

  const createIsDisabled = [
    AppPagesEnum.createCommunity,
    AppPagesEnum.createCommunityPost,
    AppPagesEnum.createFeedPost,
  ].includes(currentPage as AppPagesEnum);

  const hasNotification =
    state.notification.notificationByProfile;

  return (
    <Div className={`navBarContainer ${state.app.themeMode}`}>
      <Button
        color={feedIsActive ? 'primary' : 'grey800'}
        shape={'round'}
        variant={'transparent'}
        onClick={() => navigate(routes.feed)}
      >
        <Icon
          icon={faHouse}
          color={feedIsActive ? 'primary' : 'grey800'}
        />
      </Button>
      <Button
        color={familiesIsActive ? 'primary' : 'grey800'}
        shape={'round'}
        variant={'transparent'}
        onClick={() => navigate(routes.families)}
      >
        <Icon
          icon={faPeopleRoof}
          color={familiesIsActive ? 'primary' : 'grey800'}
        />
      </Button>
      <Button
        color={'grey800'}
        shape={'round'}
        variant={'contained'}
        disabled={createIsDisabled}
        onClick={navigateToCreateByRoute}
        className={'createNewButton'}
      >
        <Icon icon={faPlus} color={'grey800'} />
      </Button>
      <Button
        color={communitiesIsActive ? 'primary' : 'grey800'}
        shape={'round'}
        variant={'transparent'}
        onClick={() => navigate(routes.communities)}
      >
        <Icon
          icon={faUsers}
          color={communitiesIsActive ? 'primary' : 'grey800'}
        />
      </Button>
      <Button
        color={notificationsIsActive ? 'primary' : 'grey800'}
        shape={'round'}
        variant={'transparent'}
        onClick={() => navigate(routes.notifications)}
        flourish={!hasNotification.viewed}
        flourishLocationX={'right'}
        flourishLocationY={'top'}
      >
        <Icon
          icon={faBell}
          color={notificationsIsActive ? 'primary' : 'grey800'}
        />
      </Button>
    </Div>
  );
};

export default NavBar;
