import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  UpdatePostMutation,
  UpdatePostMutationVariables,
} from "../../../__generated__/graphql";

const useUpdatePost = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "createPost";

  const handleOnCompleted = (data: UpdatePostMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.post.updatePost,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [updatePost] = useMutation(api.mutation.post.UPDATE_POST, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    context: {
      headers: {
        "x-apollo-operation-name": "createPost",
      },
    },
  });

  return (args: UpdatePostMutationVariables) => {
    if (!state.post.updatePost.loading) {
      updatePost({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useUpdatePost;
