import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  StateQuery,
  StateQueryVariables,
} from "../../../__generated__/graphql";
import storage from "../../../utils/storage";

const useState = (state: AppStoreState, dispatch: Dispatch<ReducerActions>) => {
  const parent = "common";
  const type = "state";

  const handleOnCompleted = (data: StateQuery) => {
    storage.saveToStorage("state", data.common.state);
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.common.state,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [getStates] = useLazyQuery(api.query.common.STATE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: StateQueryVariables) => {
    if (!state.common.state.loading) {
      getStates({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useState;
