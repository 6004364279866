import { gql } from "../../../__generated__";

const COMMUNITIES = gql(`
  query Communities {
    community {
      communities {
        communities_joined {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          my_member_status
          is_member
          role
        }
        communities_near {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          my_member_status
          is_member
          role
        }
        communities_other {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          my_member_status
          is_member
          role
        }
      }
    }
  }
`);

const COMMUNITY = gql(`
  query Community($communityUuid: String!) {
    community {
      community(community_uuid: $communityUuid) {
        uuid
        name
        description
        zip
        image_url
        private
        member_count
        member_count_online
        my_member_status
        is_member
        role
      }
    }
  }
`);

const COMMUNITY_BY_FAMILY = gql(`
  query CommunityByFamily {
    community {
      communityByFamily {
        uuid
        name
        description
        zip
        image_url
        private
      }
    }
  }
`);

const SUGGESTED_COMMUNITIES_FOR_NEW_FAMILY_MEMBER = gql(`
  query SuggestCommunitiesForNewFamilyMember {
    community {
      suggestCommunitiesForNewFamilyMember {
        uuid
        name
        description
        zip
        image_url
        private
        member_count
        member_count_online
        my_member_status
        is_member
        role
      }
    }
  }
`);

const ALL_COMMUNITIES = gql(`
  query AllCommunities($refine: sortAndFilterBaseArgs) {
    community {
      allCommunities(refine: $refine) {
        data {
          uuid
          name
          description
          zip
          image_url
          private
          member_count
          member_count_online
          my_member_status
          is_member
          role
        }
        limit
        offset
        total
        phrase
      }
    }
  }
`);

const community = {
  COMMUNITIES,
  COMMUNITY,
  COMMUNITY_BY_FAMILY,
  SUGGESTED_COMMUNITIES_FOR_NEW_FAMILY_MEMBER,
  ALL_COMMUNITIES,
};

export default community;
