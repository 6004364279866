import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  MyPostsQuery,
  MyPostsQueryVariables,
} from "../../../__generated__/graphql";

const useMyPosts = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "myPosts";

  const handleOnCompleted = (data: MyPostsQuery) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.post.myPosts,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [myPosts] = useLazyQuery(api.query.post.MY_POSTS, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    fetchPolicy: "no-cache",
  });

  return (args: MyPostsQueryVariables) => {
    if (!state.post.myPosts.loading) {
      myPosts({
        variables: args,
      });
      dispatch({
        parent,
        type,
        payload: {
          loading: true,
        },
      });
    }
  };
};

export default useMyPosts;
