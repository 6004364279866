import React, { SyntheticEvent } from 'react';
import { FlexRow, Text, Div } from '../_ui';
import Avatar, { AvatarProps } from './Avatar';
import '../../styles/components/_common/avatarAndName.scss';
import {
  TextOptions,
  TextOptionsEnum,
} from '../../types/theme/typography';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import routes from '../../navigation/routes';

//TODO make name required and fix usages
interface AvatarAndNameProps extends AvatarProps {
  name?: string;
  textVariant?: TextOptions;
  familyMemberUUID?: string | null;
  navigateToProfile?: boolean;
}

const AvatarAndName: React.FC<AvatarAndNameProps> = ({
  name = 'N/A',
  textVariant = TextOptionsEnum.span,
  familyMemberUUID,
  navigateToProfile = false,
  ...avatarProps
}) => {
  const navigate = useCustomNavigate();

  const handleNavigate = (e: SyntheticEvent) => {
    if (navigateToProfile) {
      e.stopPropagation();
      navigate(routes.viewPublicProfile, [familyMemberUUID]);
    }
  };

  return (
    <FlexRow inline className={'avatarAndName'}>
      <Avatar {...avatarProps} alt={name} />
      <Div onClick={handleNavigate}>
        <Text
          color={'grey400'}
          variant={textVariant as TextOptions}
        >
          {name}
        </Text>
      </Div>
    </FlexRow>
  );
};

export default AvatarAndName;
