import { Card, FlexRow, Loader } from '../../components/_ui';
import React, { useEffect } from 'react';
import '../../styles/pages/profile.scss';
import { useStore } from '../../hooks/useStore';
import routes from '../../navigation/routes';
import { MyFamilySelection } from '../../components/_common';
import {
  FamilyMemberProfile,
  FamilyProfile,
} from '../../components/profile';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const Profile = () => {
  const navigate = useCustomNavigate();
  const { state, actions } = useStore();

  useEffect(() => {
    actions.profile.query.familyProfile({});
    // set initial profile here to MY profile.
  }, []);

  useEffect(() => {
    if (!state.profile.familyProfile.loading) {
      actions.profile.setCurrentProfile(
        state.profile.familyProfile.data,
      );
      if (state.profile.familyProfile.data)
        actions.profile.setFamilyMemberOrFamilyUUID(
          state.profile.familyProfile.data.family_member.uuid,
        );
    }
  }, [state.profile.familyProfile]);

  useEffect(() => {
    if (state.profile.familyProfile.error) {
      actions.app.setErrorMessage(
        state.profile.familyProfile.error,
      );
      navigate(routes.feed);
    }
  }, [state.profile.familyProfile.error]);

  if (state.profile.familyProfile.loading) {
    return (
      <Loader
        position={'center'}
        color={'white'}
        variant={'overlay'}
      />
    );
  }

  const myProfile =
    state.profile.familyProfile.data?.family_member;

  const familyMember =
    state.profile.selectedProfile?.family_member;
  const communities =
    state.profile.selectedProfile?.my_communities || [];
  const interests =
    state.profile.selectedProfile?.my_interests || [];

  return (
    <FlexRow column inline className={'fullWidth'}>
      {myProfile?.is_admin && <MyFamilySelection />}
      {state.profile.familyMemberProfile.loading && (
        <Loader variant={'overlay'} color={'white'} />
      )}
      <Card className="profile">
        {state.profile.editFamily ? (
          <FamilyProfile />
        ) : (
          <FamilyMemberProfile
            familyMember={familyMember}
            communities={communities}
            interests={interests}
            doesOwn
          />
        )}
      </Card>
    </FlexRow>
  );
};

export default Profile;
