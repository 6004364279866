import { Div, FlexRow, Icon, Text } from '../_ui';
import {
  faBell,
  faCalendar,
  faComment,
  faFaceFrown,
  faFaceSmile,
  faFlag,
  faHeart,
  faReply,
} from '@fortawesome/free-solid-svg-icons';
import React, { SyntheticEvent } from 'react';

export interface EmojiActionRowProps {
  handleClickCalendar?: () => void;
  handleClickAlert?: () => void;
  handleClickComment?: () => void;
  handleClickLike?: () => void;
  handleClickReply?: () => void;
  handleClickApprove?: () => void;
  handleClickDisapprove?: () => void;
  handleReportContent: () => void;
}

const EmojiActionRow: React.FC<EmojiActionRowProps> = ({
  handleClickCalendar,
  handleClickAlert,
  handleClickComment,
  handleClickLike,
  handleClickReply,
  handleClickApprove,
  handleClickDisapprove,
  handleReportContent,
}) => {
  const calendarClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickCalendar');
    handleClickCalendar?.();
  };

  const alertClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickAlert');
    handleClickAlert?.();
  };
  const commentClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickComment');
    handleClickComment?.();
  };
  const likeClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickLike');
    handleClickLike?.();
  };

  const replyClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('handleClickReply');
    handleClickReply?.();
  };

  const approveClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('approveClick');
    handleClickApprove?.();
  };
  const disapproveClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('disapproveClick');
    handleClickDisapprove?.();
  };

  const reportContent = (e: SyntheticEvent) => {
    e.stopPropagation();
    handleReportContent();
  };

  return (
    <FlexRow inline className={'emojiActionRow'}>
      {handleClickCalendar && (
        <Div onClick={calendarClick}>
          <Text color={'quaternary'}>
            <Icon icon={faCalendar} />
          </Text>
        </Div>
      )}
      {handleClickAlert && (
        <Div onClick={alertClick}>
          <Text color={'tertiary'}>
            <Icon icon={faBell} />
          </Text>
        </Div>
      )}
      {handleClickComment && (
        <Div onClick={commentClick}>
          <Text color={'secondary'}>
            <Icon icon={faComment} />
          </Text>
        </Div>
      )}
      {handleClickReply && (
        <Div onClick={replyClick}>
          <Text color={'secondary'}>
            <Icon icon={faReply} />
          </Text>
        </Div>
      )}
      {handleClickLike && (
        <Div onClick={likeClick}>
          <Text color={'primary'}>
            <Icon icon={faHeart} />
          </Text>
        </Div>
      )}
      {handleClickApprove && (
        <Div onClick={approveClick}>
          <Text color={'tertiary'}>
            <Icon icon={faFaceSmile} />
          </Text>
        </Div>
      )}
      {handleClickDisapprove && (
        <Div onClick={disapproveClick}>
          <Text color={'primary'}>
            <Icon icon={faFaceFrown} />
          </Text>
        </Div>
      )}
      <Div onClick={reportContent}>
        <Text color={'error'}>
          <Icon icon={faFlag} />
        </Text>
      </Div>
    </FlexRow>
  );
};

export default EmojiActionRow;
