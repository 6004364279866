import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from '../pages/SignIn';
import CreateProfile from '../pages/CreateProfile';
import routes from './routes';
import { CreatePost, EditPost, Feed, Post } from '../pages/feed';
import AuthenticatedRoute from './AuthenticatedRoute';
import Providers from '../providers';
import { PageLayout } from '../components/_common';
import { Notifications } from '../pages/notifications';
import {
  Community,
  CommunityByCategory,
  CreateCommunity,
  CreateCommunityPost,
  EditCommunity,
  EditCommunityPost,
  InviteToCommunity,
  ViewAllCommunities,
  ViewCommunities,
} from '../pages/communities';
import {
  MeetFamilies,
  MyFamilies,
  ReceivedRequests,
  SentRequests,
  ViewConnectionProfile,
} from '../pages/families';
import { Chat, Chats, NewChat } from '../pages/chat';
import {
  AddFamilyMember,
  EditFamilyProfile,
  EditProfile,
  Profile,
  PublicProfile,
} from '../pages/profile';
import NotFound from './NotFound';
import { ManageBlockedUsers, Settings } from '../pages/settings';

// potential page transition options
// https://dev.to/fazliddin04/react-router-v6-animated-transitions-diy-3e6l
// https://stackoverflow.com/questions/70646421/how-to-listen-for-route-change-in-react-router-dom-v6
// https://stackoverflow.com/questions/74351136/nested-child-route-transitions-in-react-router-dom-6-4-3-using-framer-motion/74352029#74352029
// probably easiest to roll our own to handle this stuffs using history library.

const Router = () => {
  return (
    <BrowserRouter>
      {/*<AnimatedRoutes>*/}
      <Routes>
        <Route element={<Providers />} path={routes.home.url}>
          <Route path={routes.signIn.url} element={<SignIn />} />
          <Route
            path={routes.createProfile.url}
            element={<CreateProfile />}
          />
          <Route element={<AuthenticatedRoute />}>
            <Route element={<PageLayout />}>
              <Route path={routes.feed.url}>
                <Route index element={<Feed />} />
                <Route
                  path={routes.feedPost.url}
                  element={<Post />}
                />
                <Route
                  path={routes.createFeedPost.url}
                  element={<CreatePost />}
                />
                <Route
                  path={routes.editFeedPost.url}
                  element={<EditPost />}
                />
              </Route>
              <Route path={routes.notifications.url}>
                <Route index element={<Notifications />} />
              </Route>
              <Route path={routes.communities.url}>
                <Route index element={<ViewCommunities />} />
                <Route
                  element={<ViewAllCommunities />}
                  path={routes.allCommunities.url}
                />
                <Route
                  element={<Community />}
                  path={routes.community.url}
                />
                <Route
                  path={routes.communityPost.url}
                  element={<Post />}
                />
                <Route
                  element={<CommunityByCategory />}
                  path={routes.allCommunities!.url}
                />
                <Route
                  element={<CreateCommunity />}
                  path={routes.createCommunity.url}
                />
                <Route
                  element={<EditCommunity />}
                  path={routes.editCommunity.url}
                />
                <Route
                  element={<CreateCommunityPost />}
                  path={routes.createCommunityPost.url}
                />
                <Route
                  element={<EditCommunityPost />}
                  path={routes.editCommunityPost.url}
                />
                <Route
                  element={<InviteToCommunity />}
                  path={routes.inviteToCommunity.url}
                />
              </Route>
              <Route path={routes.families.url}>
                <Route index element={<MyFamilies />} />
                <Route
                  path={routes.meetFamilies.url}
                  element={<MeetFamilies />}
                />
                <Route
                  path={routes.sentRequests.url}
                  element={<SentRequests />}
                />
                <Route
                  path={routes.receivedRequests.url}
                  element={<ReceivedRequests />}
                />
                <Route
                  path={routes.familyProfile!.url}
                  element={<ViewConnectionProfile />}
                />
              </Route>
              <Route path={routes.chats.url}>
                <Route index element={<Chats />} />
                <Route
                  path={routes.chat.url}
                  element={<Chat />}
                />
                <Route
                  path={routes.newChat.url}
                  element={<NewChat />}
                />
              </Route>
              <Route path={routes.profile.url}>
                <Route index element={<Profile />} />
                <Route
                  path={routes.editProfile.url}
                  element={<EditProfile />}
                />
                <Route
                  path={routes.editFamilyProfile.url}
                  element={<EditFamilyProfile />}
                />
                <Route
                  path={routes.addFamilyMember.url}
                  element={<AddFamilyMember />}
                />
              </Route>
              <Route
                path={routes.viewPublicProfile.url}
                element={<PublicProfile />}
              />
              <Route path={routes.settings.url}>
                <Route element={<Settings />} index />
                <Route
                  path={routes.blockedUsers.url}
                  element={<ManageBlockedUsers />}
                />
              </Route>
              <Route path={'*'} element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      {/*</AnimatedRoutes>*/}
    </BrowserRouter>
  );
};

export default Router;
