import {
  AppStoreActions,
  AppStoreState,
  BaseState,
} from '../../types/reducers';
import { generalAppState, generalAppStoreActions } from './app';
import { commonActions, commonState } from './common';
import { communityActions, communityState } from './community';
import {
  connectionActions,
  connectionState,
} from './connection';
import { familyActions, familyState } from './family';
import {
  notificationActions,
  notificationState,
} from './notification';
import { postActions, postState } from './post';
import { profileActions, profileState } from './profile';
import { tagActions, tagState } from './tag';
import { chatActions, chatState } from './chat';
import React, {
  createContext,
  useEffect,
  useReducer,
} from 'react';
import appReducer from '../../reducers';
import appActions from '../../actions';

import storage from '../../utils/storage';

const defaultAppState: AppStoreState = {
  app: generalAppState,
  common: commonState,
  community: communityState,
  connection: connectionState,
  family: familyState,
  notification: notificationState,
  post: postState,
  profile: profileState,
  tag: tagState,
  chat: chatState,
};

const defaultAppActions: AppStoreActions = {
  app: generalAppStoreActions,
  common: commonActions,
  community: communityActions,
  connection: connectionActions,
  family: familyActions,
  notification: notificationActions,
  post: postActions,
  profile: profileActions,
  tag: tagActions,
  chat: chatActions,
};

export const defaultState: BaseState = {
  dispatch: () => null,
  state: defaultAppState,
  actions: defaultAppActions,
};

export const StoreContext = createContext(defaultState);

const StoreProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state = {} as AppStoreState, dispatch] = useReducer(
    appReducer,
    defaultState.state,
  );

  const ctx: BaseState = {
    state,
    actions: appActions(state, dispatch),
    dispatch,
  };

  useEffect(() => {
    if (state.app.token) {
      ctx.actions.app.initialize(ctx.actions);
    } else {
      storage.getFromStorage('token').then((token) => {
        // TODO check token for viability here...
        if (token) ctx.actions.app.setToken(token);
      });
    }
  }, [state.app.token]);

  return (
    <StoreContext.Provider value={ctx}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
