import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import api from '../../../api';
import { BlockedUsersProfilesQuery } from '../../../__generated__/graphql';

const useBlockedUsersProfiles = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'common';
  const type = 'blockedUsersProfiles';

  const handleOnCompleted = (
    data: BlockedUsersProfilesQuery,
  ) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.common.blockedUsersProfiles,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [blockedUsersProfiles] = useLazyQuery(
    api.query.common.BLOCKED_USERS_PROFILES,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: 'no-cache',
    },
  );

  return () => {
    blockedUsersProfiles();
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useBlockedUsersProfiles;
