import {
  ModalProps,
  UserToReportArgs,
} from '../../types/reducers/app';
import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useNavigateForwardToRoute from './useNavigateForwardToRoute';
import useInitializeApplication from './useInitializeApplication';
import { AppPagesEnum } from '../../types/pages';
import useSwitchTheme from './useSwitchTheme';

const useAppBaseActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  return {
    initialize: useInitializeApplication(state, dispatch),
    navigateForwardToRoute: useNavigateForwardToRoute(
      state,
      dispatch,
    ),
    navigateBack: (path: AppPagesEnum) =>
      dispatch({
        parent: 'app',
        type: 'navigateBack',
        payload: {
          currentPage: path,
        },
      }),
    setToken: (token: string) =>
      dispatch({
        parent: 'app',
        type: 'setToken',
        payload: token,
      }),
    openSideNav: () => {
      dispatch({
        parent: 'app',
        type: 'openSideNav',
        payload: {
          sideBarOpen: true,
        },
      });
    },
    closeSideNav: () => {
      dispatch({
        parent: 'app',
        type: 'closeSideNav',
        payload: {
          sideBarOpen: false,
        },
      });
    },
    toggleTheme: useSwitchTheme(state, dispatch),
    setModal: (modalProps: ModalProps) => {
      dispatch({
        parent: 'app',
        type: 'setModal',
        payload: modalProps,
      });
    },
    closeModal: () => {
      dispatch({
        parent: 'app',
        type: 'closeModal',
      });
    },
    setServerError: (msg: string) => {
      dispatch({
        parent: 'app',
        type: 'setServerError',
        payload: msg,
      });
    },
    setErrorMessage: (msg: string) => {
      dispatch({
        parent: 'app',
        type: 'setErrorMessage',
        payload: msg,
      });
    },
    setWarnMessage: (msg: string) => {
      dispatch({
        parent: 'app',
        type: 'setWarnMessage',
        payload: msg,
      });
    },
    setSuccessMessage: (msg: string) => {
      dispatch({
        parent: 'app',
        type: 'setSuccessMessage',
        payload: msg,
      });
    },
    resetMessages: () => {
      dispatch({
        parent: 'app',
        type: 'resetMessages',
      });
    },
    setUserToReport: (args: UserToReportArgs | null) =>
      dispatch({
        parent: 'app',
        type: 'setUserToReport',
        payload: args,
      }),
  };
};

export default useAppBaseActions;
