import { ChatStoreActions } from '../../types/reducers/chat/chatActions';
import {
  AppStoreState,
  ReducerActions,
} from '../../types/reducers';
import { Dispatch } from 'react';
import useChats from './api/useChats';
import useChatTranscript from './api/useChatTranscript';
import useDeleteMessage from './api/useDeleteMessage';
import useSendChat from './api/useSendChat';
import useChatConnections from './api/useChatConnections';

export const useChatActions = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
): ChatStoreActions => {
  return {
    clearChatTranscript: () =>
      dispatch({
        parent: 'chat',
        type: 'clearChatTranscript',
      }),
    query: {
      chatTranscript: useChatTranscript(state, dispatch),
      chatConnections: useChatConnections(state, dispatch),
    },
    mutation: {
      deleteMessage: useDeleteMessage(state, dispatch),
      sendChat: useSendChat(state, dispatch),
    },
  };
};

export default useChatActions;
