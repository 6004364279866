import { StatusBar, Style } from '@capacitor/status-bar';
import { PlatformType } from '../types/mobile';
import storage from '../utils/storage';

const setupStatusBar = async (platform: PlatformType) => {
  try {
    const themeMode = await storage.getFromStorage('themeMode');
    const statusBarStyle =
      themeMode === 'dark' ? Style.Dark : Style.Light;
    // Set the status bar style
    await StatusBar.setStyle({
      style: statusBarStyle,
    });

    if (platform === 'android') {
      // Set the background color
      await StatusBar.setBackgroundColor({ color: '#ffffff' });
    }
  } catch (error) {
    console.warn(
      'StatusBar plugin not implemented for this platform',
      error,
    );
  }
};

export default setupStatusBar;
