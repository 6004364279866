import MessageProvider from './MessageProvider';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { Modal } from '../components/_ui';
import AuthenticationProvider from './AuthenticationProvider';
import StoreProvider from './storeProvider';

const Providers = () => {
  return (
    <StoreProvider>
      <MessageProvider>
        <AuthenticationProvider>
          {/*<AnimatedRoutes>*/}
          <Outlet />
          {/*</AnimatedRoutes>*/}
        </AuthenticationProvider>
      </MessageProvider>
      <Modal />
    </StoreProvider>
  );
};

export default Providers;
