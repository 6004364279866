import {
  Control,
  Controller,
  FieldValues,
  Path,
} from 'react-hook-form';
import { Button, Div, Icon, Input } from '../_ui';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

type UploadImageProps<T extends FieldValues> = {
  control: Control<T>;
  setFile: (args: string | ArrayBuffer | null) => void;
  name: Path<T>;
  large?: boolean;
};

const UploadImage = <T extends FieldValues>({
  control,
  setFile,
  name,
  large = false,
}: UploadImageProps<T>) => {
  return (
    <Div
      className={[
        'chooseFileContainer',
        large && 'fullWidth',
      ].join(' ')}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Input
            className={'hiddenFileInput'}
            type={'file'}
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = () => {
                  setFile(reader.result);
                  field.onChange(e.target.files);
                };
              }
            }}
          />
        )}
      />
      {large ? (
        <Button
          fullWidth
          variant={'contained'}
          color={'primary'}
          size={'md'}
        >
          Upload Image
        </Button>
      ) : (
        <Icon
          icon={faCamera}
          height={35}
          width={35}
          color={'primary'}
        />
      )}
    </Div>
  );
};

export default UploadImage;
