import { FlexRow, Image, Loader, Pill } from "../_ui";
import { useStore } from "../../hooks/useStore";
import { CommunityObjectType, Maybe } from "../../__generated__/graphql";
import { SyntheticEvent } from "react";

const ChooseCommunities = () => {
  const { state, actions } = useStore();

  const chosenCommunityIds = (state.profile.chosenCommunities || []).map(
    (c) => c?.uuid,
  );

  const handleManageCommunity =
    (community: Maybe<CommunityObjectType>) => (e: SyntheticEvent) => {
      e.stopPropagation();
      if (!community) {
        return null;
      }
      if (chosenCommunityIds.indexOf(community?.uuid) > -1) {
        actions.profile.removeCommunity(community);
      } else {
        actions.profile.addCommunity(community);
      }
    };

  return (
    <FlexRow wrap spreadX={false}>
      {state.community.suggestedCommunitiesForNewFamilyMember.loading && (
        <Loader position={"center"} />
      )}
      {(state.community.suggestedCommunitiesForNewFamilyMember.data || []).map(
        (comm) => (
          <Pill
            onClick={handleManageCommunity(comm)}
            title={comm?.name}
            key={comm?.uuid}
            variant={"outlined"}
            color={"primary"}
            active={chosenCommunityIds.indexOf(comm?.uuid) > -1}
            imageStart={
              <Image
                src={
                  comm?.image_url || "/defaultImages/defaultCommunityImage.jpg"
                }
                alt={comm?.name || ""}
                height={30}
                width={30}
                withBorderRadius
              />
            }
          />
        ),
      )}
    </FlexRow>
  );
};

export default ChooseCommunities;
