import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch, useEffect } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import api from "../../../api";
import {
  CustomSortAndFilterBase_PostFeed,
  PostFeedQuery,
} from "../../../__generated__/graphql";

const usePostFeed = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "post";
  const type = "postFeed";

  const handleOnCompleted = (data: PostFeedQuery) => {
    if (data.post.postFeed.offset === data.post.postFeed.limit) {
      dispatch({
        parent,
        type,
        payload: {
          loading: false,
          ...data.post.postFeed,
        },
      });
    } else {
      // emit separate action for lazy loading
      dispatch({
        parent,
        type: "addPostsToExistingFeed",
        payload: {
          loading: false,
          ...data.post.postFeed,
        },
      });
    }
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [postFeed, { called, loading }] = useLazyQuery(
    api.query.post.POST_FEED,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    // This is used to help with lazy loading inside useFeedFetcher.
    dispatch({
      parent: "post",
      type: "postFeedAPIParams",
      payload: {
        called,
        loading,
      },
    });
  }, [called, loading]);

  return (args: CustomSortAndFilterBase_PostFeed) => {
    if (
      !state.post.postFeed.loading &&
      args.offset != null &&
      args.limit != null
    ) {
      postFeed({
        variables: {
          refine: args,
        },
      });
      dispatch({
        parent,
        type,
        payload: {
          loading: true,
        },
      });
    }
  };
};

export default usePostFeed;
