import {
  Button,
  Div,
  Divider,
  FlexRow,
  Input,
  Link,
  Loader,
  Switch,
  Text,
} from '../components/_ui';
import React from 'react';
import '../styles/pages/createProfile.scss';
import { useStore } from '../hooks/useStore';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import routes from '../navigation/routes';

type CreateProfileInputs = {
  email: string;
  last_name: string;
  zip: string;
  password: string;
  nickname: string;
  phone_number: string;
  agreeToTermsOfService: boolean;
};

// TODO need to require a relation type on this. in order for family cards to work correctly.
const CreateProfile = () => {
  const { actions, state } = useStore();
  const { register, handleSubmit, formState, control } =
    useForm<CreateProfileInputs>();
  const { errors, isValid } = formState;

  const handleCreateProfile: SubmitHandler<
    CreateProfileInputs
  > = async (formValues) => {
    actions.profile.mutation.createNewProfile({
      email: formValues.email,
      lastName: formValues.last_name,
      zip: formValues.zip,
      password: formValues.password,
      nickname: formValues.nickname,
      phone: formValues.phone_number,
    });
  };

  return (
    <FlexRow
      column
      centerX
      centerY
      inline
      className={'createProfileContainer'}
    >
      <Div className={'createProfileHeader'}>
        <Text variant={'h3'} color={'grey800'}>
          Create your family profile
        </Text>
        <Divider color={'transparent'} />
        <Text variant={'p'} color={'grey400'}>
          Create your profile to share updates, organize events,
          and celebrate special moments together. Join now and
          strengthen your family bonds!
        </Text>
      </Div>
      <Div className={'createProfileCard'}>
        <Div>
          <Input
            textSize={'md'}
            type={'email'}
            label={'email'}
            error={errors.email?.message}
            {...register('email', {
              required: 'Email is required',
            })}
          />
          <Input
            textSize={'md'}
            type={'password'}
            label={'password'}
            error={errors.email?.message}
            {...register('password', {
              required: 'Password is required',
            })}
          />
          <Input
            textSize={'md'}
            type={'text'}
            label={'nickname'}
            error={errors.email?.message}
            {...register('nickname', {
              required: 'Nickname is required',
            })}
          />
          <Input
            textSize={'md'}
            type={'text'}
            label={'last name'}
            error={errors.last_name?.message}
            {...register('last_name', {
              required: 'Last name is required',
            })}
          />
          <FlexRow>
            <Input
              textSize={'md'}
              required={true}
              type={'tel'}
              label={'phone number'}
              error={errors.phone_number?.message}
              {...register('phone_number', {
                required: 'Phone number is required',
              })}
            />
            <Input
              textSize={'md'}
              type={'text'}
              required={true}
              label={'zip code'}
              error={errors.zip?.message}
              {...register('zip', {
                required: 'Zip is required',
              })}
            />
          </FlexRow>
          <FlexRow column inline centerY centerX>
            <Divider color={'transparent'} />
            <Divider color={'transparent'} />
            <Text
              variant={'p'}
              color={'grey400'}
              align={'center'}
            >
              By checking this box, you agree to the{' '}
              <Link
                to={`${process.env.REACT_APP_EULA_AGREEMENT}`}
              >
                {' '}
                End-User License Agreement (EULA).
              </Link>
            </Text>
            <Divider color={'transparent'} />
            <Controller
              name="agreeToTermsOfService"
              control={control}
              rules={{
                required:
                  'You must agree to the End-User License Agreement (EULA) to create an account',
              }}
              render={({ field, ...rest }) => (
                <Switch
                  containerClassName={'flexRow'}
                  label={'Agree'}
                  {...field}
                  value={!!field.value}
                />
              )}
            />
          </FlexRow>
        </Div>
        {state.profile.createNewProfile.error && (
          <Div>
            <Text color={'primary'} variant={'h6'}>
              {state.profile.createNewProfile.error}
            </Text>
          </Div>
        )}
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        <Divider color={'transparent'} />
        <FlexRow
          className={'createProfileButtonRow'}
          centerX
          centerY
          start={false}
        >
          <Button
            color={'primary'}
            variant={'transparent'}
            routeTo={routes.signIn}
          >
            Back
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={
              state.profile.createNewProfile.loading || !isValid
            }
            onClick={handleSubmit(handleCreateProfile)}
          >
            {state.profile.createNewProfile.loading && (
              <Loader />
            )}{' '}
            Create Profile
          </Button>
        </FlexRow>
      </Div>
    </FlexRow>
  );
};

export default CreateProfile;
