import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  GenerateImageMutation,
  GenerateImageMutationVariables,
} from "../../../__generated__/graphql";

const useGenerateImage = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "common";
  const type = "generateImage";

  const handleOnCompleted = (data: GenerateImageMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.common.generateImage,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [generateImage] = useMutation(api.mutation.common.GENERATE_IMAGE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: GenerateImageMutationVariables) => {
    if (!state.common.generateImage.loading) {
      generateImage({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useGenerateImage;
