import {
  AppStoreState,
  ReducerActions,
} from '../../../types/reducers';
import { Dispatch } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import api from '../../../api';
import {
  UpdateFamilyMutation,
  UpdateFamilyMutationVariables,
} from '../../../__generated__/graphql';
import useFamilyProfile from '../../profile/api/useFamilyProfile';

const useCreateFamilyMember = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = 'family';
  const type = 'updateFamily';
  const familyProfile = useFamilyProfile(state, dispatch);

  const handleOnCompleted = (data: UpdateFamilyMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        ...data.family.updateFamily,
      },
    });
    familyProfile({});
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [updateFamily] = useMutation(
    api.mutation.family.UPDATE_FAMILY,
    {
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      context: {
        headers: {
          'x-apollo-operation-name': 'updateFamily',
        },
      },
    },
  );

  return (args: UpdateFamilyMutationVariables) => {
    if (!state.family.updateFamily.loading) {
      updateFamily({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
      },
    });
  };
};

export default useCreateFamilyMember;
