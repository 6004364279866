import { AppStoreState } from '../types/reducers';
import { NotificationReducerActions } from '../types/reducers/notification';
import { NotificationStatusEnumType } from '../__generated__/graphql';

const notificationReducer = (
  state: AppStoreState,
  action: NotificationReducerActions,
) => {
  const notifState = state.notification.notificationByProfile;
  switch (action.type) {
    case 'notificationByProfile':
      notifState.loading = action.payload.loading || false;
      // append new notifications
      const existingNotifications = notifState.data || [];
      const notifications = new Set([
        ...(action.payload.data || []),
        ...existingNotifications,
      ]);

      notifState.data = [...notifications]
        .reduce((acc, current) => {
          const isDuplicate = acc.some(
            (item) =>
              // @ts-ignore
              item?.notification_content.uuid ===
              // @ts-ignore
              current.notification_content.uuid,
          );
          if (!isDuplicate) {
            // @ts-ignore
            acc.push(current);
          }
          return acc;
        }, [])
        .sort((a, b) =>
          // @ts-ignore
          a.notification_content.created_at >
          // @ts-ignore
          b.notification_content.created_at
            ? -1
            : 1,
        );
      if (
        action.payload.data &&
        action.payload.data.length > 0
      ) {
        const newNotifications = action.payload.data.filter(
          (n) =>
            n &&
            n.notification_content &&
            n.notification_content.status ===
              NotificationStatusEnumType.New,
        );
        if (newNotifications.length > 0) {
          notifState.viewed = false;
        }
      }
      if (action.payload.error) {
        notifState.error = action.payload.error;
      }
      if (action.payload.total)
        notifState.total = action.payload.total;
      if (action.payload.limit)
        notifState.limit = action.payload.limit;
      if (action.payload.offset)
        notifState.offset = action.payload.offset;
      if (action.payload.called)
        notifState.called = action.payload.called;
      return state;
    case 'viewNotifications':
      notifState.viewed = action.payload.viewed;
      notifState.data = (notifState.data || []).map(
        (n) =>
          n && {
            ...n,
            notification_content: {
              ...n.notification_content,
              notification_status:
                NotificationStatusEnumType.Read,
            },
          },
      );
      return state;
    default:
      return state;
  }
};

export default notificationReducer;
