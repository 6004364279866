import { AppStoreState, ReducerActions } from "../../../types/reducers";
import { Dispatch } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import api from "../../../api";
import {
  JoinCommunityMutation,
  JoinCommunityMutationVariables,
} from "../../../__generated__/graphql";

const useJoinCommunity = (
  state: AppStoreState,
  dispatch: Dispatch<ReducerActions>,
) => {
  const parent = "community";
  const type = "joinCommunity";

  const handleOnCompleted = (data: JoinCommunityMutation) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        data: data.community.joinCommunity,
      },
    });
  };
  const handleOnError = (error: ApolloError) => {
    dispatch({
      parent,
      type,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  };

  const [joinCommunity] = useMutation(api.mutation.community.JOIN_COMMUNITY, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  });

  return (args: JoinCommunityMutationVariables) => {
    if (!state.community.joinCommunity.loading) {
      joinCommunity({
        variables: args,
      });
    }
    dispatch({
      parent,
      type,
      payload: {
        loading: true,
        joiningCommunityUUID: args.communityUuid,
      },
    });
  };
};

export default useJoinCommunity;
