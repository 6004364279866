import { AppPagesEnum } from '../../types/pages';
import { Capacitor } from '@capacitor/core';
import {
  GeneralAppState,
  GeneralAppStoreActions,
} from '../../types/reducers/app';

export const generalAppState: GeneralAppState = {
  currentPage: AppPagesEnum.feed,
  prevPages: [],
  prevUrls: [],
  sideBarOpen: false,
  themeMode: 'light',
  profile: null,
  systemMessages: null,
  token: null,
  modalProps: {
    open: false,
    successButtonText: 'Ok',
    cancelButtonText: 'Cancel',
    title: null,
    handleClose: null,
    handleSuccess: null,
  },
  messages: {
    serverError: null,
    errorMessage: null,
    warnMessage: null,
    successMessage: null,
  },
  mobile: {
    isMobileWidth: window.innerWidth <= 768,
    isNative: Capacitor.isNativePlatform(),
    platform: Capacitor.getPlatform(),
  },
  userToReport: {},
};

export const generalAppStoreActions: GeneralAppStoreActions = {
  setToken: () => null,
  navigateForwardToRoute: () => null,
  navigateBack: () => null,
  initialize: async () => null,
  openSideNav: () => null,
  closeSideNav: () => null,
  toggleTheme: () => null,
  setModal: () => null,
  closeModal: () => null,
  setServerError: () => null,
  setErrorMessage: () => null,
  setWarnMessage: () => null,
  setSuccessMessage: () => null,
  resetMessages: () => null,
  setUserToReport: () => null,
};
